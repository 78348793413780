var myObjectPath = require("object-path");
import SingleStore from './SingleStore';

import { OBJECT_TYPE, AUTH } from '../../../SharedLibs/Constants.js'

import {
    sMaster
} from './SyncMaster.js';

import { accountStore } from './AccountStore'


class ConfigStore extends SingleStore {
    constructor() {
        super();

        // --- Set the debug name --
        this.deb.setNames('ConfigStore', 'config');

        this.storeName = 'config';
        this.clientConfig = undefined;
        this.objectType = OBJECT_TYPE.CONFIG;
    }

    async getClientConfig() {
        this.log("downloadConfig");
        this.clientConfig = await this.getAnonAction('getclientconfig');
        if (!this.clientConfig) return false;
        return true;
    }

    /**
     *
     *  ██████╗ ███████╗████████╗████████╗███████╗██████╗ ███████╗
     * ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝
     * ██║  ███╗█████╗     ██║      ██║   █████╗  ██████╔╝███████╗
     * ██║   ██║██╔══╝     ██║      ██║   ██╔══╝  ██╔══██╗╚════██║
     * ╚██████╔╝███████╗   ██║      ██║   ███████╗██║  ██║███████║
     *  ╚═════╝ ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝
     *
     */
    get isSet() {
        return this.clientConfig ? true : false;
    }

    getClientField(name, defaultValue) {
        return myObjectPath.get(this.clientConfig, name, defaultValue);
    }

    getTheme(name) {
        let themes = this.getClientField('client.themes', {});
        return themes[name];
    }

    get appName() {
        return this.getClientField('appName', 'Lynvictus');
    }
    get logo() {
        return this.getClientField('logo');
    }
    get chat() {
        return this.getClientField('chat', {});
    }
    get themeNames() {
        let themes = this.getClientField('themes', {});
        return Object.keys(themes);
    }
    get theme() {
        return this.getClientField('client.themes', {});
    }
    get instance() {
        return this.getClientField('instance', "unknown");
    }
    get fixedTheme() {
        return this.getClientField('client.defaultTheme', true)
    }
    get canChangeTheme() {
        return this.fixedTheme ? false : true;
    }
    get maintenance() {
        return this.getClientField('maintenance', {});
    }
    get defaultTheme() {
        return this.getClientField('client.defaultTheme')
    }
    get authentications() {
        return this.getClientField('authentications', {})
    }
    get guestValidators() {
        return this.getClientField('guestValidators', [])
    }

    getAuthByType(type) {
        for (let authName in this.authentications) {
            let auth = this.authentications[authName];
            if (auth.switch !== true) continue;
            if (auth.type === type) return auth;
        }
        return undefined;
    }
    get localAuth() {
        return this.getAuthByType(AUTH.LOCAL);
    }

    get googleAuth() {
        return this.getAuthByType(AUTH.GOOGLE);
    }

    get ldapAuth() {
        return this.getAuthByType(AUTH.LDAP);
    }

    /**
     * Return true if signin is enabled.
     */
    get userSigninEnabled() {
        let auth = this.localAuth;
        if (!auth) return false;

        if ("local" in auth) {
            if ("signin" in auth.local) {
                if ("switch" in auth.local.signin) {
                    return (auth.local.signin.switch);
                }
            }
        }
        // I should then do the non local auth case ()
        return this.getClientField(`authentications.${AUTH.LOCAL}.local.signin.switch`, false)
    }

    get escrowSwitch() {
        return this.getClientField('escrow.switch', false)
    }

    get escrowAllUserQuorum() {
        return this.getClientField('escrow.allUserQuorum', {})
    }

    get escrowQuorumMembersQuorum() {
        return this.getClientField('escrow.quorumMembersQuorum', {})
    }

    getCGU(lang) {
        let gcus = this.getClientField("gcus", {});
        if (gcus[lang]) return gcus[lang];
        if (gcus["en"]) return gcus["en"];
        return "empty";
    }
    get server() {
        return this.getClientField('server', {
            "websockets": true
        });
    }
    get fileSetStats() {
        return this.getClientField('fileSetStats', false)
    }

    get rights() {
        return this.getClientField('rights', {
            "changeConfig": [],
            "createUsers": [],
            "lockUsers": [],
            "createGroups": [],
            "createQuorumGroups": [],
        });
    }

    get expirations() {
        return this.getClientField('expirations', {})
    }

    get instanceURL() {
        return this.getClientField('instanceURL', "")
    }


    /*
     *
     * ██████╗ ██╗ ██████╗ ██╗  ██╗████████╗███████╗
     * ██╔══██╗██║██╔════╝ ██║  ██║╚══██╔══╝██╔════╝
     * ██████╔╝██║██║  ███╗███████║   ██║   ███████╗
     * ██╔══██╗██║██║   ██║██╔══██║   ██║   ╚════██║
     * ██║  ██║██║╚██████╔╝██║  ██║   ██║   ███████║
     * ╚═╝  ╚═╝╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝
     *
     */

    canModifyRootDirectory() {
        return this.canModify();
    }

    canCreate() {
        return false;
    }

    canManage() {
        if (accountStore.isMemberOfOneOfThoseGroups(this.rights.changeConfig)) return true;
        return false;
    }

    canDelete() {
        return false;
    }

    canModify() {
        return this.canManage();
    }

    async testLdapConfig(ldapConfig) {
        try {
            await this.postAction('testLdapConfig', undefined, ldapConfig);
        } catch (e) {
            console.log(e);
            throw e;
        }
        return true;
    }
}

export var configStore = new ConfigStore();
configStore.registerTo(sMaster);