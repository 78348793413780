import SchmluxStore, {
	WITHOUT_EMITCHANGE,
} from './SchmluxStore.js';
import {
	ProgressAction,
	ACTIONSTATUS as STATUS,
} from '../Libs/ProgressAction'

import {
	Checker,
} from '../../../SharedLibs/Checker'

// import { accountStore } from './AccountStore';


export const VUE = {
	short: 0,
	full: 1,
};

export const ACTIONSTATUS = STATUS;

class ProgressActionsStore extends SchmluxStore {
	constructor() {
		super();

		// --- Set the debug name --
		this.deb.setNames( 'ProgressActionsStore', 'progress', 'store' );

		this.singleton = ProgressAction;
		this.storeName = 'progressActions';
		this.options = {
			defaultVueLevel: VUE.full,
			maxVueLevel: VUE.full,
			defaultRange: [0, 50]
		}
	}

	/**
	 * Create an new device.
	 *
	 * @param {object} data
	 */
	create(data, emitChange = WITHOUT_EMITCHANGE) {
		// ------------ Used for debbuging ----------------------------
		this.log("create", data);

		new Checker({
			"_id": "string+",
			"max": "int",
			"name": "string+",
			"object": {},
			"parentProgressId" : "string",
		}, 'ProgressCreate').checkThrow(data);

		let old = this.getInStoreById( data._id );
		if ( old ) {
			this.cancel(data._id, emitChange)
		}

		let el = new this.singleton( this, /* accountStore */ );
		el.init( data );
		this.addToStore(data._id, el, this.options.defaultVueLevel );
		if ( emitChange ) this.emitChange();
		return el;
	}

	inc( _id, emitChange = WITHOUT_EMITCHANGE ) {
		let el = this.getInStoreById( _id );
		if ( ! el ) return this._false('Progress not found')
		el.inc();
		if ( emitChange ) this.emitChange();
		return true;
	}

	cancel( _id, emitChange = WITHOUT_EMITCHANGE ) {
		let el = this.getInStoreById( _id );
		if ( ! el ) return this._false('Progress not found')
		el.cancel();
		if ( emitChange ) this.emitChange();
		return true;
	}

	error( _id, err, emitChange = WITHOUT_EMITCHANGE ) {
		let el = this.getInStoreById( _id );
		if ( ! el ) return this._false('Progress not found')
		el.error( err );
		if ( emitChange ) this.emitChange();
		return true;
	}

	end( _id, emitChange = WITHOUT_EMITCHANGE ) {
		let el = this.getInStoreById( _id );
		if ( ! el ) return this._false('Progress not found')
		el.end();
		if ( emitChange ) this.emitChange();
		return true
	}

	reassignId( oldId, newId ) {
		let el = this.getInStoreById( oldId );
		if ( ! el ) return this._false('Progress not found')
		el.reassignId( newId );
		this.data[ newId ] = this.data[ oldId ];
		delete this.data[ oldId ];
		return true

	}

	clean( emitChange = WITHOUT_EMITCHANGE ) {
		for ( let _id of this.getInStoreIds() ) {
			let el = this.getInStoreById( _id );
			if ( ! el ) continue;
			if ( ! el.isFinished ) continue;
			this.addDeleteToEmitChange(_id);
			delete( this.data[ _id ] )
		}
		if ( emitChange ) this.emitChange();
		return true;
	}

}

export var progressActionsStore = new ProgressActionsStore();
