/* Writen by script */

export const inter = {
    "root": {
        "First page": "First page",
        "Previous page": "Previous page",
        "Next page": "Next page",
        "Last page": "Last page",
        "Show filesets where I am": "Show vaults where I am",
        "Show": "Show",
        "Access to fileset": "Access to vault \"{name}\"",
        "Here is the access to the fileset": "Here is the access to the vault \"{name}\"",
        "Named Fileset created": "Vault \"{name}\" created",
        "Your account is validated": "Your account is validated",
        "Wanted added to filesets": "Request for access by a guest was added to the vault",
        "Reinitializing keys": "Reinitializing keys",
        "Forgot my passphrase": "Forgot my passphrase",
        "You must choose a new password": "You must choose a new password",
        "You can close this window": "You can close this window",
        "Ask": "Ask",
        "Cipher": "Cipher",
        "full screen": "full screen",
        "Skip": "Skip",
        "Private key in memory ?": "Private key in memory ?",
        "Are you sure to drop this key from memory ?": "Drop your private key from memory ?",
        "drop": "drop",
        "release": "release",
        "Need password": "Password needed",
        "ChangePassword": "Change your password",
        "Old password for this key": "Old password for this key",
        "New password for this key": "New password for this key",
        "FingerPrint": "Fingerprint",
        "Are you sure to revoke this key ?": "Revoke this key ?",
        "Are you sure to delete this key ?": "Delete this key ?",
        "Quorum of device - creation step": "Quorum of device - creation step",
        "Min device count": "Mininmum device count",
        "Keys on this device": "Keys on this device",
        "Are you sure to delete this device ?": "Delete this device key ?",
        "Are you sure to drop this signature ?": "Drop this signature key ?",
        "Move multiple objects": "Move {num} objects",
        "Rename file or folder": "Rename file or folder",
        "Are you sure to delete thoses devices ?": "Are you sure to delete these devices ?",
        "Device created": "Device created",
        "Downloading": "Downloading",
        "More details": "More details",
        "Fake finished": "Fake finished",
        "Sure to launch Fake": "Sure to launch Fake",
        "Rename fileset": "Rename vault",
        "Are you sure to sign thoses friends ?": "Are you sure to sign the keys of these contacts ?",
        "Sign": "Sign",
        "Advanced search on friends": "Advanced search on contacts",
        "Soon": "Soon",
        "Created": "Created",
        "Loading notifications": "Loading notifications",
        "You need to increase the threshold": "You need to increase the threshold",
        "Send mail": "Send mail",
        "Follow the link": "Follow the link",
        "Search a user": "Search for a user",
        "Sorry, but you must not be here.": "Sorry, but you must not be here.",
        "Todos": "Todos",
        "Actions": "Actions",
        "Advanced search on actions": "Search for actions",
        "Are you sure to delete thoses urls ?": "Delete those file request urls ?",
        "From": "From",
        "Advanced search on urls": "Search for file request urls",
        "App config": "Configuration",
        "Status": "Status",
        "Reply": "Reply",
        "Home": "Home",
        "Devices": "Devices",
        "Rename": "Rename",
        "New group": "New group",
        "URLs": "File request urls",
        "Administration": "Administration",
        "Your access is under validation": "Your identity and the right to access to this vault must be validated by",
        "Quorums": "Quorums",
        "Emails": "Emails",
        "Description": "Description",
        "Properties": "Properties",
        "Size": "Size",
        "Dataroom not decypherable": "This vault cannot be decrypted",
        "Vaults": "Vaults",
        "Help": "Help",
        "Logout": "Logout",
        "Decrypt": "Decrypt",
        "Refuse": "Refuse",
        "Close": "Close",
        "Accept": "Accept",
        "Unknown": "Unknown",
        "Name": "Name",
        "Expire": "Expire",
        "Change password": "Change your password",
        "Last Update": "Last update",
        "Expiration date": "Expiration date",
        "Creation date": "Creation date",
        "Users": "Users",
        "Yes": "Yes",
        "No": "No",
        "Cancel": "Cancel",
        "Loading": "Loading",
        "Dataroom crypted": "Vault crypted",
        "Owner": "Owner",
        "Comments": "Comments",
        "General": "General",
        "Date": "Date",
        "Crypted": "Crypted",
        "Logs": "Logs",
        "Crypted thread": "Crypted thread",
        "Act as": "Act as",
        "Next": "Next",
        "Share": "Share",
        "Shares": "Shares",
        "Subject": "Subject",
        "Copy to me": "Copy to me",
        "Prev": "Previous",
        "Message": "Message",
        "Invite": "Invite",
        "Delete": "Delete",
        "Themes": "Themes",
        "Delete my account": "Delete my account",
        "New password": "New password",
        "Enter": "Enter",
        "Enter a name": "Enter a name",
        "Change": "Change",
        "For": "For",
        "Create": "Create",
        "Encrypt and send": "Encrypt and send",
        "Groups": "Groups",
        "An email has been sent": "An email has been sent to \"{email}\"",
        "Refuse access": "Refuse access",
        "Accept access": "Accept access",
        "Ask for access": "Ask for access",
        "Cancel request": "Cancel request",
        "Password": "Password",
        "You need to change your password": "You need to change your password",
        "Username": "Username",
        "User created": "User created",
        "New user": "New user",
        "Tchat": "Tchat",
        "There is currently no selected thread": "There is currently no selected thread",
        "Chose someone in the list below": "Chose someone in the list below",
        "Select a friend": "Select a friend",
        "No member(s)": "No member(s)",
        "Select a user": "Select a user",
        "The list is empty": "The list is empty",
        "Create a new quorum group": "Create a new quorum group",
        "No account yet ?": "No account yet ?",
        "I forget my password": "I forgot my password",
        "Password reinitialization": "Password reinitialization",
        "Login": "Login",
        "Login LDAP": "Login LDAP",
        "Display Name": "Display Name",
        "DisplayName changed": "Displayed name changed to \"{displayName}\"",
        "LoginName": "Login",
        "Choose a password": "Choose a password",
        "Email": "Email",
        "A email has been sent": "An email has been sent to ",
        "Please check your email and click on the verification link.": "Please check your email and click on the verification link. If you do not receive this email, please also check your spam or bulk email folder.",
        "A email has been sent. Please check your email and click on the link to choose a new password": "A email has been sent to \"{email}\". Please check your email and click on the link to choose a new password.",
        "Congratulation": "Congratulation",
        "Your email is validated": "Your email has successfully been verified.",
        "Error": "Error",
        "Your email cannot be validate !": "Your email cannot be validate !",
        "Never": "Never",
        "Password reinitialized": "Password reinitialized",
        "Anonymous files transfered achieved": "Anonymous files transfered to \"{name}\" achieved",
        "Ready": "Ready",
        "Sharing": "Sharing",
        "Secreting": "Secreting",
        "Quorum group created": "Quorum group created",
        "Group created": "Group created",
        "Send files to": "Send files to \"{name}\"",
        "Files": "Files",
        "Object": "Object",
        "Save": "Save",
        "Are you sure to destroy your account ?": "Are you sure to destroy your account ? It will delete all your vaults.",
        "Sorry, unable to display this file": "Sorry, unable to display this file",
        "Download": "Download",
        "Edit": "Edit",
        "Send a customized notification email": "Send a customized notification email",
        "See": "Visualisation",
        "Add users": "Manage users",
        "Confirmation": "Confirmation",
        "reinitConfirm": "Be careful, your access to all non-shared data will be lost during the procedure",
        "Thread deleted": "Thread deleted",
        "Are you sure to delete this thread ?": "Are you sure to delete this thread ?",
        "Fileset created": "Vault created",
        "New folder": "New folder",
        "Folder created": "Folder \"{name}\" created",
        "Share(s) added": "A user sharing this vault was added",
        "Change passphrase": "Change passphrase",
        "Current password": "Current password",
        "Crypting key": "Encryption key",
        "Signing key": "Signature key",
        "Quorum key": "Quorum key",
        "Quorum member key": "Quorum member key",
        "Revoked": "Revoked",
        "InProgress1": "This quorum key is waiting for the approval of a first quorum member",
        "InProgress2": "This quorum key is waiting for the approval of a second quorum member",
        "Set": "Set",
        "Valid": "Valid",
        "In progress": "In progress",
        "OR": "OR",
        "actions required": "action(s) required",
        "CREATE AN ACCOUNT": "CREATE AN ACCOUNT",
        "Create an account": "Create an account",
        "Back to login": "Back to login",
        "Back to home": "Back to home",
        "I read and I accept terms and conditions": "I read and I accept the usage terms and conditions",
        "LOGIN TO YOUR ACCOUNT": "LOGIN TO YOUR ACCOUNT",
        "Auth with Google": "Authentication with Google",
        "Email deleted": "Email \"{email}\" deleted",
        "Are you sure to drop email ?": "Are you sure to drop \"{email}\" ?",
        "Avatar changed": "Avatar changed",
        "Avatar": "Avatar",
        "Write here a new message": "Write here a new message",
        "Edit the current message": "Edit the current message",
        "crypted message": "Crypted message",
        "View list": "View as list",
        "View tiles": "View as tiles",
        "You need more rights to see this content": "You need more rights to see this content",
        "Show more elements": "Show more elements",
        "Number of use": "Number of use",
        "Search": "Search",
        "No result found": "No result found",
        "My emails": "My emails",
        "My logs": "My logs",
        "My password": "My password",
        "My account": "My account",
        "Device without name, you may had a new one": "Device without name, you may add a new one",
        "Keys": "Keys",
        "Signed keys": "Signed keys",
        "Signed since": "Signed since",
        "The keys you signed": "The keys you signed",
        "Private key in memory up to": "Private key in memory up to",
        "Finger print": "Finger print",
        "Create a new Quorum with these devices": "Create a new Quorum with these devices",
        "You are on this device": "You are on this device",
        "Unknown Key Or Removed Key": "Unknown key or removed key",
        "Unknown user": "Unknown user",
        "Signature not found": "Signature not found",
        "Creation date not found": "Creation date not found",
        "Show debug functions": "Show debug functions",
        "Use care when changing the following settings": "Be careful when changing the following settings, you can make your account unusable, contact the support if you have questions",
        "Advanced search on users": "Advanced search on users",
        "No item(s) selected yet": "No item(s) selected yet",
        "Vaults shared with": "Vaults shared with",
        "Friends": "Contacts",
        "New friend": "New contact",
        "Trusted connection": "Trusted connection",
        "Sign friend": "Trust a contact",
        "Add friend": "Add a contact",
        "Advanced search on groups": "Search for groups",
        "Continue": "Continue",
        "Signed By": "Signed by",
        "No share user selected": "No user sharing this vault selected",
        "Fileset renamed": "Vault renamed",
        "by ": "By ",
        "fileset closed": "Vault closed",
        "fileset open": "Vault opened",
        "Move": "Move",
        "Type": "Type",
        "Role": "Role",
        "Login name": "Login name",
        "Quorum groups": "Quorum groups",
        "Advanced search on quorums": "Search for quorums",
        "Quorum Group name": "Quorum group name",
        "Threshold": "Threshold",
        "Members": "Members",
        "Send test mail": "Send test mail",
        "Add a new mail": "Add a new email address",
        "Clear": "Clear",
        "Rename folder": "Rename folder",
        "Move to": "Move \"{elName}\" to",
        "Advanced search on files": "Advanced search on files",
        "No group selected": "No group selected",
        "Select a group": "Select a group",
        "No notifications": "No notifications",
        "New url": "New file request url",
        "New fileset": "New vault",
        "Number of available deposits": "Number of available deposits",
        "New share": "New contact to share with",
        "Url created & Link sent": "File request url created & sent",
        "browser error": "Your browser is outdated or incompatible with the application.",
        "Please use another browser": "Please upgrade or install Firefox / Chrome / Edge(chromium)",
        "Fake": "Fake Action",
        "Your account is created and ready to use": "Your account is created and ready to use",
        "Some notifications": "Some notifications",
        "Adding share(s)": "Adding share(s)",
        "Url created": "Url created",
        "Url updated": "Url updated",
        "Create a new url": "Create a new url",
        "Link name": "Link name",
        "Must be filled": "Must be filled",
        "must be a number": "must be a number",
        "New device": "New device",
        "Enter the code": "Enter the code",
        "Too many attempts": "Too many attempts",
        "Invalid key": "Invalid key",
        "Please plug in and active your key": "Please plug in and active your key",
        "Advanced search on folders": "Advanced search on folders",
        "Number of items to display": "Number of items to show",
        "Select": "Select",
        "Suppression": "Suppression",
        "cancelWanted": "Cancel request",
        "This is not a valid String": "This is not a valid String",
        "Add a device": "Add a device",
        "No device set, please add one": "No device set, please add one",
        "Personal Settings": "Personal Settings",
        "Maintenance": "Maintenance",
        "Decrypt ok ": "Decrypt ok",
        "Canceled": "Cancelled",
        "My devices": "My devices",
        "New Fileset": "New vault",
        "New Folder": "New vault folder",
        "Rename FileSet": "Rename vault",
        "already exist": "Name already exists",
        "New directory": "New directory",
        "Directory created": "Directory created",
        "File added created": "File added",
        "Selection mode": "Selection mode",
        "Filesets": "Vaults",
        "Folders": "Folders",
        "Message deleted": "Message deleted",
        "add": "Add",
        "Fileset name": "Vault name",
        "Updated": "Updated",
        "Copied to clipboard": "Copied to clipboard",
        "Add an email to your account": "Add an email to your account",
        "30 chars max": "30 chars max",
        "Url renamed": "Url renamed",
        "Already exists": "Name already exists",
        "All logs decrypted": "All logs decrypted",
        "File renamed": "File renamed",
        "Share created": "Share with another contact",
        "Share updated": "Share updated",
        "Are you sure to delete thoses shares ?": "Are you sure to delete these shares ?",
        "Ip address": "IP address",
        "Owner name": "Owner name",
        "Object Name": "Object name",
        "Advanced search on logs": "Search on logs",
        "Ip adress": "IP address",
        "Notifications": "Notifications",
        "There is no message, write an new one in text field below": "There is no message, write an new one below",
        "New quorum group": "New quorum group",
        "Quorum Group created": "Quorum group created",
        "Are you sure to delete thoses quorum groups ?": "Are you sure to delete these quorum groups ?",
        "Are you sure to delete thoses users ?": "Are you sure to delete these users ?",
        "Are you sure to delete thoses groups ?": "Are you sure to delete these groups ?",
        "Group updated": "Group updated",
        "Decrypt all logs": "Decrypt all logs",
        "Decrypt all": "Decrypt all",
        "All logs that you have right to decrypt are so": "All logs that you have right to decrypt are so",
        "No todos": "No todo left",
        "No quorum group": "No quorum group",
        "no log": "No log",
        "No group": "No group",
        "No friend": "No friend",
        "No file": "No file",
        "No device registered": "No device registered",
        "Recipient email": "Recipient email",
        "must be an email": "must be an email",
        "Creating Directory": "Creating a directory",
        "Folder renamed": "Folder renamed",
        "Quorum group updated": "Quorum group updated",
        "The threshold cannot be more than then number of admins": "The threshold cannot be more than then number of admins",
        "Create a new user": "Create a new user",
        "User updated": "User updated",
        "Validated": "Validated",
        "This url is no longer available": "This file request url is no longer available",
        "Please ask the sender to send you a new one.": "Please ask the sender to send you a new one.",
        "File reader not done": "Error while reading file",
        "cannot apply a workflow to an non -set element ": "cannot apply a workflow to an empty element",
        "workflow error": "workflow error with message \"{message}\"",
        "setWFsContent error": "error setting workflow with message \"{message}\"",
        "User doenst exists": "User \"{login}\" does not exists",
        "Folder doesnt exists in path": "Folder \"{name}\" does not exist in \"{path}\"",
        "Fileset doesnt exists in path": "Vault \"{name}\" does not exist in \"{path}\"",
        "There was an error from our server. Please contact us.": "There was an error from our server. Please contact us.",
        "The selected File mut be an image": "The selected file mut be an image",
        "not an instance of UserInteract !": "not an instance of UserInteract !",
        "Move error": "Error while moving",
        "Cannot change : No keyStore ": "Cannot change : no keyStore",
        "Invalid password": "Invalid password",
        "Need Passphrase": "Need passphrase",
        "cannot download file like that outside the browser": "cannot download file like that outside the browser",
        "Anonymous Fileset not found": "Anonymous vault not found",
        "Recipient not found for anonymous fileset": "Contact ({_id}) not found",
        "No keyStore found": "No keyStore found",
        "syncGetUniq does not return an array": "syncGetUniq does not return an array",
        "syncGetUniq return more than one element": "syncGetUniq return more than one element",
        "Database not open. exited": "Database not opened. Exiting",
        "Unable to close database": "Unable to close database",
        "Passport cannot serialize something different from a user": "Passport cannot serialize something different from a user",
        "User not found": "User \"{login}\" not found",
        "Error while confirmation": "Error while confirmation",
        "Two factor strategy type not registered": "The two factor strategy type \"{type}\" is not registered",
        "Two Factor Strategy config is mandatory": "Two Factor Strategy config is mandatory",
        "Device already set": "Device already set",
        "Two factor strategy is not registered": "Two factor strategy is not registered",
        "Two Factor Strategy does not exists for user": "Two Factor Strategy does not exists for user \"{login}\"",
        "Fail to register U2F key": "Fail to register U2F key",
        "No email in google account": "No email in google account",
        "Cannot create an existing user": "Cannot create an existing user",
        "Cannot find usersStore": "Cannot find usersStore",
        "Error while deleting new email": "Error while deleting new email",
        "Cannot found or create quorumsStore": "Cannot find or create quorumsStore",
        "Ldap may be down or url may be wrong.": "Ldap may be down or url may be wrong.",
        "Error with bindDN or bindCredentials": "Error with bindDN or bindCredentials",
        "Invalid filter for searchBase": "Invalid filter for searchBase",
        "Invalid filter for searchFilter": "Invalid filter for searchFilter",
        "Config not set": "Configuration not set",
        "config not found in DB": "Configuration not found in the database",
        "Device does not exist for validation": "Device does not exist for validation",
        "Error while resolveChallenge of device": "Error while resolveChallenge of device",
        "Anonymous cannot save an existing fileset": "Anonymous cannot save an existing vault",
        "Cannot create another root dir": "Cannot create another root folder",
        "No users store found": "The user database is not found",
        "Error while preset": "Error while preset",
        "No devicesStore found": "The devices database is not found",
        "This phonenumber is already used": "This phone number is already used",
        "SMS otp not initialized": "SMS otp not initialized",
        "wrong code": "wrong code",
        "isDirectoryPolicyCompliant Fileset parent dir not found": "Vault parent folder not found",
        "Fileset without owner": "Vault without owner",
        "Fileset owner not found": "The owner of the vault was not found",
        "You are automaticaly logged": "You are automatically logged in",
        "You can now log on with your password": "You can now log in with your password",
        "Welcome": "Welcome",
        "You have been invited": "You have been invited",
        "Please choose a password": "Please choose a password",
        "No account": "No account for \"{displayName}\"",
        "Search file by owner": "Search file by owner",
        "The name of the owner": "The name of the owner",
        "Advanced search on filesets": "Advanced search on vaults",
        "In share": "In share",
        "In Quorum": "In quorum",
        "Content Validator": "Content validator",
        "Search filesets shared with": "Search vaults shared with",
        "The name of the user": "The name of the user",
        "By selecting users": "By selecting users",
        "The name of the last modifier": "The name of the last modifier",
        "Generic Search": "Generic search",
        "more advanced": "more advanced",
        "search on groups": "search on groups",
        "The group will contain all selected users": "The group will contain all selected users",
        "Search a member": "Search for a user",
        "Search groups containing those users": "Search groups containing those users",
        "part of the name only available": "part of the name only available",
        "The name of the member": "The name of the user",
        "Extract current page to csv": "Extract current page to csv",
        "Extract page": "Extract page",
        "Extract all page to csv": "Extract all pages to csv",
        "Extract all": "Extract all",
        "search on messages": "search on messages",
        "Log begin time": "Log begin time",
        "Log end time": "Log end time",
        "search on quorums": "search on quorums",
        "Search quorums by owner": "Search quorums by owner",
        "search on users": "search for users",
        "Search users by owner": "Search users by owner",
        "Parent not found": "Parent not found ({_id})",
        "Group object not filled": "Group object not filled",
        "No recipient for Notification": "No recipient for notification",
        "unknown user with Id": "unknown user ({_id})",
        "recipientUser not understandable": "recipientUser not understandable",
        "Notification not set": "Notification not set",
        "Cannot find or create quorumStore": "Cannot find or create quorumStore",
        "Cannot find or create userStore": "Cannot find or create userStore",
        "Security violation": "Security violation ({message})",
        "User object not filled": "User object not filled",
        "Cannot find or create filesetsStore": "Cannot find or create filesetsStore",
        "Error while creating user": "Error while creating user",
        "This is the link to give to your friend": "This is the link to give to your friend",
        "You can copy paste it": "You may copy / paste it",
        "Configuration": "Configuration",
        "Configuration updated": "Configuration updated",
        "Passphrase": "Passphrase",
        "Theme": "Theme",
        "Delete my account ?": "Delete my account ?",
        "Dashboard": "Dashboard",
        "About": "About",
        "Password close to be strong": "Password nearly strong",
        "Password good but not enough": "Password good but not enough",
        "Password not so bad... ok": "Password not so bad... ok",
        "Password too weak, do better": "Password too weak, do better",
        "Password too weak": "Password too weak",
        "Password realy too weak": "Password really too weak",
        "Password cannot be empty": "Password cannot be empty",
        "verification mismatch": "verification mismatch",
        "password for verification": "password for verification",
        "Old password": "Old password",
        "Unknown type": "Unknown type",
        "Release cryptographic access": "Release cryptographic access",
        "Crypt for ?": "Crypt for \"{name}\" ?",
        "Which keys will be used to sign ?": "Which keys will be used to sign ?",
        "Change the protection of cryptographic keys": "Change the protection of cryptographic keys",
        "Protect your key": "Protect your key",
        "UserKey": "UserKey",
        "I_Need_Passphrase": "I need your passphrase",
        "You can crypt for these keys :": "You can crypt for these keys :",
        "You may have to enter old password and new password": "You may have to enter old password and new password",
        "Enter a new password": "Enter a new password",
        "Ok": "Ok",
        "Invalid code": "Invalid code",
        "Add a phone to your account": "Add a phone to your account",
        "Confirm phone": "Confirm phone",
        "Go back": "Go back",
        "validate": "validate",
        "Your phone has been added.": "Your phone has been added.",
        "Error when registering device": "Error when registering device",
        "Add a key to your account": "Add a key to your account",
        "Please plug in your key to register": "Please plug in your key to register",
        "Register": "Register",
        "Key added": "Key added",
        "Your key has been added.": "Your key has been added.",
        "More infos": "More infos",
        "email": "email",
        "New": "New",
        "Chat": "Chat",
        "Quorumgroups": "Quorumgroups",
        "Profile": "Profile",
        "Identity": "Identity",
        "Click": "Click",
        "Or drop your files here": "Or drop your files here",
        "Drop here your files or click on the download": "Drop here your files or click on download",
        "TwoFactors": "Two factors authentication",
        "You are going to remove a 2FA device, are you sure?": "You are going to remove a 2FA device, are you sure?",
        "File(s) added or modified": "File(s) added or modified",
        "Are you sure to delete configuration for group ": "Are you sure to delete configuration for group \"{name}\" ?",
        "Not authorized": "Not authorized",
        "Given by": "Given by",
        "in folder": "in folder",
        "Can create new filesets": "Can create new vaults",
        "Can create directories": "Can create directories",
        "Can modify this rights": "Can modify this rights",
        "Can delete this directory": "Can delete this directory",
        "Can modify subdir rights": "Can modify subdir rights",
        "Your current rights": "Your current rights",
        "Folder name": "Folder name",
        "Visibility": "Visibility",
        "Not visible if empty": "Not visible if empty",
        "Visible for members below": "Visible for members below",
        "All groups": "All groups",
        "Avoid validation for": "Avoid validation for",
        "Validators for new shares": "Validators for new shares",
        "Validators for content": "Validators for content",
        "Recovering friends": "Recovering friends",
        "Add configuration for a new group": "Add configuration for a new group",
        "Select Display": "Select Display",
        "must be a string": "must be a string",
        "Last modification": "Last modification",
        "Update": "Update",
        "Groups of Managers": "Groups of Managers",
        "IP limitation": "IP limitation",
        "Email limitation for invitation": "Email limitation for invitation",
        "Create a new group": "Create a new group",
        "Group name": "Group name",
        "unread": "unread",
        "Encrypted notification": "Encrypted notification",
        "roles": "roles",
        "Temporary password": "Temporary password",
        "Invitation link": "Invitation link",
        "Access to vault": "Access to vault \"{name}\"",
        "Here is the access to the vault": "Here is the access to the vault \"{name}\"",
        "Member of": "Member of",
        "No group(s)": "No group(s)",
        "Login already exist": "Login already exist",
        "Locked": "Locked",
        "Error while reinit password": "Error while reinitializing your password",
        "User with this confirmString not found": "User with this confirmString not found",
        "Error while validation of device": "Error while validating of device",
        "Error while initChallenge of device": "Error while initializing keys of this device",
        "This folder is not empty": "This folder is not empty",
        "Must have valid emails before": "Must have valid emails before",
        "File is already a directory": "File \"{name}\" is already a directory",
        "File under saving": "File \"{name}\" save in progress",
        "workflow not found": "Workflow \"{name}\" not found",
        "Notification without encryption structure": "Notification without encryption structure ({_id})",
        "fileset_wanted_text": "Guest \"{user}\" want access to the vault \"{name}\"",
        "Maintenance end": "Maintenance will finish at {endDate}",
        "Must be a square": "Must be maximum size {width}x{height}px",
        "File deleted": "File \"{name}\" deleted",
        "Are you sure to delete file ?": "Are you sure to delete \"{name}\" ?",
        "Device deleted": "Device \"{name}\" deleted",
        "Are you sure to delete device ?": "Are you sure to delete device \"{name}\" ?",
        "Are you sure to delete Device ?": "Are you sure to delete device \"{name}\" ?",
        "File downloaded": "File \"{name}\" downloaded",
        "Fileset deleted": "Vault \"{name}\" deleted",
        "Are you sure to delete fileset ?": "Are you sure to delete the vault \"{name}\" ?",
        "Folder deleted": "Folder \"{name}\" deleted",
        "Are you sure to delete folder ?": "Are you sure to delete folder \"{name}\" ?",
        "Friend signed": "Friend \"{name}\" signed",
        "Are you sure to signed this friend ?": "Are you sure to sign the keys of contact \"{name}\" ?",
        "Group deleted": "Group \"{name}\" deleted",
        "Are you sure to delete group ?": "Are you sure to delete group \"{name}\" ?",
        "Quorum deleted": "quorum \"{name}\" deleted",
        "Are you sure to delete quorum group ?": "Are you sure to delete quorum group \"{name}\" ?",
        "Accept access with quorum": "Accept access with quorum group \"{name}\"",
        "Access accepted": "Grant access \"{name}\" added",
        "Access requested": "Access asked to \"{name}\"",
        "Request cancelled": "Your request to access to \"{name}\" was refused",
        "Are you sure to cancel your request ?": "Are you sure to cancel your request to \"{name}\" ?",
        "Share deleted": "The sharing with \"{name}\" was deleted",
        "Are you sure to delete share ?": "Are you sure to stop sharing with \"{name}\" ?",
        "Refuse access with quorum": "Refuse acces to \"{name}\"",
        "Access refused": "Access request from \"{name}\" refused",
        "Are you sure to give access to ?": "Are you sure to give access to \"{name}\" ?",
        "Access granted": "Access to \"{name}\" granted",
        "Are you sure to refuse access to ?": "Are you sure to refuse access to \"{name}\" ?",
        "Url deleted": "Invitation \"{name}\" deleted",
        "Are you sure to delete url ?": "Are you sure to delete file request url \"{name}\" ? ",
        "User deleted": "User \"{login}\" deleted",
        "Are you sure to delete user ?": "Are you sure to delete user \"{login}\" ?",
        "object not found in store": "Object not found in store ({_id})",
        "Fileset not found": "Vault not found ({_id})",
        "Cannot preset user ": "Cannot preset user \"{name}\"",
        "Cannot invite a quorum": "Cannot invite a quorum ({_id})",
        "Cannot invite an external user to a non clear fileset": "Cannot invite an external user to a non clear vault ({_id})",
        "upload file": "Upload file \"{name}\"",
        "Unable to add wanted": "Unable to add wanted ({_id})",
        "User not found in store": "User not found ({userId})",
        "Unable to remove wanted": "Unable to remove wanted ({_id})",
        "Unable to refuse wanted": "Unable to refuse wanted ({_id})",
        "Unable to remove share": "Unable to stop sharing with ({_id})",
        "Unable to create dir": "Unable to create directory \"{newDir}\"",
        "Reading file": "Reading file \"{name}\"",
        "User not find for anonymous share": "User not find for anonymous share ({_id})",
        "Please remove quorum group from folders configuration before deleting it": "Please remove quorum group from folders configuration before deleting it",
        "Error CRUD": "Error CRUD in database \"{DataBase}\"",
        "Database error": "Database error",
        "User not found in database": "Authentication failed for \"{login}\"",
        "Not authorised from this @IP ": "Connection forbidden from the ip address \"{ip}\"",
        "Confirmation String authentication failed": "Confirmation string authentication failed for \"{userName}\"",
        "User not ready": "User \"{login}\" is not ready",
        "Authentication failed": "Authentication failed for \"{login}\"",
        "User not found by email ": "User not found by email ({email})",
        "user not found": "User \"{login}\" not found",
        "Confirmation String invalid": "Confirmation string invalid for \"{login}\"",
        "quorum not found": "quorum not found ({_id})",
        "Unknown User with id": "Unknown user ({_id})",
        "Parent folder doesnt exist": "parent folder for \"{name}\" doesn't exist",
        "group already exist": "Group \"{name}\" already exist",
        "QuorumGroup number of admins too small": "Quorum group \"{name}\" has a number of administrators too small",
        "QuorumGroup already exist": "Quorum group \"{name}\" already exist",
        "User without keyStore": "User without keyStore ({_id})",
        "No keyStore in quorumgroup ": "No keyStore in quorum group ({_id})",
        "Cannot get data from an empty element": "Cannot get data from an empty element in database \"{DataBase}\"",
        "Object without keyStore": "Object \"{name}\" without keyStore",
        "No credentials defined for user ": "No credentials ({type}) defined for user \"{login}\"",
        "User already exist": "User \"{login}\" already exist",
        "Email not verified": "The email was not verified for \"{login}\"",
        "Email already exist": "The email \"{email}\" already exists",
        "Email doesnt exist": "Email \"{email}\" doesnt exist",
        "No credentials type defined for user": "No credentials ({type}) defined for user \"{login}\"",
        "Credential type not implemented": "Credential ({type}) not implemented",
        "Cannot flush from an empty element": "Cannot flush from an empty element ({DataBase})",
        "Error while flushing element": "Error while flushing element ({DataBase})",
        "You have no right to do this search": "You have no right to do this search ({storeName})",
        "Object not found": "Object ({_id}) not found",
        "Cannot change workflow for this object": "Cannot change workflow for this object ({_id})",
        "Cannot save an empty object": "Cannot save an empty object ({DataBase})",
        "Please ask an administrator": "Please ask an administrator",
        "Manage": "Manage",
        "Add file": "Add files",
        "Add comments": "Add comments",
        "Add share": "Add shares",
        "See logs": "See logs",
        "See shares": "See shares",
        "All access and all management function": "All access and all management function",
        "Can download file. otherwise only see in the browser": "Can download file. otherwise only see in the browser",
        "Can add or modify files": "Can add or modify files",
        "Can see and add comments": "Can see and add comments",
        "Can add new users on this fileset": "Can share this vault with new users",
        "Can see all activities on the fileset": "Can see all activities on this vault",
        "Can see all users shareing this fileset": "Can see users sharing this vault",
        "Choose user": "Choose a user",
        "Select rights": "Select rights",
        "Customise emails and share": "Customise emails and share",
        "Summary": "Summary",
        "Choose rights for thoses persons": "Give rights for thoses persons",
        "Custom emails thoses persons": "Customise emails for thoses persons",
        "share not done": "Share not done (error)",
        "share ok": "Share ok",
        "invitation to send": "Invitation to send",
        "You must Send him his password or the direct link": "You must provide him this password or the direct link",
        "password": "Password",
        "invitation sent": "Invitation sent",
        "email has received an email with the invitation link": "\"{email}\" has received an email with the invitation link",
        "He must check his email,... and his SPAM too": "He must check his email,... and his SPAM too",
        "You will validate his login": "You will validate his login",
        "Please stay connected and look at notifications or your emails": "Please stay connected and look at notifications or your emails",
        "Finish": "Finish",
        "content of the email": "Body of the email",
        "Group not found": "Group not found ({groupId})",
        "Loading Console": "Loading Console",
        "Loading content": "Loading content",
        "Loading login": "Loading login",
        "thank you to join us": "thank you to join us",
        "Loading application": "Loading application",
        "Cannot force a new password to a user with keyStore": "Cannot force a new password to a user with keyStore",
        "Error while changing local credentials": "Error while changing local credentials",
        "Error while setting temporary Authentication String": "Error while setting temporary Authentication String",
        "You must change for a new personal password first": "You must change for a new personal password first",
        "You must enter the temporary password.": "You must enter the temporary password.",
        "Then you will be redirected to changing it for a personnal one": "Then you will be redirected to changing it for a personnal one",
        "Loading vault": "Loading vault",
        "Loading folder": "Loading folder",
        "Loading users": "Loading users",
        "Loading user": "Loading user",
        "Loading groups": "Loading groups",
        "Loading group": "Loading group",
        "Loading quorum groups": "Loading quorum groups",
        "Loading quorum group": "Loading quorum group",
        "Loading logs": "Loading logs",
        "Crypting and saving fileset": "Crypting and saving fileset {name}",
        "Loading urls": "Loading invitations",
        "Loading url": "Loading invitation",
        "Loading about": "Loading about",
        "Loading friends": "Loading friends",
        "Loading friend": "Loading friend",
        "Loading actions": "Loading actions",
        "Access accepted with quorum": "Access \"{name}\" granted with quorum \"{quorumName}\"",
        "with post-validation": "with post-validation",
        "with password": "with password",
        "Unknow share type": "Unknown share type ({type})",
        "If you sent the password, you do not need to send the link below :": "If you sent the password, you do not need to send the link below :",
        "Could not rename with this name (it previously exists, no ? )": "Could not rename with \"{newname}\" (it previously exists, no ? )",
        "Error fetching parent": "Error fetching parent",
        "Error fetching brothers": "Error fetching brothers",
        "Url will be automatically added at the end of the email": "Url will be automatically added at the end of the email",
        "You don't have rights to see configuration": "You don't have rights to see configuration",
        "You don't know this user's keys, are you sure you want to trust this user ?": "You don't know this user's keys, are you sure you want to trust this user ?",
        "An old key which isn't existing anymore": "An old key which isn't existing anymore",
        "You don't have the right to see logs": "You don't have the right to see logs",
        "You don't have the right to see comments": "You don't have the right to see comments",
        "You don't have the right to see this share": "You don't have the right to see this share",
        "fileset_wanted_user": "Guest \"{user}\" asks you for an access to the vault \"{name}\"",
        "fileset_wanted_quorum": "Guest \"{user}\" asks the quorum for an access to the vault \"{name}\"",
        "Your session has expired": "Too much time without activity. You must reauthenticate.",
        "Password asked": "Password asked",
        "Ask password change": "Ask password change to the user",
        "lock": "Lock",
        "Ask password to quorum": "Ask your password to the secret administrators",
        "A password is asked to the quorum members": "A password is asked to the secret administrators",
        "In clicking below, you confirm asking to the quorum to deliver to you your password back": "In clicking below, you confirm asking to the quorum to deliver to you your password back",
        "Password change asked": "Password change asked",
        "Are you sure to ask for password change ?": "Are you sure to ask for password change for {login} ?",
        "Are you sure to ask for password change for these users ?": "Are you sure to ask for password change for these users ?"
    }
};
export const errors = {
    "root": {
        "Warning": "Warning",
        "Must be a valid email": "Must be a valid email",
        "Must be filled": "Must be filled",
        "Sorry, object not found": "Sorry, object not found",
        "Cannot change password for non local account": "Cannot change password for non local account",
        "New file": "New file",
        "move finished": "Move finished",
        "Are you sure to delete thoses elements ?": "Are you sure to delete these elements ?",
        "Searching": "Searching",
        "Cannot add a message in the thread, you are not a member of this fileset !": "Cannot add a message in the thread, you are not a member of this vault",
        "Cannot remove a message in the thread, is not a member of this fileset !": "Cannot remove a message in the thread, is not a member of this vault",
        "Error FileSetShare without any encryption data": "Share without any encryption data",
        "Error ciphering": "Encryption error",
        "No keystore in accountStore": "No keystore in accountStore",
        "no related thread found": "no related thread found",
        "Thread not decrypted": "Thread not decrypted",
        "Cannot built attributs structure": "Cannot build attributes structure",
        "Cannot invite an external user": "Cannot invite an external user",
        "Cannot invite an external user not from collegues": "Cannot invite an external user not from existing contacts",
        "No validators available. cannot add you.": "No validators available. Cannot add you.",
        "Folder not found for fileset": "Unable to find folder ({_id}) for vault \"{name}\"",
        "Owner of the Fileset not found": "The owner ({_id}) of the vault is not found",
        "Unable to rm inod": "Unable to delete file or folder",
        "Cannot add files to a crypted fileset": "Cannot add files to an encrypted vault",
        "Cannot find directory": "Cannot find directory",
        "Cannot get file from a crypted fileset": "Cannot get file from an encrypted vault",
        "No keyStore for this fileset": "No keyStore available",
        "No sessionKeyId for this fileset": "No session key available",
        "cannot download this file": "Impossible to download this file"
    }
};
export const notifications = {
    "root": {
        "unknown_notif": "Unknown message!",
        "unknown_notif_text": "Unknown message!"
    }
};
export const mail = {
    "root": {
        "Confirm": "Confirm",
        "Link": "Link"
    }
};
export const sms = {
    "root": {
        "Verification code": "Here is your validation code : \"{otp}\"",
        "A code has been sent to your phone": "A code has been sent to your phone"
    }
};