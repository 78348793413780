import {
    manager,
    ASK
} from './Manager.js';



/**
 * Interaction structure with the user
 * 
 */
export class UserInteract {

    constructor(type, userName, deviceName) {
        this.type = type;
        this.userName = userName;
        this.deviceName = deviceName;
        this.keys = {};
        this.pubKeys = {};
        this.currentAttempts = 0;
        this.maxAttempts = manager.MAXATTEMPTPASSPHRASE;
        this.answer = undefined;
        this.shareKeys = undefined;
    }


    get numberOfKeys() {
        return Object.keys(this.keys).length;
    }

    addKeyInfos(keyInfos, struct) {
        struct[keyInfos._id] = {
            "_id": keyInfos._id,
            "name": keyInfos.name,
            "type": keyInfos.type,
            "status": keyInfos.status,
            "usages": keyInfos.usages,
            "fingerPrint" : keyInfos.fingerPrint,
            "preferedKey": false,
            "badPassPhrase": false,
        }
    }
    
    addKey(keyInfos) {
        return this.addKeyInfos( keyInfos, this.keys )
    }

    addUserSignKeys(keyInfos) {
        return this.addKeyInfos( keyInfos, this.pubKeys )
    }

    setBadPassphrase(keyId) {
        if (this.keys[keyId]) this.keys[keyId].badPassPhrase = true;
    }

    setDefaultGlobalPassphrase( passphrase ) {
        this.defaultGlobalPassphrase = passphrase;
        return true;
    }
    
    setDefaultNewGlobalPassphrase( passphrase ) {
        this.defaultNewGlobalPassphrase = passphrase;
        return true;
    }
}