import {
    TYPE,
    KEYUSAGE,
    STATUS,
    KEYDEFAULTNAME,
} from './GenericKey.js';
import {
    ElGamalKey
} from './ElGamalKey.js';
import {
    SignKey
} from './SignKey.js';
import {
    ShareKeys
} from './ShareKeys.js';
import {
    Share
} from './Share.js';
import {
    QuorumKey
} from './QuorumKey.js';
import {
    MemberKey
} from './MemberKey.js';
import {
    SessionKey
} from './SessionKey.js';
import {
    PassKey
} from './PassKey.js';

import {
    debug,
    error,
    log,
    TRUSTLEVEL,
    manager,
} from './Manager.js';
import {
    randomId
} from '../Common.js';

/**
 * The KeyStore for a User
 */
export class GenericKeyStore {

    /**
     * constructor for a keyStore
     * 
     * @param {string} userId 
     * @param {string} name the user Name (a name for the keyStore)
     */
    constructor(userId, name) {

        this.name = name ? name : "KeyStore " + userId;
        this.userId = userId;
        this.keys = {};
        this.pref = {
            "cryptKeyId": undefined,
            "signKeyId": undefined,
            "signFileKeyId": undefined,
        }

        // --- List of session keys ------
        this.sKeys = {};

        //--- Storage of signatures i have done ---
        this.signatures = {};

        this.myTrustLevel = manager.applicationTrustLevel;

        this.modified = false;

        this.debugFlag = false;

        // --- KeyStores for devicesKeyStore ----
        this.devicesKeyStore = undefined;

        this.cachedKeyStores = {};

        // --- curent works in progress ---
        this.works = {};

        // --- bindings for functions ----------
        this.decrypt = this.decrypt.bind(this);
        this.decryptSessionKeyInternal = this.decryptSessionKeyInternal.bind(this);
    }

    changeProgress(_id) {
        if (!manager.progressFunc) return false;
        let s = this.works[_id];
        if (!s) return false;

        try {
            manager.progressFunc({
                _id: _id,
                userId: this.userId,
                title: s.title,
                currentMessage: s.currentMessage,
                aborted: s.aborted,
                finished: s.finished,
                max: s.max,
                i: s.i,
            })
        } catch (e) {
            return error("GenericKeyStore.js - Error in progress function ", e);
        }
        return true;
    }

    startAction(_id = "main", title = undefined, max = -1) {
        let currentMessage = title;
        if (!this.works[_id]) {
            this.works[_id] = {
                title: title,
                currentMessage: title,
                aborted: false,
                finished: false,
                max: max,
                i: 0,
                sub: 0,
            };
            currentMessage = undefined;
        }
        this.works[_id].sub++;
        this.works[_id].currentMessage = currentMessage;
        this.changeProgress(_id);

    }
    finishAction(_id = "main", somethingModified = true) {

        if (somethingModified) this.modified = true;

        if (this.works[_id]) {
            this.works[_id].i++;
            this.works[_id].sub--;

            if (this.works[_id].max > -1) {
                if (this.works[_id].i > this.works[_id].max) this.works[_id].max++;
            }

            if (this.works[_id].sub <= 0) {
                this.works[_id].sub = 0;
                this.works[_id].finished = true;
            }
        }
        this.changeProgress(_id);
        if ((this.works[_id]) && (this.works[_id].finished)) delete(this.works[_id]);

    }
    abortAction(_id = "main") {
        if (this.works[_id]) {
            this.works[_id].aborted = true;
            this.works[_id].finished = true;
        }
        this.changeProgress(_id);
        delete(this.works[_id]);
    }

    isFinished(_id = "main") {
        if (this.works[_id]) {
            return (this.works[_id].finished);
        }
        return true;
    }

    ismodified(_id = "main") {
        return this.modified && this.isFinished();
    }

    async save() {
        return true;
    }

    async log(message) {
        if (!log) return true;
        await log(this.userId, message);
    }

    setName(newName) {
        this.name = newName;
        this.modified = true;

        // --- Save the keyStore if we can (don't wait for the await )
        this.save();
        return true;
    }

    set trustLevel(t) {
        if (t < manager.applicationTrustLevel) return error("GenericKeyStore.js - Trust Level not allowed")
        if (t >= Object.keys(TRUSTLEVEL).length) throw new Error("GenericKeyStore.js - Trust Level doesn't exist")
        this.myTrustLevel = t;
    }

    get trustLevel() {
        return this.myTrustLevel ? this.myTrustLevel : manager.applicationTrustLevel;
    }

    setUserId(userId) {
        this.userId = userId;
        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            key.setUserId(userId);
        }
        this.modified = true;
    }

    setKeyName(keyId, newName) {
        this.startAction("main", "Change key name");
        let key = this.keys[keyId];
        if (!key) return false;
        key.name = newName;
        this.finishAction("main", true);
        // --- Save the keyStore if we can
        if (this.ismodified()) this.save();

    }

    getNewDefaultKeyName(type) {
        let r = new RegExp("^" + KEYDEFAULTNAME[type] + " \([0-9]+\)");
        let max = 0;
        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            let m = key.name.match(r);
            if (m) {
                let i = parseInt(m[1])
                if (i > max) max = i;
            }
        }
        max++;
        return KEYDEFAULTNAME[type] + " " + max;
    }

    async deleteKey(keyId) {
        let key = this.keys[keyId];
        if (!key) return false;

        this.startAction("main", "Delete key");

        await key.del(this);
        delete(this.keys[keyId]);
        await this.log("GenericKeyStore.js - key  " + keyId + " deleted");

        this.finishAction("main", true);
        // --- Save the keyStore if we can
        if (this.ismodified()) this.save();
    }



    async revokeKeys(keyIds) {


        let kIds = keyIds ? Array.isArray(keyIds) ? keyIds : [keyIds] : undefined;

        this.startAction("main", "Revoke key");

        for (let keyId in this.keys) {
            if ((kIds) && (kIds.indexOf(keyId) == -1)) continue;
            let key = this.keys[keyId];
            if (!key) return false;
            if (!key.isRevocable()) continue;

            await key.revoke();
            await this.log("key  " + keyId + " revoked");

        }

        this.finishAction("main", true);
        // --- Save the keyStore if we can

        if (this.ismodified()) this.save();

    }


    async createTemporaryPassKey(passphrase, _id = undefined) {
        const k = new PassKey(this.userId);
        await k.generate(passphrase);
        k._id = _id ? _id : "passkey";
        return k;
    }

    async createKey(type) {
        let k = undefined;
        switch (type) {
            case TYPE.elGamal:
                k = new ElGamalKey(this.userId);
                break;
            case TYPE.ECDSA:
                k = new SignKey(this.userId);
                break;
            case TYPE.quorum:
                k = new QuorumKey(this.userId);
                break;
            case TYPE.quorumMember:
                k = new MemberKey(this.userId);
                break;
            default:
                error("Unknown key type");
        }
        if (!k) return error("Key creation error");

        k.changeName(this.getNewDefaultKeyName(type));

        await k.generate();
        k._id = randomId();
        this.keys[k._id] = k;
        this.modified = true;

        return k._id;
    }

    /**
     * Creation of an elGamal Key
     */
    async createElGamalKey() {
        this.startAction("main", "Create internal elgamal Key");
        let _id = await this.createKey(TYPE.elGamal)
        this.finishAction("main", true);
        // --- Save the keyStore if we can
        if (this.ismodified()) await this.save();
        return _id;
    }

    /**
     * create the admin part of the quorumKey
     * 
     * @param {QumrumKey} quorumKey the corresponding QuorumKey
     * 
     */
    async createQuorumPrivateKey(quorumKey) {
        if (!(quorumKey instanceof QuorumKey)) return error("GenericKeyStore.js - createQuorumPrivateKey: need a quorumKey")
        this.startAction("main", "Create quorum member key");
        let k = new MemberKey(this.userId);
        k.create(quorumKey);
        k._id = randomId();
        this.keys[k._id] = k;
        this.modified = true;
        await this.log("GenericKeyStore.js - New MemberKey " + k._id + " created");

        this.finishAction("main", true);
        // --- Save the keyStore if we can
        if (this.ismodified()) await this.save();

        return k._id;
    }

    getFirstElgamalKeys() {
        //DTEP//console.log("lybcrypt/lib/Keys/GenericKeyStore.js - getFirstElgamalKeys");
        let keys = this.getCryptKeys([TYPE.elGamal]);
        return keys[0];
    }

    getKey(_id) {
        return this.keys[_id]
    }

    getKeyByName(name) {
        for (let keyId in this.keys) {
            if (this.keys[keyId].name == name) return this.keys[keyId];
        }
        return undefined;
    }

    get numberOfKeys() {
        return Object.keys(this.keys).length;
    }

    getQuorumMemberKey(quorumUserId, quorumKeyId) {
        for (let keyId in this.keys) {
            if (this.keys[keyId].isMemberOf(quorumUserId, quorumKeyId)) return this.keys[keyId];
        }
        return undefined;
    }


    /**
     * Compute my secretKey with all shareKeys provides by every
     * admin of the quprum
     * 
     * @param {string} quorumUserId 
     * @param {string} quorumKeyId 
     * @param {ShareKeys[]} secretSharesList 
     * @returns {MemberKey|ShareKeys|boolean} a memberKey if the decrypt is OK, or a 
     * ShareKeys if decrypt in progress by a quorum, or false if error
     */
    async computeMemberSecretKey(quorumUserId, quorumKeyId, secretSharesList) {
        debug("GenericKeyStore.js - computeMemberSecretKey " + quorumUserId + "/" + quorumKeyId, secretSharesList);

        let key = this.getQuorumMemberKey(quorumUserId, quorumKeyId);
        if (!key) return error("GenericKeyStore.js - I am not a member of this quorum", {
            "quorumUserId": quorumUserId,
            "quorumKeyId": quorumKeyId,
        });
        let clears = [];

        this.startAction("main", "Compute member secrets");

        // ---- one or more admin didnt do the job (for the moment) --
        let length = Object.keys(secretSharesList);
        if (length < key.max) return error("GenericKeyStore.js - Initalisation not finished. must wait");

        for (let rank in secretSharesList) {
            let shareKeys = secretSharesList[rank];

            if (!(shareKeys instanceof ShareKeys)) return error("GenericKeyStore.js - computeMemberSecretKey must be instance of ShareKeys");

            debug("GenericKeyStore.js - decrypt sharekeys from rank " + rank, shareKeys);
            let clear = await this.decrypt(shareKeys);
            if (!clear) return error("GenericKeyStore.js - Cannot decrypt secretSharesList");
            // ---- Decyphering by a device quorum, not finished ---
            if (clear instanceof ShareKeys) {
                secretSharesList[rank] = clear;
                return clear;
            }

            clears.push({
                "pub": clear.publicPart,
                "secret": clear.secretShare
            });
        }

        debug("GenericKeyStore.js - computeMemberSecretKey got clears ", clears);

        for (let c of clears) {
            await key.computeMySecret(c.pub, c.secret);
        }

        this.finishAction("main", true);
        // --- Save the keyStore if we can
        if (this.ismodified()) await this.save();

        return key;
    }

    async decryptSessionKey() {
        // must be overwritten
        return error("GenericKeyStore.js - decryptSessionKey GenericKeyStore. Cannot be there");
    }


    /**
     * Return the list of keys according to criterias
     * 
     * @param {[int]|int} types 
     * @param {[int]|int} usages 
     * @param {[int]|int} status 
     * @param {string} group 
     * 
     * @return {[keys]}
     */
    getKeys(types, usages, status, group) {
        let myTypes = ((types) && (!Array.isArray(types))) ? [types] : types;
        let myUsages = ((usages) && (!Array.isArray(usages))) ? [usages] : usages;
        let myStatus = ((status) && (!Array.isArray(status))) ? [status] : status;

        let a = [];
        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            if (key.available(myTypes, myUsages, myStatus, group)) a.push(key);
        }
        return a;
    }

    /**
     * Return the list of keys available for data encyphering
     * 
     * @param {[int]|int} types 
     * @param {string} group 
     * 
     * @return {[keys]}
     */
    getCryptKeys(types, group) {
        let a = [];

        // --------- A selected key for crypt -------------------------
        if (this.pref.cryptKeyId) {
            let key = this.keys[this.pref.cryptKeyId];
            if (key) {
                let myTypes = ((types) && (!Array.isArray(types))) ? [types] : types;
                if (key.isAvailable([KEYUSAGE.cipher], myTypes, undefined, group)) return ([key]);
            }
        }

        return this.getKeys(types, KEYUSAGE.cipher, undefined, group);
    }

    /**
     * Get the list of key Ids for keys which can encrypt.
     * 
     * @param {[int]|int} types 
     * @param {string} group 
     * 
     * @return {[string]}
     */
    getCryptKeyIds(types, group) {
        let ids = [];
        let keys = this.getCryptKeys(types, group);
        for (let key of keys) {
            ids.push(key._id);
        }
        return ids;
    }


    /**
     * Return a list of available keys for adding a wanted
     * 
     */
    getAvailableKeysForWanted(preferedId) {
        return this.getKeysUsage([KEYUSAGE.askWanted], preferedId);
    }

    checkKeysUsage(listOfUsages) {
        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            if (key.isAvailable(listOfUsages)) return true;
        }
        return false;
    }

    getKeysUsage(listOfUsages, preferedId) {

        if (preferedId) {
            let key = this.keys[preferedId];
            if ((key) && (key.isAvailable(listOfUsages))) return [key];
        }

        let keys = [];
        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            if (key.isAvailable(listOfUsages)) keys.push(key);
        }
        return keys;
    }


    /**
     * Test if can sign keys
     * @return {boolean} true if can sign
     */
    canSignKeys() {
        return false;
    }

    /**
     * Test if at least one key is available for crypt
     * @return {boolean} true if can crypt
     */
    canCrypt() {
        return this.checkKeysUsage([KEYUSAGE.cipher]);
    }


    canDecrypt(shareKeys) {
        if (!(shareKeys instanceof ShareKeys)) return error("GenericKeyStore.js - canDecrypt must be instance of ShareKeys");

        for (let keyId of shareKeys.keyIds) {
            let share = shareKeys.getByKey(keyId);
            if (!(share instanceof Share)) continue;
            let key = (keyId == "0") ? this.getFirstElgamalKeys() : this.keys[keyId];
            if (!key) continue;
            if (key.isAvailableForDecyphering()) return true;
        }
        return false;
    }

    /**
     * Return the list of available Key for signing
     */
    getSignKeys() {
        return [];
    }

    getSignKeyIds() {
        return [];
    }

    async getContent() {
        let d = {
            "pref": {
                "cryptKeyId": this.pref.cryptKeyId,
                "signKeyId": this.pref.signKeyId,
                "signFileKeyId": this.pref.signFileKeyId,
            },
            "name": this.name,
            "myTrustLevel": this.myTrustLevel,
            "userId": this.userId,
            "keys": {},
        }
        for (let keyId in this.keys) {
            d.keys[keyId] = await this.keys[keyId].getContent();
        }
        return d;
    }

    async getInfos() {
        let d = {
            "pref": {
                "cryptKeyId": this.pref.cryptKeyId,
                "signKeyId": this.pref.signKeyId,
                "signFileKeyId": this.pref.signFileKeyId,
            },
            "name": this.name,
            "myTrustLevel": this.myTrustLevel,
            "userId": this.userId,
            "keys": {},
        }
        for (let keyId in this.keys) {
            d.keys[keyId] = await this.keys[keyId].getInfos();
        }
        return d;
    }


    /**
     * Return only the public part (no encrypted privete key)
     * for all keys (in READY status), or only keys marked as prefered
     */
    async getPublicContent() {
        let d = {
            "pref": {
                "cryptKeyId": this.pref.cryptKeyId,
            },
            "keys": {},
            "userId": this.userId,
            "name": this.name,
        }
        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            d.keys[keyId] = await this.keys[keyId].getPublicContent();
        }
        return d;
    }

    async setContent(d) {
        this.modified = false;
        if (d.pref) {
            this.pref = {
                "cryptKeyId": d.pref.cryptKeyId,
                "signKeyId": d.pref.signKeyId,
                "signFileKeyId": d.pref.signFileKeyId,
            }
        }
        if ((d.userId) && (d.userId != "0")) {
            if ((!this.userId) || (this.userId == "0")) this.userId = d.userId
        }
        if ((!this.userId) || (this.userId == "0")) {
            error("GenericKeyStore.js - userId of the keyStore is null or \"0\"");
        }

        this.name = d.name;
        this.myTrustLevel = d.myTrustLevel > manager.applicationTrustLevel ? d.myTrustLevel : manager.applicationTrustLevel;

        for (let keyId in d.keys) {
            let k = undefined;
            let key = d.keys[keyId];
            switch (key.type) {
                case TYPE.elGamal:
                    k = new ElGamalKey(this.userId);
                    break;
                case TYPE.ECDSA:
                    k = new SignKey(this.userId);
                    break;
                case TYPE.quorum:
                    k = new QuorumKey(this.userId);
                    break;
                case TYPE.quorumMember:
                    k = new MemberKey(this.userId);
                    break;
                default:
                    error("Unknown key type");
            }
            if (!k) continue;
            await k.setContent(key)
            this.keys[keyId] = k;
        }
        return true;
    }

    async setPublicContent(d) {
        return this.setContent(d);
    }

    get quorumInfos() {
        let quorumInfos = {};
        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            if (key.status == STATUS.revoked) continue;
            if (key.type == TYPE.quorumMember) {
                if (!quorumInfos[key.quorumUserId]) quorumInfos[key.quorumUserId] = {};
                quorumInfos[key.quorumUserId][key.quorumKeyId] = key.quorumInfos;
                continue;
            }
            if (key.type == TYPE.quorumKey) {
                if (!quorumInfos[key.quorumUserId]) quorumInfos[key.quorumUserId] = {};
                quorumInfos[key.quorumUserId][key.quorumKeyId] = key.getInfos;
                continue;
            }
        }
        return quorumInfos;
    }

    /**
     * crypt any data type for a user.
     * 
     * @param {{}|string|ArrayBuffer} data json object or string
     * @param {string} encryptedFormat the output format
     * @return {ShareKeys} 
     */
    async crypt(data, encryptedFormat = "base64") {

        //await this.genSymKey();

        // --- create a session Key ---
        let sessionKeyId = this.createSessionKey();
        let sessionKey = this.sKeys[sessionKeyId];

        let r = await this.symGenerateKey(sessionKeyId, this);
        if (r == false) return error("GenericKeyStore.js - Error while generating Session key")

        let shareKeys = new ShareKeys(this.userId);

        for (let key of sessionKey.linkedKeys) {
            let s = await key.cipherSessionKey(sessionKey);
            if (s instanceof Share) shareKeys.addOrUpdate(s);
        }

        if (shareKeys.numberOfShares == 0) return error("Error ciphering");
        await this.log("GenericKeyStore.js - Crypt for me(" + this.userId + ") done");

        // ------- transform into a string or an arraybuffer---------
        let type = undefined;
        let s = undefined;

        if (data instanceof ArrayBuffer) {
            type = "arraybuffer"
            s = data;
        } else if (data instanceof Object) {
            type = "json";
            s = JSON.stringify(data);
        } else {
            type = "string";
            s = data;
        }

        // ------- transform into a string or an arraybuffer---------
        shareKeys.addorUpdateSymEncryption(await this.symCipher(sessionKeyId, s, encryptedFormat), type, encryptedFormat);
        this.deleteSessionKey(sessionKeyId);
        return shareKeys;
    }


    async decrypt(shareKeys) {

        if (!(shareKeys instanceof ShareKeys)) return error("GenericKeyStore.js - decrypt must be instance of ShareKeys");
        debug("GenericKeyStore.js - decrypt sessionKey = ", shareKeys);

        let sessionKeyId = await this.decryptSessionKey(shareKeys);
        if (!sessionKeyId) return error("error decyphering session key");

        // --- retrieve symetric encrypted data from the shareKeys
        let ouputFormat = shareKeys.clearFormat == "arraybuffer" ? "raw" : "string";
        let clear = await this.symDecipher(sessionKeyId, shareKeys.encrypted, shareKeys.encryptedFormat, ouputFormat);

        // ---- drop the session key ---
        this.deleteSessionKey(sessionKeyId);

        if (shareKeys.clearFormat == "json") return JSON.parse(clear);
        debug("decrypt clear = " + clear);

        return clear;
    }


    /**
     * Crypt the session Key with all available keys in the store
     * (or the prefered one)
     * 
     */
    async cipherSessionKey(sessionKeyId) {
        if (!sessionKeyId) return error("GenericKeyStore.js - error cipherSessionKey no session key");

        let sessionKey = this.sKeys[sessionKeyId];
        if (!sessionKey) return error("GenericKeyStore.js - error cipherSessionKey key for sessionKeyId = " + sessionKeyId + " not found");

        let keys = this.getCryptKeys();
        let shareKeys = new ShareKeys(this.userId);
        for (let key of keys) {
            let s = await key.cipherSessionKey(sessionKey);
            if (s instanceof Share) shareKeys.addOrUpdate(s);
        }

        if (shareKeys.numberOfShares == 0) return error("GenericKeyStore.js - Error ciphering");
        await this.log("GenericKeyStore.js - Crypt for me(" + this.userId + ") done");

        return shareKeys;
    }


    /**
     * decrypt with the first available key which can decrypt
     * Decrypt the sessionKey (AES key) and install it into a SessionKey object
     * 
     * @param {ShareKeys} shareKeys 
     * @param {string[]} providedKeyIds 
     * @param {string} passphraseForPassKey // in case of encryption with a passphrase (PassKey)
     * @return {SessionKey} or false if pbs
     */
    async decryptSessionKeyInternal(shareKeys, providedKeyIds, passphraseForPassKey) {
        if (!(shareKeys instanceof ShareKeys)) return error("GenericKeyStore.js - decryptSessionKeyInternal must be instance of ShareKeys");

        let keyIds = providedKeyIds ? providedKeyIds : shareKeys.keyIds;

        for (let keyId of keyIds) {
            //DTEP//console.log("GenericKeyStore.js - decryptSessionKeyInternal - keyId : ", keyId);
            let share = shareKeys.getByKey(keyId);

            // --- try to decypher with a passphrase
            if ((share instanceof Share) && (share.isEncryptedWithPass())) {
                if (!passphraseForPassKey) continue;
                const passKey = await this.createTemporaryPassKey(passphraseForPassKey);
                let sessionKey = await passKey.decipherSessionKey(share);
                if (sessionKey instanceof SessionKey) {
                    await this.log("GenericKeyStore.js - SessionKey decrypted with passphrase ");
                    return (this.createSessionKey(sessionKey));
                }
            }

            // --- For compatibility
            let key = (keyId === "0") ? this.getFirstElgamalKeys() : this.keys[keyId];
            //DTEP//console.log("GenericKeyStore.js - decryptSessionKeyInternal - key : ", key);
            if (!key) {
                //DTEP//console.log("GenericKeyStore.js - decryptSessionKeyInternal - continue");
                continue;
            }
            if (!key.canDecrypt()) {
                // --- try to release privateKey with the PassPhraseManager
                if (!this.passManager) continue;
                let passphrase = await this.passManager.getPassphrase();
                if (!passphrase) continue;
                let r = await key.releasePrivateKey(passphrase);
                if (!r) continue;
                if (!key.canDecrypt()) continue;
                //DTEP//console.log("GenericKeyStore.js - decryptSessionKeyInternal - !key.canDecrypt");
                debug("GenericKeyStore.js - decryptSessionKeyInternal key  " + key._id + " private part released by  PassphraseManager");
            }

            //DTEP//console.log("GenericKeyStore.js - decryptSessionKeyInternal - key.canDecrypt");
            if (!share) share = shareKeys.getByKey("0");

            if (!(share instanceof Share)) {
                error("decryptSessionKey share " + keyId + " not instance of Share. Skipped.");
                continue;
            }
            let sessionKey = await key.decipherSessionKey(share);
            if (sessionKey instanceof SessionKey) {
                await this.log("GenericKeyStore.js - SessionKey decrypted with key " + key._id);
                return (this.createSessionKey(sessionKey));
            }
        }
        return false;
    }


    getKeyFromDeviceKeyStore1() {
        return undefined;
    }


    /**
     * 
     * add a wanted to all available shareKeys
     * 
     * @param {ShareKeys} shareKeys List of Shares
     * @param {string} [expire=0] optional expiration date
     * @return list of keyIds who add wanted
     */
    async addWanted(shareKeys, expire = 0) {

        if (!(shareKeys instanceof ShareKeys)) return error("GenericKeyStore.js - addWanted must be instance of ShareKeys");
        let wantedKeyIds = [];

        let keys = this.getAvailableKeysForWanted();
        if (keys.length == 0) return error("GenericKeyStore.js - No available key for addWanted");
        for (let keyId of shareKeys.keyIds) {
            let share = shareKeys.getByKey(keyId);
            if (!(share instanceof Share)) return error("GenericKeyStore.js - Cannot addWanted to a non share Structure");
            if (!share.canAddWanted()) continue;
            for (let key of keys) {
                await key.addWanted(share)
                wantedKeyIds.push(key._id);
            }
        }
        await this.log("GenericKeyStore.js - Wanted added with keys " + wantedKeyIds.join(', '));
        return wantedKeyIds;
    }



    /**
     * accept wanted for a simple elGamal key
     * 
     * @param {ShareKeys} shareKeys The shareKeys
     * @param {string} userId The Id of the user who want the access.
     * @param {[string]} trustKeyIds The list of his keys i trust.
     * @param {[string]} selectedKeysIds The list of keyIds i choose in the interface.
     * 
     * @return {false|ShareKeys} the shareKeys for userId (or false if error).
     */
    async acceptWantedIntern(shareKeys, userId, trustKeyIds, selectedKeysIds, globalPassphrase) {

        debug(this.userId + " acceptWantedIntern", {
            shareKeys: shareKeys,
            userId: userId,
            trustKeyIds: trustKeyIds,
            selectedKeysIds: selectedKeysIds,
            globalPassphrase: globalPassphrase,
        });

        let listOfKeyIds = selectedKeysIds ? selectedKeysIds : Object.keys(this.keys);

        for (let keyId of listOfKeyIds) {
            let key = this.keys[keyId];
            if (!key) continue;
            if (!key.canAcceptWanted()) {
                if ((key.canAddWanted()) && (key.type == TYPE.quorum) && (globalPassphrase)) {
                    debug("GenericKeyStore.js - acceptWantedIntern With a device quorum key  " + key._id, key);
                } else {

                    // --- try to release privateKey with the PassPhraseManager
                    if (!this.passManager) continue;
                    let passphrase = await this.passManager.getPassphrase();
                    if (!passphrase) continue;
                    let r = await key.releasePrivateKey(passphrase);
                    if (!r) continue;
                    if (!key.canAcceptWanted()) {
                        debug("GenericKeyStore.js - acceptWantedIntern key  " + key._id + " cannot accept wanted ", key);
                        continue;
                    }
                    debug("GenericKeyStore.js - acceptWantedIntern key  " + key._id + " private part released by  PassphraseManager");
                }
            }

            let share = shareKeys.getByKey(keyId);
            if (!share) share = shareKeys.getByKey("0");
            if (!share) continue;

            if (!(share instanceof Share)) {
                error("GenericKeyStore.js - acceptWantedIntern share " + keyId + " not instance of Share. Skipped.", share);
                continue;
            }
            if (!share.isWantedBy(userId)) continue;
            for (let askerKeyId in share.getWanted(userId)) {
                if (trustKeyIds.indexOf(askerKeyId) == -1) {
                    error("GenericKeyStore.js - acceptWantedIntern askey keyId " + askerKeyId + " not trusted. Skipped.");
                    continue;
                }

                let s = undefined;
                // ---- accept directly ---
                if (key.canAcceptWanted()) {
                    s = await key.acceptWanted(share, userId, askerKeyId);
                    if (!(s instanceof Share)) {
                        error("GenericKeyStore.js - acceptWantedIntern direct key " + key._id + " error while accept. skipped");
                        continue;
                    }
                } else { // ---- accept with a quorum key ---
                    s = await this.acceptWantedWithDeviceQuorum(shareKeys, userId, askerKeyId, keyId, globalPassphrase)
                    debug("GenericKeyStore.js - acceptWantedIntern device quorum return ", s);
                    if (!(s instanceof ShareKeys)) {
                        error("GenericKeyStore.js - acceptWantedIntern device quorum key " + key._id + " error while accept. skipped");
                        continue;
                    }
                    return s;
                }

                let newShareKeys = new ShareKeys(userId);
                newShareKeys.duplicate(shareKeys);
                newShareKeys.userId = userId;

                newShareKeys.addOrUpdate(s);
                debug("GenericKeyStore.js - acceptWantedIntern new ShareKeys", newShareKeys);
                return (newShareKeys);
            }
        }

        debug("GenericKeyStore.js - acceptWantedIntern cannot accept");
        return false;
    }


    async acceptWantedWithDeviceQuorum() {
        // must be overwritten
        return error("GenericKeyStore.js - acceptWantedWithDeviceQuorum cannot be there. Must be overwritten");
    }


    /**
     * accept wanted for a quorum
     * 
     * @param {ShareKeys} shareKeys The shareKeys
     * @param {string} userId The Id of the user who want the access.
     * @param {[string]} trustKeyIds The list of his keys i trust.
     * @param {[string]} memberKeyIds The list of member keys related to quorum keys.
     * @param {[string]} selectedKeysIds The list of keyIds i choose in the interface.
     * 
     * @return {false|true|ShareKeys} the shareKeys for userId (or false if error, or tru if surcrypt added).
     */
    async acceptWantedQuorumIntern(shareKeys, userId, trustKeyIds, memberKeyIds, selectedKeysIds) {

        debug(this.userId + " acceptWantedQuorumIntern", {
            shareKeys: shareKeys,
            userId: userId,
            trustKeyIds: trustKeyIds,
            memberKeyIds: memberKeyIds,
            selectedKeysIds: selectedKeysIds,
        });

        let listOfKeyIds = selectedKeysIds ? selectedKeysIds : memberKeyIds;
        let oneAddSurcrypt = false;

        for (let keyId of listOfKeyIds) {
            let key = this.keys[keyId];
            if (!key) continue;

            if ((key.type == TYPE.quorumMember) && (key.status == STATUS.ready)) {
                if (!key.canAcceptWanted()) {
                    debug("GenericKeyStore.js - try to release privateKey with the PassPhraseManager for acceptWantedQuorumIntern");
                    if (!this.passManager) continue;
                    let passphrase = await this.passManager.getPassphrase();
                    if (!passphrase) continue;
                    let r = await key.releasePrivateKey(passphrase);
                    if (!r) continue;
                }
            }

            if (!key.canAcceptWanted()) {
                debug("GenericKeyStore.js - acceptWantedQuorumIntern key  " + key._id + " cannot accept wanted ", key);
                continue;
            }

            let quorumKeyId = key.quorumKeyId;
            let share = shareKeys.getByKey(quorumKeyId);
            if (!(share instanceof Share)) {
                debug("GenericKeyStore.js - acceptWantedQuorumIntern share " + quorumKeyId + " not instance of Share. Skipped.");
                continue;
            }
            if (!share.isWantedBy(userId)) continue;
            if (!share) share = shareKeys.getByKey("0");
            if (!share) continue;
            if (!share.isWantedBy(userId)) continue;

            for (let askerKeyId in share.getWanted(userId)) {
                if ((trustKeyIds) && (trustKeyIds.indexOf(askerKeyId) == -1)) {
                    error("GenericKeyStore.js - acceptWantedQuorumIntern askey keyId " + askerKeyId + " not trusted. Skipped.");
                    continue;
                }
                let s = await key.acceptWanted(share, userId, askerKeyId);
                if (s instanceof Share) {
                    let newShareKeys = new ShareKeys(userId);
                    newShareKeys.duplicate(shareKeys);
                    newShareKeys.userId = userId;

                    newShareKeys.addOrUpdate(s);
                    debug("GenericKeyStore.js - acceptWantedIntern new ShareKeys", newShareKeys);
                    return (newShareKeys);
                }
                if (s == true) oneAddSurcrypt = true;
            }
        }

        // ---- I had one at least surcrypt on a wanted 
        if (oneAddSurcrypt == true) {
            debug("GenericKeyStore.js - acceptWantedQuorumIntern sucrypt added");
            return true;

        }

        debug("GenericKeyStore.js - acceptWantedQuorumIntern cannot accept");
        return false;
    }



    /**
     * ███████╗██╗   ██╗███╗   ███╗ ██████╗██████╗ ██╗   ██╗██████╗ ████████╗
     * ██╔════╝╚██╗ ██╔╝████╗ ████║██╔════╝██╔══██╗╚██╗ ██╔╝██╔══██╗╚══██╔══╝
     * ███████╗ ╚████╔╝ ██╔████╔██║██║     ██████╔╝ ╚████╔╝ ██████╔╝   ██║   
     * ╚════██║  ╚██╔╝  ██║╚██╔╝██║██║     ██╔══██╗  ╚██╔╝  ██╔═══╝    ██║   
     * ███████║   ██║   ██║ ╚═╝ ██║╚██████╗██║  ██║   ██║   ██║        ██║  
     * ╚══════╝   ╚═╝   ╚═╝     ╚═╝ ╚═════╝╚═╝  ╚═╝   ╚═╝   ╚═╝        ╚═╝ 
     * 
     * this is the symetric part for hybrid cryptography
     */

    /**
     * Generate a Session Key from elgamal to AES encrypted key
     * and store it and return an Id
     * 
     * @param {KeyStore} keyStore 
     * @param {String[]} keyIds 
     * 
     * @return String
     */
    createSessionKey(s) {
        let id = randomId();
        if (s instanceof SessionKey) {
            this.sKeys[id] = s;
        } else {
            this.sKeys[id] = new SessionKey();
        }
        return id;
    }

    async symGenerateKey(id, keyStore, keyIds) {
        let sessionKey = this.getSessionKey(id);
        if (!sessionKey) return error("GenericKeyStore.js - sessionKey not found for id=", id);

        let kStore = keyStore ? keyStore : this;
        if (!(kStore instanceof GenericKeyStore))
            return error("GenericKeyStore.js - generateSessionKey only accept keyStore", kStore);
        if ((keyIds) && (!Array.isArray(keyIds)))
            return error("GenericKeyStore.js - generateSessionKey keyIds not an array", keyIds);

        return sessionKey.generate(kStore, keyIds);
    }

    async duplicateSessionKey(id) {
        let sessionKey = this.getSessionKey(id);
        if (!sessionKey) return error("GenericKeyStore.js - sessionKey not found for id=", id);
        let newSessionKeyId = this.createSessionKey();
        let aesKey = await sessionKey.extract();
        this.sKeys[newSessionKeyId].setKey(sessionKey.elGamalSessionKey, aesKey, sessionKey.elGamalGroup)
        return newSessionKeyId;
    }

    deleteSessionKey(id) {
        if (typeof id !== "string") return error("GenericKeyStore.js - deleteSessionKey id is not a string");
        delete(this.sKeys[id]);
        return true;
    }

    getSessionKey(id) {
        if (typeof id !== "string") return error("GenericKeyStore.js - getSessionKey id is not a string");
        this.log("GenericKeyStore.js - getSessionKey - id : ");
        this.log(id);
        this.log("GenericSessionKey - getSessionKey - this : ");
        this.log(this);
        return this.sKeys[id];
    }

    async symCipher(id, data, outputFormat) {
        let sessionKey = this.getSessionKey(id);
        if (!sessionKey) return error("GenericKeyStore.js - sessionKey not found for id=", id);
        //DTEP//console.log("GenericKeyStore.js - symCipher - sessionKey : ", sessionKey);
        this.log(sessionKey);
        return sessionKey.cipher(data, outputFormat);
    }

    async symDecipher(id, encrypted, inputFormat, outputFormat) {
        let sessionKey = this.getSessionKey(id);
        if (!sessionKey) return error("GenericKeyStore.js - sessionKey not found for id=", id);
        return sessionKey.decipher(encrypted, inputFormat, outputFormat);
    }

}