import { withStyles, withTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Zoom } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';


import { MyComponent } from '../../MyComponent.jsx';
import { translate } from '../../../../../SharedLibs/Langs.js';

import CoolText from './CoolText.jsx'


const styles = (theme) => ({
	dialog: {
		minWidth: '30%',
		minHeight: 250,
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		flexFlow: 'column',
	},
	titleTitle: {

	},
	content: {
		minWidth: 500,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	buttons: {
		display: 'flex',
	}
});

class InfoDisplay extends MyComponent {

	constructor(props) {
		super(props);

		this.setMessage = this.setMessage.bind(this);
		this.closeMessage = this.closeMessage.bind(this);

		this.state = {
			"open": false,
			"message": undefined,
			"options": {},
			"title": undefined,
		};
	}

	closeMessage() {
		this.setState({ "title": undefined, "message": undefined, 'options': {}, 'open': false });
	}

	/**
	* Set the way to contact me
	*/
	componentDidMount() {
		this.props.global.registerFunction('info', this.setMessage)
	}

	/**
	* Unregister the setMessage function
	*/
	componentWillUnmount() {
		this.props.global.registerFunction('info', undefined)
	}

	/**
	* Add a message to display
	*
	*/
	setMessage(title, message, options) {
		console.log(`info got message`, arguments);
		this.setState({ "title": title, "message": message, 'options': options, 'open': true });
	}



	render() {

		return (
			<Dialog
				id="information"
				PaperProps={{ className: classNames(this.props.classes.dialog) }}
				open={this.state.open}
				TransitionComponent={Zoom}
				onClose={this.closeMessage}
			>
				{
					this.state.title ?
						<DialogTitle>
							<div
								id='title'
								key='title'
								className={this.props.classes.title}
							>
								<Typography key='title' className={this.props.classes.titleTitle}
									align='center'
									variant='h4'
								>
									{this.state.title}
								</Typography>
							</div>
						</DialogTitle>
						:
						null
				}
				<DialogContent
					className={this.props.classes.content}
				>
					<CoolText
						global={this.props.global}
						onClick={this.closeMessage}
						key="content"
						texts={this.state.message}
					/>
				</DialogContent>
				<DialogActions
					className={this.props.classes.buttons}
				>
					<Button
						id="infoSubmit"
						variant="contained"
						color='primary'

						onClick={this.closeMessage}
					>
						{translate("Close")}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}



}


InfoDisplay.propTypes = {
	'global': PropTypes.object,
	'classes': PropTypes.object,
	'theme': PropTypes.object,
};

export default withTheme()(withStyles(styles)(InfoDisplay));