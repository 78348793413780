import {
    HASHTYPE,
} from '../Hash.js';
import {
    SymCrypt
} from '../SymCrypt.js';
import {
    TYPE,
    KEYUSAGE,
    KEYFORMAT,
    STATUS
} from './GenericKey.js';
import {
    QuorumKey
} from './QuorumKey.js';
import {
    GenericKeyStore,
} from './GenericKeyStore.js';
import {
    DeviceKeyStore
} from './DeviceKeyStore.js';
import {
    ShareKeys
} from './ShareKeys.js';
import {
    Share
} from './Share.js';
import {
    keyStoreCache
} from './KeyStoreCache.js';

import {
    UserInteract
} from './UserInteract.js';

import {
    PassphraseManager
} from './PassphraseManager.js'

import {
    debug,
    error,
    log,
    manager,
    TRUSTLEVEL,
    ASK,
} from './Manager.js';
import {
    randomId,
    toHexString
} from '../Common.js';
import {
    MemberKey
} from './MemberKey.js';


/**
 * The KeyStore for a User
 */
export class KeyStore extends GenericKeyStore {

    /**
     * constructor for a keyStore
     *
     * @param {string} userId
     * @param {string} name the user Name (a name for the keyStore)
     */
    constructor(userId, name) {
        super(userId, name);

        this.signKey = this.signKey.bind(this);

        // --- KeyStores for devices ----
        this.withDevices = true;
        this.devices = {};
        this.currentDevice = undefined;

        // --- default shared Secret for device quorum decipher
        this.defaultSharedSecret = undefined;

        // --- For signature ------
        this.signatures = {};

        // Passphrase manager
        this.passManager = new PassphraseManager();
    }

    async save() {
        if (!manager.saveKeyStore) return true;
        let content = await this.getContent();
        let publicContent = await this.getPublicContent();
        let r = await manager.saveKeyStore(this.userId, content, publicContent);
        if (!r) return false;
        await this.log("KeyStore " + this.userId + " (" + this.name + ") saved.");

        this.modified = false;
        return true
    }

    setInteractFunction(func) {
        super.setInteractFunction(func);
        if (!this.withDevices) return;
        for (let _id in this.devices) {
            this.devices[_id].setInteractFunction(func);
        }
    }

    setDebugFunction(func) {
        super.setDebugFunction(func);
        if (!this.withDevices) return;
        for (let _id in this.devices) {
            this.devices[_id].setDebugFunction(func);
        }
    }
    setErrorFunction(func) {
        super.setErrorFunction(func);
        if (!this.withDevices) return;
        for (let _id in this.devices) {
            this.devices[_id].setErrorFunction(func);
        }
    }
    setGetKeyStoresFunction(func) {
        super.setGetKeyStoresFunction(func);
        if (!this.withDevices) return;
        for (let _id in this.devices) {
            this.devices[_id].setGetKeyStoresFunction(func);
        }
    }

    setUserId(userId) {
        let oldUserId = this.userId;
        super.setUserId(userId);
        this.signatures[userId] = this.signatures[oldUserId];
        delete(this.signatures[oldUserId]);
    }

    async setPreferedKey(_id) {
        if (_id) {
            if (!this.keys[_id]) return error("lybcrypt - KeyStore.js - preferedCryptKey key not found");
            if (!this.keys[_id].isAvailable([KEYUSAGE.cipher]))
                return error("lybcrypt - KeyStore.js - preferedCryptKey key not for cipher");
        }
        this.startAction("main", "Set prefered Key");
        this.pref.cryptKeyId = _id;
        this.finishAction("main", true);
        // --- Save the keyStore if we can
        if (this.ismodified()) return await this.save();


        return true;
    }

    set preferedCryptKey(_id) {
        if (!_id) {
            this.startAction("main", "Unset prefered crypted key");
            this.pref.cryptKeyId = undefined;
            debug("lybcrypt- KeyStore.js - Unset preferedKey")
            this.finishAction("main", true);
            return;
        }
        if (!this.keys[_id]) return error("lybcrypt - KeyStore.js - preferedCryptKey key not found");

        if (this.keys[_id].isAvailable([KEYUSAGE.cipher])) {
            debug("lybcrypt- KeyStore.js - Set preferedKey " + _id);
            this.startAction("main", "Set prefered crypted key");
            this.pref.cryptKeyId = _id;
            this.finishAction("main", true);
        }
        return;
    }

    get preferedCryptKey() {
        return this.pref.cryptKeyId;
    }

    async deleteDevice(deviceId) {
        if (!this.withDevices) return error("lybcrypt - KeyStore.js - This keyStore is without devices");
        if (!this.devices[deviceId]) return error("lybcrypt - KeyStore.js - Device " + deviceId + " does not exist");
        if ((this.currentDevice) && (this.currentDevice.userId == deviceId)) return error("lybcrypt - KeyStore.js - Cannot delete current Device");

        this.startAction("main", "Delete device");

        let deviceKeyStore = this.devices[deviceId];
        for (let keyId in deviceKeyStore.keys) {
            let key = deviceKeyStore.keys[keyId];
            await key.del(this);
        }

        delete(this.devices[deviceId]);
        this.finishAction("main", true);
        await this.log("KeyStore.js - Device " + deviceId + " deleted");

        // --- Save the keyStore if we can
        if (this.ismodified()) return await this.save();

        return true;
    }

    async removeAdminFromQuorumKey(keyId, adminId) {
        let key = this.key[keyId];
        if (!key) return error("lybcrypt - KeyStore.js - removeAdminFromQuorumKey - Key not found " + keyId);
        if (!key.type == TYPE.quorumKey) return error("lybcrypt - KeyStore.js - removeAdminFromQuorumKey - Key not a quorum key");

        this.startAction("main", "remove administrator from quorum");

        await key.removeAdmin(adminId);

        this.finishAction("main", true);
        await this.log("lybcrypt - KeyStore.js - Administrator " + adminId + " removed from quorum " + keyId);

        // --- Save the keyStore if we can
        if (this.ismodified()) return await this.save();
        return true;
    }

    /**
     * Creation of an elGamal Key
     *
     * @param {string|boolean} passphrase
     *
     * passphrase = "blabla" -> passphrase
     * passphrase = undefined -> will ask for the passphrase
     * passphrase = true -> will ask for the passphrase
     * passphrase = false -> no passphrase, store localy
     */
    async createElGamalKey(passphrase = undefined) {
        this.startAction("main", "Create elgamal Key");
        let _id = await this.createKey(TYPE.elGamal)
        await this.log("lybcrypt - KeyStore.js - New elgamalKey " + _id + " created");
        /*
        let r = undefined;
        if ((passphrase === undefined) || (passphrase)) {
            let p = passphrase === true ? undefined : passphrase;
            r = await this.protectPrivateKeyWithPassphrase(_id, p);
        } else {
            r = await this.savePrivateKeyLocaly(_id);
        }
        

        if (!r) {
            this.abortAction("main");
            return false;
        }
*/
        if (this.canSignKeys()) await this.signKey(this, _id);
        this.finishAction("main", true);

        // --- Save the keyStore if we can
        if (this.ismodified()) await this.save();

        return _id;
    }

    /**
     * Creation of a key sign key
     *
     * @param {string|boolean} passphrase
     *
     * passphrase = "blabla" -> passphrase
     * passphrase = undefined -> will ask for the passphrase
     * passphrase = true -> will ask for the passphrase
     * passphrase = false -> no passphrase, store localy
     *
     */
    async createSignKey(passphrase = undefined) {
        this.startAction("main", "Create sign key");
        let _id = await this.createKey(TYPE.ECDSA);
        /*
        let r = undefined;

        if ((passphrase === undefined) || (passphrase)) {
            let p = passphrase === true ? undefined : passphrase;
            r = await this.protectPrivateKeyWithPassphrase(_id, p);
        } else {
            r = await this.savePrivateKeyLocaly(_id);
        }
        if (!r) {
            this.abortAction("main");
            return false;
        }
        */

        let canAlreadySign = this.canSignKeys();
        if (canAlreadySign) await this.signKey(this, _id);
        // --- Sign all previous Keys with this new one --
        for (let keyId in this.keys) {
            if (_id == keyId) continue;
            let key = this.keys[keyId];
            if (key.isAvailable([])) await this.signKey(this, keyId);
        }
        this.finishAction("main", true);
        await this.log("New signKey " + _id + " created");

        // --- Save the keyStore if we can
        if (this.ismodified()) await this.save();


        return _id;
    }


    async create(passphrase, withDeviceKeyStore = true) {
        this.startAction("main", "Create keyStore");
        let _id = await this.createSignKey(passphrase ? passphrase : false);
        if (!_id) return false;
        //if (passphrase)
        await this.protectPrivateKeyWithPassphrase(_id, passphrase);
        _id = await this.createElGamalKey(passphrase ? passphrase : false);
        //if (passphrase)
        await this.protectPrivateKeyWithPassphrase(_id, passphrase);
        if (!_id) return false;
        this.defaultSharedSecret = randomId(); //"12345";

        if (withDeviceKeyStore) {
            this.currentDevice = await this.createDeviceKeyStore();
        }

        this.finishAction("main", true);
        await this.log("KeyStore initialized");



        // --- Save the keyStore if we can
        if (this.ismodified()) await this.save();

        return true;
    }

    async releaseAllKeysWithPassphrase(passphrase) {
        if (this.passManager) {
            //debug("lybcrypt - KeyStore.js -releaseAllKeysWithPassphrase with PassphraseManager " + passphrase);
            debug("lybcrypt - KeyStore.js -releaseAllKeysWithPassphrase with PassphraseManager and given passphrase");
            return await this.passManager.save(passphrase);
        }

        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            if (!key) continue;
            if (key.type == TYPE.quorum) continue;
            if (key.status != STATUS.ready) continue;
            if (key.privateKeyReleased) continue;
            await key.releasePrivateKey(passphrase);
            debug("lybcrypt - KeyStore.js -releasePrivateKey for key  " + key._id + " = " + key.privateKeyReleased);
        }
    }

    /**
     * Drop all private keys for keyIds
     *
     * @param {string|[string]} keyIds
     *
     * keyIds = string -> drop the private key for this key.
     * keyIds = [string] -> drop the private key for those keys.
     * keyIds = undefined -> drop all private key.
     *
     */
    async dropPrivateKeys(keyIds) {
        let kIds = keyIds ? Array.isArray(keyIds) ? keyIds : [keyIds] : undefined;

        for (let keyId in this.keys) {
            if ((kIds) && (kIds.indexOf(keyId) == -1)) continue;
            let key = this.keys[keyId];
            if (!key) continue;
            if (!key.privateKeyReleased) continue;
            await key.dropPrivateKey();
        }

        return true;
    }


    async justReleasePrivateKeys(keyIds, passphrase) {
        let kIds = keyIds ? Array.isArray(keyIds) ? keyIds : [keyIds] : undefined;

        let interact = new UserInteract(ASK.RELEASEPRIVATE, this.name, this.currentDevice.name);

        for (let keyId in this.keys) {
            if ((kIds) && (kIds.indexOf(keyId) == -1)) continue;
            let key = this.keys[keyId];
            if (!key) continue;
            if (key.privateKeyReleased) continue;

            if (passphrase) {
                await key.releasePrivateKey(passphrase);
                if (key.privateKeyReleased) continue;
            }

            if (this.passManager) {
                let pass = await this.passManager.getPassphrase();
                await key.releasePrivateKey(pass);
                if (key.privateKeyReleased) continue;
            }

            interact.addKey(await key.getInfos());
        }

        if (interact.numberOfKeys == 0) return true;

        for (let attempt = 0; attempt < manager.MAXATTEMPTPASSPHRASE; attempt++) {
            interact.currentAttempts = attempt;
            let r = await manager.interfaceFunc(interact).catch((e) => {
                debug("lybcrypt - KeyStore.js -ask return an error", e);
                return false;
            })
            if (!r) {
                debug("lybcrypt - KeyStore.js -Aborted by User")
                return false;
            }

            let releaseWithGlobalPassphrase = false;

            // ---- release and protect with the newPassphrase ---
            this.startAction("main", "Release private key");
            let oneError = false;
            for (let keyId of r.selectedKeyIds) {
                if ((kIds) && (kIds.indexOf(keyId) == -1)) continue;
                let key = this.keys[keyId];
                if (!key) continue;
                let passphrase = r.keyPassphrase[keyId] ? r.keyPassphrase[keyId] : r.globalPassphrase;
                let res = await key.releasePrivateKey(passphrase);
                if (!res) {
                    interact.setBadPassphrase(keyId);
                    oneError = true;
                    continue;
                }
                releaseWithGlobalPassphrase = r.keyPassphrase[keyId] ? false : true;
            }
            if (oneError) {
                this.abortAction("main");
            } else {
                this.finishAction("main", false);

                // --- Save the global passphrase in the passManager.
                if ((this.passManager) && (releaseWithGlobalPassphrase))
                    this.passManager.save(r.globalPassphrase);
                return true;
            }
        }

        return error("lybcrypt - KeyStore.js - Too many attempts");
    }

    /**
     * change the protection of privateKeys with new passphrase
     *
     * @param {string} newPassphrase
     * @param {[string]} keyIds
     * @param {string} oldPassphrase
     */
    async changePassphrase(newPassphrase, keyIds, oldPassphrase) {
        debug("lybcrypt - KeyStore.js - changePassphrase ", {
            "newPassphrase": newPassphrase,
            "keyIds": keyIds,
            "oldPassphrase": oldPassphrase
        });

        let interact = new UserInteract(ASK.CHANGEPASSPHRASE, this.name, this.currentDevice.name);
        let keysToChangeDirectly = [];
        for (let keyId in this.keys) {
            if ((keyIds) && (keyIds.indexOf(keyId) == -1)) continue;
            let key = this.keys[keyId];
            if (!key) continue;
            debug("lybcrypt - KeyStore.js - Try to change passphrase for key " + key._id + " " + key.name);
            if (!key.isAvailable([KEYUSAGE.decipher, KEYUSAGE.signKey])) continue;
            if (key.privateKeyStoredLocaly) continue;
            debug("lybcrypt - KeyStore.js - Must change passphrase for key " + key._id + " " + key.name);
            if ((!key.privateKeyReleased) && (oldPassphrase)) {
                debug("lybcrypt - KeyStore.js - Release privatekey with oldPassphrase for key " + key._id + " " + key.name);
                await key.releasePrivateKey(oldPassphrase);
            }
            if ((!key.privateKeyReleased) && (this.passManager)) {
                let oldP = await this.passManager.getPassphrase();
                debug("lybcrypt - KeyStore.js - Release privatekey with passManager for key " + key._id + " " + key.name);

                await key.releasePrivateKey(oldP);

            }
            if (key.privateKeyReleased) {
                debug("lybcrypt - KeyStore.js - Privatekey released for key " + key._id + " " + key.name);
                key.resetTimeout();
                if (newPassphrase) {
                    keysToChangeDirectly.push(keyId);
                } else {
                    interact.addKey(await key.getInfos());
                }
                continue;
            }
            if (oldPassphrase)
                return false;
            interact.addKey(await key.getInfos());
        }

        // --- change encryption for keys directly available
        if (keysToChangeDirectly.length > 0) {
            this.startAction("main", "Changing key protection");
            for (let keyId of keysToChangeDirectly) {
                let key = this.keys[keyId];
                let r = await key.cryptPrivateKeyWithPassphrase(newPassphrase);
                if (!r) return error("lybcrypt - KeyStore.js - Error while changing passphrase");
                await this.log("Passphrase changed for key " + key._id);
            }
            // ---Recording the passphrase in the new passphrase manager ---
            if (this.passManager) {
                this.passManager.save(newPassphrase);
            }
        }

        // ---- No key available for changing 
        if (interact.numberOfKeys == 0) {
            debug("lybcrypt - KeyStore.js - No keys for changing passphrase");
            if (keysToChangeDirectly.length > 0) {
                this.finishAction("main");
                // --- Save the keyStore if we can
                if (this.ismodified()) return await this.save();
            }
            return true;
        }

        if (oldPassphrase)
            interact.setDefaultGlobalPassphrase(oldPassphrase);
        if (newPassphrase)
            interact.setDefaultNewGlobalPassphrase(newPassphrase);

        for (let attempt = 0; attempt < manager.MAXATTEMPTPASSPHRASE; attempt++) {
            interact.currentAttempts = attempt;
            let r = await manager.interfaceFunc(interact).catch((e) => {
                debug("lybcrypt - KeyStore.js - Ask return an error", e);
                return false;
            })
            if (!r) {
                debug("lybcrypt - KeyStore.js - Aborted by User")
                return false;
            }

            if (!r.newPassphrase) {
                debug("lybcrypt - KeyStore.js - Must choose a new passphrase");
                continue;
            }
            // ---- release and protect with the newPassphrase ---
            let canSaveGlobalPassphrase = false;
            this.startAction("main", "Changing key protection");
            for (let keyId of r.selectedKeyIds) {
                if ((keyIds) && (keyIds.indexOf(keyId) == -1)) continue;
                let key = this.keys[keyId];
                if (!key) continue;
                let passphrase = r.keyPassphrase[keyId] ? r.keyPassphrase[keyId] : r.globalPassphrase;
                let res = await key.releasePrivateKey(passphrase);
                if (!res) {
                    interact.setBadPassphrase(keyId);
                    continue;
                }
                res = await key.cryptPrivateKeyWithPassphrase(r.newPassphrase);
                if (!res) return error("lybcrypt - KeyStore.js - Error while changing passphrase");
                await this.log("Passphrase changed for key " + key._id);
                canSaveGlobalPassphrase = true;
            }

            if (canSaveGlobalPassphrase) {
                // --- Save the global passphrase in the passManager.
                if (this.passManager)
                    this.passManager.save(r.newPassphrase);
                this.finishAction("main");

                // --- Save the keyStore if we can
                if (this.ismodified()) return await this.save();

                return true;
            }

        }


        return error("lybcrypt - KeyStore.js - Too many attempts");
    }



    /**
     * encrypt the private key with the passphrase.
     * If passphrase is undefined, try with the passphrase
     * from the passphraseManager, otherwise ask the user.
     *
     * @param {*} keyId
     * @param {*} givenPassphrase
     */
    async protectPrivateKeyWithPassphrase(keyId, givenPassphrase) {
        let k = this.getKey(keyId);
        if (!k) return error("lybcrypt - KeyStore.js - Key not found", {
            "_id": keyId
        });

        let passphrase = givenPassphrase;
        //debug("lybcrypt - KeyStore.js - protectPrivateKeyWithPassphrase with passphrase= " + passphrase);
        debug("lybcrypt - KeyStore.js - protectPrivateKeyWithPassphrase with given passphrase");

        if (!passphrase) {
            if (this.passManager) passphrase = await this.passManager.getPassphrase();
            debug("lybcrypt - KeyStore.js - protectPrivateKeyWithPassphrase - Get from passManager= " + passphrase);
            if (!passphrase) {
                let deviceName = this.currentDevice ? this.currentDevice.name : undefined;
                let interact = new UserInteract(ASK.NEWPASSPHRASE, this.name, deviceName);
                interact.addKey(await k.getInfos());

                for (let attempt = 0; attempt < manager.MAXATTEMPTPASSPHRASE; attempt++) {
                    interact.currentAttempts = attempt;
                    let r = await manager.interfaceFunc(interact).catch((e) => {
                        debug("lybcrypt - KeyStore.js - Ask return an error", e);
                        return false;
                    })
                    if (!r) {
                        debug("lybcrypt - KeyStore.js - Aborted by User")
                        return false;
                    }
                    passphrase = r.globalPassphrase;
                    //debug("lybcrypt - KeyStore.js - protectPrivateKeyWithPassphrase - get from ask = " + passphrase);
                    debug("lybcrypt - KeyStore.js - protectPrivateKeyWithPassphrase - get passphrase from ask");
                    if (!passphrase) return false;
                    break;
                }
            }
        }

        this.startAction("main", "protect private key with passphrase");
        await this.log("Protect key " + k._id + " with passphrase");
        //debug("lybcrypt - KeyStore.js -protectPrivateKeyWithPassphrase protect with = " + passphrase);
        debug("lybcrypt - KeyStore.js -protectPrivateKeyWithPassphrase protect with passphrase ");

        let a = await k.cryptPrivateKeyWithPassphrase(passphrase);

        // --- Save the global passphrase in the passManager.
        if (this.passManager)
            this.passManager.save(passphrase);

        this.finishAction("main");
        // --- Save the keyStore if we can
        if (this.ismodified()) await this.save();

        return a;
    }


    async createDeviceKeyStore() {
        if (!this.withDevices) return error("lybcrypt - KeyStore.js - This keyStore is without any device");

        this.startAction("main", "Create device keyStore");
        let _id = randomId();
        this.devices[_id] = new DeviceKeyStore(_id, this);
        let kId = await this.devices[_id].createElGamalKey();
        // --- Sign the key of the device --
        //      if (this.canSignKeys()) await this.signKey(this.devices[_id], kId);
        this.finishAction("main", true);
        await this.log("New device KeyStore " + _id + " created");

        // --- Save the keyStore if we can
        if (this.ismodified()) await this.save();

        return this.devices[_id];
    }


    getDeviceKeyStore(_id) {
        return this.devices[_id];
    }


    getDeviceKeyStoresInfos() {
        let d = {};
        for (let _id in this.devices) {
            d[_id] = {
                "name": this.devices[_id].name,
                "current": this.devices[_id].onThisDevice
            };
        }
        return d;
    }


    get numberOfDeviceKeyStore() {
        return Object.keys(this.devices).length;
    }

    /**
     * Creation of a quorumKey
     *
     * @param {int} quorum the threshold
     * @param {string[]} adminsList list of adminId
     */
    async createQuorumKey(quorum, adminsList) {
        this.startAction("main", "Create quorum key");
        let k = new QuorumKey(this.userId);
        k.create(quorum, adminsList);
        k._id = randomId();
        this.keys[k._id] = k;
        // this.withDevices = false; // --- set no device for the case of a quorumkeyStore
        this.finishAction("main", true);
        await this.log("New QuorumKey " + k._id + " created");

        // --- Save the keyStore if we can
        if (this.ismodified()) await this.save();

        return k._id;
    }

    /**
     * Creation of a quorum Device Key
     * (call createQuorumKey and create the first member )
     *
     * @param {int} quorum the threshold
     * @param {string[]} adminsList list of adminId
     */
    async createQuorumDeviceKey(quorum, adminsList) {
        if (!this.withDevices) return error("lybcrypt - KeyStore.js - createQuorumDeviceKey - This keyStore is without any device");

        this.startAction("main", "Create quorum Device key");

        let _id = await this.createQuorumKey(quorum, adminsList);
        let k = this.getKey(_id);

        k.deviceQuorumKey = true;

        debug("lybcrypt - KeyStore.js -create a quorum device key in sharing for " + this.currentDevice.userId);
        await this.createRelatedMemberQuorumDeviceKey(k);

        this.finishAction("main", true);
        await this.log("New QuorumDeviceKey " + k._id + " created");

        // --- Save the keyStore if we can
        if (this.ismodified()) await this.save();

        return k._id;
    }


    async createRelatedMemberQuorumDeviceKey(key) {
        if (!this.withDevices) return error("lybcrypt - KeyStore.js - createRelatedMemberQuorumDeviceKey - This keyStore is without devices");
        if (!this.currentDevice) return error("lybcrypt - KeyStore.js - createRelatedMemberQuorumDeviceKey - No current device");
        this.startAction("main", "Create related quorum member key");
        debug("lybcrypt - KeyStore.js - Create a quorum device member key " + this.currentDevice.userId);

        // ---- create a memberKey and add shares ----
        let mkeyId = await this.currentDevice.createQuorumPrivateKey(key);
        if (!mkeyId) return error("lybcrypt - KeyStore.js - Error creating createQuorumPrivateKey");
        let memberKey = this.currentDevice.getKey(mkeyId);

        let d = {};
        for (let keyStoreId in key.admins) {
            d[keyStoreId] = this.devices[keyStoreId];
        }

        let secretSharesList = await memberKey.initAndShare(d);
        if (!secretSharesList) return error("lybcrypt - KeyStore.js - Error computing shareList");
        let r = await key.addShares(this.currentDevice.userId, secretSharesList);
        debug("lybcrypt - KeyStore.js - Create a member ", secretSharesList);
        debug("lybcrypt - KeyStore.js - Create a member ", key.admins);

        this.finishAction("main", true);
        await this.log("New memberKey " + mkeyId + " related to " + key._id + " created");

        // --- Save the keyStore if we can
        if (this.ismodified()) await this.save();

        return r;
    }


    async initAndShare(keyId) {
        let key = this.getKey(keyId);
        if (!key) return error("lybcrypt - KeyStore.js - Key not found", {
            "_id": _id
        });
        if (!(key instanceof MemberKey)) return error("lybcrypt - KeyStore.js - initAndShare key " + keyId + " is not a quorum Member key");

        let memberIds = key.memberIds;
        let keyStores = await this.getAnotherKeyStore(memberIds);

        // ------- keyStores Verifications ---------
        if (Object.keys(keyStores) == memberIds.length) return error("lybcrypt - KeyStore.js - initAndShare one or more keyStores for admin is missing")

        let secretSharesList = await key.initAndShare(keyStores);
        if (secretSharesList == false) return false;

        await this.log("initAndShare for key " + key._id);
        this.modified = true;
        return secretSharesList;
    }


    getQuorumSharesForMe(keyId, memberId) {
        let key = this.getKey(keyId);
        if (!key) return error("lybcrypt - KeyStore.js - Key not found", {
            "_id": _id
        });
        if (!key instanceof QuorumKey) return error("lybcrypt - KeyStore.js - initAndShare key " + keyId + " is not a quorum key");
        return key.getSharesForMe(memberId);
    }


    async addQuorumMemberShares(quorumKeyId, memberId, secretSharesList) {
        this.startAction("main", "Add quorum member's shares");
        debug("lybcrypt - KeyStore.js - addQuorumMemberShares " + quorumKeyId + " for " + memberId, secretSharesList);

        let kq = this.getKey(quorumKeyId);
        if (!kq) return error("lybcrypt - KeyStore.js -  addQuorumMemberShares - quorumKeyId " + quorumKeyId + " not found");
        let r = await kq.addShares(memberId, secretSharesList);
        if (!r) return error("lybcrypt - KeyStore.js - addQuorumMemberShares - Error adding shares");
        this.finishAction("main", true);
        await this.log("addQuorumMemberShares for key " + memberId);

        // --- Save the keyStore if we can
        if (this.ismodified()) await this.save();

        return true;
    }

    async addQuorumPublicKey(_id, userId, publicKeyString) {
        let key = this.getKey(_id);
        if (!key) return error("lybcrypt - KeyStore.js - Key not found", {
            "_id": _id
        });
        this.startAction("main", "Add quorum public key");
        await this.log("addQuorumPublicKey " + _id + " for " + userId);
        this.finishAction("main", true);
        let r = key.addAdminPublicKey(userId, publicKeyString);
        if (r) {
            // --- Save the keyStore if we can
            if (this.ismodified()) await this.save();
        }
        return r;
    }


    /**
     * Test if can sign keys
     * @return {boolean} true if can sign
     */
    canSignKeys() {
        return this.checkKeysUsage([KEYUSAGE.signKey]);
    }


    /**
     * Return the list of available Key for signing
     */
    getSignKeys() {
        let a = [];

        // --------- A selected key for crypt -------------------------
        if (this.pref.signKeyId) {
            let key = this.keys[this.pref.signKeyId];
            if ((key) && (key.isAvailable([KEYUSAGE.signKey]))) {
                a.push(key)
                return a;
            }
        }

        // --------- Return all the available keys for crypt -----------
        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            if (key.isAvailable([KEYUSAGE.signKey])) a.push(key)
        }
        return a;
    }

    getSignKeyIds() {
        let ids = [];
        let keys = this.getSignKeys();
        for (let key of keys) {
            ids.push(key._id);
        }
        return ids;
    }



    async getContent() {
        let d = await super.getContent();
        d.defaultSharedSecret = this.defaultSharedSecret;
        d.signatures = this.signatures;
        //d.withDevices = this.withDevices;
        d.devices = {};
        for (let _id in this.devices) {
            d.devices[_id] = await this.devices[_id].getContent();
        }
        return d;
    }


    async getInfos() {
        let d = await super.getInfos();
        d.signatures = this.signatures;
        d.withDevices = this.withDevices;
        d.devices = {};
        for (let _id in this.devices) {
            d.devices[_id] = await this.devices[_id].getInfos();
        }
        d.currentDeviceId = this.currentDevice ? this.currentDevice.userId : undefined;
        d.manager = {
            "error": error ? true : false,
            "debug": manager.debugFlag,
            "debugStart": manager.debugStart,
            "debugFlag": manager.debugFlag,
            "saveTempoSharesToStorage": manager.saveTempoSharesToStorage ? true : false,
            "loadTempoSharesToStorage": manager.loadTempoSharesToStorage ? true : false,
            "saveKeyStore": manager.saveKeyStore ? true : false,
            "setProgressFunc": manager.setProgressFunc ? true : false,
            "getPublicKeyStores": manager.getPublicKeyStores ? true : false,
            "applicationTrustLevel": manager.applicationTrustLevel,
            "MAXATTEMPTPASSPHRASE": manager.MAXATTEMPTPASSPHRASE,
            "interfaceFunc": manager.interfaceFunc ? true : false,
        };

        if (this.passManager) {
            d.passManager = this.passManager.getInfos();
        } else {
            d.passManager = {
                "set": false
            }
        }

        return d;
    }



    /**
     * Return only the public part (no encrypted privete key)
     * for all keys (in READY status), or only keys marked as prefered
     */
    async getPublicContent() {
        let d = await super.getPublicContent();
        d.signatures = this.signatures;
        d.withDevices = this.withDevices;
        d.devices = {};
        return d;
    }

    async setContent(d, withDeviceKeyStore = true) {

        debug("lybcrypt - KeyStore.js - setContent " + this.userId);
        await super.setContent(d);
        this.signatures = d.signatures ? d.signatures : {};
        //this.withDevices = d.withDevices;
        this.defaultSharedSecret = d.defaultSharedSecret;

        // Clear the cache 
        keyStoreCache.clear();
        this.modified = false;

        this.devices = {};
        if (!this.withDevices) return true;
        if (!withDeviceKeyStore) return true;

        // --- In case of devices ---
        if (d.devices) {
            for (let _id in d.devices) {
                this.devices[_id] = new DeviceKeyStore(_id, this);
                await this.devices[_id].setContent(d.devices[_id]);
                if (this.devices[_id].onThisDevice) {
                    debug("lybcrypt - KeyStore.js - On the device " + _id + " " + this.devices[_id].name);
                    this.currentDevice = this.devices[_id];
                }
            }
        }
        if (!this.currentDevice) {
            debug("lybcrypt - KeyStore.js - On a new device, creating a keyStore");
            this.currentDevice = await this.createDeviceKeyStore();
        }

        // Check if a quorum Device key concern the current device and
        // if some job to do
        debug("lybcrypt - KeyStore.js - Test if there is a quorum key for device " + this.currentDevice.userId);
        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            if (key.type != TYPE.quorum) continue;

            debug("lybcrypt - KeyStore.js - A quorum key " + this.userId + " " + key._id);

            if (key.status == STATUS.sharing) {

                // ---- This device is not a member of this quorum
                if (!key.admins[this.currentDevice.userId]) continue;

                debug("lybcrypt - KeyStore.js - A quorum key for init " + this.userId + " " + key._id);

                // ---- shares already added ---------
                if (key.admins[this.currentDevice.userId].shares) continue;

                await this.createRelatedMemberQuorumDeviceKey(key);
                this.finishAction("main", true);
            }


            if (key.status == STATUS.setsecret) {
                // ---- This device is not a member of this quorum
                if (!key.admins[this.currentDevice.userId]) continue;

                debug("lybcrypt - KeyStore.js - A quorum key for sharing " + this.userId + " " + key._id);

                let secretSharesList = key.getSharesForMe(this.currentDevice.userId);
                if (!secretSharesList) {
                    console.error("lybcrypt - KeyStore.js - No secret shared for me in this quorum. skip");
                    continue;
                }
                let pubKey = await this.currentDevice.computeMemberSecret(this.userId, key._id, secretSharesList);
                if (!pubKey) {
                    console.error("lybcrypt - KeyStore.js - No pubKey provide by computeMemberSecret. skip");
                    continue;
                }
                this.startAction("main", "Adding device quorum public key");
                let r = await key.addAdminPublicKey(this.currentDevice.userId, pubKey);
                if (!r) {
                    console.error("lybcrypt - KeyStore.js - Error while addAdminPublicKey. skip");
                    this.finishAction("main", false);
                    continue;
                }

                this.finishAction("main", true);
            }


            if (key.status == STATUS.ready) {

                // ---- This device is not a member of this quorum
                if (!key.admins[this.currentDevice.userId]) continue;

                debug("lybcrypt - KeyStore.js - A quorum key ready " + this.userId + " " + key._id);
                this.startLoadTempoShareListener(key._id);
                continue;
            }
        }

        // --- Save the keyStore if we can
        if (this.ismodified()) {
            debug("lybcrypt - KeyStore.js - setContent - Save keyStore", this.modified);
            await this.save();
        }

        return true;
    }

    /**
     * Check if member of quorum Key and if the status is not ready
     * do what whe have to do to initialise the quorum key
     */
    async checkMemberQuorumAction() {
        this.startAction("main", "Check for quorum initialisation");

        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            if (key.type != TYPE.quorumMember) continue;

            let quorumKeyStores = await this.getAnotherKeyStore([key.quorumUserId]);
            if (!quorumKeyStores) {
                error("lybcrypt - KeyStore.js - No keyStores with quorum with Id " + key.quorumUserId);
                continue;
            }
            let quorumKeyStore = quorumKeyStores[key.quorumUserId];
            if (!quorumKeyStore) {
                error("lybcrypt - KeyStore.js - No keyStore with quorum with Id " + key.quorumUserId);
                continue;
            }

            // --- the quorum key is deleted. delete the member key
            let qk = quorumKeyStore.getKey(key.quorumKeyId);
            if ((!qk) || (qk.status == STATUS.deleted)) {
                debug("lybcrypt - KeyStore.js - The quorum key has disapeared. Droping the key", key);
                await key.del(this);
                delete(this.keys[keyId]);
                this.modified = true;
                continue;
            }
            // --- the quorum key is deleted. delete the member key

            if (key.status == STATUS.ready) continue;
            debug("lybcrypt - KeyStore.js - A quorum member key with something to do", key);

            debug("lybcrypt - KeyStore.js - Got the quorumKeyStore", quorumKeyStore);
            let quorumKey = quorumKeyStore.getKey(key.quorumKeyId);
            if (!quorumKey) {
                error("lybcrypt - KeyStore.js - No keyStore with quorum Key corresponding to the key " + key.quorumKeyId);
                continue;

            }
            // --- initAndShare --- 
            if (key.status == STATUS.sharing) {

                let memberIds = key.memberIds;
                let keyStores = await this.getAnotherKeyStore(memberIds);
                // ------- keyStores Verifications ---------
                if (Object.keys(keyStores) == memberIds.length) {
                    error("lybcrypt - KeyStore.js - initAndShare - One or more keyStores for admin is missing");
                    continue;
                }

                let secretSharesList = await key.initAndShare(keyStores);
                if (secretSharesList == false) continue;

                let r = await quorumKeyStore.addQuorumMemberShares(key.quorumKeyId, this.userId, secretSharesList);
                if (r) {
                    await this.log("initAndShare for key " + key._id + " succedded.");
                    this.modified = true;
                }
            }
            // --- init and share currently done. just check if the ohers have done the job
            if (key.status == STATUS.shared) {
                debug("lybcrypt - KeyStore.js - key status = shared, quorum key status = " + quorumKey.status);
                if (quorumKey.status == STATUS.setsecret) {
                    key.status = STATUS.setsecret;
                    this.modified = true;
                }
            }

            // Add publicKey to the quorum
            if (key.status == STATUS.setsecret) {
                debug("lybcrypt - KeyStore.js - key status = setsecret");
                let secretSharesList = quorumKeyStore.getQuorumSharesForMe(key.quorumKeyId, this.userId);
                let pubKey = await this.computeMemberSecret(key.quorumUserId, key.quorumKeyId, secretSharesList)
                if (!pubKey) {
                    error("lybcrypt - KeyStore.js - No pubKey provide by computeMemberSecret. skip");
                    continue;
                }
                let r = await quorumKeyStore.addQuorumPublicKey(key.quorumKeyId, this.userId, pubKey);
                if (!r) {
                    error("lybcrypt - KeyStore.js - Error while addQuorumPublicKey. skip");
                    continue;
                }

                key.status = STATUS.ready;
                this.modified = true;

                // --- protect the privatekey with the passphrase
                await this.protectPrivateKeyWithPassphrase(key._id);

                await this.log("computeMemberSecret for key " + key._id + " succedded.");

            }
        }
        this.finishAction("main", false);

        // --- Save the keyStore if we can
        if (this.ismodified()) {
            debug("lybcrypt - KeyStore.js - checkMemberQuorumAction - Save keyStore", this.modified);
            await this.save();
        }

        return true;

    }


    async setPublicContent(d) {
        return this.setContent(d, false);
    }

    async computeMemberSecret(quorumUserId, quorumKeyId, secretSharesList) {
        let keyPub = await this.computeMemberSecretKey(quorumUserId, quorumKeyId, secretSharesList);
        if (!keyPub) return false;

        if (keyPub instanceof ShareKeys) return keyPub;

        // ---- Sign this key -----
        let a = this.getSignKeys();
        if (a.length > 0) {
            await this.signKey(quorumUserId, quorumKeyId, keyPub);
        }
        return keyPub.getPublicKey(KEYFORMAT.STRING);
    }

    getKeyFromDeviceKeyStore1(keyId) {
        if (!this.withDevices) return undefined;
        return this.currentDevice.keys[keyId];
    }


    /**
     * return true if the admin has added his share for the others
     * ( only in state STATUS.sharing )
     *
     * @param {_ID} keyId
     * @param {_ID} userId
     *
     * @returns {undefined|boolean} true if ok, false if must add, or undefined if not the purpose
     */
    hasAdminAddedShare(keyId, userId) {
        let key = this.keys[keyId];
        if (key.type != TYPE.quorumKey) return undefined;
        return key.hasAdminAddedShare(userId);
    }

    /**
     * return true if the admin has added the public key
     * ( only in state STATUS.setsecret )
     *
     * @param {_ID} keyId
     * @param {_ID} userId
     *
     * @returns {undefined|boolean} true if ok, false if must add, or undefined if not the purpose
     */
    hasAdminAddedPubKey(keyId, userId) {
        let key = this.keys[keyId];
        if (key.type != TYPE.quorumKey) return undefined;
        return key.hasAdminAddedPubKey(userId);
    }


    /**
     * Decrypt the sessionKey (AES key) and add a new SessionKey
     * object in list of session keys
     *
     * ask user to choose key if necessary
     *
     * @param {ShareKeys} shareKeys 
     * @param {string} passphraseForPassKey in case of decyphering a share encrypted with passKey
     * @return {string} the sessionKeyId.
     */
    async decryptSessionKey(shareKeys, passphraseForPassKey) {
        if (!(shareKeys instanceof ShareKeys)) return error("lybcrypt - KeyStore.js - decryptSessionKey must be instance of ShareKeys");

        //DTEP//console.log("KeyStore.js - decryptSessionKey - start");
        //DTEP//console.log("KeyStore.js - decryptSessionKey - this : ", this);
        debug("lybcrypt - KeyStore.js - decryptSessionKey", shareKeys)
            /**
             * Try to decrypt directly with the first
             * elgamal key with privateKey released
             */

        let sessionKeyId = await this.decryptSessionKeyInternal(shareKeys, undefined, passphraseForPassKey);
        if (sessionKeyId) return (sessionKeyId);


        /**
         * Ask the user to chose keys for deciphering and give passphrase         *
         */
        let interact = await this.fillInteractDecryptKeyChoice(shareKeys);
        //DTEP//console.log("lybcrypt - KeyStore.js - decryptSessionKey - interact : ", interact);
        if (!interact) return false;

        for (let attempt = 0; attempt < manager.MAXATTEMPTPASSPHRASE; attempt++) {
            interact.currentAttempts = attempt;
            let r = await manager.interfaceFunc(interact).catch((e) => {
                debug("lybcrypt - KeyStore.js - Ask returns an error", e);
            })
            if (!r) {
                debug("lybcrypt - KeyStore.js - Aborted by user")
                return false;
            }

            // ---- Release all privateKeys we can ---
            let s = await this.releasePrivateKeys(interact, r.selectedKeyIds, r.globalPassphrase, r.keyPassphrase);
            if (s === false) {
                debug("lybcrypt - KeyStore.js - Unable to release any key");
                continue;
            }

            // ---- Try to decypher with a "normal key" --
            sessionKeyId = await this.decryptSessionKeyInternal(shareKeys, r.selectedKeyIds, r.globalPassphrase);
            if (sessionKeyId) return sessionKeyId;


            // ---- Try to decypher with a "deviceQuorum Key" --
            sessionKeyId = await this.decryptSessionKeyWithQuorum(shareKeys, r.selectedKeyIds, r.globalPassphrase);
            if (sessionKeyId) return sessionKeyId;


        }
        //DTEP//console.log("KeyStore.js - decryptSessionKey - stop");
        return error("lybcrypt - KeyStore.js - Too many attempts");
    }

    /**
     * release all private key we can
     *
     * @param {Interact} interact
     * @param {stering[]} keyIds
     * @param {string} globalPassphrase
     * @param {string[]} keyPassphrase
     * @return true if at least one privatekey is released
     *
     */
    async releasePrivateKeys(interact, keyIds, globalPassphrase, keyPassphrase) {
        if (!keyIds) return error("lybcrypt - KeyStore.js - releasePrivateKeys keyIds undefined");

        let oneKeyReleased = false;
        let canSaveGlobalPassphrase = false;
        for (let keyId of keyIds) {
            let key = this.keys[keyId];
            if (!key) continue;

            // ---- A elGamal or ECDSA key ---------------------------------
            if (key.isAvailable([KEYUSAGE.decipher, KEYUSAGE.signKey])) {
                let passphrase = keyPassphrase[keyId] ? keyPassphrase[keyId] : globalPassphrase;
                if (!passphrase) {
                    debug("lybcrypt - KeyStore.js - releasePrivateKeys - No passphrase for " + keyId + ". Skipped");
                    continue;
                }
                let r = await key.releasePrivateKey(passphrase);
                if (r === false) interact.setBadPassphrase(keyId);
                if (r === true) {
                    oneKeyReleased = true;
                    await this.log("Private key released for  " + key._id);
                    if (!keyPassphrase[keyId]) canSaveGlobalPassphrase = true;
                }
                continue;
            }

            // ---- A Device quorum key ---------------------------------
            if (key.isAvailable([KEYUSAGE.askWanted])) {
                oneKeyReleased = true;
                continue;
            }


        }

        if (canSaveGlobalPassphrase) {
            // --- Save the global passphrase in the passManager.
            if (this.passManager)
                this.passManager.save(globalPassphrase);
            return true;
        }

        return oneKeyReleased;
    }


    /**
     * build the interact object for the user.
     *
     * @param {shareKeys} shareKeys
     *
     * @return {interact}
     */
    async fillInteractDecryptKeyChoice(shareKeys) {
        if (!(shareKeys instanceof ShareKeys)) {
            return error("lybcrypt - KeyStore.js - fillInteractDecryptKeyChoice - ShareKeys must be an instance of ShareKeys");
        }

        //DTEP//console.log("lybcrypt/lib/Keys/KeyStore.js - fillInteractDecryptKeyChoice - shareKeys.keyIds.length", shareKeys.keyIds.length);
        let interact = new UserInteract(ASK.CRYPTKEYCHOICE, this.name, this.currentDevice.name);
        let oneKeyFound = false;
        //DTEP//for (let keyId of shareKeys.keyIds) {
        //DTEP//console.log("lybcrypt/lib/Keys/KeyStore.js - fillInteractDecryptKeyChoice - keyId :" + keyId);
        //DTEP//}
        for (let keyId of shareKeys.keyIds) {
            //DTEP//console.log("lybcrypt/lib/Keys/KeyStore.js - fillInteractDecryptKeyChoice - keyId : " + keyId);

            let share = shareKeys.getByKey(keyId);
            //console.log("lybcrypt/lib/Keys/KeyStore.js - fillInteractDecryptKeyChoice - share : ", share);
            if (!(share instanceof Share)) {
                error("lybcrypt - KeyStore.js - fillInteractDecryptKeyChoice - Share " + keyId + " is not an instance of Share. Skipped.");
                continue;
            }

            // --- an encryption with a passphrase (made by pPassKey)
            if (share.isEncryptedWithPass()) {
                const passKey = this.createTemporaryPassKey();
                interact.addKey(await passKey.getInfos());
            }

            let key = (keyId == "0") ? this.getFirstElgamalKeys() : this.keys[keyId];
            //DTEP//console.log("lybcrypt/lib/Keys/KeyStore.js - fillInteractDecryptKeyChoice - key", key);
            //DTEP//console.log("lybcrypt/lib/Keys/KeyStore.js - fillInteractDecryptKeyChoice - keyId", keyId);
            //DTEP//console.log("lybcrypt/lib/Keys/KeyStore.js - fillInteractDecryptKeyChoice - this.getFirstElgamalKeys()._id", this.getFirstElgamalKeys()._id);
            if (!key) continue;

            // ---- A elGamal key ---------------------------------
            //DTEP//console.log("lybcrypt - KeyStore.js - fillInteractDecryptKeyChoice - key.isAvailable : ", key.isAvailable([KEYUSAGE.decipher]))
            if (key.isAvailable([KEYUSAGE.decipher])) {
                interact.addKey(await key.getInfos());
                oneKeyFound = true;
                continue;
            }

            // ---- A Device quorum key ---------------------------------
            if (key.isAvailable([KEYUSAGE.askWanted])) {
                if (!share.isWantedBy(this.currentDevice.userId))
                    interact.addKey(await key.getInfos())
                oneKeyFound = true;
                continue;
            }

        }

        if (!oneKeyFound) {
            return error(
                "lybcrypt/KeyStore.js - fillInteractDecryptKeyChoice - No key found to decipher : usage " + KEYUSAGE.decipher
            );
        }
        return interact;
    }


    /**
     *
     * @param {ShareKeys} shareKeys
     * @param {[string]} providedKeyIds The list of the chosen quorum key
     * @param {string} globalPassphrase the temporary passphrase used to crypt/decrypt on the second device
     *
     * @return {string} the sessionKey Id
     */
    async decryptSessionKeyWithQuorum(shareKeys, providedKeyIds, globalPassphrase) {
        if (!(shareKeys instanceof ShareKeys)) return error("lybcrypt - KeyStore.js - decryptSessionKeyInternal must be an instance of ShareKeys");
        if (!this.withDevices) return error("lybcrypt - KeyStore.js - This keyStore has no device");
        if (!this.currentDevice) return error("lybcrypt - KeyStore.js - No current device");

        debug("lybcrypt - KeyStore.js - decryptSessionKeyWithQuorum - ", {
            shareKeys: shareKeys,
            providedKeyIds: providedKeyIds,
            globalPassphrase: globalPassphrase
        });

        let keyIds = providedKeyIds ? providedKeyIds : shareKeys.keyIds;
        let shareModified = false;
        let newShareCreated = false;

        // ----- Add wanted by the device ------------------
        let wantedKeyIds = await this.currentDevice.addWanted(shareKeys);
        if (wantedKeyIds === false) return error("lybcrypt - KeyStore.js - decryptSessionKeyWithQuorum - AddWanted failed");

        // ----- Accept quorum Wanted for this device ------
        // -- If wanted is for a quorum i'm member of at least one of its keys
        let myKeyIds = [];
        for (let mykeyId in this.currentDevice.keys) {
            for (let keyId of shareKeys.keyIds) {
                if (!this.currentDevice.keys[mykeyId].isMemberOf(shareKeys.userId, keyId)) continue;
                myKeyIds.push(mykeyId);
            }
        }

        let r = await this.currentDevice.acceptWantedQuorumIntern(shareKeys, this.currentDevice.userId, undefined, myKeyIds);
        if (r == false) return error("lybcrypt - KeyStore.js -decryptSessionKeyWithQuorum - acceptInternal failed");

        debug("lybcrypt - KeyStore.js - decryptSessionKeyWithQuorum - acceptInternal ok");

        let passphrase = globalPassphrase ? globalPassphrase : this.defaultSharedSecret;
        r = await this.cryptSharedKeys(shareKeys, passphrase);
        if (!r) return error("lybcrypt - KeyStore.js - Error while cyphering sharedKeys")

        // ---- Send shares to all members -------------
        for (let keyId of providedKeyIds) {
            debug("lybcrypt - KeyStore.js - saveTempoSharesToStorage " + this.userId + " " + keyId, {
                "expire": 0,
                "crypted": shareKeys.getCryptedContent(),
                "metaData": shareKeys.getInfos(),
            });
            await manager.saveTempoSharesToStorage(this.userId, keyId, {
                "expire": 0,
                "crypted": shareKeys.getCryptedContent(),
                "metaData": shareKeys.getInfos(),
            })
        }

        debug("lybcrypt - KeyStore.js - Sent to all devices done");
        let wantedList = shareKeys.wanterIds;
        let listenersIds = [];
        let proms = [];
        for (let wanterId in wantedList) {
            let wanted = wantedList[wanterId];
            for (let keyId in wanted) {
                debug("lybcrypt - KeyStore.js - Wait for new share for " + wanterId + "/" + keyId);
                proms.push(this.waitLoadTempoSharesToStorage(wanterId, keyId))
                listenersIds.push(wanterId + "/" + keyId);

            }
        }

        // --- The first quorum win !
        let content = await Promise.race(proms);
        if (!content.crypted) return error("lybcrypt - KeyStore.js - 1not a encrypted sharedKey structure ");
        if (!content.metaData) return error("lybcrypt - KeyStore.js - 2not a encrypted sharedKey structure ", content);
        if (!content.metaData.shares) return error("lybcrypt - KeyStore.js - 3not a encrypted sharedKey structure ");
        debug("lybcrypt - KeyStore.js -decryptSessionKeyWithQuorum - Got one return ", content);

        // --- Stop all Listeners 
        for (let id of listenersIds) this.stopListener(id);

        // --- clear all Temporary shares ----------
        proms = [];
        for (let keyId of providedKeyIds) {
            debug("lybcrypt - KeyStore.js - Clear saveTempoSharesToStorage " + this.userId + " " + keyId);
            proms.push(manager.saveTempoSharesToStorage(this.userId, keyId, undefined));
        }
        for (let wanterId in wantedList) {
            let wanted = wantedList[wanterId];
            for (let keyId in wanted) {
                debug("lybcrypt - KeyStore.js - Clear for new share for " + wanterId + "/" + keyId);
                proms.push(manager.saveTempoSharesToStorage(wanterId, keyId, undefined))
            }
        }
        await Promise.all(proms);
        // --- clear all Temporary shares ----------


        // --- decrypt the content ----
        let c = await this.decryptSharedKeys(content.crypted, passphrase)
        if (!c) return error("lybcrypt - KeyStore.js - Bad passphrase unable to decrypt");

        let newShareKeys = new ShareKeys(this.currentDevice.userId);
        newShareKeys.setContent(c);

        if (shareKeys.symEncrypt.encrypted != newShareKeys.symEncrypt.encrypted) {
            newShareKeys.symEncrypt.encrypted = shareKeys.symEncrypt.encrypted;

            throw new Error("lybcrypt - KeyStore.js - Error sym copy");
            //return error("lybcrypt - KeyStore.js -error sym copy");
        }

        debug("lybcrypt - KeyStore.js - Set new ShareKeys and decrypt it old", shareKeys);
        debug("lybcrypt - KeyStore.js - Set new ShareKeys and decrypt it", newShareKeys);

        let sessionKeyId = await this.currentDevice.decryptSessionKey(newShareKeys);
        // Copy the sessionKey from deviceKeyStore to currentKeyStore
        this.sKeys[sessionKeyId] = this.currentDevice.sKeys[sessionKeyId];

        return (sessionKeyId);
    }



    /**
     * Crypt the sharedKeys in AES with the passphrase.
     * keep all information in the shareKeys structure
     *
     * @param {SharedKeys} shareKeys
     * @param {string} passphrase
     */
    async cryptSharedKeys(shareKeys, passphrase) {
        if (!(shareKeys instanceof ShareKeys)) return error("lybcrypt - KeyStore.js -cryptSharedKeys shareKeys not instance of ShareKeys");
        let symCrypt = new SymCrypt(error, debug);
        await symCrypt.key.wrapKeyFromPassphrase(passphrase);
        let s = JSON.stringify(shareKeys.getContent());
        let crypted = await symCrypt.cipher(s, "base64");
        if (!crypted) return error("lybcrypt - KeyStore.js -cryptSharedKeys error while ciphering")
        shareKeys.setCryptedContent(crypted, symCrypt.key.params);
        return true;
    }


    /**
     * Decrypt the crypted SharedKeys and give a json object usable for SharedKeys.setContent()
     *
     * @param {string} cryptedContent (in "base64")
     * @param {*} passphrase
     */
    async decryptSharedKeys(cryptedContent, passphrase) {
        if (!cryptedContent) return error("lybcrypt - KeyStore.js -cannot decrypt ShareKeys, no cryptedContent");
        if (!cryptedContent.crypted) return error("lybcrypt - KeyStore.js -cannot decrypt ShareKeys, no cryptedContent");
        let p = cryptedContent.params;
        if (!p) return error("lybcrypt - KeyStore.js -cannot decrypt ShareKeys, no params");

        let symCrypt = new SymCrypt(error, debug);
        let a = await symCrypt.key.wrapKeyFromPassphrase(
            passphrase, p.keylength,
            p.salt, p.iteration,
            p.hash);
        let clear = await symCrypt.decipher(cryptedContent.crypted, "base64", "string");
        if (!clear) return error("lybcrypt - KeyStore.js -decryptSharedKeys error while deciphering (bad passphrase ?)")
        return (JSON.parse(clear));
    }

    /**
     *
     * Wait for the encrypted shareKey from another device
     *
     * @param {string} userId
     * @param {string} keyId
     *
     *
     */
    async waitLoadTempoSharesToStorage(userId, keyId) {
        let listenerId = userId + '/' + keyId;
        this.stopListener(listenerId);
        if (this.listeners[listenerId]) return error("lybcrypt - KeyStore.js -listener already up");
        return new Promise((resolve, reject) => {
            this.listeners[listenerId] = setInterval(async() => {
                let content = await manager.loadTempoSharesToStorage(userId, keyId);
                if (!content) return false;
                this.stopListener(listenerId);
                resolve(content);
            }, 500);

        })
    }



    async acceptWanted(shareKeys, userId) {
        if (!(shareKeys instanceof ShareKeys)) return error("lybcrypt - KeyStore.js -acceptWanted shareKeys must be instance of ShareKeys");

        debug("lybcrypt - KeyStore.js -" + this.userId + " acceptWanted", {
            shareKeys: shareKeys,
            userId: userId,
        });

        // ---- Test if we trust the user key  ----
        let wanterKeyIds = [];
        for (let keyId of shareKeys.keyIds) {
            let share = shareKeys.getByKey(keyId);
            if (!(share instanceof Share)) {
                error("lybcrypt - KeyStore.js -acceptWanted share " + keyId + " not instance of Share. Skipped.", share);
                continue;
            }
            if (!share.isWantedBy(userId)) continue;
            for (let askerKeyId in share.getWanted(userId)) {
                wanterKeyIds.push(askerKeyId);
            }
        }
        if (wanterKeyIds.length == 0) {
            return error("lybcrypt - KeyStore.js -No wanted keys for " + userId);
        }
        let trustKeyIds = await this.reduceTrustKeys(userId, wanterKeyIds, ASK.ACCEPTFORUNTRUST);
        if (trustKeyIds.length == 0) {
            return error("lybcrypt - KeyStore.js -All recipient publicKeys are unknown. I refuse to give access for him");
        }
        debug("lybcrypt - KeyStore.js - acceptWanted - Trusting keys for " + userId, trustKeyIds);
        // ---- Test if we trust the user key  ----


        // ---- if a wanted is for me ----
        if (shareKeys.userId == this.userId) {
            debug("lybcrypt - KeyStore.js - acceptWanted - Direct for me.");
            let s = await this.acceptWantedForMe(shareKeys, userId, trustKeyIds);
            if (s === true) return true;
            if (s instanceof ShareKeys) return s;
            return false;
        }
        // ---- if a wanted is for me ----

        // -- If wanted is for a quorum i'm member of at least one of its keys
        let myKeyIds = [];
        for (let mykeyId in this.keys) {
            for (let keyId of shareKeys.keyIds) {
                if (!this.keys[mykeyId].isMemberOf(shareKeys.userId, keyId)) continue;
                debug("lybcrypt - KeyStore.js - acceptWanted for quorum " + shareKeys.userId + "/" + keyId);
                myKeyIds.push(mykeyId);
            }
        }

        if (myKeyIds.length > 0) {
            let s = await this.acceptWantedQuorum(shareKeys, userId, trustKeyIds, myKeyIds);
            if (s === true) return true;
            if (s instanceof ShareKeys) return s;
            return false;
        }
        // -- If wanted is for a quorum i'm member of at least one of its keys

        return error("lybcrypt - KeyStore.js -acceptWanted not for me or not for a quorum i'm member");
    }


    async acceptWantedForMe(shareKeys, userId, trustKeyIds) {
        if (!(shareKeys instanceof ShareKeys)) return error("lybcrypt - KeyStore.js -acceptWantedMe accept must be instance of ShareKeys");

        debug(this.userId + " acceptWantedForMe", {
            shareKeys: shareKeys,
            userId: userId,
            trustKeyIds: trustKeyIds,
        });

        /**
         * Try to decrypt directly with the first
         * elgamal key with privateKey released
         */
        let r = await this.acceptWantedIntern(shareKeys, userId, trustKeyIds);
        if (r instanceof ShareKeys) return r;

        let interact = await this.fillInteractAcceptWantedKeyChoice(shareKeys, userId, trustKeyIds);
        if (!interact) return error("lybcrypt - KeyStore.js -No available key found for accept ");

        for (let attempt = 0; attempt < manager.MAXATTEMPTPASSPHRASE; attempt++) {
            interact.currentAttempts = attempt;
            let r = await manager.interfaceFunc(interact).catch((e) => {
                debug("lybcrypt - KeyStore.js - Ask return an error", e);
            })
            if (!r) {
                debug("lybcrypt - KeyStore.js - Aborted by User")
                return false;
            }

            // ---- Release all privateKeys we can ---
            let s = await this.releasePrivateKeys(interact, r.selectedKeyIds, r.globalPassphrase, r.keyPassphrase);
            if (s === false) {
                debug("lybcrypt - KeyStore.js - Unable to release any key");
                continue;
            }

            // ---- Try to accept wanted ------------------
            s = await this.acceptWantedIntern(shareKeys, userId, trustKeyIds, r.selectedKeyIds, r.globalPassphrase);
            if (s instanceof ShareKeys) return s;

        }
        return error("lybcrypt - KeyStore.js -Too much attempt");

    }

    async fillInteractAcceptWantedKeyChoice(shareKeys, userId, trustedWanterKeyIds) {
        if (!(shareKeys instanceof ShareKeys)) return error("lybcrypt - KeyStore.js -fillInteractAcceptWantedKeyChoice shareKeys must be instance of ShareKeys");
        let interact = new UserInteract(ASK.ACCEPTKEYCHOICE, this.name, this.currentDevice.name);
        let oneKeyFound = false;

        for (let keyId of shareKeys.keyIds) {
            let key = (keyId == "0") ? this.getFirstElgamalKeys() : this.keys[keyId];
            if (!key) continue;

            let share = shareKeys.getByKey(keyId);
            if (!(share instanceof Share)) {
                error("lybcrypt - KeyStore.js -fillInteractAcceptWantedKeyChoice share " + keyId + " not instance of Share. Skipped.");
                continue;
            }
            if (!share.isWantedBy(userId)) continue;
            let oneTrustedKeyFound = false;
            for (let wanterKeyId of trustedWanterKeyIds) {
                if (share.isWantedBy(userId, wanterKeyId)) oneTrustedKeyFound = true;
            }
            if (!oneTrustedKeyFound) continue;

            // -- An elGamal Key --------------------
            if (key.isAvailable([KEYUSAGE.acceptWanted])) {
                interact.addKey(await key.getInfos());
                oneKeyFound = true;
                continue;
            }
            // -- An quorum Device Key --------------------
            if (key.isAvailable([KEYUSAGE.askWanted])) {
                interact.addKey(await key.getInfos());
                oneKeyFound = true;
                continue;
            }

        }

        if (!oneKeyFound) return error("lybcrypt - KeyStore.js -No key found for usage " + KEYUSAGE.acceptWanted);
        return interact;
    }


    async acceptWantedQuorum(shareKeys, userId, trustKeyIds, memberKeyIds) {
        debug(this.userId + " acceptWantedQuorum", {
            shareKeys: shareKeys,
            userId: userId,
            trustKeyIds: trustKeyIds,
            memberKeyIds: memberKeyIds,
        });

        // ---- try directly ----
        let r = await this.acceptWantedQuorumIntern(shareKeys, userId, trustKeyIds, memberKeyIds);
        if (r === true) return true;
        if (r instanceof ShareKeys) return r;

        let interact = await this.fillInteractAcceptWantedQuorumKeyChoice(shareKeys, userId, trustKeyIds, memberKeyIds);
        if (!interact) return error("lybcrypt - KeyStore.js -No available key found for accept ");

        for (let attempt = 0; attempt < manager.MAXATTEMPTPASSPHRASE; attempt++) {
            interact.currentAttempts = attempt;
            let r = await manager.interfaceFunc(interact).catch((e) => {
                debug("lybcrypt - KeyStore.js - Ask returns an error", e);
            })
            if (!r) {
                debug("lybcrypt - KeyStore.js - Aborted by user")
                return false;
            }

            // ---- Release all privateKeys we can ---
            let s = await this.releasePrivateKeys(interact, r.selectedKeyIds, r.globalPassphrase, r.keyPassphrase);
            if (s === false) {
                debug("lybcrypt - KeyStore.js - Unable to release any key");
                continue;
            }

            // ---- Try to accept wanted ------------------
            s = await this.acceptWantedQuorumIntern(shareKeys, userId, trustKeyIds, memberKeyIds, r.selectedKeyIds);
            if (s === true) return true;
            if (s instanceof ShareKeys) return s;

        }
        return error("lybcrypt - KeyStore.js -Too much attempt");
    }

    async fillInteractAcceptWantedQuorumKeyChoice(shareKeys, userId, trustedWanterKeyIds, memberKeyIds) {

        if (!(shareKeys instanceof ShareKeys)) return error("lybcrypt - KeyStore.js -fillInteractAcceptWantedQuorumKeyChoice shareKeys must be instance of ShareKeys");
        let interact = new UserInteract(ASK.ACCEPTKEYCHOICE, this.name, this.currentDevice.name);
        let oneKeyFound = false;

        for (let memberKeyId of memberKeyIds) {
            let key = this.keys[memberKeyId];
            if (!key) {
                debug("lybcrypt - KeyStore.js - fillInteractAcceptWantedQuorumKeyChoice - Key not found " + memberKeyId);
                continue;
            }
            let quorumKeyId = key.quorumKeyId;
            let share = shareKeys.getByKey(quorumKeyId);
            if (!(share instanceof Share)) {
                error("lybcrypt - KeyStore.js - fillInteractAcceptWantedQuorumKeyChoice - Share " + quorumKeyId + " not instance of Share. Skipped.");
                continue;
            }
            if (!share.isWantedBy(userId)) continue;
            let oneTrustedKeyFound = false;
            for (let wanterKeyId of trustedWanterKeyIds) {
                if (share.isWantedBy(userId, wanterKeyId)) oneTrustedKeyFound = true;
            }
            if (!oneTrustedKeyFound) continue;
            interact.addKey(await key.getInfos());
            oneKeyFound = true;
        }
        if (!oneKeyFound) return error("lybcrypt - KeyStore.js -No key found for usage " + KEYUSAGE.acceptWanted);
        return interact;
    }


    async acceptWantedWithDeviceQuorum(shareKeys, userId, keyUserId, quorumKeyId, globalPassphrase) {
        if (!(shareKeys instanceof ShareKeys)) return error("lybcrypt - KeyStore.js -acceptWantedWithDeviceQuorum must be instance of ShareKeys");
        if (!this.withDevices) return error("lybcrypt - KeyStore.js -this keyStore is without devices");
        if (!this.currentDevice) return error("lybcrypt - KeyStore.js -No current device");

        debug("lybcrypt - KeyStore.js - acceptWantedWithDeviceQuorum - ", {
            shareKeys: shareKeys,
            userId: userId,
            keyUserId: keyUserId,
            quorumKeyId: quorumKeyId,
            globalPassphrase: globalPassphrase
        });

        // ----- Accept quorum Wanted for this device ------
        // -- If wanted is for a quorum i'm member of at least one of its keys
        let myKeyIds = [];
        for (let mykeyId in this.currentDevice.keys) {
            for (let keyId of shareKeys.keyIds) {
                if (!this.currentDevice.keys[mykeyId].isMemberOf(shareKeys.userId, quorumKeyId)) continue;
                myKeyIds.push(mykeyId);
            }
        }

        let r = await this.currentDevice.acceptWantedQuorumIntern(shareKeys, userId, [keyUserId], myKeyIds);
        if (r == false) return error("lybcrypt - KeyStore.js -acceptWantedWithDeviceQuorum acceptInternal failed");

        debug("lybcrypt - KeyStore.js - acceptWantedWithDeviceQuorum - Ok");

        let passphrase = globalPassphrase ? globalPassphrase : this.defaultSharedSecret;
        r = await this.cryptSharedKeys(shareKeys, passphrase);
        if (!r) return error("lybcrypt - KeyStore.js - Error while cyphering sharedKeys")

        // ---- Send shares to all members -------------
        debug("lybcrypt - KeyStore.js - saveTempoSharesToStorage " + this.userId + " " + quorumKeyId, {
            "expire": 0,
            "crypted": shareKeys.getCryptedContent(),
            "metaData": shareKeys.getInfos(),
        });
        await manager.saveTempoSharesToStorage(this.userId, quorumKeyId, {
            "expire": 0,
            "crypted": shareKeys.getCryptedContent(),
            "metaData": shareKeys.getInfos(),
        })

        debug("lybcrypt - KeyStore.js - Sent to all devices done");

        let content = await this.waitLoadTempoSharesToStorage(userId, keyUserId);

        if (!content.crypted) return error("lybcrypt - KeyStore.js - 1not a encrypted sharedKey structure ");
        if (!content.metaData) return error("lybcrypt - KeyStore.js - 2not a encrypted sharedKey structure ", content);
        if (!content.metaData.shares) return error("lybcrypt - KeyStore.js - 3not a encrypted sharedKey structure ");
        debug("lybcrypt - KeyStore.js - acceptWantedWithDeviceQuorum - Got one return ", content);

        // --- Stop the Listeners 
        this.stopListener(userId + "/" + keyUserId);

        // --- decrypt the content ----
        let c = await this.decryptSharedKeys(content.crypted, passphrase)
        if (!c) return error("lybcrypt - KeyStore.js - Bad passphrase unable to decrypt");

        let newShareKeys = new ShareKeys(userId);
        newShareKeys.setContent(c);

        debug("lybcrypt - KeyStore.js - acceptWantedWithDeviceQuorum - Set new ShareKeys", newShareKeys);
        return (newShareKeys)


    }


    /**
     * Save the private key in the local Storage
     *
     * @param {string[]} keyIdsToSave The key to save Localy
     */
    async savePrivateKeyLocaly(keyIdsToSave) {

        let keyIdsToSaveList = Array.isArray(keyIdsToSave) ? keyIdsToSave : [keyIdsToSave];

        debug("lybcrypt - KeyStore.js -savePrivateKeyLocaly", {
            "keyIdsToSaveList": keyIdsToSaveList,
        });

        let askKeyIds = [];
        for (let keyId of keyIdsToSaveList) {
            let key = this.getKey(keyId);
            if (!key) continue;

            if (key.privateKeyReleased) {
                this.modified = true;
                key.withLocalStorage = true;
                await this.log("private key fot key " + keyId + " saved localy");
                continue;
            }

            askKeyIds.push(keyId);
        }

        if (askKeyIds.length == 0) return true;

        let interact = new UserInteract(ASK.SAVELOCALY, this.name, this.currentDevice.name);

        for (let attempt = 0; attempt < manager.MAXATTEMPTPASSPHRASE; attempt++) {
            interact.currentAttempts = attempt;
            let r = await manager.interfaceFunc(interact).catch((e) => {
                debug("lybcrypt - KeyStore.js - savePrivateKeyLocaly - Asks return an error", e);
            })
            if (!r) {
                debug("lybcrypt - KeyStore.js - SavePrivateKeyLocaly - Aborted by User")
                return false;
            }

            // ---- Release all privateKeys we can ---
            let s = await this.releasePrivateKeys(interact, r.selectedKeyIds, r.globalPassphrase, r.keyPassphrase);
            if (s === false) {
                debug("lybcrypt - KeyStore.js - savePrivateKeyLocaly - Unable to release any key");
                continue;
            }

            this.startAction("main", "store keys localy")
            let oneKeyError = false;
            for (let keyId of r.selectedKeyIds) {
                let key = this.getKey(keyId);
                if (!key) continue;

                if (key.privateKeyReleased) {
                    this.modified = true;
                    key.withLocalStorage = true;
                    await this.log("private key fot key " + keyId + " saved localy");
                    continue;
                }
                oneKeyError = true;
            }

            if (!oneKeyError) {
                this.finishAction("main", true);

                // --- Save the keyStore if we can
                if (this.ismodified()) await this.save();

                return true;
            }
        }

        return error("lybcrypt - KeyStore.js - Too many attempts");

    }





    /*
     * ███████╗██╗ ██████╗ ███╗   ██╗ █████╗ ████████╗██╗   ██╗██████╗ ███████╗
     * ██╔════╝██║██╔════╝ ████╗  ██║██╔══██╗╚══██╔══╝██║   ██║██╔══██╗██╔════╝
     * ███████╗██║██║  ███╗██╔██╗ ██║███████║   ██║   ██║   ██║██████╔╝█████╗  
     * ╚════██║██║██║   ██║██║╚██╗██║██╔══██║   ██║   ██║   ██║██╔══██╗██╔══╝  
     * ███████║██║╚██████╔╝██║ ╚████║██║  ██║   ██║   ╚██████╔╝██║  ██║███████╗
     * ╚══════╝╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝
     */



    /**
     * Retreive a list of keyStores
     * @param {[string]} userIds
     * @return {{}} a list of keyStore indexed per userId
     */
    async getAnotherKeyStore(userIds) {
        let d = {};
        let searchArray = [];
        for (let userId of userIds) {
            if ((userId == this.userId) || (userId == "0") || (!userId)) {
                d[userId] = this;
                continue;
            }
            // ----- i'm looking for one of my device keystores -> ignore
            if (this.devices[userId]) {
                continue;
            }

            let keyStore = keyStoreCache.getByUserId(userId);
            if (keyStore) {
                d[userId] = keyStore;
                continue;
            }

            searchArray.push(userId);
        }

        if ((manager.getPublicKeyStores) && (searchArray.length > 0)) {
            let keyStores = await manager.getPublicKeyStores(searchArray);
            debug("lybcrypt - KeyStore.js - getPublicKeyStoresFunc - Return keyStores content", keyStores);
            if (Array.isArray(keyStores)) {
                for (let keyStore of keyStores) {
                    if (!keyStore) continue;
                    if (!keyStore.userId) continue;
                    if (d[keyStore.userId]) continue;
                    if (searchArray.indexOf(keyStore.userId) == -1) continue;

                    let k = undefined;
                    if (keyStore instanceof KeyStore) {
                        k = keyStore;
                    } else {
                        k = new KeyStore(keyStore.userId);
                        await k.setPublicContent(keyStore);
                    }
                    d[keyStore.userId] = k;
                    keyStoreCache.addKeyStore(k);
                }
            } else {
                error("lybcrypt - KeyStore.js - getPublicKeyStoresFunc - Did not return a list of keyStores")
            }
        }

        return d;
    }




    /**
     * Verify the signature of the keyId in the KeyStore given in param
     *
     * @param {KeyStore} keyStore
     * @param {string} keyId the keyId in the keyStore to sign
     * @param {int} deep the current deep in the tree
     * @return {boolean} true of ok
     */
    async deepVerifySignature(userId, keyId, myKey, path = []) {

        debug("lybcrypt - KeyStore.js - deepVerifySignature for " + userId + "/" + keyId + " by ", {
            signatures: this.signatures,
            myKey: myKey,
            path: path
        });

        let myUserId = userId == this.userId ? "0" : userId;


        if (path.length > manager.MAXTRUSTUSERS) return false;

        if ((this.signatures[myUserId]) &&
            (this.signatures[myUserId][keyId]) &&
            (this.signatures[myUserId][keyId][myKey._id])) {

            // ---- get the keyStore for verification ----------
            let keyStores = await this.getAnotherKeyStore([userId]);
            let keyStore = keyStores[userId];

            if (!keyStore) {
                debug("lybcrypt - KeyStore.js - Cannot find public keyStore for " + userId)
                return false;
            }

            // ---- This key is signed be me and myKeyId -------
            let hash = await keyStore.getFingerPrint(keyId, HASHTYPE.sha512);

            let sig = await myKey.verify(hash, this.signatures[myUserId][keyId][myKey._id].signature);

            if (sig === true) return path.concat({
                "userId": userId,
                "keyId": keyId,
                "signature": this.signatures[myUserId][keyId][myKey._id]
            });
            return error("lybcrypt - KeyStore.js - Signature mismatch");
        }

        // ---------- Try for all users and all keys i have signed ------
        let otherIds = [];
        for (let uId in this.signatures) {
            if ((uId == "0") || (uId == 0) || (uId == this.userId)) continue;
            otherIds.push(uId);
        }
        let keyStores = await this.getAnotherKeyStore(otherIds);
        for (let uId in keyStores) {
            if (uId == this.userId) continue;
            let keyStore = keyStores[uId];
            for (let kId in this.signatures[uId]) {
                let key = keyStore.getKey(kId);
                if (!key) continue;
                let pp = await keyStore.deepVerifySignature(userId, keyId, key, path.concat({
                    "userId": uId,
                    "keyId": kId,
                    "signature": this.signatures[uId][kId][myKey._id]
                }));
                if (pp) return (pp);
            }
        }
        return false;
    }


    async verifySignature(userId, keyId) {

        debug("lybcrypt - KeyStore.js - verifySignature for " + userId + "/" + keyId, this.signatures);

        let keys = this.getSignKeys();
        for (let key of keys) {
            let path = await this.deepVerifySignature(userId, keyId, key, []);
            if (path) return path;
        }
        return false;
    }


    /**
     * Return the fingerprint of the key
     *
     * @param {string} keyId
     * @param {int} hashType
     */
    getFingerPrint(keyId, hashType) {
        let key = this.keys[keyId];
        if (!key) return error("lybcrypt - KeyStore.js - Key not found", {
            "_id": keyId
        });
        return key.fingerPrint(hashType);
    }



    async fillInteractSignKeyChoice() {

        let interact = new UserInteract(ASK.SIGNKEYCHOICE, this.name, this.currentDevice.name);
        let oneKeyFound = false;
        let keyIds = this.getSignKeyIds();

        for (let keyId of keyIds) {
            let key = this.keys[keyId];
            if (!key) continue;

            if (key.privateKeyReleased) continue;

            interact.addKey(await key.getInfos());
            oneKeyFound = true;
        }

        if (!oneKeyFound) return error("lybcrypt - KeyStore.js - No key found to sign key (usage : " + KEYUSAGE.signKey + " )");
        return interact;
    }



    /**
     * Sign the key
     * with the this.pref.cryptKeyId if set or with the first one
     * with the privatekey, or ask the privateKey of one of the available
     * key to the user
     *
     * @param {KeyStore||string} keyStore the keyStore to sign or the userId
     * @param {string} keyId the leyId of this keyStore to sign
     * @return {boolean} true if OK.
     */
    async signKeyInternal(keyStore, keyId, providedKeyIds, givenKey = undefined) {

        debug("lybcrypt - KeyStore.js - signKeyInternal - ", {
            keyStore: keyStore,
            keyId: keyId,
            providedKeyIds: providedKeyIds,
            givenKey: givenKey
        });


        let key = undefined;
        let hash = undefined;
        let userId = undefined;
        let userName = undefined;
        let keyName = undefined;
        if (keyStore instanceof GenericKeyStore) {
            userId = keyStore.userId;
            key = keyStore.getKey(keyId);
            if (!key) return error("lybcrypt - KeyStore.js - Key not found", {
                "_id": keyId
            });
            hash = await key.fingerPrint(HASHTYPE.sha512);
            keyName = key.name;
            userName = keyStore.name;
        } else {
            userId = keyStore;
            hash = givenKey ? await givenKey.fingerPrint(HASHTYPE.sha512) : undefined;
        }

        if (!hash) {
            return error("lybcrypt - KeyStore.js - Key signature unable to compute hash (no key given)");
        }

        let keyIds = providedKeyIds ? providedKeyIds : this.getSignKeyIds();
        for (let kId of keyIds) {
            let key = this.keys[kId];
            if (!key) continue;

            if (!key.canSign()) {
                // --- try to release privateKey with the PassPhraseManager
                debug("lybcrypt - KeyStore.js - Try to release privateKey with the PassPhraseManager for sign");
                if (!this.passManager) continue;
                let passphrase = await this.passManager.getPassphrase();
                if (!passphrase) continue;
                let r = await key.releasePrivateKey(passphrase);
                debug("lybcrypt - KeyStore.js - Try to release privateKey with the PassPhraseManager " + passphrase + " for sign = " + r);
                if (!r) continue;
                if (!key.canSign()) {
                    debug("lybcrypt - KeyStore.js - signKeyInternal key  " + key._id + " cannot sign ", key);
                    continue;
                }
            }

            this.startAction("main", "Signing key");
            let signature = await key.sign(hash);
            if (signature) {
                this.addSignature(userId, keyId, key._id, signature, userName, keyName);
                this.finishAction("main", true);
                let myUserId = userId == this.userId ? "0" : userId;

                await this.log("Signature added for  " + myUserId + "/" + keyId + " by my key " + key._id);

                // --- Save the keyStore if we can
                if (this.ismodified()) await this.save();

                return true;
            } else {
                this.abortAction();
            }

        }
        return false;
    }



    async signUserKey(userId, keyId = undefined) {

        debug("lybcrypt - KeyStore.js - signUserKey - ", {
            userId: userId,
            keyId: keyId,
        });

        let keyStores = await this.getAnotherKeyStore([userId]);
        if (!keyStores) return error("lybcrypt - KeyStore.js - No Public keyStores returned.");
        if (!keyStores[userId]) return error("lybcrypt - KeyStore.js - Public keyStore for " + userId + " not found.");

        let keyIds = keyId ? [keyId] : keyStores[userId].getSignKeyIds();
        for (let kId of keyIds) {
            let r = await this.signKey(keyStores[userId], kId);
            if (r === false) return false;
        }
        return true;
    }


    /**
     * Sign the key
     * with the this.pref.cryptKeyId if set or with the first one
     * with the privatekey, or ask the privateKey of one of the available
     * key to the user
     *
     * @param {KeyStore||string} keyStore the keyStore to sign or the userId
     * @param {string} keyId the leyId of this keyStore to sign
     * @return {boolean} true if OK.
     */
    async signKey(keyStore, keyId, givenKey = undefined) {

        debug("lybcrypt - KeyStore.js -signKey - ", {
            keyStore: keyStore,
            keyId: keyId,
            givenKey: givenKey
        });

        /**
         * Try to decrypt directly with the first
         * elgamal key with privateKey released
         */
        let r = await this.signKeyInternal(keyStore, keyId, undefined, givenKey);
        if (r == true) return true;


        /**
         * Ask the user to chose keys for deciphering and give passphrase         *
         */
        let interact = await this.fillInteractSignKeyChoice();
        if (!interact) return false;

        for (let attempt = 0; attempt < manager.MAXATTEMPTPASSPHRASE; attempt++) {
            interact.currentAttempts = attempt;
            let r = await manager.interfaceFunc(interact).catch((e) => {
                debug("lybcrypt - KeyStore.js - signKey - Ask returns an error", e);
            })
            if (!r) {
                debug("lybcrypt - KeyStore.js -signKey - Aborted by User")
                return false;
            }

            // ---- Release all privateKeys we can ---
            let s = await this.releasePrivateKeys(interact, r.selectedKeyIds, r.globalPassphrase, r.keyPassphrase);
            if (s === false) {
                debug("lybcrypt - KeyStore.js - signKey - Unable to release any key");
                continue;
            }

            // ---- Sign --
            r = await this.signKeyInternal(keyStore, keyId, r.selectedKeyIds, givenKey);
            if (r == true) return true;
        }
        return error("lybcrypt - KeyStore.js - Too many attempts");
    }


    /**
     * Add the signature into the set of signature of this key
     *
     * @param {string} userId
     * @param {string} keyId the _Id of the ke which sign this one
     * @param {string} signature
     */
    addSignature(userId, keyId, myKeyId, signature, userName, keyName) {
        let myUserId = userId == this.userId ? "0" : userId;
        if (!this.signatures[myUserId]) this.signatures[myUserId] = {};
        if (!this.signatures[myUserId][keyId]) this.signatures[myUserId][keyId] = {};
        this.signatures[myUserId][keyId][myKeyId] = {
            "userName": userName,
            "keyName": keyName,
            "signature": signature,
            "ctime": Date.now(),
        }
        debug("lybcrypt - KeyStore.js - adding signature " + myUserId + "/" + keyId + " by my key " + myKeyId, this.signatures[myUserId][keyId][myKeyId])
        return true;
    }


    async dropSignature(userId, keyId, myKeyId) {
        if (userId) {
            if (!this.signatures[userId]) return false;
            if (keyId) {
                if (myKeyId) {
                    delete this.signatures[userId][keyId][myKeyId];
                } else {
                    delete this.signatures[userId][keyId];
                }
            } else {
                delete(this.signatures[userId]);
            }
            return true;
        }

        // ------ erase all signature i have done with my key 
        // --------------------------------------------------
        if (!myKeyId) return false;
        this.startAction("main", "Deleting signature");
        for (let uId in this.signatures) {
            for (let keyId in this.signatures[uId]) {
                delete this.signatures[uId][keyId][myKeyId];
                this.modified = true;
                await this.log("Signature deleted for " + uId + "/" + keyId + " by my key " + myKeyId);
                if (Object.keys(this.signatures[uId][keyId]).length == 0)
                    delete this.signatures[uId][keyId];
                if (Object.keys(this.signatures[uId]).length == 0)
                    delete this.signatures[uId];
            }
        }
        this.finishAction("main", false);

        // --- Save the keyStore if we can
        if (this.ismodified()) await this.save();


    }


    /**
     * Update a keyStore from a modificated keyStore from a member
     *
     * @param {String} userId
     * @param {KeyStore} keyStore
     */
    async updateKeyStoreByMember(userId, keyStore) {

        this.startAction("main", "Updating keyStore be member");
        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            let k = keyStore.getKey(keyId);

            if (!k) {
                continue;
            }
            if (key.type != TYPE.quorum) {
                continue;
            }


            if (key.getAdminRank(userId) == undefined) {
                continue;
            }

            this.keys[keyId] = k;
            this.modified = true;
        }
        this.finishAction("main", false);
        return true;
    }


    /**
     *
     *  ██████╗██╗   ██╗██████╗ ██╗  ██╗███████╗██████╗ ██╗███╗   ██╗ ██████╗
     * ██╔════╝╚██╗ ██╔╝██╔══██╗██║  ██║██╔════╝██╔══██╗██║████╗  ██║██╔════╝
     * ██║      ╚████╔╝ ██████╔╝███████║█████╗  ██████╔╝██║██╔██╗ ██║██║  ███╗
     * ██║       ╚██╔╝  ██╔═══╝ ██╔══██║██╔══╝  ██╔══██╗██║██║╚██╗██║██║   ██║
     * ╚██████╗   ██║   ██║     ██║  ██║███████╗██║  ██║██║██║ ╚████║╚██████╔╝
     *  ╚═════╝   ╚═╝   ╚═╝     ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝ ╚═════╝
     *
     */


    async trustValidation(userId, key) {
        if (!userId) return true;
        if (userId == this.userId) return true;
        if (this.trustLevel == TRUSTLEVEL.dontcare) return true;

        // --- Test if the key is signed be me
        if (await this.verifySignature(userId, key._id)) {
            debug("lybcrypt - KeyStore.js - Trust key " + userId + "/" + key._id);
            return true;
        }

        return false
    }


    async reduceTrustKeys(userId, publicKeyIds, askType) {
        if (!publicKeyIds) return [];
        if (publicKeyIds.length == 0) return [];

        if (this.trustLevel == TRUSTLEVEL.dontcare) return publicKeyIds;

        // ---- get the keyStore for verification ----------
        let keyStores = await this.getAnotherKeyStore([userId]);
        let keyStore = keyStores[userId];
        if (!keyStore) {
            debug("lybcrypt - KeyStore.js - No keyStore found for " + userId + " for reduceTrustKeys");
            return [];
        }


        let trustedKeyIds = [];
        for (let keyId of publicKeyIds) {
            let key = keyStore.getKey(keyId);
            if (!key) continue;

            let trust = await this.trustValidation(userId, key);
            if (trust) {
                trustedKeyIds.push(keyId);
                continue;
            }
        }

        // --- At least on key trusted. it is enought ----
        if (trustedKeyIds.length > 0) return trustedKeyIds;

        if (this.trustLevel == TRUSTLEVEL.paranoid) return [];


        // ----- Fill the available keys ---------------
        let interact = new UserInteract(askType, this.name, this.currentDevice.name);
        interact.otherName = keyStore.name;
        for (let keyId of publicKeyIds) {
            let key = keyStore.getKey(keyId);
            if (!key) continue;
            interact.addKey(await key.getInfos());
        }

        // ----- Fill the userId public keys in cas of signing them ------
        let signKeyIds = keyStore.getSignKeyIds();
        for (let keyId of signKeyIds) {
            let key = keyStore.getKey(keyId);
            if (!key) continue;
            interact.addUserSignKeys(await key.getInfos());
        }

        // --------- ask the user ---
        let r = await manager.interfaceFunc(interact).catch((e) => {
            debug("lybcrypt - KeyStore.js - reduceTrustKeys - Ask returns an error", e);
        })
        if (!r) {
            debug("lybcrypt - KeyStore.js - reduceTrustKeys - Aborted by User")
            return false;
        }

        // ---- sign the the userId publicKeys --------
        if ((r.signKeyIds) && (r.signKeyIds.length > 0)) {
            for (let keyId of r.selectedKeyIds) {
                let s = await this.signKey(keyStore, keyId);
            }
        }

        return (r.selectedKeyIds);
    }

    /**
     * encrypt the session key with a passphrase
     * and return a ShareKeys structure 
     * 
     * @param {string} sessionKeyId 
     * @param {string} passphrase 
     * @param {string} userId 
     */
    async cryptSessionKeyWithPass(sessionKeyId, passphrase, userId = "0") {

        if (!passphrase) {
            return error("lybcrypt - KeyStore.js - Cannot passphrase encryption without passphrase")
        }

        this.startAction(userId, "Cyphering session key with passphrase");

        let sessionKey = this.sKeys[sessionKeyId];
        if (!sessionKey) {
            this.abortAction(userId, false);
            return error("lybcrypt - KeyStore.js - SessionKeyId not found", sessionKeyId);
        }

        const passKey = await this.createTemporaryPassKey(passphrase);
        //console.log(`lybcrypt - KeysStore.js - passkey`, passKey);
        await this.log(`lybcrypt - KeysStore.js - passkey`, passKey);
        let s = await passKey.cipherSessionKey(sessionKey);
        if (!(s instanceof Share)) {
            return error("lybcrypt - KeyStore.js - Passphrase encryption error");
        }

        let shareKeys = new ShareKeys(userId);
        shareKeys.addOrUpdate(s);

        await this.log("lybcrypt - KeyStore.js - Crypt with passphrase for " + userId + " done");

        this.finishAction(userId, false);
        return shareKeys;
    }

    /**
     * encrypt the session key with one public key of the recipient
     * and return a ShareKeys structure 
     * 
     * @param {string} sessionKeyId 
     * @param {string} userId 
     */
    async cryptSessionKeyFor(sessionKeyId, userId) {

        this.startAction(userId, "Cyphering session key");

        // ---- get the keyStore for verification ----------
        let keyStores = await this.getAnotherKeyStore([userId]);
        let keyStore = keyStores[userId];
        if (!keyStore) {
            this.abortAction(userId, false);
            return error("lybcrypt - KeyStore.js - Cannot cipher for public keyStore for " + userId + ". keyStore not found");
        }

        let sessionKey = this.sKeys[sessionKeyId];
        if (!sessionKey) {
            this.abortAction(userId, false);
            return error("lybcrypt - KeyStore.js - SessionKeyId not found", sessionKeyId);
        }

        // ---- Select the recipient keys ----
        let availableKeyIds = keyStore.getCryptKeyIds(undefined, sessionKey.elGamalGroup);
        if ((!availableKeyIds) || (availableKeyIds.length == 0)) {
            this.abortAction(userId, false);
            return error("lybcrypt - KeyStore.js - The recipient " + userId + " doesnt have a crypto key for group " + sessionKey.elGamalGroup);
        }

        let trustKeyIds = await this.reduceTrustKeys(userId, availableKeyIds, ASK.CRYPTFORUNTRUST);
        if (trustKeyIds.length == 0) {
            this.abortAction(userId, false);
            return error("lybcrypt - KeyStore.js - All recipient publicKeys are unknown. I refuse to crypt for him");
        }

        let shareKeys = new ShareKeys(userId);
        for (let keyIds of trustKeyIds) {
            let key = keyStore.keys[keyIds];
            if (!key) continue;

            let s = await key.cipherSessionKey(sessionKey);
            if (s instanceof Share) shareKeys.addOrUpdate(s);
        }

        if (shareKeys.numberOfShares == 0) {
            this.abortAction(userId, false);
            return error("lybcrypt - KeyStore.js - Error ciphering");
        }
        await this.log("Crypt for " + userId + " done");

        this.finishAction(userId, false);

        return shareKeys;
    }

    /**
     * crypt any data type for a user.
     *
     * @param {string} userId the recipient's user Id
     * @param {{}|string|ArrayBuffer} data json object or string
     * @param {string} encryptedFormat the output format
     * @return {ShareKeys}
     */
    async cryptFor(userId, data, encryptedFormat = "base64") {

        this.startAction(userId, "Cyphering");
        // --- create a session Key ---
        let sessionKeyId = this.createSessionKey();

        let r = await this.symGenerateKey(sessionKeyId);
        if (r == false) {
            this.abortAction(userId);
            return error("lybcrypt - KeyStore.js - Error while generating Session key")
        }

        let shareKeys = await this.cryptSessionKeyFor(sessionKeyId, userId);
        if (!shareKeys) {
            this.abortAction(userId);
            return error("lybcrypt - KeyStore.js - Error ciphering");
        }

        // ------- transform into a string or an arraybuffer---------
        let type = undefined;
        let s = undefined;

        if (data instanceof ArrayBuffer) {
            type = "arraybuffer"
            s = data;
        } else if (data instanceof Object) {
            type = "json";
            s = JSON.stringify(data);
        } else {
            type = "string";
            s = data;
        }
        // ------- transform into a string or an arraybuffer---------
        shareKeys.addorUpdateSymEncryption(
            await this.symCipher(sessionKeyId, s, encryptedFormat), type, encryptedFormat);

        // --- drop the sessionKey
        this.deleteSessionKey(sessionKeyId);

        this.finishAction(userId, false);
        return shareKeys;
    }


    /**
     *
     *
     * ██╗     ██╗███████╗████████╗███████╗███╗   ██╗███████╗██████╗ ███████╗
     * ██║     ██║██╔════╝╚══██╔══╝██╔════╝████╗  ██║██╔════╝██╔══██╗██╔════╝
     * ██║     ██║███████╗   ██║   █████╗  ██╔██╗ ██║█████╗  ██████╔╝███████╗
     * ██║     ██║╚════██║   ██║   ██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗╚════██║
     * ███████╗██║███████║   ██║   ███████╗██║ ╚████║███████╗██║  ██║███████║
     * ╚══════╝╚═╝╚══════╝   ╚═╝   ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚══════╝
     *
     */


    async saveTempoShare1(shareKeys, keyId) {
        if (!(shareKeys instanceof ShareKeys)) return error("lybcrypt - KeyStore.js - saveTempoShare must be instance of ShareKeys");
        if (!manager.saveTempoSharesToStorage) return error("lybcrypt - KeyStore.js - saveTempoSharesToStorage undefined");
        let r = await manager.saveTempoSharesToStorage(this.userId, keyId, shareKeys.getContent());
        return r;
    }


    async decryptQuorumShareByUser(content) {
        let interact = new UserInteract(ASK.QUORUMMEMBERDECRYPT, this.name, this.currentDevice.name);
        interact.cryptedShareKeys = content;
        let shareKeys = undefined;
        for (let attempt = 0; attempt < manager.MAXATTEMPTPASSPHRASE; attempt++) {
            interact.currentAttempts = attempt;
            let r = await manager.interfaceFunc(interact).catch((e) => {
                debug("lybcrypt - KeyStore.js - decryptQuorumShareByUser - Ask return an error", e);
            })
            if (!r) {
                debug("lybcrypt - KeyStore.js - decryptQuorumShareByUser - Aborted by User")
                return false;
            }

            let passphrase = r.globalPassphrase ? r.globalPassphrase : this.defaultSharedSecret;
            let c = await this.decryptSharedKeys(content.crypted, passphrase)
            if (!c) {
                error("lybcrypt - KeyStore.js - decryptQuorumShareByUser - Bad passphrase unable to decrypt");
                continue;
            }
            shareKeys = new ShareKeys(this.userId);
            shareKeys.setContent(c);
            debug("lybcrypt - KeyStore.js - decryptQuorumShareByUser - Got a clear shareKeys");
            await this.log("Partial decrypt with quorum for " + this.userId + " done");
            return ({
                "shareKeys": shareKeys,
                "passphrase": passphrase
            });
        }
        return error("lybcrypt - KeyStore.js - Too many attempts");
    }


    checkIfCanDoQuorumSurcrypt(content, quorumKeyId) {


        // --- Nothing to do ---
        if (!content) return true;
        if (!content.crypted) return error("lybcrypt - KeyStore.js - 1not a encrypted sharedKey structure ");
        if (!content.metaData) return error("lybcrypt - KeyStore.js - 2not a encrypted sharedKey structure ", content);
        if (!content.metaData.shares) return error("lybcrypt - KeyStore.js - 3not a encrypted sharedKey structure ");

        // --- I'm not a member of this quorum ??? 
        let memberKey = this.currentDevice.getQuorumMemberKey(this.userId, quorumKeyId);
        if (!memberKey) return false;

        // ---- nothig for this quorum ???
        let share = content.metaData.shares[quorumKeyId];
        if (!share) return false;
        let wanted = share.wanted;
        if (!wanted) return false;
        for (let wanterId in wanted) {
            let wanter = wanted[wanterId];
            for (let wanterKeyId in wanter) {
                if (!wanter[wanterKeyId].surCrypt) return true;
                if (!wanter[wanterKeyId].surCrypt[memberKey.myRank]) return true;
            }
        }
        return false;

    }


    async startLoadTempoShareListener(quorumKeyId) {
        if (!manager.loadTempoSharesToStorage) return error("lybcrypt - KeyStore.js - loadTempoSharesToStorage undefined");
        if (!manager.saveTempoSharesToStorage) return error("lybcrypt - KeyStore.js - saveTempoSharesToStorage undefined");

        // --- I'm not a member of this quorum ??? 
        let memberKey = this.currentDevice.getQuorumMemberKey(this.userId, quorumKeyId);
        if (!memberKey) return false;
        if (!memberKey.canAcceptWanted()) return false;

        if (!this.listeners) this.listeners = {};

        if (this.listeners[this.userId + '/' + quorumKeyId]) {
            debug("lybcrypt - KeyStore.js - startLoadTempoShareListener - Listener for " + this.userId + '/' + quorumKeyId + " already started");
            return false;
        }

        debug("lybcrypt - KeyStore.js - startLoadTempoShareListener - Start listener for " + this.userId + '/' + quorumKeyId + " on " + this.currentDevice.name);
        await this.log("start listener for " + this.userId + '/' + quorumKeyId + " on " + this.currentDevice.name);

        let iAmWorking = false;

        this.listeners[this.userId + '/' + quorumKeyId] = setInterval(async() => {
            if (iAmWorking) return false;
            let content = await manager.loadTempoSharesToStorage(this.userId, quorumKeyId);
            if (!content) return false;
            debug("lybcrypt - KeyStore.js - startLoadTempoShareListener - Loading " + this.userId + '/' + quorumKeyId + " on " + this.currentDevice.name, content);
            if (!this.checkIfCanDoQuorumSurcrypt(content, quorumKeyId)) {
                return false;
            }

            debug("lybcrypt - KeyStore.js - startLoadTempoShareListener - checkIfCanDoQuorumSurcrypt - Some job to do ");
            iAmWorking = true;

            let obj = await this.decryptQuorumShareByUser(content);
            if (!obj) {
                iAmWorking = false;
                return error("lybcrypt - KeyStore.js - startLoadTempoShareListener - Unable to decrypt shareKeys");
            }
            let shareKeys = obj.shareKeys;
            let passphrase = obj.passphrase;
            if (!shareKeys) {
                iAmWorking = false;
                return error("lybcrypt - KeyStore.js - startLoadTempoShareListener - Unable tu decrypt shareKeys");
            }

            let share = shareKeys.getByKey(quorumKeyId);
            if (!share) {
                iAmWorking = false;
                return error("lybcrypt - KeyStore.js - startLoadTempoShareListener - No share for quorum " + quorumKeyId);
            }

            let shareModified = false;


            let wanted = share.wanted;
            if (!wanted) {
                iAmWorking = false;
                return false;
            }
            for (let askerId in wanted) {
                let wanter = wanted[askerId];
                for (let askerKeyId in wanter) {
                    if ((wanter[askerKeyId].surCrypt) && (wanter[askerKeyId].surCrypt[memberKey.myRank])) continue;
                    let r = await memberKey.acceptWanted(share, askerId, askerKeyId);
                    if (r == true) {
                        debug("lybcrypt - KeyStore.js - startLoadTempoShareListener - acceptwanted added, share modified");
                        shareModified = true;
                    }
                    if (r == false) return error("lybcrypt - KeyStore.js - startLoadTempoShareListener - Error while quorum accept wanted")
                    if (r instanceof Share) {
                        let newShareKeys = new ShareKeys(askerId);
                        newShareKeys.duplicate(shareKeys);
                        newShareKeys.userId = askerId;
                        newShareKeys.addOrUpdate(r);
                        debug("lybcrypt - KeyStore.js - startLoadTempoShareListener - Listener quorum decrypt new ShareKeys", newShareKeys);

                        r = await this.cryptSharedKeys(newShareKeys, passphrase);
                        if (!r) {
                            iAmWorking = false;
                            return error("lybcrypt - KeyStore.js - startLoadTempoShareListener - Error while cyphering sharedKeys")
                        }

                        await manager.saveTempoSharesToStorage(askerId, askerKeyId, {
                            "expire": 0,
                            "crypted": newShareKeys.getCryptedContent(),
                            "metaData": newShareKeys.getInfos(),
                        });

                        await manager.saveTempoSharesToStorage(this.userId, quorumKeyId, undefined);
                    }

                }
            }

            if (shareModified) {
                r = await this.cryptSharedKeys(shareKeys, passphrase);
                if (!r) {
                    iAmWorking = false;
                    return error("lybcrypt - KeyStore.js - startLoadTempoShareListener - Error while cyphering sharedKeys")
                }

                await manager.saveTempoSharesToStorage(shareKeys.userId, quorumKeyId, {
                    "expire": 0,
                    "crypted": shareKeys.getCryptedContent(),
                    "metaData": shareKeys.getInfos(),
                });


            }

            iAmWorking = false;
            return true;

        }, 500)
        debug("lybcrypt - KeyStore.js - startLoadTempoShareListener - Listener loadTempoSharesToStorage for quorum device key " + quorumKeyId + " started");
    }


    async stopListener(listenerId) {
        if (!this.listeners) return true;
        if (!this.listeners[listenerId]) return true;
        clearInterval(this.listeners[listenerId]);
        delete this.listeners[listenerId];
        debug("lybcrypt - KeyStore.js - stopListener - Listener for " + listenerId + " stopped");
        await this.log("lybcrypt - KeyStore.js - stopListener - Listener for " + listenerId + " stopped");
    }

    async stopListeners() {
        debug("lybcrypt - KeyStore.js - Stop listeners on " + this.currentDevice.name);
        for (let listenerId in this.listeners) await this.stopListener(listenerId);
    }
}