import {
    Share
} from './Share.js';
import {
    randomId
} from '../Common.js';

/**
 * A 
 */
export class ShareKeys {

    constructor(userId) {
        this.userId = userId;
        this._id = randomId();
        this.type = "SHAREDKEYSV1";
        this.shares = {};
        this.expire = 0;
        this.symEncrypt = {};
        this.extendData = {};
    }



    getContent() {
        let d = {};
        d._id = this._id;
        d.userId = this.userId;
        d.type = this.type;
        d.expire = this.expire;
        d.extendData = this.extendData;
        d.symEncrypt = {
            "encrypted": this.symEncrypt.encrypted,
            "clearFormat": this.symEncrypt.clearFormat,
            "encryptedFormat": this.symEncrypt.encryptedFormat,
        };


        d.shares = {};
        if (this.shares) {
            for (let keyId in this.shares) {
                d.shares[keyId] = this.shares[keyId].getContent();
            }
        }
        return (d);
    }

    /**
     * Get metadata on the ShareKeys
     * (like getContent(), byt without cryptographic content)
     * 
     */
    getInfos() {
        let d = {};
        d._id = this._id;
        d.userId = this.userId;
        d.type = this.type;
        d.expire = this.expire;
        d.extendData = this.extendData;
        d.symEncrypt = {
            "clearFormat": this.symEncrypt.clearFormat,
            "encryptedFormat": this.symEncrypt.encryptedFormat,
        };
        d.shares = {};
        if (this.shares) {
            for (let keyId in this.shares) {
                d.shares[keyId] = this.shares[keyId].getInfos();
            }
        }
        return (d);
    }

    importData(userId, data) {
        if (!userId) return false;
        if (!data) return false;

        let params = {
            hash: data.hash,
            iteration: data.iteration,
            length: data.length,
            keylen: data.length,
            salt: data.salt,
        }

        this.userId = userId;
        this.shares["0"] = new Share("0", {
            "alpha": data.dataKey.alpha,
            "beta": data.dataKey.beta,
            "group": data.dataKey.group,
        }, params, data.expire);
        return true;
    }

    setContent(d) {
        this.shares = {};
        this._id = d._id;
        this.userId = d.userId;
        this.type = d.type;
        this.expire = d.expire;
        this.extendData = d.extendData;
        if (d.symEncrypt) {

            this.symEncrypt = {
                "encrypted": d.symEncrypt.encrypted,
                "clearFormat": d.symEncrypt.clearFormat,
                "encryptedFormat": d.symEncrypt.encryptedFormat,
            }
        }
        if (d.shares) {
            for (let keyId in d.shares) {
                this.shares[keyId] = new Share();
                this.shares[keyId].setContent(d.shares[keyId]);
            }
        }
    }

    setCryptedContent(crypted, params) {
        this.crypted = {
            crypted: crypted,
            params: params
        };
        return true;
    }

    getCryptedContent() {
        return this.crypted ? this.crypted : {};
    }

    getByKey(keyId) {
        return this.shares[keyId];
    }

    get keyIds() {
        if (!this.shares) console.error("lybcrypt - ShareKeys.js - keyIds - error getting keyIds");
        return Object.keys(this.shares);
    }

    get numberOfShares() {
        return this.keyIds.length;
    }

    addOrUpdate(share) {
        if (!(share instanceof Share)) {
            console.error("lybcrypt - ShareKeys.js - addOrUpdate - Cannot add a non share into shareKeys");
            return false;
        }
        this.shares[share.keyId] = share;
    }

    addorUpdateSymEncryption(encrypted, clearFormat, encryptedFormat) {
        this.symEncrypt = {
            "encrypted": encrypted,
            "clearFormat": clearFormat,
            "encryptedFormat": encryptedFormat,
        }
        return true;
    }

    duplicate(shareKeys) {
        if (!(shareKeys instanceof ShareKeys)) {
            console.error("lybcrypt - ShareKeys.js - duplicate - Non ShareKeys structure");
            return false;
        }
        this._id = shareKeys._id;
        this.userId = shareKeys.userId;
        if (shareKeys.symEncrypt) {
            this.symEncrypt = {
                "encrypted": shareKeys.symEncrypt.encrypted,
                "clearFormat": shareKeys.symEncrypt.clearFormat,
                "encryptedFormat": shareKeys.symEncrypt.encryptedFormat,
            }
        }
    }

    get encrypted() {
        if (!this.symEncrypt) return undefined;
        return this.symEncrypt.encrypted;
    }
    get clearFormat() {
        if (!this.symEncrypt) return undefined;
        return this.symEncrypt.clearFormat;
    }
    get encryptedFormat() {
        if (!this.symEncrypt) return undefined;
        return this.symEncrypt.encryptedFormat;
    }

    deleteShare(keyId) {
        delete this.shares[keyId];
    }

    get wanterIds() {
        let wanterIds = {};
        for (let keyId in this.shares) {
            let share = this.shares[keyId];
            if (!share.wanted) continue;
            let wanted = share.wanted;
            for (let wanterId in wanted) {
                let wanter = wanted[wanterId];
                for (let wanterKeyId in wanter) {
                    if (wanter[wanterKeyId].surCrypt) {
                        if (!wanterIds[wanterId]) wanterIds[wanterId] = {};
                        wanterIds[wanterId][wanterKeyId] = true;
                    }
                }
            }
        }
        return wanterIds;
    }

    deleteWanted(userId) {
        let modified = false;
        for (let keyId in this.shares) {
            let share = this.shares[keyId];
            if (!share.wanted) continue;
            if (share.wanted[userId]) {
                delete(share.wanted[userId]);
                modified = true;
            }
        }
        return modified;
    }

    isWantedBy(userId, userKeyId = undefined) {
        for (let keyId in this.shares) {
            let share = this.shares[keyId];
            if (share.isWantedBy(userId, userKeyId)) return true;
        }
        return false;
    }

    checkIfWanted() {
        for (let keyId in this.shares) {
            let share = this.shares[keyId];
            if (share.numOfWantedUsers > 0) {
                return true;
            }
        }
        return false;
    }

    isSurcryptBy(wantedUserId, rank) {
        for (let keyId in this.shares) {
            let share = this.shares[keyId];
            if (share.isSurcryptBy(wantedUserId, undefined, rank)) return true;
        }
        return false;
    }
}