import {
    GenericKey,
    TYPE,
    STATUS,
    KEYUSAGE,
    KEYFORMAT,
} from './GenericKey.js';
import {
    Signature
} from '../Signature.js';
import {
    error,
    debug
} from './Manager.js';


export class SignKey extends GenericKey {
    constructor(userId) {
        super(userId);
        this.name = "Generic ECDSA Key";
        this.type = TYPE.ECDSA;
        this.status = STATUS.valid;
        this.usages = [KEYUSAGE.signKey, KEYUSAGE.verifyKey];

        // ----- All keys i have signed -----------
        this.hasSigned = {};

        this.signature = new Signature(error, debug);
    }

    async getContent() {
        let d = await super.getContent();
        d.publicKeyStruct = this.signature.publicKeyStruct;
        d.encryptedPrivateKeyStruct = this.signature.encryptedPrivateKeyStruct;
        d.hasSigned = this.hasSigned;
        return d;
    }
    async getPublicContent() {
        let d = await super.getPublicContent();
        d.publicKeyStruct = this.signature.publicKeyStruct;
        d.hasSigned = this.hasSigned;
        return d;
    }

    /**
     * Set the content of the key from a saved object
     * @param {object} d 
     */
    async setContent(d) {
            await super.setContent(d);
            await this.signature.importPublicKey(d.publicKeyStruct);

            if (d.encryptedPrivateKeyStruct) {
                this.signature.encryptedPrivateKeyStruct = d.encryptedPrivateKeyStruct;
            }
            this.hasSigned = d.hasSigned ? d.hasSigned : {};
            return true;
        }
        /**
         * Set the content of the key from a saved object (public part only)
         * @param {object} d 
         */
    async setPublicContent(d) {
        await super.setPublicContent(d);
        await this.signature.importPublicKey(d.publicKeyStruct);
        this.hasSigned = d.hasSigned ? d.hasSigned : {};
        return true;
    }

    canSign() {
        return this.privateKeyReleased;
    }

    /**
     * Revoke a key
     */
    revoke() {
        this.status = STATUS.revoked;
        //this.signature.encryptedPrivateKeyStruct = undefined;
        return true;
    }

    /**
     * Return true if the key is revocable
     */
    isRevocable() {
        if (this.status != STATUS.ready) return false;
        return true;
    }

    /**
     * Return the public Key.
     * Can be async due to the extraction from a device for example
     * 
     * @param {string} format 
     */
    async getPublicKey(format = undefined) {
        switch (format) {
            case KEYFORMAT.STRING:
                return JSON.stringify(this.signature.publicKeyStruct);
            case KEYFORMAT.RAW:
            default:
                return this.signature.publicKeyStruct;
        }
    }

    /**
     * Return true if the private key is available for decyphering, signature, ...
     */
    get privateKeyReleased() {
        if (this.signature.privateKey) return true;
        return false;
    }

    async dropPrivateKey() {
        this.signature.privateKey = undefined;
        return super.dropPrivateKey();
    }

    /**
     * Crypt the provate Key with passphrase
     * @param {string} passphrase 
     */
    async cryptPrivateKeyWithPassphrase(passphrase) {
        if (!this.signature.privateKey) return false;
        let struct = await this.signature.cryptPrivateKeyWithPassphrase(passphrase);
        if (struct) return true;
        return false;
    }



    /**
     * Release the encrypted PrivateKey with a passphrase
     * Can be async due to the decryption from a device for example
     * @param {string} passphrase 
     * @return {boolean} true if operation OK
     */
    async releasePrivateKey(passphrase) {
        if (this.signature.privateKey) return true;
        if (!this.signature.encryptedPrivateKeyStruct) return false;
        try {
            await this.signature.releasePrivateKey(passphrase);
        } catch (e) {
            return false;
        }
        this.resetTimeout();

        return true;

    }


    /**
     * Generate a keys pair
     * Can be async due to the decryption from a device for example
     * @return {boolean} true if operation OK
     */
    async generate() {
        let ret = await this.signature.generateKeys();
        this.resetTimeout();
        return ret;
    }


    /**
     * signature with the private key
     * @param {object} data 
     * @param {string} outputFormat 
     */
    async sign(keyHash) {
        if (!this.privateKeyReleased) return this.err("lybcrypt - SignKey.js - sign - No privateKey for signing");
        return this.signature.sign(keyHash, "base64");
    }

    /**
     * Verify the signature with the public key
     * @param {object} data 
     * @param {object} signature 
     * @param {string} inputFormat 
     */
    async verify(data, signature, inputFormat = undefined) {
        return this.signature.verify(data, signature, inputFormat);
    }
}