const path = require('path');

import {
    Hash,
    HASHTYPE,
    HASHOUTPUT
} from '../Hash.js';
import {
    Share
} from './Share.js';
import {
    error,
    debug,
    manager,
} from './Manager.js';

export const KEYUSAGE = {
    "digitalSignature": 0,
    "nonRepudiation": 1,
    "keyEncipherment": 2,
    "dataEncipherment": 3,
    "keyAgreement": 4,
    "keyCertSign": 5,
    "cRLSign": 6,
    "encipherOnly": 7,
    "decipherOnly": 8,

    "signKey": 10,
    "verifyKey": 11,
    "askWanted": 20,
    "cipher": 21,
    "decipher": 22,
    "acceptWanted": 23,
}

export const STATUS = {
    "unknown": 0,
    "ready": 1,
    "valid": 1,
    "sharing": 2, // -- For quorum keys 
    "setsecret": 3, // -- For quorum keys 
    "shared": 4, // -- For quorum member keys 
    "revoked": 99,
    "deleted": 999,
}

export const TYPE = {
    "unknown": 0,
    "elGamal": 1,
    "RSA": 2,
    "ECDSA": 3,
    "quorum": 4,
    "quorumMember": 5,
    "passphrase": 6,
}

export const KEYDEFAULTNAME = {
    0: "Generic key",
    1: "Ciphering key elGamal",
    2: "Ciphering key RSA",
    3: "Signature key ECDSA",
    4: "Quorum key",
    5: "Member quorum",
}

export const REVOKECASE = {
    "noReason": 0,
    "compromised": 1,
    "superseded": 2,
    "noLongerUsed": 3
}

export const KEYFORMAT = {
    "unknown": 0,
    "STRING": "string",
    "RAW": "raw",
}

var DEVICENAME = "LybKey";

export function setDeviceName(t) {
    DEVICENAME = t;
}
export function getDeviceName() {
    return DEVICENAME;
}

const fs = require('fs');


export class GenericKey {
    constructor(userId) {
        this.type = TYPE.unknown;
        this.status = STATUS.unknown;
        this.name = "Generic Key";
        this.userId = userId;
        this.usages = [];
        this._id = undefined;

        this.lastPrivateKeyUsage = 0;
        this.maxDelayPrivateKeyInMemory = 300000; // in MS;
        this.dropPrivateKey = this.dropPrivateKey.bind(this);
        this.timeoutIdentifier = undefined;

        this.privateKeyStoredLocaly = false;
    }

    setUserId(userId) {
        this.userId = userId;
    }

    get group() {
        return undefined;
    }

    available(types, usages, status, group) {
        if (types) {
            if (types.indexOf(this.type) == -1) return false;
        }

        if (usages) {
            for (let usage of usages) {
                if (this.usages.indexOf(usage) > -1) return true;
            }
        }

        if (status) {
            if (status.indexOf(this.status) == -1) return false;

        }

        if (group) {
            if (this.group != group) return false;
        }

        return true;
    }

    /**
     * Check if the key is available for one of this usage
     * 
     * @param {Array} usages 
     */
    isAvailable(usages, types, options) {
        if (this.type == TYPE.unknown) return false;
        if (this.status != STATUS.valid) return false;

        if (types) {
            if (types.indexOf(this.type) == -1) return false;
        }

        if (options) {
            if (options.group) {
                if (this.group != options.group) return false;
            }
        }

        if ((usages) && (usages.length > 0)) {
            for (let usage of usages) {
                if (this.usages.indexOf(usage) > -1) return true;
            }
        }

        return false;
    }

    isShareToMe(share) {
        if (!(share instanceof Share)) return error("Cannot decrypt a non share Structure");
        if (share.keyId == "0") return true;
        if (share.keyId == this._id) return true;
        return false;
    }

    isAvailableForDecyphering() {
        return false;
    }

    canDecrypt() {
        return false;
    }
    canSign() {
        return false;
    }
    canAddWanted() {
        return false;
    }
    canAcceptWanted() {
        return false;
    }


    resetTimeout() {
        if (this.timeoutIdentifier) clearTimeout(this.timeoutIdentifier);
        this.expirePrivateKeyInMemory = 0;
        if ((this.privateKeyReleased) &&
            (this.maxDelayPrivateKeyInMemory > 0) &&
            (this.privateKeyStoredLocaly == false)) {
            this.expirePrivateKeyInMemory = Date.now() + this.maxDelayPrivateKeyInMemory;
            this.timeoutIdentifier = setTimeout(this.dropPrivateKey, this.maxDelayPrivateKeyInMemory);
        }
    }

    set privateKeyDelay(d) {
        this.maxDelayPrivateKeyInMemory = d;
        this.resetTimeout();
    }

    /**
     * Prepare the delete of the key
     */
    async del(keyStore) {
        this.status = STATUS.deleted;
        this.withLocalStorage = false;
        return true;
    }

    changeName(newName) {
        this.name = newName;
    }

    async getInfos() {
        let d = {
            "_id": this._id,
            "name": this.name,
            "type": this.type,
            "status": this.status,
            "usages": this.usages,
            "revocable": this.isRevocable(),
            "localyStorable": this.localyStorable(),
            "privateKeyStoredLocaly": this.privateKeyStoredLocaly,
            "privateKeyPresent": this.privateKeyString ? true : false,
            "privateKeyReleased": this.privateKeyReleased,
            "expirePrivateKeyInMemory": this.expirePrivateKeyInMemory,
            "fingerPrint": await this.fingerPrint(HASHTYPE.sha512)
        }
        return d;
    }

    /**
     * Drop the clear privateKey
     */
    async dropPrivateKey() {

        this.resetTimeout();
        return true;
    }

    /**
     * Return the content of the Key object for saving somewhere
     * @return {object} the key
     */
    async getContent() {
        let d = {
            "name": this.name,
            "status": this.status,
            "type": this.type,
            "usages": this.usages,
            "_id": this._id,
            "privateKeyStoredLocaly": this.privateKeyStoredLocaly,
        }
        return d;
    }

    async getPublicContent() {
        let d = {
            "name": this.name,
            "status": this.status,
            "type": this.type,
            "usages": this.usages,
            "_id": this._id,
        }
        return d;
    }


    /**
     * Set the content of the key from a saved object
     * @param {object} d 
     */
    async setContent(d) {
        this.name = d.name;
        this.status = d.status;
        this.type = d.type;
        this.usages = d.usages;
        this._id = d._id;
        this.privateKeyStoredLocaly = d.privateKeyStoredLocaly;

        if (this.privateKeyStoredLocaly == true) {
            this.loadPrivateKeyLocaly();
        }
        return true;
    }

    get foundPrivateKeyLocaly() {
        if (!this.privateKeyStoredLocaly) return false;
        return true;
        // if (this.releasePrivateKey) return true;
        // return false;
    }

    /**
     * All action to do on the key before deleting
     */
    willDelete() {
        return true;
    }

    /**
     * Set the content of the key from a saved object
     * @param {object} d 
     */
    async setPublicContent(d) {
        return this.setContent(d);
    }

    /**
     * Revoke a key
     */
    revoke() {
        return error("Cannot revoke this key");
    }

    /**
     * Return true if the key is revocable
     */
    isRevocable() {
        return false;
    }

    // Only for quorum key
    hasAdminAddedShare() {
        return undefined;
    }

    // Only for quorum key
    hasAdminAddedPubKey() {
        return undefined;
    }

    /**
     * Return the public Key.
     * Can be async due to the extraction from a device for example
     * 
     * @param {string} format 
     */
    async getPublicKey(format = undefined) {
        return undefined;
    }

    /**
     * Return true if the private key is available for decyphering, sign, ...
     */
    get privateKeyReleased() {
        return false;
    }
    get privateKeyString() {
        return undefined;
    }
    set privateKeyString(pk) {
        // do nothing by default, overright
    }
    set withLocalStorage(b) {
        this.privateKeyStoredLocaly = b ? true : false;
        if (this.privateKeyStoredLocaly == false) return this.deletePrivateKeyLocaly();
        return this.savePrivateKeyLocaly();
    }
    get withLocalStorage() {
        return this.privateKeyStoredLocaly;
    }

    /**
     * Save the private key in the localStorage if possible.
     */
    savePrivateKeyLocaly() {
        if (this.privateKeyStoredLocaly == false) return false;
        if (!this.privateKeyReleased) return false;

        let storage = {
            "privateKeyString": this.privateKeyString,
        }
        let stringValue = JSON.stringify(storage);

        // ----- In a browser
        if (typeof (localStorage) != 'undefined') {
            let stringKey = DEVICENAME + ":" + this.userId + ":" + this._id;
            localStorage.setItem(stringKey, stringValue);
            return true;
        }

        // ----- In nodeJs
        let stringKey = DEVICENAME + "_" + this.userId + "_" + this._id;
        let fileName = path.resolve(manager.NODEKEYSDIRECTORY + "/" + stringKey + ".key");
        fs.writeFileSync(fileName, stringValue, 'utf8');
        return true;
    }

    /**
     * Load the privatekey from localstorage if possible
     */
    loadPrivateKeyLocaly() {
        if (this.privateKeyStoredLocaly == false) return false;


        let storageString = null;
        // ---- in a browser
        if (typeof (localStorage) != 'undefined') {
            let stringKey = DEVICENAME + ":" + this.userId + ":" + this._id;
            storageString = localStorage.getItem(stringKey);
        } else {
            // ----- In nodeJs
            let stringKey = DEVICENAME + "_" + this.userId + "_" + this._id;
            let fileName = path.resolve(manager.NODEKEYSDIRECTORY + "/" + stringKey + ".key");
            if (!fs.existsSync(fileName)) return false;
            storageString = fs.readFileSync(fileName, 'utf8');
        }

        if (!storageString) return false;
        let data = JSON.parse(storageString);
        if (data.privateKeyString) {
            this.privateKeyString = data.privateKeyString;
            return true;
        }
    }

    /**
     * Erase the privatekey from the localstorage 
     */
    deletePrivateKeyLocaly() {
        if (typeof (localStorage) == 'undefined') return true;
        localStorage.removeItem(DEVICENAME + ":" + this.userId + ":" + this._id);
        return true;
    }

    localyStorable() {
        return true;
    }

    /**
     * Release the encrypted PrivateKey with a passphrase
     * Can be async due to the decryption from a device for example
     * @param {string} passphrase 
     * @return {boolean} true if operation OK
     */
    async releasePrivateKey(passphrase) {
        this.resetTimeout();
        return undefined;
    }

    /**
     * Crypt the provate Key with passphrase
     * @param {string} passphrase 
     * @return {boolean} true if OK, false if cannot change
     */
    async cryptPrivateKeyWithPassphrase(passphrase) {
        return false;
    }

    /**
     * return the hash of the public key
     * can be async
     * @param {string} hash 
     */
    async fingerPrint(hash = undefined) {
        let k = await this.getPublicKey(KEYFORMAT.STRING);
        if (!k) return undefined;
        if (hash) {
            let h = new Hash(error, debug, hash);
            return h.hash(k, HASHOUTPUT.base64)
        }
        let a = [];
        for (let hh in HASHTYPE) {
            let h = new Hash(error, debug, HASHTYPE[hh]);
            a.push(await h.hash(k, HASHOUTPUT.base64));
        }
        return a;
    }

    /**
     * Generate a keys pair
     * Can be async due to the decryption from a device for example
     * @return {boolean} true if operation OK
     */
    async generate() {
        this.resetTimeout();
        return false
    }

    isMemberOf() {
        return false;
    }

    /**
     * crypt with the public key
     * @param {object} data 
     * @param {string} format 
     */
    async crypt(data, format = undefined) {
        return false;
    }

    /**
     * decrypt with the private key
     * @param {object} data 
     * @param {string} outputFormat 
     */
    async decrypt(encrypted, outputFormat = undefined) {
        this.resetTimeout();
        return undefined;
    }

    /**
     * sign with the private key
     * @param {object} data 
     * @param {string} outputFormat 
     */
    async sign(data, outputFormat = undefined) {
        this.resetTimeout();
        return undefined;
    }

    /**
     * Verify the signature with the public key
     * @param {object} data 
     * @param {object} signature 
     * @param {string} inputFormat 
     */
    async verify(data, signature, inputFormat = undefined) {
        return undefined;
    }

    /**
     * Import keys from a file
     * @param {File} file 
     */
    async importKey(file) {
        return error("Cannot import this key")
    }

    /**
     * Import keys from a file
     * @param {File} file 
     */
    async exportKey() {
        return error("Cannot export this key")
    }


    /**
     * ███████╗██╗   ██╗███╗   ███╗ ██████╗██████╗ ██╗   ██╗██████╗ ████████╗
     * ██╔════╝╚██╗ ██╔╝████╗ ████║██╔════╝██╔══██╗╚██╗ ██╔╝██╔══██╗╚══██╔══╝
     * ███████╗ ╚████╔╝ ██╔████╔██║██║     ██████╔╝ ╚████╔╝ ██████╔╝   ██║   
     * ╚════██║  ╚██╔╝  ██║╚██╔╝██║██║     ██╔══██╗  ╚██╔╝  ██╔═══╝    ██║   
     * ███████║   ██║   ██║ ╚═╝ ██║╚██████╗██║  ██║   ██║   ██║        ██║  
     * ╚══════╝   ╚═╝   ╚═╝     ╚═╝ ╚═════╝╚═╝  ╚═╝   ╚═╝   ╚═╝        ╚═╝ 
     * 
     * this is the symetric part for hybrid cryptography
     */

    async cipherSessionKey() {
        return undefined;
    }
    async decipherSessionKey(encrypted) {
        return undefined;
    }

}