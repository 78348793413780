import SchmluxStore, {
    WITH_EMITCHANGE as SWITH_EMITCHANGE,
    WITHOUT_EMITCHANGE as SWITHOUT_EMITCHANGE
} from './SchmluxStore';
import {
    accountStore
} from './AccountStore';

import {
    LynError
} from '../../../SharedLibs/LynError';

import {
    translate
} from '../../../SharedLibs/Langs';

import {
    getNewObjectID
} from '../../../SharedLibs/Tools';

import {
    Checker,
    _ID
} from '../../../SharedLibs/Checker'

import {
    progressActionsStore
} from './ProgressActionsStore';
export const WITHOUT_EMITCHANGE = SWITHOUT_EMITCHANGE;
export const WITH_EMITCHANGE = SWITH_EMITCHANGE;

export default class BaseStore extends SchmluxStore {

    constructor() {
        super();

        // --- For extraction --
        // a list of fields (WARNING a getter must exist in the corresponding object)
        this.csvFields = ['_id', 'displayName', 'creationDate', 'ownerId', 'ownerName'];
    }





    /**
     *
     * update on field
     *
     * @param {Object} data
     */
    async patch(obj, fields, givenVueLevel, emitChange = this.WITH_EMITCHANGE) {

        let pId = this.initGenericProgress("update ", obj);

        await this.update(obj._id, fields, givenVueLevel, emitChange);

        this.stopProgress(pId);
        if (emitChange) {
            this.addUpdateToEmitChange(obj._id);
            this.emitChange();
        }
    }


    /**
     *
     * Move from one folder to another
     *
     * @param {Object} data
     */
    async move(data, givenVueLevel, emitChange = this.WITH_EMITCHANGE) {
        this.log("move", arguments);
        new Checker({
            "_id": _ID,
            "parentId": _ID,
        }, 'move').checkThrow(data);

        let f = await this.getById(data._id);
        if (!f) return this.error("object not found in store", {
            "_id": data._id
        });

        return this.patch(f, { 'parentId': data.parentId }, givenVueLevel, emitChange)
    }



    // eslint-disable-next-line require-await
    async merge(obj, el, /* oldVueLevel, newVueLevel */ ) {
        this.log(`merge object define workflow`, this.workflowsDescriptions);

        obj.setContent(el);
        return obj;
    }

    // eslint-disable-next-line require-await
    async append(el, /* vueLevel */ ) {
        const obj = new this.singleton(this, accountStore);

        this.log(`append object define workflow`, this.workflowsDescriptions);
        for (let w in this.workflowsDescriptions) {
            obj.defineWorkFlows(w, this.workflowsDescriptions[w]);
        }
        obj.setContent(el);
        return obj;
    }

    createNew() {
        const obj = new this.singleton(this, accountStore);
        obj.initServerMeta(accountStore.owner);
        return obj;
    }



    /**
     *
     * Move from one folder to another
     *
     * @param {Object} data
     */
    async rename(data, givenVueLevel, emitChange = this.WITH_EMITCHANGE) {
        this.log("rename", arguments);
        new Checker({
            "_id": _ID,
            "newName": "string+",
        }, 'move').checkThrow(data);

        let f = await this.getById(data._id);
        if (!f) return this.error("object not found in store", {
            "_id": data._id
        });
        let flag = await this.isValidFlag(data.newName, f);
        //console.log("BaseStore - rename - flag : " + flag);
        if (flag) {
            //console.log("BaseStore - rename - ok, renaming");
            return this.patch(f, { 'name': data.newName }, givenVueLevel, emitChange)
        } else {
            //console.log("BaseStore - rename - no renaming");
            throw new LynError("Could not rename with this name (it previously exists, no ? )", { newname: data.newName });
        }
    }

    async isValidFlag(nameToTest, object) {
        //console.log("BaseStore - isValidFlag");
        if (!new Checker("string+").check(nameToTest)) {
            return (false);
        }

        let parent = await object.getParent();
        if (!parent) {
            //console.log("BaseStore.js - isValidFlag - no parent");
            return (false);
        }

        //let brothers = (await object.getChilds(parent)).filter((brother) => brother.objectType === object.objectType);
        let brothers = (await this.getChilds(parent));
        //console.log("BaseStore.js - isValidFlag - object : ", object);
        //console.log("BaseStore.js - isValidFlag - parent : ", parent);
        //console.log("BaseStore.js - isValidFlag - brothers : ", brothers);
        let objectId;
        objectId = object._id;
        for (let b of brothers) {
            //console.log("BaseStore.js - isValidFlag - b : " + b.name);
            let currentId;
            if (b.objectType === "FSET") {
                currentId = b.data._id;
            } else {
                currentId = b._id
            }
            //if (b._id === object._id) continue
            if (currentId === objectId) continue
            if (b.name === nameToTest) {
                // Already existing name or object
                return (false);
            }
        }
        // All other cases are ok.
        //console.log("BaseStore.js - isValidFlag - true")
        return (true);
    }

    async getCount(select, givenVueLevel) {
        let count = await this.getActionNoUpdate('getcount', [], select, givenVueLevel, undefined)
        return count
    }



    /**
     *
     * ██████╗ ██████╗  ██████╗  ██████╗ ██████╗ ███████╗███████╗███████╗
     * ██╔══██╗██╔══██╗██╔═══██╗██╔════╝ ██╔══██╗██╔════╝██╔════╝██╔════╝
     * ██████╔╝██████╔╝██║   ██║██║  ███╗██████╔╝█████╗  ███████╗███████╗
     * ██╔═══╝ ██╔══██╗██║   ██║██║   ██║██╔══██╗██╔══╝  ╚════██║╚════██║
     * ██║     ██║  ██║╚██████╔╝╚██████╔╝██║  ██║███████╗███████║███████║
     * ╚═╝     ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝
     *
     */

    sleep(m, err) {
        return new Promise((r, rej) => {
            setTimeout(() => {
                if (err) rej("fucking error");
                return r(true);
            }, m)
        })
    }

    async fake(obj) {
        let i = 20;
        let pId = this.initGenericProgress("fake", obj, i);
        for (let j = 0; j < i; j++) {
            await this.sleep(1000)
                .catch((e) => {
                    this.log(`error e`, e);
                    return this.error(e, undefined, pId)
                });
            this.incrementProgress(pId)
        }
        this.stopProgress(pId);
    }


    cleanProgress() {
        return progressActionsStore.clean(progressActionsStore.WITH_EMITCHANGE);
    }

    initGenericProgress(name, objOrId, max, parentProgressId) {
        let _id = objOrId ? (typeof objOrId === "string" ? objOrId : objOrId._id) : getNewObjectID();
        this.log("client/src/stores/BaseStore.js - initGenericProgress - _id : ", _id);
        let data = {
            '_id': _id,
            'object': typeof objOrId === "string" ? undefined : objOrId,
            'parentProgressId': parentProgressId,
            'name': name,
            'max': max,
        };

        //this.log(`initGenericProgress`, data);
        let el = progressActionsStore.create(data, progressActionsStore.WITH_EMITCHANGE);
        return el._id;
    }

    reassignProgressId(oldId, newId) {
        return progressActionsStore.reassignId(oldId, newId);
    }

    incrementProgress(_id) {
        return progressActionsStore.inc(_id, progressActionsStore.WITH_EMITCHANGE)
    }

    stopProgress(_id) {
        progressActionsStore.end(_id, progressActionsStore.WITH_EMITCHANGE);
        this.cleanProgress()
    }

    cancelProgress(_id) {
        return progressActionsStore.cancel(_id)
    }

    errorProgress(_id, err) {
        this.log(`error progress `, _id);
        return progressActionsStore.error(_id, err, progressActionsStore.WITH_EMITCHANGE)
    }


    /*
     *
     * ███████╗██████╗ ██████╗  ██████╗ ██████╗ ███████╗
     * ██╔════╝██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██╔════╝
     * █████╗  ██████╔╝██████╔╝██║   ██║██████╔╝███████╗
     * ██╔══╝  ██╔══██╗██╔══██╗██║   ██║██╔══██╗╚════██║
     * ███████╗██║  ██║██║  ██║╚██████╔╝██║  ██║███████║
     * ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝
     *
     */

    error(message, data, _id) {
        if (_id) this.errorProgress(_id, message);
        return super.error(message, data);
    }

}