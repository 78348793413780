/**
 * A share for a user for On key
 */
export class Share {

    constructor(keyId, encrypted, params = {}, expire = 0) {
        this.keyId = keyId;
        this.encrypted = encrypted;
        this.params = params;
        this.expire = expire;
    }

    getContent() {
        let d = {
            "keyId": this.keyId,
            "encrypted": this.encrypted,
            "params": this.params,
            "expire": this.expire,
            "wanted": {},
        }

        for (let userId in this.wanted) {
            d.wanted[userId] = {};
            for (let keyId in this.wanted[userId]) {
                d.wanted[userId][keyId] = {
                    keyId: keyId,
                    expire: this.wanted[userId][keyId].expire,
                    encrypted: {
                        "alpha": this.wanted[userId][keyId].encrypted.alpha,
                        "beta": this.wanted[userId][keyId].encrypted.beta,
                        "group": this.wanted[userId][keyId].encrypted.group,
                    },
                    surCrypt: {},
                }
                for (let rank in this.wanted[userId][keyId].surCrypt) {
                    d.wanted[userId][keyId].surCrypt[rank] = this.wanted[userId][keyId].surCrypt[rank];
                }
            }
        }

        return (d);
    }


    isEncryptedWithPass() {
        if (!this.encrypted) return false;
        if (this.encrypted.encrypted) return true;
        return false;
    }

    isEncryptedWithElGamal() {
        if (!this.encrypted) return false;
        if (this.encrypted.alpha) return true;
        return false;
    }

    /**
     * Get metadata on the Share
     * (like getContent(), byt without cryptographic content)
     * 
     */
    getInfos() {
        let d = {
            "keyId": this.keyId,
            "params": this.params,
            "expire": this.expire,
            "wanted": {},
        }

        for (let userId in this.wanted) {
            d.wanted[userId] = {};
            for (let keyId in this.wanted[userId]) {
                d.wanted[userId][keyId] = {
                    keyId: keyId,
                    expire: this.wanted[userId][keyId].expire,
                    surCrypt: {},
                }
                for (let rank in this.wanted[userId][keyId].surCrypt) {
                    d.wanted[userId][keyId].surCrypt[rank] = true;
                }
            }
        }
        return (d);
    }

    setContent(d) {
        this.keyId = d.keyId;
        this.encrypted = d.encrypted;
        this.expire = d.expire;
        this.wanted = d.wanted;
        this.params = d.params;
    }

    get alpha() {
        return this.encrypted.alpha;
    }
    get group() {
        return this.encrypted.group;
    }
    get beta() {
        return this.encrypted.beta;
    }

    /**
     * Check if this crypto element can be used for wanted system.
     * @return {boolean}
     */
    canAddWanted() {
        if ((this.encrypted) && (this.encrypted.group)) return true;
        return false;
    }

    getWanted(userId, keyId) {
        if (!this.wanted) return undefined;
        if (!this.wanted[userId]) return undefined;
        if (!keyId) return this.wanted[userId];
        return (this.wanted[userId][keyId]);
    }

    deleteWanted(userId, keyId) {
        if (!this.wanted) return true;
        if (!this.wanted[userId]) return true;
        if (keyId) {
            delete this.wanted[userId][keyId];
            if (Object.keys(this.wanted[userId]).length == 0) delete this.wanted[userId];
        } else {
            delete this.wanted[userId];
        }
        return true;
    }

    addWanted(userId, keyId, encrypted, expire = 0) {
        if (!this.canAddWanted()) return this.symEncryptedFormat("Cannot addWanted to this share");
        if (!this.wanted) this.wanted = {};
        if (!this.wanted[userId]) this.wanted[userId] = {};
        this.wanted[userId][keyId] = {
            "keyId": keyId,
            "encrypted": encrypted,
            "expire": expire,
        }
        return true;
    }

    get numOfWantedUsers() {
        if (!this.wanted) return 0;
        return Object.keys(this.wanted).length;
    }

    isWantedBy(userId, keyId = undefined) {
        if (!this.wanted) return false;
        if (!this.wanted[userId]) return false;
        if (!keyId) return true;
        if (!this.wanted[userId][keyId]) return false;
        return true;
    }

    isSurcryptBy(wantedUserId, wantedKeyId, rank) {
        let wanted = this.getWanted(wantedUserId, wantedKeyId);
        if (!wanted) return false;
        if (!wantedKeyId) {
            for (let keyId in wanted) {
                let w = wanted[keyId];
                if (!w.surCrypt) return false;
                if (!w.surCrypt[rank]) return false;
            }
        } else {
            if (!wanted.surCrypt) return false;
            if (!wanted.surCrypt[rank]) return false;
        }
        return true;
    }

    refuseWanted(userId, keyId) {
        if (!this.wanted) return false;
        if (!this.wanted[userId]) return false;
        delete(this.wanted[userId][keyId]);
        if (Object.keys(this.wanted[userId]).length == 0) delete(this.wanted[userId]);
        if (Object.keys(this.wanted).length == 0) delete(this.wanted);
        return true;
    }


}