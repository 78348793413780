import {
    GenericKey,
    TYPE,
    STATUS,
    KEYUSAGE,
    KEYFORMAT,
} from './GenericKey.js';
import {
    ElGamal
} from '../ElGamal.js';
import {
    Share
} from './Share.js';
import {
    ShareKeys
} from './ShareKeys.js';
import {
    debug,
    error
} from './Manager.js';
import {
    SessionKey
} from './SessionKey.js';

export class QuorumKey extends GenericKey {
    constructor(userId) {
        super(userId);
        this.name = "Generic Quorum Key";
        this.type = TYPE.quorum;
        this.usages = [KEYUSAGE.cipher, KEYUSAGE.askWanted];
        this.quorum = 0;
        this.max = 0;
        this.deviceQuorumKey = false;
        this.elGamal = new ElGamal(error, debug);
    }


    getAdmins() {
        let admins = {};
        for (let i in this.admins) {
            admins[i] = {};
            admins[i].rank = this.admins[i].rank;
            if (!this.admins[i].shares) continue;
            admins[i].shares = {};
            for (let j in this.admins[i].shares) {
                admins[i].shares[j] = this.admins[i].shares[j].getContent();
            }
        }
        return admins;
    }

    async getContent() {
        let d = await super.getContent();
        d.quorum = this.quorum;
        d.max = this.max;
        d.deviceQuorumKey = this.deviceQuorumKey;

        d.publicKeyString = this.elGamal.publicKeyString;
        if (this.adminPublicKeys) {
            d.adminPublicKeys = {};
            for (let i in this.adminPublicKeys) {
                d.adminPublicKeys[i] = this.adminPublicKeys[i];
            }
        }

        d.admins = this.getAdmins();
        return d;
    }


    async getPublicContent() {
        let d = await super.getPublicContent();
        d.quorum = this.quorum;
        d.max = this.max;
        d.deviceQuorumKey = this.deviceQuorumKey;
        d.publicKeyString = this.elGamal.publicKeyString;
        if (this.adminPublicKeys) {
            d.adminPublicKeys = {};
            for (let i in this.adminPublicKeys) {
                d.adminPublicKeys[i] = this.adminPublicKeys[i];
            }
        }

        if (this.status != STATUS.ready) {
            d.admins = this.getAdmins();
        }
        return d;
    }

    async getInfos() {
        let d = await super.getInfos();
        d.quorum = this.quorum;
        d.deviceQuorumKey = this.deviceQuorumKey;
        d.max = this.max;

        d.admins = {};
        for (let i in this.admins) {
            d.admins[i] = this.admins[i].rank;
        }

        return d;
    }


    /**
     * Set the content of the key from a saved object
     * @param {object} d 
     */
    async setContent(d) {
        await super.setContent(d);
        this.max = d.max;
        this.quorum = d.quorum;
        this.deviceQuorumKey = d.deviceQuorumKey;
        if (d.publicKeyString) {
            this.elGamal.publicKeyString = d.publicKeyString;
        }
        if (d.adminPublicKeys) {
            this.adminPublicKeys = {};
            for (let i in d.adminPublicKeys) {
                this.adminPublicKeys[i] = d.adminPublicKeys[i];
            }
        }

        this.admins = {};
        for (let i in d.admins) {
            this.admins[i] = {};
            this.admins[i].rank = d.admins[i].rank;
            if (!d.admins[i].shares) continue;
            this.admins[i].shares = {};
            for (let j in d.admins[i].shares) {
                this.admins[i].shares[j] = new ShareKeys(this.userId);
                this.admins[i].shares[j].setContent(d.admins[i].shares[j]);

            }
        }

        return true;
    }
    async setPublicContent(d) {
        await super.setPublicContent(d);
        this.max = d.max;
        this.quorum = d.quorum;
        this.deviceQuorumKey = d.deviceQuorumKey;

        if (d.publicKeyString) {
            this.elGamal.publicKeyString = d.publicKeyString;
        }
        this.adminPublicKeys = d.adminPublicKeys;
        return true;
    }

    isAvailableForDecyphering() {
        if (!this.deviceQuorumKey) return false;
        if (this.status == STATUS.ready) return true;
    }

    canAddWanted() {
        if (this.status == STATUS.ready) return true;
        return false;
    }

    localyStorable() {
        return false;
    }

    /**
     * Revoke a key
     */
    revoke() {
        this.status = STATUS.revoked;
        //this.elGamal.publicKeyString = undefined;
        return true;
    }

    /**
     * Return true if the key is revocable
     */
    isRevocable() {
        if (this.status != STATUS.ready) return false;
        return true;
    }

    /**
     * Return the public Key.
     * Can be async due to the extraction from a device for example
     * 
     * @param {string} format 
     */
    async getPublicKey(format = undefined) {
        switch (format) {
            case KEYFORMAT.STRING:
                return this.elGamal.publicKeyString;
            case KEYFORMAT.RAW:
            default:
                return this.elGamal.publicKey;
        }
    }

    get ranksForAdmins() {
        let d = {};
        let userIds = Object.keys(this.admins);
        for (let i = 0; i < userIds.length; i++) {
            let userId = userIds[i];
            let rank = this.admins[userId].rank;
            d[rank] = userId;
        }
        return d;
    }

    /**
     * Return the admin Rank 
     * @param {string} userId 
     */
    getAdminRank(userId) {
        if (!this.admins[userId]) return undefined;
        return this.admins[userId].rank;
    }

    /**
     * Return the list of members of the quorum
     */
    getMembers() {
        return Object.keys(this.admins);
    }

    /**
     * return true if the admin has added his share for the others
     * ( only in state STATUS.sharing )
     * 
     * @param {_ID} userId 
     * 
     * @returns {undefined|boolean} true if ok, false if must add, or undefined if not the purpose
     */
    hasAdminAddedShare(userId) {
        if (!this.admins[userId]) return undefined;
        if (this.status == STATUS.setsecret) return true;
        if (this.status != STATUS.sharing) return undefined;
        if (this.admins[userId].shares) return true;
        return false;
    }

    /**
     * return true if the admin has added the public key
     * ( only in state STATUS.setsecret )
     * 
     * @param {_ID} userId 
     * 
     * @returns {undefined|boolean} true if ok, false if must add, or undefined if not the purpose
     */
    hasAdminAddedPubKey(userId) {
        if (!this.admins[userId]) return undefined;
        if (this.status == STATUS.ready) return true;
        if (this.status != STATUS.setsecret) return undefined;
        if (!this.adminPublicKeys) return false;
        if (this.adminPublicKeys[userId]) return true;
        return false;
    }

    /**
     * 
     * initialize the quorum key with identified 
     * members
     * 
     * @param {int} quorum the threshold
     * @param {array} userIds array of admin userId
     */
    create(quorum, userIds) {
        this.status = STATUS.sharing;
        this.quorum = quorum;
        this.max = userIds.length;
        if (this.quorum < 2) return error("Quorum too small in quormumKey")
        if (this.max < this.quorum) return error("Not enougth admins in quormumKey")
        this.admins = {};

        for (let i = 0; i < userIds.length; i++) {
            this.admins[userIds[i]] = {
                "rank": i + 1,
            }
        }

        return true;
    }

    /**
     * Remove an admin from the adminList.
     * if status == ready and if we are below the quorum, the key must be deleted
     * if status == sharing or setsecret, the key must be deleted
     * @param {string} userId 
     * @return {boolean} true if ok. false if error
     */
    async removeAdmin(userId, keyStore) {
        if (this.admins[userId]) return error("Administrator " + userId + " not a member of this quorum");
        if ((this.status == STATUS.sharing) || (this.status == STATUS.setsecret)) {
            debug("lybcrypt - QuorumKey.js - removeAdmin - Remove member of a quorum in the status " + this.status + " = delete");
            this.del();
            return true;
        }

        delete(this.admins[userId]);
        if (Object.keys(this.admins).length < this.quorum) {
            await this.del(keyStore);
        }
        return true;
    }


    async del(keyStore) {
        if (this.status == STATUS.deleted) return true;
        super.del(keyStore);

        // If on a keyStore with devices and a quorum key of devices
        // erase all devices member keys
        if (!keyStore.withDevices) return true;
        for (let _id in keyStore.devices) {
            let k = keyStore.devices[_id];
            await k.deleteMemberKeyIfNoQuorumKey();
        }
        return true;
    }

    /**
     * Add secretSharesList from on admin (userId) into the structure
     * 
     * @param {string} userId 
     * @param {ShareKeys[]} secretSharesList 
     */
    addShares(userId, secretSharesList) {

        if (this.status != STATUS.sharing) return error("Cannot add share to a non sharing status");
        if (!this.admins[userId]) return error("Not a member of this quorum")

        //if (!(secretSharesList instanceof ShareKeys)) return error("addShares secretSharesList must be instance of ShareKeys");

        debug("lybcrypt - QuorumKey.js - addShares - Adding share for " + userId, secretSharesList);
        debug("lybcrypt - QuorumKey.js - addShares - Adding share for + current " + userId, this.admins);

        this.admins[userId].shares = secretSharesList;

        // ------------- Change the status if all admin had shared with others -------------------------
        let count = 0;
        for (let i in this.admins) {
            if (!this.admins[i].shares) continue;
            count++;
        }
        if (count == this.max) {
            debug("lybcrypt - QuorumKey.js - addShares - Changing key status to setSecret");
            this.status = STATUS.setsecret;
        }

        return true;
    }

    addAdminPublicKey(userId, publicKeyString) {
        if (this.status != STATUS.setsecret) return false;
        if (!this.adminPublicKeys) this.adminPublicKeys = {};
        debug("lybcrypt - QuorumKey.js - AddAdminPublicKey " + userId + " " + publicKeyString, Object.assign({}, this.adminPublicKeys));

        if (!this.adminPublicKeys[userId]) this.adminPublicKeys[userId] = publicKeyString;

        // ------------- Change the status if all admin had shared with others -------------------------
        let count = 0;
        let publicKey = undefined;
        for (let i in this.adminPublicKeys) {
            if (!publicKey) publicKey = this.adminPublicKeys[i];
            if (publicKey != this.adminPublicKeys[i]) return error("lybcrypt - QuorumKey.js - addAdminPublicKey - Quorum admin publicKey mismatch");
            count++;
        }
        if (count == this.max) {
            this.status = STATUS.ready;
            delete this.adminPublicKeys;
            this.elGamal.publicKeyString = publicKey;
        }
        return true;
    }

    getSharesForMe(userId) {
        if (this.status != STATUS.setsecret) return false;

        if (!this.admins[userId]) return error("lybcrypt - QuorumKey.js - getSharesForMe - Not a member of this quorum")
        let myRank = this.admins[userId].rank;

        let secretSharesList = {};

        for (let i in this.admins) {
            if (!this.admins[i].shares) continue;
            secretSharesList[this.admins[i].rank] = this.admins[i].shares[myRank];
        }

        return secretSharesList;
    }


    /**
     * Return true if the private key is available for decyphering, sign, ...
     */
    get privateKeyReleased() {
        if (this.elGamal.privateKey) return true;
        return false;
    }

    /**
     * No private key to crypt
     * @param {string} passphrase 
     */
    async cryptPrivateKeyWithPassphrase(passphrase) {
        return false;
    }

    /**
     * Cannot releéase the private key. stupid.
     */
    async releasePrivateKey(passphrase) {
        return false;
    }


    /**
     * Generate a keys pair
     * Can be async due to the decryption from a device for example
     * @return {boolean} true if operation OK
     */
    async generate() {
        let ret = await this.elGamal.generateKeys();
        this.resetTimeout();
        return ret;
    }

    async genSessionKeyForWrap() {
        let sessionKey = await this.elGamal.genSessionKeyForWrap();
        return sessionKey;
    }

    async cipherSessionKey(sessionKey) {

        if (!(sessionKey instanceof SessionKey))
            return error("lybcrypt - QuorumKey.js - cipherSessionKey - No session Key to encrypt in ElGamal");

        let encrypted = await this.elGamal.crypt(sessionKey.elGamalSessionKey)
        return new Share(this._id, encrypted, sessionKey.symCrypt.key.params);

        /*
        let key = await this.symCrypt1.key.extract();
        if (!key) return error("No session Key to encrypt in ElGamal");
        let kOAEP = await this.symCrypt1.padRSA_OAEP(key, this.elGamal.P );
        if (!kOAEP) return error("No RSA_OAEP to encrypt in ElGamal");

        let encrypted = await this.elGamal.crypt(kOAEP)
        return new Share(this._id, encrypted);
        */
    }

    async decipherSessionKey(encrypted) {
        return error("lybcrypt - QuorumKey.js - decipherSessionKey - Cannot decipher with a quorum key");
    }

}