import { translate } from './Langs.js';

const STACKSIZE = 30;
var stack = [];

/**
 * 
 * Push the message into the stack.
 * the stack is used in cas of error to follow how the error
 * append.
 * 
 * @param {String} type type of message (error, log, warn)
 * @param {String} message 
 * @param {*} data 
 */
export const appendStack = (type, message, data) => {
    stack.push({
        "type": type,
        "message": message,
        "data": data
    });
    if (stack.length > STACKSIZE) stack.shift();
}


// =========================================================================================
// ============ lynvictus Errors ===========================================================
// =========================================================================================
export class LynError extends Error {
    constructor(message, props = undefined) {

        let m;
        if (typeof(message) === "string") {
            //console.log("LynError.js - constructor - typeof(message) is string");
            m = translate(message, props);
        } else if (message.name === "LynError") {
            //console.log("LynError.js - constructor - message is a LynError");
            // This should not happen, it is a bad call of LynError
            m = message.message;
        } else {
            console.error("LynError.js - constructor - message is of a strange type - should not happen");
            // Unknow LynError
            m = translate(message, props);
        }
        // m should be a string. Is it the case ?
        //console.log("LynError.js - constructor - typeof(m) : ", typeof(m));
        //console.log("LynError.js - constructor - m.name : ", m.name);
        //console.log("LynError.js - constructor - m : ", m);

        super(m);
        this.name = "LynError";
        this.props = props;
        this.debugStack = stack;
        this.message = m;
        console.error(`Lyn-Error "${message}"`, props);
        // I just do not want the stack always. Let's try that :
        const no_stack = ["User not found in database"];
        //console.log("no_stack.includes(message) : ", no_stack.includes(message))
        if (!(no_stack.includes(message))) {
            console.error("Lyn-Error stack", this.stack);
        }
    }

    get content() {
        return ({
            "name": this.name,
            "code": undefined,
            "props": this.props,
            "debugStack": this.debugStack,
            "message": this.message
        });
    }

}