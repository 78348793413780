/* Writen by script */

export const inter = {
  "de": {
    "App config": "App-Konfiguration",
    "Status": "Satzung",
    "Reply": "Antwort",
    "Home": "Startseite",
    "New url": "Neue Einladung",
    "URLs": "Einladungen",
    "Administration": "Verwaltung",
    "Quorums": "Beschlussfähigkeit",
    "Size": "Größe",
    "Vaults": "Kaffees",
    "Help": "Helfer",
    "Enter": "Geben Sie  ein.",
    "Decrypt": "Entziffern Sie",
    "Delete": "Löschen Sie",
    "Logout": "Abmelden",
    "Refuse": "Verweigerer",
    "Close": "schließen",
    "Accept": "Akzeptieren Sie",
    "Edit": "bearbeiten",
    "Send a customized notification email": "Senden Sie eine personalisierte E-Mail-Benachrichtigung",
    "Name": "Nom",
    "Expire": "Verfällt am",
    "Properties": "Eigenschaften",
    "Change password": "Kennwörter",
    "Last Update": "Zuletzt geändert",
    "Expiration date": "Verfallsdatum",
    "Creation date": "Erstellungsdatum",
    "Users": "Benutzer",
    "Rename": "umbenennen",
    "Yes": "Oui",
    "No": "Nicht",
    "Cancel": "Abbruch",
    "Loading": "Laden von",
    "Dataroom crypted": "Verschlüsselter Safe",
    "Owner": "Eigentümer",
    "Comments": "Kommentare",
    "General": "Allgemein",
    "Date": "Datum",
    "Crypted": "Zahlenangaben",
    "Logs": "Protokolle",
    "Crypted thread": "Verschlüsselte Konversation",
    "Act as": "Handeln als",
    "Next": "Nächste",
    "Share": "Teilen Sie",
    "Shares": "Gemeinsame Nutzung von",
    "Subject": "Sujet",
    "Copy to me": "Kopieren Sie mich",
    "Prev": "Vorherige",
    "Message": "Nachricht",
    "Invite": "Laden Sie  ein.",
    "Themes": "Themenbereiche",
    "Tchat": "Diskussionen",
    "There is currently no selected thread": "Derzeit gibt es keine ausgewählten Diskussionen.",
    "Chose someone in the list below": "Wählen Sie jemanden aus der folgenden Liste",
    "Select a friend": "Wählen Sie einen Freund",
    "No member(s)": "Die Auswahl ist leer.",
    "Select a user": "Wählen Sie einen Benutzer",
    "Delete my account": "Mein Konto löschen",
    "New password": "Neues Kennwort",
    "Change": "Wechsler",
    "For": "Gießen Sie",
    "Create": "Erstellen Sie",
    "Encrypt and send": "Verschlüsseln und senden",
    "Refuse access": "Zugang verweigern",
    "Accept access": "Zugang gewähren",
    "Ask for access": "Zugang beantragen",
    "Cancel request": "Beenden Sie die Anwendung",
    "Password": "Kennwort",
    "You need to change your password": "Es ist Zeit, Ihr Passwort zu ändern",
    "User created": "Benutzer erstellt",
    "New user": "Neuer Benutzer",
    "Username": "Anmeldung",
    "No account yet ?": "Noch kein Konto?",
    "Login": "Anmeldung",
    "Login LDAP": "Se-Verbinder (LDAP)",
    "Choose a password": "Wählen Sie ein Passwort",
    "Email": "E-Mail-Adresse",
    "OR": "OU",
    "CREATE AN ACCOUNT": "ERSTELLUNG D'UN COMPTE",
    "Create an account": "Ein Konto erstellen",
    "Back to login": "Zurück zum Anmeldeformular",
    "Back to home": "Zurück zur Startseite",
    "LOGIN TO YOUR ACCOUNT": "ANSCHLUSS EINES",
    "I read and I accept terms and conditions": "Ich habe die allgemeinen Nutzungsbedingungen gelesen und akzeptiere sie.",
    "A email has been sent": "Eine E-Mail wurde gesendet an",
    "Please check your email and click on the verification link.": "Beobachten Sie Ihre Mail und klicken Sie auf den PrüfungLink. Wenn Sie diese E-Mail nicht erhalten haben, überprüfen Sie bitte zuerst Ihren Junk-Mail-Ordner.",
    "Congratulation": "Bravo",
    "Error": "Erreur",
    "Your email cannot be validate !": "Ihre E-Mail kann nicht verifiziert werden!",
    "Never": "Jamais",
    "Anonymous files transfered achieved": "Anonyme Übertragung an \"{name}\" abgeschlossen",
    "Ready": "Leihgabe",
    "Sharing": "Teilen Sie",
    "Secreting": "Geheimnisse",
    "Quorum group created": "Quorum-Gruppe eingerichtet",
    "Description": "Beschreibung",
    "Send files to": "An \"{name}\" senden",
    "Files": "Fichiers",
    "Object": "Objet",
    "Save": "Registrieren Sie",
    "Are you sure to destroy your account ?": "Sind Sie sicher, dass Sie Ihr Konto vernichten wollen?",
    "Unknown": "Unbekannt",
    "Sorry, unable to display this file": "Tut mir leid, diese Datei kann nicht angezeigt werden",
    "Give rights": "Rechte geben",
    "Download": "herunterladen",
    "Add users": "Benutzer verwalten",
    "Confirmation": "Bestätigung",
    "Link": "Pfandrecht",
    "Are you sure to delete this thread ?": "Sind Sie sicher, dass Sie diese Diskussion löschen wollen?",
    "Fileset created": "Truhe erstellt",
    "New folder": "Neues Tresorfach-Verzeichnis",
    "Folder created": "Brustverzeichnis \"{name}\" erstellt",
    "Share(s) added": "Aktie(n) hinzugefügt",
    "Change passphrase": "Ändern Sie die Passphrase",
    "Forgot my passphrase": "Ich habe meine Passphrase vergessen",
    "Current password": "Aktuelles Kennwort",
    "Set": "initialisieren",
    "The list is empty": "Die Liste ist leer.",
    "Create a new quorum group": "Erstellen Sie eine neue Beschlussfähigkeitsgruppe",
    "Auth with Google": "Verbindung mit Google",
    "Enter a name": "Geben Sie einen Namen ein",
    "I forget my password": "Ich habe mein Passwort vergessen.",
    "Password reinitialization": "Passwort zurücksetzen",
    "A email has been sent. Please check your email and click on the link to choose a new password": "Eine E-Mail wurde an \"{email}\" geschickt. Bitte überprüfen Sie Ihre Mailbox und klicken Sie auf den Link, um ein neues Passwort zu wählen.",
    "Password reinitialized": "Passwort zurücksetzen",
    "reinitConfirm": "Warnung: Wenn Sie Ihr Passwort ändern, sind alle nicht freigegebenen Tresore unzugänglich.",
    "Emails": "Mails",
    "Your email is validated": "Ihre E-Mail wurde erfolgreich verifiziert.",
    "Valid": "Valider",
    "In progress": "Laufend",
    "actions required": "Aktion(en) anfordern",
    "Email deleted": "Mail {email} gelöscht",
    "Are you sure to drop email ?": "Sind Sie sicher, dass Sie \"{email}\" löschen wollen?",
    "An email has been sent": "Eine E-Mail wurde an \"{email}\" geschickt.",
    "LoginName": "Benutzer",
    "Groups": "Gruppen",
    "New group": "Neue Gruppe",
    "Group created": "Gruppe erstellt",
    "Devices": "Geräte",
    "Display Name": "Angezeigter Name",
    "DisplayName changed": "Der Anzeigename wurde in \"{displayName}\" geändert.",
    "Avatar changed": "Modifizierter Avatar",
    "Avatar": "Avatar",
    "Your access is under validation": "Ihre Identität und Ihre Zugangsberechtigung zu diesem Safe müssen von folgenden Personen überprüft werden",
    "Dataroom not decypherable": "Nicht entzifferbarer Safe",
    "Write here a new message": "Schreiben Sie unten eine neue Nachricht:",
    "Edit the current message": "Ändern Sie die folgende Meldung :",
    "crypted message": "Verschlüsselte Nachricht",
    "View list": "Listenansicht",
    "View tiles": "Symbol-Ansicht",
    "You need more rights to see this content": "Sie haben keine Berechtigungen zum Anzeigen dieses Inhalts.",
    "Show more elements": "Weitere Artikel anzeigen",
    "New file": "Neue Datei",
    "Number of use": "Anzahl der Verwendungen",
    "Search": "Rechercher",
    "No result found": "Keine Ergebnisse gefunden",
    "My emails": "Gleiche E-Mails",
    "My logs": "Monatsprotokolle",
    "My password": "Mein Passwort",
    "My account": "Mein Konto",
    "Device without name, you may had a new one": "Unbenanntes Gerät, Sie sollten eines hinzufügen",
    "Keys": "Schlüssel",
    "Signed keys": "Signierte Schlüssel",
    "Signed since": "Unterzeichnet seit",
    "The keys you signed": "Die von Ihnen unterzeichneten Schlüssel",
    "Private key in memory up to": "Der private Schlüssel wird im Speicher gespeichert, bis",
    "Finger print": "Numerisches Impressum",
    "Create a new Quorum with these devices": "Erstellen eines neuen Quorums mit diesen Geräten",
    "You are on this device": "Sie befinden sich auf diesem Gerät",
    "Unknown Key Or Removed Key": "Schlüssel unbekannt oder gelöscht",
    "Unknown user": "Unbekannter Benutzer",
    "Signature not found": "Unterschrift nicht gefunden",
    "Creation date not found": "Erstellungsdatum nicht gefunden",
    "Show debug functions": "Debugging-Funktionen anzeigen",
    "Use care when changing the following settings": "Seien Sie vorsichtig, wenn Sie die folgenden Einstellungen ändern, Sie könnten Ihr Konto unbrauchbar machen, wenden Sie sich an den Support, wenn Sie Fragen haben.",
    "Advanced search on users": "Erweiterte Benutzersuche",
    "Advanced search on vaults": "Erweiterte Tresorfachsuche",
    "No item(s) selected yet": "Keine(s) ausgewählte(s) Element(e)",
    "Vaults shared with": "Gemeinsame Tresore mit",
    "Remove existing filters": "Vorhandene Filter löschen",
    "Friends": "Beziehungen",
    "New friend": "Neue Beziehung",
    "Trusted connection": "Beziehung bestätigt",
    "Sign friend": "Bestätigen Sie eine Beziehung",
    "Add friend": "Eine Beziehung hinzufügen",
    "Continue": "Fortsetzung",
    "Signed By": "Bestätigt durch",
    "Create a new url": "Erstellen Sie eine neue Einladung",
    "Link name": "Name des Links",
    "Must be filled": "Muss ausgefüllt werden",
    "Recipient email": "E-Mail des Empfängers",
    "must be an email": "Muss eine E-Mail sein",
    "must be a number": "Es muss eine Zahl sein",
    "No notifications": "Keine Benachrichtigung",
    "No share user selected": "Kein Benutzer für die Freigabe ausgewählt",
    "Rename folder": "Benennen Sie den Ordner um",
    "Fileset renamed": "Umbenanntes Depot",
    "by ": "Par",
    "Rename FileSet": "Den Stamm umbenennen",
    "fileset open": "Box öffnen",
    "Move": "Verschieben Sie",
    "Type": "Geben Sie  ein.",
    "Role": "Rolle",
    "Login name": "Anmeldename",
    "Quorum groups": "Quorum-Gruppen",
    "Advanced search on quorums": "Erweiterte Quorum-Suche",
    "Quorum Group name": "Name der Beschlussfähigkeitsgruppe",
    "Threshold": "Schwelle",
    "Members": "Mitglieder",
    "Send test mail": "Senden Sie eine Test-E-Mail",
    "Add a new mail": "Eine E-Mail hinzufügen",
    "Clear": "Löschen Sie",
    "Move to": "Verschieben Sie \"{elName}\" auf",
    "Advanced search on files": "Erweiterte Dateisuche",
    "No group selected": "Keine Gruppe ausgewählt",
    "Select a group": "Wählen Sie eine Gruppe",
    "New fileset": "Neues Depot",
    "Number of available deposits": "Anzahl der verfügbaren Stämme",
    "Back": "zurück",
    "New share": "Neue Freigabe",
    "Url created & Link sent": "Einladung und E-Mail gesendet",
    "Please use another browser": "Bitte verwenden Sie einen anderen Firefox- oder Chrome-Browser.",
    "Fake": "Simulierte Aktion",
    "Your account is created and ready to use": "Ihr Konto ist erstellt und bereit",
    "Some notifications": "Benachrichtigungen",
    "Adding share(s)": "Gemeinsame Nutzung im Gange",
    "Enter the code": "Geben Sie den Code ein",
    "Too many attempts": "Zu viele Versuche, bitte versuchen Sie es später noch einmal.",
    "Invalid key": "Notenschlüssel nicht gültig",
    "Please plug in and active your key": "Bitte geben Sie Ihren Schlüssel ein und aktivieren Sie ihn.",
    "Advanced search on folders": "Erweiterte Suche nach Tresorfachverzeichnissen",
    "New device": "Neues Gerät",
    "Device created": "Gerät erstellt"
  }
};
export const errors = {
  "de": {
    "Warning": "Achtung",
    "Must be a valid email": "Muss eine gültige E-Mail-Adresse sein",
    "Must be filled": "Kann nicht leer sein",
    "Sorry, object not found": "Entschuldigung, Objekt nicht gefunden",
    "Cannot change password for non local account": "Das Passwort eines Drittkonto kann nicht geändert werden.",
    "Crypting key": "Verschlüsselungsschlüssel",
    "Signing key": "Signaturschlüssel",
    "Quorum key": "Quorum-Schlüssel",
    "Quorum member key": "Schlüssel zur Beschlussfähigkeit",
    "Revoked": "Widerrufen",
    "InProgress1": "Dieser Schlüssel wartet auf die erste Bestätigung der Beschlussfähigkeit.",
    "InProgress2": "Diese Taste wartet auf eine zweite Beschlussfähigkeitsaufforderung."
  }
};
export const notifications = {
  "de": {
    "unknown_notif": "Unbekannte Nachricht!",
    "unknown_notif_text": "Unbekannte Nachricht!"
  }
};
export const mail = {
  "de": {
    "Confirm": "Bestätiger"
  }
};
export const sms = {
  "de": {
    "Verification code": "Hier ist Ihr Validierungscode : \"{otp}\"",
    "A code has been sent to your phone": "Ein Code wurde an Ihr Telefon gesendet"
  }
};