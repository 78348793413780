var myObjectPath = require("object-path");

import {
    SORTORDER,
    SELECT_OPERATORS
} from './Constants.js';

import {
    Checker
} from './Checker.js';

import mime from 'mime-types'

export var timeStampMs = Date.now();

export var baseUrl = '';

var sizeRange = [
    [1, 'B'],
    [1024, 'KB'],
    [1024 * 1024, 'MB'],
    [1024 * 1024 * 1024, 'GB'],
    [1024 * 1024 * 1024 * 1024, 'TB']
];
var sizeRangeSearch = [
    [1, '[oB]'],
    [1024, 'K[oB]'],
    [1024 * 1024, 'M[oB]'],
    [1024 * 1024 * 1024, 'G[oB]'],
    [1024 * 1024 * 1024 * 1024, 'T[oB]']
];



Number.isInteger = Number.isInteger || function(value) {
    return typeof value === "number" &&
        isFinite(value) &&
        Math.floor(value) === value;
};

export function detectIE() {
    if (typeof(window) === 'undefined') return false;

    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

export function objectGet(obj, path, def) {
    let a = myObjectPath.get(obj, path, def);
    if (a === undefined) return def;
    if (a === null) return def;
    return a;
}

export function objectSet(obj, path, v) {
    return myObjectPath.set(obj, path, v);
}

export function objectDel(obj, path) {
    return myObjectPath.del(obj, path);
}

export function updateTimeStamp() {
    timeStampMs = Date.now();
}
export function updateUrl(protocol, host, originalUrl, staticUrlPrefix) {

    if (staticUrlPrefix) {
        baseUrl = staticUrlPrefix;
        //console.log("update baseUrl from " + originalUrl + " to " + baseUrl);
        return;
    }
    let r = /\/[^/]*$/;
    baseUrl = protocol + "://" + host + originalUrl.replace(r, "");
    //console.log("update baseUrl from " + originalUrl + " to " + baseUrl);
}

export function chainPromise(first, generator, ...params) {
    return first.then(() => {
        return generator(...params)
    });
}


/**
 * Change a string from "Hi ${name} ${surname}"
 * by "Hi toto titi" if data = { name : toto, surname : titi}
 * @param  {String} s    The string to interpolate
 * @param  {Object} data The object containing all values
 * @return {String}      The result of the interpolation
 */
export function interpolate(s, data) {
    let r = /\$\{([^}]*)\}/g;
    s = s.replace(r, (a, b) => {
        if (data[b] === undefined) return "";
        return data[b];
    });
    return (s);
}

export function interpolateBis(s, data) {
    let r = /\{([^}]*)\}/g;
    s = s.replace(r, (a, b) => {
        if (data[b] === undefined) return "";
        return data[b];
    });
    return (s);
}


/**
 *    Compute a score on the password
 *
 *     if (score > 80)
 *        return "strong";
 *     if (score > 60)
 *         return "good";
 *    if (score >= 30)
 *        return "weak";
 */
export function scorePassword(pass) {
    let score = 0;
    if (!pass) return score;

    // award every unique letter until 5 repetitions
    let letters = new Object();
    for (let i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    let variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    }

    let variationCount = 0;
    for (let check in variations) {
        variationCount += (variations[check] == true) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return parseInt(score);
}

/**
 * generation of a random String (default size : 20)
 * @return {String} a random String
 */
export function randomString(length = 20) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

export function randomBuffer(length = 32) {
    var text = "";
    for (let i = 0; i < length; i++) {
        let c = Math.floor(Math.random() * 255);
        text += String.fromCharCode(c);
    }
    return text;
}

export function randomByteArray(length = 32) {
    let arr = [];
    for (let i = 0; i < length; i++) {
        let c = Math.floor(Math.random() * 255);
        arr.push(c);
    }
    return arr;
}

export function stringToByteArray(str) {
    let arr = [];
    for (let i = 0; i < str.length; i++) {
        arr.push(str.charCodeAt(i))
    }
    return arr;
}

export function byteArrayToString(arr, length = null) {
    var result = "";
    var l = (length == null ? arr.length : length);
    for (let i = 0; i < l; i++) {
        result += String.fromCharCode(parseInt(arr[i]));
    }
    return result;
}


/* export function toId(s) {
  if (!s) return 'undefined_Id';
  let r = /[^A-a0-9]/g;
  return s.replace(r, '__');
} */
export const toId = function(s) {
    // eslint-disable-next-line no-useless-escape
    let r = /[\$\.\{\}\[\]\s\:\,\(\)\@\%\^\'\"\&\?\!\*\+\=\-\#\<\>\/\\\|]/g;
    if (!s) {
        var stack = new Error().stack;
        console.log("PRINTING CALL STACK");
        console.log(stack);
        return undefined
    }
    return s.replace(r, '__').toLowerCase();
}

var hexChar = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f"];

function byteToHex(b) {
    return hexChar[(b >> 4) & 0x0f] + hexChar[b & 0x0f];
}

export function getNewObjectID() {
    var date = Math.floor(timeStampMs / 1000)
    let s = "";
    for (let i = 3; i >= 0; i--) {
        let by = (date >> (8 * i)) & 0xff;
        s += byteToHex(by);
    }
    for (let i = 0; i < 8; i++) {
        let c = Math.floor(Math.random() * 255);
        let b = c.toString(16);
        if (b.length == 1) b = '0' + b;
        s += b;
    }
    return (s);
}

/**
 * Check if a string is like a regexp
 * @param  {String} str     The string to test
 * @param  {String} reglike The like
 */
export function like(str, reglike) {
    var likereg = reglike.replace(/([slmntrdfgcbp])/ig, "$1{1,2}");
    var reg = new RegExp(likereg, 'i');
    if (str.match(reg)) return true;
    return false;
}

/**
 * compute the number of Blocks need to carry size of data
 * @param  {int} size      the size
 * @param  {int} blockSize the size of the block
 * @return {int}           the number blocks
 */
export function getBlockNumber(size, blockSize) {
    let s = Math.floor(size / blockSize);
    if (size % blockSize > 0) s++;
    return (s);
}

/**
 * enter a delay (3d 1h, ...) znd return in miliseconds
 * @param  {[type]} t [description]
 * @return {milisecond}   [description]
 */
export function toDelay(t) {
    if (!t) return 0;
    if (t == 0) return 0;
    if (t == "0") return 0;
    // ------- directly a timestamp ---------------------------
    if (new Checker("expire").check(t) != true) return t;

    let add = 0;
    let res = t.match(/^([0-9]+)s/);
    if (res) {
        add += parseInt(res[1]);
    }

    res = t.match(/^(-*)([0-9]+)m/);
    if (res) {
        add += res[1] ? -1 * parseInt(res[2]) * 60 : parseInt(res[2]) * 60;
    }

    res = t.match(/^(-*)([0-9]+)h/);
    if (res) {
        add += res[1] ? -1 * parseInt(res[2]) * 3600 : parseInt(res[2]) * 3600;
    }

    res = t.match(/^(-*)([0-9]+)d/);
    if (res) {
        add += res[1] ? -1 * parseInt(res[2]) * 84400 : parseInt(res[2]) * 84400;
    }

    res = t.match(/^(-*)([0-9]+)w/);
    if (res) {
        add += res[1] ? -1 * parseInt(res[2]) * 604800 : parseInt(res[2]) * 604800;
    }

    res = t.match(/^(-*)([0-9]+)M/);
    if (res) {
        add += res[1] ? -1 * parseInt(res[2]) * 2678400 : parseInt(res[2]) * 2678400;
    }

    res = t.match(/^(-*)([0-9]+)Y/);
    if (res) {
        add += res[1] ? -1 * parseInt(res[2]) * 30806000 : parseInt(res[2]) * 30806000;
    }

    res = t.match(/^(-*)([0-9]+)y/);
    if (res) {
        add += res[1] ? -1 * parseInt(res[2]) * 30806000 : parseInt(res[2]) * 30806000;
    }

    return add * 1000;
}

/**
 * Switch from "delay" to timeStampMs
 * @return {TimeStamp} The timeStampMs
 */
export function expireToTimeStamp(expire) {

    if (!expire) return 0;
    if (expire == 0) return 0;
    if (expire == "0") return 0;

    // ------- directly a timestamp ---------------------------
    if (new Checker("int+").check(expire) == true) return expire;

    let add = toDelay(expire);

    if (add != 0) {
        let t = timeStampMs; // actual time in milisecond
        return (t + add);
    }
    let res = Date.parse(expire);
    if (res) {
        return res;
    }
    return (console.error("Unable to understand date or expiration (" + expire + ")"));
}


/**
 * Return the minimal expiration of the two
 * @param {*} exp1
 * @param {*} exp2
 */
export function minExpire(exp1, exp2) {
    if (exp1 === undefined) return exp2;
    if (exp2 === undefined) return exp1;
    if (exp1 == 0) return exp2;
    if (exp2 == 0) return exp1;
    return (exp2 > exp1 ? exp1 : exp2);
}


/**
 * return a tabLine like
 * +----+-------------+--+
 * @param  {Array} b length of each segment
 * @return {String}   the line as a string
 */
export function tabLine(b) {
    let s = "+";
    for (let i = 0; i < b.length; i++) {
        for (let j = 0; j < b[i]; j++) {
            s += "-";
        }
        s += "+";
    }
    return s;
}

export function fileConvertSize(aSize, fixedUnity = undefined) {
    if (!aSize) return "0";
    aSize = Math.abs(parseInt(aSize, 10));

    if (fixedUnity) {
        for (let i = 0; i < sizeRange.length; i++) {
            if (sizeRange[i][1] != fixedUnity) continue;
            return (aSize / sizeRange[i][0]).toFixed(2);
        }
        return undefined;
    }

    for (let i = 0; i < sizeRange.length; i++) {
        if (aSize < sizeRange[i][0]) return (aSize / sizeRange[i - 1][0]).toFixed(2) + ' ' + sizeRange[i - 1][1];
    }
}

export function sizeToOctets(sizeText) {
    if (!sizeText) return 0;

    let size = 0.0;
    for (let i = 0; i < sizeRangeSearch.length; i++) {
        let search = new RegExp("^([0-9.]+)" + sizeRangeSearch[i][1] + "$");
        let res = sizeText.match(search);
        if (res) {
            size = parseFloat(res[1]) * sizeRangeSearch[i][0];
        }
    }

    return Math.abs(size);
}

export function compareSortTwoObjects(e1, e2, field, order, getFieldFunc = objectGet) {
    let f1 = getFieldFunc(e1, field);
    if ((Array.isArray(f1)) || (typeof f1 === 'object')) return 1 * order;
    let f2 = getFieldFunc(e2, field);
    if ((Array.isArray(f2)) || (typeof f2 === 'object')) return 1 * order;
    if (Number.isInteger(f1) && Number.isInteger(f2)) return ((f1 - f2) * order);
    if (f1 === f2) return 0;
    if (f1 < f2) return (-1 * order);
    return order;
}

export function sortObjectsByElement(arr, field, order = SORTORDER.ASCENDANT) {
    if (!arr) return arr;
    return arr.sort((e1, e2) => {
        return compareSortTwoObjects(e1, e2, field, order);
    });
}

export function isAnEmail(value) {
    if (!value) return false;
    //let fullEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let catchAll = /^@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //if(value.match(fullEmail)) return true;
    if (value.match(catchAll)) return true;
    return false;
}

export function isAnIp(value) {
    let result = false;
    if (!value) return false;
    let cidr = /^(([01]?\d?\d|2[0-4]\d|25[0-5])\.){3}([01]?\d?\d|2[0-4]\d|25[0-5])\/(\d{1}|[0-2]{1}\d{1}|3[0-2])$/;
    if (value.match(cidr)) result = true;
    return result;
}

/**
 *
 * @param {string} value, contains the email to verify ("toto@lybero.net")
 * @param {array} aclArray, the array with conditions to match (["-@lybero.net","+"])
 * @return {boolean}
 */
export function isAnEmailValidatedByACL(value, aclArray) {
    if (!value) return false;
    if (!aclArray) return false;
    if (!Array.isArray(aclArray)) return false;

    let result = "0";
    let valueWithLowerCase = value.toLowerCase();
    let valueWithLowerCaseDomain = valueWithLowerCase.split('@').pop();

    for (let i = 0; i < aclArray.length; i++) {
        const element = aclArray[i];
        let type = element.substring(0, 1);
        let condition = element.substring(1);
        if (condition) {
            let domain = condition.split('@').pop();
            let domainReg = new RegExp(`^${escapeSpecialChars(domain)}$`);
            if (valueWithLowerCaseDomain.match(domainReg)) {
                if (type === "+") result = "2";
                if (type === "-") result = "1";
            }
        }
    }

    switch (result) {
        case "0": //not found, fallback to default value
            if (aclArray[aclArray.length - 1] === "+") return true;
            return false;
        case "1": // explicit reject
            return false;
        case "2": // explicit allow
            return true;
        default:
            return false;
    }

}

export function compareObject(a, b) {

    // --- Two objects ---
    if ((a instanceof Object) && (b instanceof Object)) {
        let found = {};
        for (let key in a) {
            found[key] = true;
            if (!compareObject(a[key], b[key])) return false;
        }
        for (let key in b)
            if (!found[key]) return false;

        return true;
    }

    // --- Two arrays ---
    if (Array.isArray(a) && Array.isArray(b)) {
        if (a.length != b.length) return false;
        for (let i = 0; i < a.length; i++) {
            if (!compareObject(a[i], b[i])) return false;
        }
        return true;
    }

    // --- 2 other ---
    if (a === b) return true;
    return false;
}

export function compareArray(a, b) {
    if (!Array.isArray(a)) return false;
    if (!Array.isArray(b)) return false;
    if (a.length != b.length) return false;
    for (let i = 0; i < a.length; i++) {
        if (b.indexOf(a[i]) == -1) return false;
    }
    return true;

}

export function concatUniqArray(a1, a2) {
    let a = {};
    for (let i of a1) a[i] = true;
    for (let i of a2) a[i] = true;
    return Object.keys(a);
}

/**
 *
 * @param {string} value, contains the IP to verify ("192.168.0.1")
 * @param {array} aclArray, the array with conditions to match (["-192.168.0.1/24","+"])
 * @return {boolean}
 */
export function isAnIPValidatedByACL(value, aclArray) {
    if (!value) return false;
    if (!aclArray) return false;
    if (!Array.isArray(aclArray)) return false;

    let result = "0";

    const ip4ToInt = (ip) =>
        ip.split('.').reduce((int, oct) => (int << 8) + parseInt(oct, 10), 0) >>> 0;

    const isIp4InCidr = (ip) => (cidr) => {
        let type = cidr.substring(0, 1);
        cidr = cidr.substring(1); //remove the "+" or "-" of the ip
        const [range, bits = 32] = cidr.split('/');
        const mask = ~(2 ** (32 - bits) - 1);
        let match = (ip4ToInt(ip) & mask) === (ip4ToInt(range) & mask);
        if (match && type === '-') result = "1";
        if (match && type === '+') result = "2";
    };

    const isIp4InCidrs = (ip, cidrs) => cidrs.some(isIp4InCidr(ip));

    isIp4InCidrs(value, aclArray);

    switch (result) {
        case "0": //not found, fallback to default value
            if (aclArray[aclArray.length - 1] === "+") return true;
            return false;
        case "1": // explicit reject
            return false;
        case "2": // explicit allow
            return true;
        default:
            return false;
    }
}

export function lightenDarkenColor(col, amt) {
    var usePound = false;
    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export function hexToHSL(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);
    r /= 255, g /= 255, b /= 255;
    var max = Math.max(r, g, b),
        min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;
    if (max == min) {
        h = 0;
        s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
            default:
                break;
        }
        h /= 6;
    }
    var HSL = new Object();
    HSL['h'] = h;
    HSL['s'] = s;
    HSL['l'] = l;
    return HSL;
}

export function escapeSpecialChars(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function getMimeType(file) {
    let extension = file.name.match(/\.([^.]*)$/);
    return mime.types[extension[1]]
}

export function isDisplayedOnMobileDevice(width) {
    let mobileSizes = ['xs', 'sm'];
    if (mobileSizes.indexOf(width) >= 0) return true
    return false
}

// check if a key1.key2.key3 exists in an object
// configStore.info.size.max
// checkNested(configStore, "info","size","max")
export function checkNested(obj, level, ...rest) {
    if (obj === undefined) return false
    if (rest.length == 0 && obj.hasOwnProperty(level)) return true
    return checkNested(obj[level], ...rest)
}

export function matchOperator(value, operator, filterValue) {
    switch (operator) {
        case SELECT_OPERATORS.EQUALS:
            return (value === filterValue) ? true : false;
        case SELECT_OPERATORS.NOT_EQUALS:
            return (value === filterValue) ? false : true;
        case SELECT_OPERATORS.REGEX:
            if (typeof(value) !== 'string') return false;
            return value.match(filterValue) ? true : false;
        case SELECT_OPERATORS.LESSER_OR_EQUALS:
            if ((isNaN(value)) || (isNaN(filterValue))) return false;
            return (value <= filterValue) ? true : false;
        case SELECT_OPERATORS.LESSER_THAN:
            if ((isNaN(value)) || (isNaN(filterValue))) return false;
            return (value < filterValue) ? true : false;
        case SELECT_OPERATORS.GREATER_OR_EQUALS:
            if ((isNaN(value)) || (isNaN(filterValue))) return false;
            return (value >= filterValue) ? true : false;
        case SELECT_OPERATORS.GREATER_THAN:
            if ((isNaN(value)) || (isNaN(filterValue))) return false;
            return (value > filterValue) ? true : false;
        default:
            return false;
    }
}

export function replace(str, data) {
    return str.replace(/\{[^}]+\}/g, (v) => {
        let c = v.replace(/{|}/g, '')
        return myObjectPath.get(data, c, 'unknown');
    })
}