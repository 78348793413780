const crypto = require('crypto');


import {
    CryptoObject,
    utf8ToArrayBuffer,
} from './Common.js';




/**
 * convertion from String to Uint8Array
 * @param  {[String]} string [the string to convert]
 * @return {[Uint8Array]}        [the array]
 */
function stringToUint8Array(string) {
    //var string = btoa(unescape(encodeURIComponent(string)));
    var charList = string.split('');
    var uintArray = [];

    for (var i = 0; i < charList.length; i++) {
        uintArray.push(charList[i].charCodeAt(0));
    }
    //let a = new Uint8Array(uintArray);
    //console.log(String.fromCharCode.apply(null, a));
    return new Uint8Array(uintArray);
}

function equalAb(buf1, buf2) {
    if (buf1.byteLength != buf2.byteLength) return false;
    var dv1 = new Int8Array(buf1);
    var dv2 = new Int8Array(buf2);
    for (var i = 0; i != buf1.byteLength; i++) {
        if (dv1[i] != dv2[i]) return false;
    }
    return true;
}

export const HASHTYPE = {
    "sha1": "sha1",
    "sha256": "sha256",
    "sha512": "sha512",
}
export const HASHOUTPUT = {
    "raw": "raw",
    "base64": "base64",
}

/**
 *******************************************************************************
 **** Hash *********************************************************************
 *******************************************************************************
 */
export class Hash extends CryptoObject {
    /**
     * New object Hash
     * @param  {[type]} string [the type of hash]
     * @return {[type]}      [description]
     */
    constructor(errorFunc, debugFunc, type) {
        super(errorFunc, debugFunc);
        this.generateconstants(type);
    }

    generateconstants(type) {
        switch (type) {
            case 'sha1':
                this.algoString = 'sha1';
                this.algo = 'sha1';
                this.windowCryptoName = "SHA-1";
                break;
            case 'sha256':
                this.algoString = 'sha256';
                this.algo = 'sha256';
                this.windowCryptoName = "SHA-256";
                break;
            case 'sha512':
            default:
                this.algoString = 'sha512';
                this.algo = 'sha512';
                this.windowCryptoName = "SHA-512";
        }
    }

    /**
     * Return the lenght of the hash in octets
     */
    get length() {
        switch (this.algoString) {
            case 'sha512':
                return (64);
            case 'sha256':
                return (32);
            case 'sha1':
                return (20);
            default:
                return (0);
        }
        //return 0;
    }

    /**
     * Make a hash of the string passed in parameter
     * @param  {[String]} text [The string to hash]
     * @param  {[String]} [outputFormat=undefined] [the output format]
     * @return {[Promise]}      [a promise witch compute base64 encoded string with "{algo}" at the beginning]
     */
    hash(text, outputFormat = undefined) {
        let algoString = this.algoString;
        let algo = this.algo;

        let dd = (typeof text === "string") ? utf8ToArrayBuffer(text) : text;


        // --------- Use this.wCrypto -------
        if (this.windowCrypto) {
            return this.subtle.digest({
                        name: this.windowCryptoName,
                    },
                    dd
                )
                .then((hash) => {
                    let a = this.formatOutput(hash, outputFormat);
                    return a;
                })
        }

        // --------- Use node crypto module -------
        return new Promise((resolve, reject) => {
            let hash = crypto.createHash(this.algo);
            hash.update(text);
            let a = this.formatOutput(hash.digest(), outputFormat);
            resolve(a);
        });
    }

    /**
     * Verify a hash
     * @param  {[String|ArrayBuffer]} text   [The text]
     * @param  {[String]} digest [The base64 encoded hash]
     * @return {[Promise]}        [a promise witch compute a boolean for verification]
     */
    verify(text, digest, algo = undefined) {
        let digestArray = undefined;
        if (typeof digest === "string") {
            let res;
            if (!(res = digest.match(/^\{([^\}]+)\}(.*)/)))
                return (this.err("Invalid hashed string"));
            let type = res[1];
            digestArray = new Buffer(res[2], 'base64');
            this.generateconstants(type);
        } else {
            this.generateconstants(algo);
            digestArray = digest;
        }

        let dd = (typeof text === "string") ? stringToUint8Array(text) : text;

        // --------- Use this.wCrypto -------
        if (this.windowCrypto) {
            return this.subtle.digest({
                        name: this.windowCryptoName,
                    },
                    dd
                )
                .then((hash) => {
                    return equalAb(hash, digestArray);
                })
        }

        // --------- Use node crypto module -------
        return new Promise((resolve, reject) => {
            let hash = crypto.createHash(this.algo);
            hash.update(dd);
            let c = hash.digest();
            resolve(equalAb(c, digestArray));
        });
    }


    formatOutput(hash, outputFormat = undefined) {

        switch (outputFormat) {
            case "raw":
                return hash;
            default:
            case "base64":
                //        let base64String = btoa(String.fromCharCode(...new Uint8Array(hash)));
                let base64String = Buffer.from(new Uint8Array(hash)).toString('base64')
                return ('{' + this.algoString + '}' + base64String)
        }
    }



    pkpbf2() {
        // --------- Use this.wCrypto -------
        if (this.windowCrypto) {
            return this.subtle.generateKey({
                    name: "PBKDF2",
                },
                false, //whether the key is extractable (i.e. can be used in exportKey)
                ["deriveKey", "deriveBits"] //can be any combination of "deriveKey" and "deriveBits"
            )
        }
    }

}