const schmlux = require('../schmlux/index.js');
const ClientSingleStore = schmlux.ClientSingleStore;
export const WITHOUT_EMITCHANGE = schmlux.WITHOUT_EMITCHANGE;
export const WITH_EMITCHANGE = schmlux.WITH_EMITCHANGE;
import {
    progressActionsStore
} from './ProgressActionsStore';

import {
	LynError
} from '../../../SharedLibs/LynError';

// ---- For debugging ------
import Debug from '../../../SharedLibs/DebugObj';

export default class SingleStore extends ClientSingleStore {

    constructor() {
        super();
        this.singleton = undefined;

		// --- Set the debug --
		this.deb = new Debug('SingleStore');
		this.l = this.deb.l;
		this.log = this.deb.log;
        this._false = this.deb._false;
        this._true = this.deb._true;
        // --- Set the debug --

        this.setDebugFunc( (type, message, data) => {
            return this.log( `${type}/${message}`, data )
        })


        this.WITH_EMITCHANGE = WITH_EMITCHANGE;
        this.WITHOUT_EMITCHANGE = WITHOUT_EMITCHANGE;
        this.workflowsDescriptions = {};
        this.data = undefined;
        this.objectType = undefined;
    }


    getObj() {
        return this.data;
    }

	/**
	 *
	 *  ██████╗ ███████╗████████╗████████╗███████╗██████╗ ███████╗
	 * ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝
	 * ██║  ███╗█████╗     ██║      ██║   █████╗  ██████╔╝███████╗
	 * ██║   ██║██╔══╝     ██║      ██║   ██╔══╝  ██╔══██╗╚════██║
	 * ╚██████╔╝███████╗   ██║      ██║   ███████╗██║  ██║███████║
	 *  ╚═════╝ ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝
	 *
	 */
    get owner() {
        return this.getField( 'owner' )
    }
    get _id() {
        return this.getField( '_id' )
    }
    get ownerId() {
        return this.getField( 'owner._id' )
    }
    get displayName() {
        return this.getField( 'displayName', this.getField("login") )
    }

    get objTypeId() {
        return ({
            'type' : this.objectType,
            '_id' : this._id,
            'displayName' : this.displayName
          })
    }

    /*
     *
     * ██████╗ ██╗ ██████╗ ██╗  ██╗████████╗███████╗
     * ██╔══██╗██║██╔════╝ ██║  ██║╚══██╔══╝██╔════╝
     * ██████╔╝██║██║  ███╗███████║   ██║   ███████╗
     * ██╔══██╗██║██║   ██║██╔══██║   ██║   ╚════██║
     * ██║  ██║██║╚██████╔╝██║  ██║   ██║   ███████║
     * ╚═╝  ╚═╝╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝
     *
     */
    canCreate() {
        return false;
    }

    canManage( /* _id */ ) {
        return false;
    }

    canDelete(_id) {
        return this.canManage(_id);
    }

    /**
     *
     *
     *
     * ██████╗ ██████╗  ██████╗  ██████╗ ██████╗ ███████╗███████╗███████╗
     * ██╔══██╗██╔══██╗██╔═══██╗██╔════╝ ██╔══██╗██╔════╝██╔════╝██╔════╝
     * ██████╔╝██████╔╝██║   ██║██║  ███╗██████╔╝█████╗  ███████╗███████╗
     * ██╔═══╝ ██╔══██╗██║   ██║██║   ██║██╔══██╗██╔══╝  ╚════██║╚════██║
     * ██║     ██║  ██║╚██████╔╝╚██████╔╝██║  ██║███████╗███████║███████║
     * ╚═╝     ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝
     *
     */


    cleanProgress() {
        return progressActionsStore.clean(progressActionsStore.WITH_EMITCHANGE);
    }

    initGenericProgress(_id, name, max, parentProgressId) {
        let data = {
            '_id': _id,
            'parentProgressId': parentProgressId,
            'name': name,
            'max': max,
        };

        this.log(`initGenericProgress`, data);
        let el = progressActionsStore.create(data, progressActionsStore.WITH_EMITCHANGE);
        return el._id;
    }

    reassignProgressId(oldId, newId) {
        return progressActionsStore.reassignId(oldId, newId);
    }

    incrementProgress(_id) {
        return progressActionsStore.inc(_id, progressActionsStore.WITH_EMITCHANGE)
    }

    stopProgress(_id) {
        progressActionsStore.end(_id, progressActionsStore.WITH_EMITCHANGE);
        this.cleanProgress()
    }

    cancelProgress(_id) {
        return progressActionsStore.cancel(_id)
    }

    errorProgress(_id, err) {
        this.log(`error progress `, _id);
        return progressActionsStore.error(_id, err, progressActionsStore.WITH_EMITCHANGE)
    }





    /*
     *
     * ███████╗██████╗ ██████╗  ██████╗ ██████╗ ███████╗
     * ██╔════╝██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██╔════╝
     * █████╗  ██████╔╝██████╔╝██║   ██║██████╔╝███████╗
     * ██╔══╝  ██╔══██╗██╔══██╗██║   ██║██╔══██╗╚════██║
     * ███████╗██║  ██║██║  ██║╚██████╔╝██║  ██║███████║
     * ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝
     *
     */

    error(message, data) {
        throw new LynError(message, data);
    }

}