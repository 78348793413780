var myObjectPath = require("object-path");

import {
    lightenDarkenColor
} from '../../../SharedLibs/Tools.js';

import {
    Checker
} from '../../../SharedLibs/Checker.js';

import { timeStampToDateMs } from '../../../SharedLibs/Langs.js';
import { TIMEFORMAT } from '../../../SharedLibs/Constants.js';

import {
    ANONYMOUS_ACTOR,
    OBJECT_TYPE,
    OBJECT_PATH
} from '../../../SharedLibs/Constants.js';

import {
    LynError
} from '../../../SharedLibs/LynError.js'

import {
    ClientWorkFlow
} from './ClientWorkFlow.js';

// ---- For debugging ------
import Debug from '../../../SharedLibs/DebugObj'

export class GenericObject {
    constructor(store, accountStore) {
        this.data = {};
        this.objectType = OBJECT_TYPE.GENERIC;
        this.objectPath = OBJECT_PATH.GENERIC;
        this.storeName = 'generic';

        // --- Set the debug --
        this.deb = new Debug('object');
        this.l = this.deb.l;
        this.log = this.deb.log;
        this._false = this.deb._false;
        this._true = this.deb._true;
        // --- Set the debug --

        this.workflows = {};
        this.store = store;
        this.accountStore = accountStore;

        this.parent = undefined;

        this.setWFsContent = this.setWFsContent.bind(this);
    }




    get urlPath() {
        return `${this.objectPath}/${this._id}`
    }

    get objTypeId() {
        return ({
            'type': this.objectType,
            '_id': this._id,
            'displayName': this.displayName,
        })
    }

    getField(path, defaultValue) {
        return myObjectPath.get(this.data, path, defaultValue)
    }
    setField(path, value) {
        return myObjectPath.set(this.data, path, value)
    }

    getMyStore() {
        if (!this.accountStore) throw new Error('accountStore not set in object');
        return this.accountStore.getStoreByName(this.storeName);
    }
    getStoreByName(name) {
        return this.accountStore.getStoreByName(name);
    }
    getAccounStore() {
        return this.accountStore;
    }

    getParent() {
        let store = this.getMyStore();
        return store.getParent(this)
    }
    getParentInStore() {
        let store = this.getMyStore();
        return store.getParentInStore(this)
    }

    getChilds() {
        return undefined
    }



    defineWorkFlows(name, workflowDescription) {
        this.log(`define workflows ${name}`, workflowDescription);
        this.workflows[name] = new ClientWorkFlow(name, workflowDescription, this.store);
    }

    setContent(data) {
        this.data = data;
        this.setWFsContent(data.workflows);
    }

    get _id() {
        return this.data._id;
    }
    get name() {
        return this.getField("name", undefined)
    }
    get ctime() {
        return this.getField("serverMeta.ctime", 0)
    }
    get creationDate() {
        return timeStampToDateMs(this.ctime, TIMEFORMAT.UTC)
    }
    get modificationDate() {
        return timeStampToDateMs(this.mtime, TIMEFORMAT.UTC)
    }
    get mtime() {
        return this.getField("serverMeta.mtime", 0)
    }
    get lastModifierName() {
        return this.getField("serverMeta.lastModifier.login", "unknown")
    }
    get lastModifierId() {
        return this.getField("serverMeta.lastModifier.login", "unknown")
    }
    get expire() {
        return this.getField("expire", 0)
    }
    get ownerId() {
        return this.getField("serverMeta.owner._id", '0')
    }
    get ownerName() {
        return this.getField("serverMeta.owner.login", "unknown")
    }
    get status() {
        return "";
    }
    get description() {
        return this.getField("description", "")
    }
    get serverMeta() {
        return this.getField("serverMeta", {
            atime: 0,
            ctime: 0,
            mtime: 0,
            owner: ANONYMOUS_ACTOR,
            lastModifier: ANONYMOUS_ACTOR,
        })
    }
    get lstat_mode() {
        return parseInt("00000", 8);
    }

    get size() {
        return 0;
    }

    isOwner(_id) {
        //console.log("Libs/GenericObject.js - isOwner - _id : ", _id);
        //console.log("Libs/GenericObject.js - isOwner - this.ownerId : ", this.ownerId);
        if (!_id) return false;
        if (_id === this.ownerId) return true;
        return false;
    }

    get content() {
        return this.data
    }

    getContentForServer() {
        this.data.workflows = this.getWFsContent();
        return this.data
    }

    get alert() {
        return this.asWFImportantActionToDo();
    }

    get logged() {
        return false;
    }

    initServerMeta(owner = ANONYMOUS_ACTOR) {
        let d = Date.now();
        return this.setField("serverMeta", {
            atime: d,
            ctime: d,
            mtime: d,
            owner: owner,
            lastModifier: owner,
        })
    }

    move() {
        return false
    }


    getContainersChilds() {
        return undefined
    }

    buildPathString(pathObjects) {
        let path = "/";
        for (let elt of pathObjects) {
            path += elt.data.name ? elt.data.name : "root"
        }
        path += "/"

        return path
    }

    async getPath() {
        let path = [];
        let p = await this.getParent();
        while (p) {
            path.unshift(p);
            p = await p.getParent();
        }
        return path;
    }

    // Avatar management
    get avatarContent() {
        let a = this.avatarSrc;
        if (a) return {
            "type": "src",
            "data": a,
            "backgroundColor": 'none'
        };
        a = this.avatarIconName;
        if (a) return {
            "type": "icon",
            "data": a,
            "backgroundColor": this.backgroundColor
        }
        return {
            "type": "text",
            "data": this.initialsFromDisplayName,
            "backgroundColor": this.colorFromDisplayName
        }
    }

    get avatarSrc() {
        let a = this.avatarUrl;
        if (a) return a;
        return this.avatarBlob;
    }

    get avatarBlob() {
        return this.getField("avatar.blob")
    }
    set avatarBlob(b) {
        if (!this.data.avatar) this.data.avatar = {};
        return this.setField("avatar.blob", b)
    }

    get avatarUrl() {
        return this.getField("avatar.url")
    }
    set avatarUrl(s) {
        if (!this.data.avatar) this.data.avatar = {};
        return this.setField("avatar.url", s)
    }

    get avatarIconName() {
        return undefined;
    }

    get initialsFromDisplayName() {
        let d = this.displayName;
        if (!d) return "--";
        let l = d.replace("@.*", "").split(/[.\s+]/)
            //console.log("GenericObject.js - initialsFromDisplayName - toUpperCase");
        let s = "";
        if (l[0].length > 0) {
            s = l[0][0].toUpperCase();
        }
        if (l.length > 1) {
            if (l[1].length > 0) {
                s += l[1][0].toUpperCase();
            }
        } else {
            if (l[0].length > 1) s += l[0][1];
        }
        return s;
    }

    get colorFromDisplayName() {
        let d = this.displayName;
        if (!d) d = "unknown";
        let letters = '0123456789ABCDEF'.split('');
        let color = '#';
        for (var i = 0; i < 6; i++) {
            let c = d.charCodeAt(i % d.length) % (i % 2 ? 10 : 15);
            color += letters[c];
        }
        return lightenDarkenColor(color, 60);
    }

    get backgroundColor() {
        return undefined;
    }

    get topLeftIconName() {
        return undefined;
    }

    get bottomLeftIconName() {
        if (this.avatarBlob) return this.avatarIconName
        return undefined;
    }

    get avatar() {
        return this.getField("avatar")
    }

    get displayName() {
        return this.getField("name", undefined);
    }

    set avatar(struct) {
        this.data.avatar = struct;
    }
    set expire(newDate) {
        this.data.expire = newDate;
    }
    set description(newDescription) {
        this.data.description = newDescription;
    }
    set name(newName) {
        this.data.name = newName;
    }



    /*
     *
     *
     * ██╗    ██╗ ██████╗ ██████╗ ██╗  ██╗███████╗██╗      ██████╗ ██╗    ██╗███████╗
     * ██║    ██║██╔═══██╗██╔══██╗██║ ██╔╝██╔════╝██║     ██╔═══██╗██║    ██║██╔════╝
     * ██║ █╗ ██║██║   ██║██████╔╝█████╔╝ █████╗  ██║     ██║   ██║██║ █╗ ██║███████╗
     * ██║███╗██║██║   ██║██╔══██╗██╔═██╗ ██╔══╝  ██║     ██║   ██║██║███╗██║╚════██║
     * ╚███╔███╔╝╚██████╔╝██║  ██║██║  ██╗██║     ███████╗╚██████╔╝╚███╔███╔╝███████║
     *  ╚══╝╚══╝  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚══════╝ ╚═════╝  ╚══╝╚══╝ ╚══════╝
     *
     *
     *
     */



    async initWFs() {
        this.log("initWFs", arguments);
        for (let name in this.workflows) {
            let r = await this.workflows[name].init(this)
            if (r !== true) throw new LynError("workflow \"" + name + "\" init error " + r);
        }
        return true;
    }

    getWFsStatus() {
        let a = [];
        for (let name in this.workflows) {
            let w = this.workflows[name].getDisplayedStatus(this);
            if (!w) continue;
            a.push(w)
        }
        return a;
    }

    asWFImportantActionToDo() {
        this.log("asWFImportantActionToDo start")
        for (let name in this.workflows) {
            if (this.workflows[name].asImportantActionToDo(this)) return true;
        }
        this.log("asWFImportantActionToDo found no action to do")
        return false;
    }

    getWFsActions() {
        let a = [];
        for (let name in this.workflows) {
            a = a.concat(this.workflows[name].getAvailableActions(this))
        }
        return a;
    }

    async changeWFState(data) {
        this.log("changeWFState", arguments);
        new Checker({
            'name': "string+",
            'state': "string+",
            'comment': "string",
        }, 'changeWFState').checkThrow(data);

        if (!this.data) throw new LynError("cannot apply a workflow to an non -set element ");

        if (!this.workflows[data.name])
            throw new LynError("workflow not found", {
                name: data.name,
            });

        let r = await this.workflows[data.name].changeState(this, data.state, data.data, data.comment);
        if (r !== true) throw new LynError("workflow error", { 'message': r });
        return r
    }

    isWFInState(name, s) {
        if (!this.workflows[name]) return false;
        return this.workflows[name].isInState(s);
    }

    getWFDisplayName(name) {
        if (!this.workflows[name]) return undefined;
        return this.workflows[name].stateDisplayName;

    }
    getWFsContent() {
        let a = {};
        for (let name in this.workflows) a[name] = this.workflows[name].getState();
        return a;
    }

    setWFsContent(data) {

        this.log(`setContent workflows`, data);
        for (let name in data) {
            this.log(`setContent workflow ${name}`, myObjectPath.get(data, name));
            if (!this.workflows[name]) {
                console.warn(`no workflow defined for "${name}" on client side. ignore it`);
                continue;
            }
            let r = this.workflows[name].setState(myObjectPath.get(data, name))
            if (r !== true) {
                r = this.workflows[name].dataImport(this)
                if (!r) throw new LynError("setWFsContent error", { 'message': r });
            }
        }
        return true;
    }



    /*
     *
     * ██████╗ ██╗ ██████╗ ██╗  ██╗████████╗███████╗
     * ██╔══██╗██║██╔════╝ ██║  ██║╚══██╔══╝██╔════╝
     * ██████╔╝██║██║  ███╗███████║   ██║   ███████╗
     * ██╔══██╗██║██║   ██║██╔══██║   ██║   ╚════██║
     * ██║  ██║██║╚██████╔╝██║  ██║   ██║   ███████║
     * ╚═╝  ╚═╝╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝
     *
     */
    canManage() {
        return false;
    }

    canDelete() {
        return this.canManage()
    }


}