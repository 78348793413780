const EXPIRE = 300000; // 5 min

/**
 * The Caching KeyStore
 * 
 */
class KeyStoreCache {

    /**
     * constructor for a keyStore     
     * 
     */
    constructor() {
        this.keyStores = {};


        this.expire = this.expire.bind(this);
        this.timeout = undefined;
    }

    clear( userId ) {
        if ( userId ) {
            delete this.keyStores[userId];
            return true;
        }
        
        if (this.timeout) clearInterval(this.timeout);
        for (let userId in this.keyStores) {
            delete this.keyStores[userId];
        }

    }

    expire() {
        let t = Date.now();
        for (let userId in this.keyStores) {
            if (this.keyStores[userId].expire < t) delete this.keyStores[userId];
        }
    }

    getByUserId(userId) {
        if (!this.keyStores[userId]) return undefined;
        return this.keyStores[userId].keyStore;
    }

    addKeyStore(keyStore) {
        let userId = keyStore.userId;
        if (!this.keyStores[userId]) this.keyStores[userId] = {
            "keyStore": keyStore
        };
        this.keyStores[userId].expire = Date.now() + EXPIRE;

        // --- Set the timer 
        if (!this.timeout) {
            this.timeout = setInterval(this.expire, EXPIRE);
        }

    }

}


export var keyStoreCache = new KeyStoreCache();