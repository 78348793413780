var myObjectPath = require("object-path");

// ---- For debugging ------
import Debug from '../../../SharedLibs/DebugObj'

export const ACTIONSTATUS = {
  "WAITING": 0,
  "RUNNING": 1,
  "FINISHED": 2,
  "CANCELED": 3,
  "ERROR": 4,

};
export class ProgressAction {
  constructor(store, accountStore) {

    // --- Set the debug --
    this.deb = new Debug('Progress');
    this.l = this.deb.l;
    this.log = this.deb.log;
    this._false = this.deb._false;
    this._true = this.deb._true;
    // --- Set the debug --

    this.data = {};
    this.store = store;
    this.accountStore = accountStore;
    this.status = ACTIONSTATUS.WAITING;
  }

  init(data) {
    this.data = {
      '_id': data._id,
      'parentObject': data.parentObject,
      'object': data.object,
      'parentProgressId': data.parentProgressId,
      'cancelable': data.cancelable ? true : false,
      'max': data.max ? data.max : 0,
      'index': data.max ? 0 : -1,
      'name': data.name,
      'childs': {},
    };

    this.status = ACTIONSTATUS.RUNNING;

    this.parent = undefined;

    if ((data.object) && (data.object.getParent)) {
      data.object.getParent()
      .catch(() => {})
      .then( (parent) => {
        if ( ! parent ) return true;
        let parentAction = this.store.getInStoreById(parent._id);
        if (!parentAction) {
          parentAction = new ProgressAction(this.store, this.accountStore);
          parentAction.init({
            '_id': parent._id,
            'object': parent,
            'parentProgressId': undefined,
            'cancelable': false,
            'cancelled': false,
            'max': data.max ? data.max : 0,
            'index': data.max ? 0 : -1,
            'name': data.name,
          });
          this.store.addToStore(parentAction._id, parentAction);
        } else {
          parentAction.addToMax(data.max);
        }
        parentAction.data.childs[this._id] = this;
        this.parent = parentAction;
      })
      return true;
    }


    if (data.parentProgressId) {
      let parentAction = this.store.getInStoreById(data.parentProgressId);
      if (!parentAction) {
        parentAction = new ProgressAction(this.store, this.accountStore);
        parentAction.init({
          '_id': data.parentProgressId,
          'object': undefined,
          'parentProgressId': undefined,
          'cancelable': false,
          'cancelled': false,
          'max': data.max ? data.max : 0,
          'index': data.max ? 0 : -1,
          'name': data.name,
        });
        this.store.addToStore(parentAction._id, parentAction);
      } else {
        parentAction.addToMax(data.max);
      }
      parentAction.data.childs[this._id] = this;
      this.parent = parentAction;
      return true;
    }

    return true;
  }

  addToMax(m) {
    if (this.undeterminate) return false;
    this.data.max += m;
    this.store.addUpdateToEmitChange(this._id);
    if (this.parent) this.parent.addToMax(m);
    return true;
  }

  /**
   * increment the action and its parent
   */
  inc() {
    if (this.data.index === this.max) return this._false('Cannot inc. already on max');
    if (this.data.index === -1) return this._false('Cannot inc an undeterminate progress');
    if (this.status !== ACTIONSTATUS.RUNNING) return this._false('Cannot inc a non running progress');
    this.data.index++;
    if (this.parent) {
      this.parent.inc();
    }

    this.store.addUpdateToEmitChange(this._id);
    return true;
  }

  cancel() {
    if (this.withChilds) return this._false('Cannot cancel a progress with childs ')
    if (this.cancelable) return this._false('Cannot cancel a non cancellable progress ')
    this.end();
    this.status = ACTIONSTATUS.CANCELED;
    this.store.addUpdateToEmitChange(this._id);
    return true;
  }

  error(err) {
    if (this.withChilds) return this._false('Cannot error a progress with childs ')
    this.end();
    this.status = ACTIONSTATUS.ERROR;
    this.err = err;
    this.store.addUpdateToEmitChange(this._id);
    return true;
  }

  end() {
    if (this.withChilds) return this._false('Cannot end a progress with childs ');
    this.status = ACTIONSTATUS.FINISHED;
    if (this.parent) {
      delete this.parent.data.childs[this._id];
      if (!this.parent.withChilds) {
        this.parent.end();
      } else {
        if (!this.parent.undeterminate) {
          this.parent.reduce(this.max, this.index)
        }
      }
    }
    this.store.addUpdateToEmitChange(this._id);
    return true;
  }

  reduce(m, i) {
    if (this.undeterminate) return false;

    this.data.max -= m;
    this.data.index -= i;
    this.store.addUpdateToEmitChange(this._id);
    if (this.parent) return this.parent.reduce(m, i);
    return true;
  }

  reassignId(newId) {
    let oldId = this.data._id;
    this.data._id = newId;
    if (this.parent) {
      this.parent.data.childs[newId] = this;
      delete this.parent.data.childs[oldId];
    }
    return true;
  }

  getField(path, defaultValue) {
    return myObjectPath.get(this.data, path, defaultValue)
  }
  setField(path, value) {
    return myObjectPath.set(this.data, path, value)
  }

  getAccounStore() {
    return this.accountStore;
  }

  setContent(data) {
    this.data = data;
  }

  get isFinished() {
    if (this.status === ACTIONSTATUS.CANCELED) return true;
    if (this.status === ACTIONSTATUS.FINISHED) return true;
    if (this.status === ACTIONSTATUS.ERROR) return true;
    return false;
  }

  get _id() {
    return this.getField("_id", undefined)
  }
  get cancelable() {
    return this.getField("cancelable", false)
  }
  get cancelled() {
    return (this.status === ACTIONSTATUS.CANCELED ? true : false)
  }
  get max() {
    return this.getField("max", 0)
  }
  set max(m) {
    this.setField("max", m)
  }

  get index() {
    return this.getField("index")
  }
  set index(i) {
    return this.setField("index", i)
  }
  get undeterminate() {
    return (this.getField("index") === -1 ? true : false);
  }
  get withChilds() {
    return (Object.keys(this.data.childs).length > 0 ? true : false);
  }

  get percent() {
    let max = this.getField("max");
    let i = this.getField("index");
    if (max === 0) return 0;
    let p = Math.floor((i / max) * 100);
    if (p > 100) return 100;
    return p;
  }
  get name() {
    return this.getField("name", "")
  }

  get parentId() {
    return this.getField("parentId")
  }

  get content() {
    return this.data
  }

  getParent() {
    return this.parent;
  }

}