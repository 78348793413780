import {
    TYPE,
    STATUS,
} from './GenericKey.js';
import {
    debug,
    error
} from './Manager.js';
import {
    SymCrypt
} from '../SymCrypt.js';

export class SessionKey {
    constructor() {
        this.symCrypt = new SymCrypt(error, debug);

        this.elGamalSessionKey = undefined;
        this.elGamalGroup = undefined;
        this.linkedKeys = [];
    }

    /**
     * Generate a session from elgamal to AES.
     * Take the first elGamal session Key
     */
    async generate(keyStore, keyIds) {

        if (!keyStore) return error("lybcrypt - SessionKey.js - generate - No keyStore for session key generation");
        if (!Array.isArray(keyIds)) {
            keyIds = keyStore.getCryptKeyIds();
        }
        if (keyIds.length == 0) return error("lybcrypt - SessionKey.js - generate - empty keyIds for session key generation");

        // --- Select the best weight if there is different 
        // --- keys from different groups 
        let bestWeight = 0;
        for (let keyId of keyIds) {
            let key = keyStore.getKey(keyId);
            if (!key) continue;
            if (key.status != STATUS.valid) continue;
            if ((key.type == TYPE.elGamal) || (key.type == TYPE.quorum)) {
                if (key.elGamal.weight >= bestWeight) bestWeight = key.elGamal.weight;
            }
        }
        if (!bestWeight) return error("lybcrypt - SessionKey.js - generate - No elGamal key Found or selected");

        for (let keyId of keyIds) {
            let key = keyStore.getKey(keyId);
            if (!key) continue;
            if (key.status != STATUS.valid) continue;
            if ((key.type != TYPE.elGamal) && (key.type != TYPE.quorum)) continue;
            if (key.elGamal.weight != bestWeight) continue;

            this.linkedKeys.push(key);

            this.elGamalSessionKey = await key.genSessionKeyForWrap();
            this.elGamalGroup = key.elGamal.group;
            await this.symCrypt.key.wrapElGamalSessionKey(this.elGamalSessionKey);
            this.wrapParameters = this.symCrypt.key.params;

            /*
            // made with RSAOEAP . not good !!
            await this.symCrypt.generateKey();
            let k = await this.symCrypt.key.extract();
            if (!k) return error("No session Key");
            this.elGamalSessionKey = await this.symCrypt.padRSA_OAEP(k, key.elGamal.P);
            this.elGamalGroup = key.elGamal.group;
            */
            return true;

        }

        return false;
    }

    async extract() {
        return this.symCrypt.key.extract();
    }

    async cipher(data, outputFormat) {
        //console.log("SessionKey - cipher - this.symCrypt : ", this.symCrypt);
        //console.log("SessionKey - cipher - this.symCrypt.keyStruct : ", this.symCrypt.keyStruct);
        return this.symCrypt.cipher(data, outputFormat);
    }

    async decipher(encrypted, inputFormat, outputFormat) {
        // debug("decipher ", {
        //     encrypted: encrypted,
        //     inputFormat: inputFormat,
        //     outputFormat: outputFormat
        // });
        let a = await this.symCrypt.decipher(encrypted, inputFormat, outputFormat)
            // debug("decypher a = ", a)
        return a;
    }


    async setKey(elgamalKey, aesKey, group) {
        //debug("Session setKey ", {
        //    elgamalKey: elgamalKey,
        //    aesKey: aesKey,
        //    group: group
        //})
        this.elGamalSessionKey = elgamalKey;
        this.elGamalGroup = group;
        if (aesKey) await this.symCrypt.key.importKey(aesKey);
        return true;
    }

}