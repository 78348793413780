import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { MyComponent } from '../../MyComponent.jsx';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const DURATION = 10000;

const specificStyles = (theme) => ({
    close: {
        padding: theme.spacing.unit / 2,
    },
});


class SnackDisplay extends MyComponent {

	constructor(props) {
		super(props);

		this.setMessage = this.setMessage.bind(this);
		this.closeMessage = this.closeMessage.bind(this);

		this.state = {
			"open": false,
			"messages": [],
		};
	}

	closeMessage() {
		this.setState({ "messages": [], 'open': false });
	}

	/**
	* Set the way to contact me
	*/
	componentDidMount() {
		this.props.global.registerFunction('snackBar', this.setMessage)
	}

	/**
	* Unregister the setMessage function
	*/
	componentWillUnmount() {
		this.props.global.registerFunction('snackBar', undefined)
	}


	/**
	* Add a message to display
	*
	*/
	setMessage(message) {
		let m = this.state.messages;
		m.push(message)
		this.setState({ "messages": m, 'open': true });
	}


	render() {

		let r = [];
		let i = 0;
		for (let m of this.state.messages) {
			r.push(<div id={"id" + i} key={"id" + i}>{m}</div>);
			i++;
		}
		return (
			<Snackbar
				id="snackbar"
				open={this.state.open}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={r}
				autoHideDuration={DURATION}
				onClose={this.closeMessage}
				key="popupContent"
				action={[
					<IconButton
						id="closeSnackBar"
						key="close"
						aria-label="Close"
						color="inherit"
						className={this.props.classes.close}
						onClick={this.closeMessage}
					>
						<CloseIcon />
					</IconButton>,
				]}

			/>

		);


	}
}

SnackDisplay.propTypes = {
	'global': PropTypes.object,
	'classes': PropTypes.object,
};

export default withStyles(specificStyles, { withTheme: true })(SnackDisplay)