import {
    error,
    debug,
    manager
} from './Manager.js';
import {
    SymCrypt
} from '../SymCrypt.js';

export class PassphraseManager {
    constructor() {
        this.symCrypt = undefined;
        this.encryptedPassphrase = undefined;
        this.expire = 0;

        this.clearByExpire = this.clearByExpire.bind(this);
        this.save = this.save.bind(this);
        this.getPassphrase = this.getPassphrase.bind(this);
    }

    async save(passphrase) {
        if (!manager.WITHPASSPHRASEMANAGER) return true;
        this.clear();
        //debug("lybcrypt - PassphraseManager - Save passphrase " + passphrase);
        debug("lybcrypt - PassphraseManager - Save passphrase ");
        if (!passphrase) return true;
        this.symCrypt = new SymCrypt(error, debug);
        await this.symCrypt.generateKey();
        this.encryptedPassphrase = await this.symCrypt.cipher(passphrase, 'base64');
        if (!manager.PASSPHRASE_EXPIRE) {
            this.expire = 0;
            return true;
        }

        this.expire = Date.now() + manager.PASSPHRASE_EXPIRE;
        this.timeout = setTimeout(this.clearByExpire, manager.PASSPHRASE_EXPIRE);
        return true;
    }

    clearByExpire() {
        debug("lybcrypt - PassphraseManager - expiration ");
        return this.clear();
    }

    clear() {
        if (!manager.WITHPASSPHRASEMANAGER) return true;
        if (this.timeout) clearTimeout(this.timeout);
        this.expire = 0;
        debug("lybcrypt - PassphraseManager - clear ");
        this.symCrypt = undefined;
        delete(this.encryptedPassphrase);
    }

    async getPassphrase() {
        if (!manager.WITHPASSPHRASEMANAGER) return false;
        if (!this.symCrypt) return false;
        if (!this.encryptedPassphrase) return false;
        let a = await this.symCrypt.decipher(this.encryptedPassphrase, "base64", "string");
        await this.save(a);
        //debug("lybcrypt - PassphraseManager - Get passphrase " + a);
        debug("lybcrypt - PassphraseManager - Get passphrase ");
        return a;
    }

    getInfos() {
        return ({
            "set": manager.WITHPASSPHRASEMANAGER ? true : false,
            "expire": this.expire
        })
    }

}