const UTF8 = require('utf-8');
const crypto = require('crypto');

export function stringToAb(str) {
    var ab = new ArrayBuffer(str.length);
    var view = new Uint8Array(ab);
    for (var i = 0; i < str.length; ++i) {
        view[i] = str.charCodeAt(i);
    }
    return ab;
}

export function utf8ToArrayBuffer(str) {
    let out = UTF8.setBytesFromString(str);
    let ab = new ArrayBuffer(out.length);
    var bufView = new Uint8Array(ab);
    for (var i = 0; i < out.length; i++) {
        bufView[i] = out[i];
    }

    let b = UTF8.getStringFromBytes(bufView);
    return ab;
}

export function abToString(ab) {
    let b = new Uint8Array(ab);
    let a = [];
    for (var i = 0; i < b.length; i++) {
        a.push(String.fromCharCode(b[i]));
    }
    return a.join('');
}


export function concatUint8(a, b) {
    let c = new Uint8Array(a.byteLength + b.byteLength);
    for (let i = 0; i < a.byteLength; i++) c[i] = a[i];
    for (let i = 0; i < b.byteLength; i++) c[i + a.byteLength] = b[i];
    return c;
}

export function xorUint8(a, b) {
    if (a.byteLength != b.byteLength) throw new Error("xorUint8 not the same size " + a.byteLength + "!=" + b.byteLength)
    let c = new Uint8Array(a.byteLength);
    for (let i = 0; i < a.byteLength; i++) c[i] = a[i] ^ b[i];
    return c;
}


export function toHexString(b) {

    let a = [];
    for (var i = 0; i < b.length; ++i) {
        if (b[i] > 15) {
            a.push((b[i] & 0xFF).toString(16));
        } else {
            a.push('0' + (b[i] & 0xFF).toString(16));
        }
    }
    return a.join('');
}

export function randomId() {
    let hexChar = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f"];
    let date = Math.floor(Date.now() / 1000)
    let s = "";
    for (let i = 3; i >= 0; i--) {
        let by = (date >> (8 * i)) & 0xff;
        s += hexChar[(by >> 4) & 0x0f] + hexChar[by & 0x0f];
    }
    for (let i = 0; i < 8; i++) {
        let c = Math.floor(Math.random() * 255);
        let b = c.toString(16);
        if (b.length == 1) b = '0' + b;
        s += b;
    }
    return (s);
}



export class CryptoObject {
    constructor(errorFunc, debugfunc) {
        this.canUseWindowCrypto = false;
        //  if (( typeof window !== 'undefined' ) && (( window.crypto ) || ( window.msCrypto ))) {
        //  Not use msCrypto yet
        if ((typeof window !== 'undefined') && (window.crypto)) {
            this.canUseWindowCrypto = true;
            this.wCrypto = window.crypto || window.msCrypto;
            // ---- For Safari ----
            this.subtle = this.wCrypto.webkitSubtle ? this.wCrypto.webkitSubtle : this.wCrypto.subtle;
        }
        this.windowCrypto = this.canUseWindowCrypto ? true : false;

        // --- Set the Error function 
        this.errorFunc = errorFunc ? errorFunc : (message, obj) => {
            console.error(message, obj);
            return false;
        }

        // set the debug function 
        this.debugFunc = debugfunc ? debugfunc : (message, obj) => {
            return console.log("lybcrypt - DEBUG : " + message, obj);
        };
    }

    async nodePkpbf2(password, salt, iteration, keylen, hash) {
        if (!password) return this.err("error nodePkpbf2 password not set");
        if (!salt) return this.err("error nodePkpbf2 salt not set");
        if (!iteration) return this.err("error nodePkpbf2 iteration not set");
        if (!keylen) return this.err("error nodePkpbf2 keylen not set");
        if (!hash) return this.err("error nodePkpbf2 hash not set");

        return new Promise((resolve, reject) => {
            crypto.pbkdf2(password, salt, iteration, keylen, hash, (err, res) => {
                if (err) {
                    this.err("error node Pkpbf2");
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    };

    setDebugFunction(func) {
        this.debugFunc = func;
    }
    setErrorFunction(func) {
        this.errorFunc = func;
    }

    debug(message, obj) {
        if (this.debugFunc) return this.debugFunc(message, obj);
    }

    err(message, obj) {
        if (this.errorFunc) return this.errorFunc(message, obj);
    }

    set useWindowCrypto(b) {
        if (this.canUseWindowCrypto) this.windowCrypto = b ? true : false;
    }




}