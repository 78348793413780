import {
    appendStack
} from './LynError.js';
const cloneDeep = require('lodash.clonedeep');
var retrace = require('retrace');

var debugClasses = {};
export var debugString = undefined;


var helpDebugFunction = () => {
    console.log("Debug help :");
    console.log("\t?debug=foe\t\tDebug class foe");
    console.log("\t?debug=foe,bar\t\tDebug class foe & class bar");
    console.log("");
    console.log("\tAvailable classes :");
    console.log("\t" + Object.keys(debugClasses).join(', '));
    console.log("");
}

let classToDebug = [];


/**
 * return the list of classes under debug
 */
export var currentDebugClasses = () => {
    return classToDebug;
}

export var debugChanged = (s) => {
    let slist = s.split(/\s*,\s*/);
    if (slist.length !== classToDebug.length) return true;
    for (let si of slist) {
        if (classToDebug.indexOf(si) == -1) return true;
    }
    return false;
}

/**
 * Set the className to debug
 * @param {*} dString 
 */
export var setDebug = (dString) => {

    // --- clean all debug ---
    classToDebug = [];

    // ----------- stop the debug --------------
    if (!dString) return;

    // ----------- stop the debug --------------

    debugString = dString.toLowerCase();
    if (debugString == 'help') {
        return helpDebugFunction();
    }


    classToDebug = debugString.split(/\s*,\s*/);
    console.log(`D(Main): Init debug for `, classToDebug)
}


export default class DebugObject {
    constructor(...classNames) {
        this.classNames = classNames;
        this.className = this.classNames[0];

        this.debug = this.checkIfDebug(classNames);

        this.log = this.log.bind(this);
        this.l = this.l.bind(this);
        this._false = this._false.bind(this);
        this._true = this._true.bind(this);
        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        //console.log("DebugObj.js - classNames : ", classNames[0]);
        /* if (this.debug) {
            console.log("DebugObj.js - this.debug : ", this.debug);
        } */
    }

    checkIfDebug(names) {
        for (let n of names) {
            if (classToDebug.indexOf(n.toLowerCase()) > -1) return true;
        }
        return false;
    }


    setNames(...names) {
        this.className = names[0];

        for (let n of names) {
            if (this.classNames.indexOf(names) == -1) {
                this.classNames.push(n);
            }
        }
        //this.classNames.map((x) => console.log("DebugObj.js - this.classNames : " + x));

        this.debug = this.checkIfDebug(this.classNames);
    }

    _false(message, data) {
        this.log(`return false("${message}")`, data);
        return false;
    }
    _true(message, data) {
        this.log(`return true("${message}")`, data);
        return true;
    }

    start(message = "") {
        this.debug = true;
        this.currentDisplay = this.currentDisplay ? this.currentDisplay : console.log;
        this.display = console.log
        return this.display("Start debug with start() -" + message)
    }

    stop(message = "") {
        this.display("Stop debug with stop() - " + message)
        this.display = this.currentDisplay;
        this.debug = false;
    }

    /**
     * Return stack as an Array
     *
     * @param {string} stack
     */
    getStackList(stack) {
        if (!stack) return undefined;
        return stack.split(/\r?\n/);
    }

    getFileName(stackList) {
        if (!stackList) return undefined;
        if (stackList.length < 4) return undefined;
        let f = stackList[3].replace(/.*\//, '');
        f = f.replace(/:[0-9]*$/, '');
        return f;
    }

    display(message, data) {
        let d = (typeof data === 'undefined') ? undefined : cloneDeep(data);
        let e = new Error();

        retrace.map(e.stack)
            .then((m) => {
                let f = this.getFileName(this.getStackList(m));
                console.log(`D(${this.className})(${f}): ${message}`, d);
            })
            .catch(() => {
                let f = this.getFileName(this.getStackList(e.stack));
                console.log(`DD(${this.className})(${f}): ${message}`, d);
            })
    }


    l(message, data) {
        appendStack('log', message, data);
        return this.display(message, data)
    }

    log(message, data) {
        appendStack('log', message, data);

        if (!this.debug) return false;
        return this.display(message, data)
    }

    /* start() {
        this.debug = true;
        this.currentDisplay = this.currentDisplay ? this.currentDisplay : console.log;
        this.display = console.log
        return this.display("Start debug with start()")
    }
    stop() {
        this.display("Stop debug with stop()")
        this.display = this.currentDisplay;
        this.debug = false;
    } */
}