import {
    CryptoObject,
    stringToAb,
    utf8ToArrayBuffer,
    abToString,
    toHexString,
    concatUint8,
    xorUint8,
} from './Common.js';

import {
    Hash
} from './Hash.js';

const crypto = require('crypto');
var BigInteger = require('node-jsbn');
const UTF8 = require('utf-8');

export const PBKDF2_ITERATION = 10000;
export const PBKDF2_HASH = 'sha256';

export const BLOCK_SIZE = 16;


function toArrayBuffer(buffer) {
    var ab = new ArrayBuffer(buffer.length);
    var view = new Uint8Array(ab);
    for (var i = 0; i < buffer.length; ++i) {
        view[i] = buffer[i];
    }
    return ab;
}



function toBuffer8(ab) {
    //return new Buffer(new Uint8Array(ab));
    return Buffer.from(new Uint8Array(ab));
}

/**
 *******************************************************************************
 **** Symetric Key structure ***************************************************
 *******************************************************************************
 */
export class SymetricKey extends CryptoObject {
    constructor(errorFunc, debugFunc, byteLength = 32) {
        super(errorFunc, debugFunc);
        this.byteLength = byteLength;
        this.keyStruct = undefined;
    }

    get bitLength() {
        return this.byteLength * 8
    }

    async wrapKeyFromPassphrase(passphrase, length = undefined, b64Salt = undefined, iteration = PBKDF2_ITERATION, hash = PBKDF2_HASH) {
        //let salt = b64Salt ? new Buffer(b64Salt, 'base64') : crypto.randomBytes(BLOCK_SIZE);
        let salt = b64Salt ? Buffer.from(b64Salt, 'base64') : crypto.randomBytes(BLOCK_SIZE);
        let rawKey = await this.nodePkpbf2(passphrase, salt, iteration, length ? length : this.byteLength, hash);
        if (!rawKey) {
            this.err("lybcrypt - SymCrypt.js - error in wrapKeyFromPassphrase : this.nodePkpbf2 return empty value.");
            return false;
        }

        this.keyStruct = {
            "key": rawKey, //  Uint8Array format
            "salt": salt.toString('base64'),
            "iteration": iteration,
            "hash": hash,
            "keylen": length ? length : this.byteLength,
            "windowCryptoKey": undefined,
        };
        if (this.windowCrypto) {
            await this.importInWindowCrypto(true, ["encrypt", "decrypt"])
        }
        return true;
    }

    async wrapElGamalSessionKey(elGamalSessionKey, length = this.byteLength, b64Salt = undefined, iteration = 1, hash = PBKDF2_HASH) {

        let el = (typeof elGamalSessionKey !== "string") ? elGamalSessionKey.toString() : elGamalSessionKey;
        return this.wrapKeyFromPassphrase(el, length, b64Salt, iteration, hash);
    }


    get content() {
        return this.keyStruct
    }

    get params() {
        return ({
            "salt": this.keyStruct.salt,
            "iteration": this.keyStruct.iteration,
            "hash": this.keyStruct.hash,
            "keylen": this.keyStruct.keylen,
        });
    }

    get raw() {
        if (!this.keyStruct) return undefined;
        if (this.keyStruct.key) return this.keyStruct.key.buffer;
        return undefined;
    }

    get rawAsBuffer() {
        if (!this.keyStruct) return undefined;
        if (this.keyStruct.key) return toBuffer8(this.keyStruct.key);
        return undefined;

    }

    /**
     * return a promise with return the key as an ArrayBuffer
     * @return {[ArrayBuffer]} [the secret key]
     */
    extract() {
        if (!this.keyStruct) return this.err("Key not set");
        if (this.keyStruct.key) {
            return new Promise((resolve, reject) => {
                resolve(this.keyStruct.key)
            });
        }

        if ((this.keyStruct.windowCryptoKey) && (this.keyStruct.windowCryptoKey.extractable)) {
            return this.exportWindowCrypto()
        }
    }

    async importKey(rawKey) {
        this.keyStruct = {
            "key": rawKey, //  Uint8Array format
        }
        if (this.windowCrypto) {
            await this.importInWindowCrypto(true, ["encrypt", "decrypt"])
        }
        return true;
    }


    get cryptoKey() {
        if (!this.keyStruct) return this.err("lybcrypt - SymCrypt.js - cryptoKey - Key not set");
        if (!this.windowCrypto) return this.err("lybcrypt - SymCrypt.js - cryptoKey - this.wCrypto not supported");
        return this.keyStruct.windowCryptoKey;
    }

    async importInWindowCrypto(extractable = true, usages = ["encrypt", "decrypt"]) {
        //console.log("SymCrypt - importInWindowCrypto");
        if (!this.keyStruct) return this.err("lybcrypt - SymCrypt.js - impportInWindowCrypto - Key not set");
        if (!this.windowCrypto) this.err("lybcrypt - SymCrypt.js - impportInWindowCrypto - this.wCrypto not supported");

        let key = await this.subtle.importKey(
            "raw", //can be "jwk" or "raw"
            this.keyStruct.key, { //this is the algorithm options
                name: "AES-CBC",
            },
            extractable, //whether the key is extractable (i.e. can be used in exportKey)
            usages
        );
        this.keyStruct.windowCryptoKey = key;
        return true;
    }

    exportWindowCrypto() {
        if (!this.windowCrypto) this.err("lybcrypt - SymCrypt.js - exportWindowCrypto - this.wCrypto not supported");

        return this.subtle.exportKey(
            "raw", //can be "jwk" or "raw"
            this.cryptoKey //extractable must be true
        )
    }

    generate() {
        // --------- Use this.wCrypto -------
        //console.log("SymCrypt.js - generate");
        if (this.windowCrypto) {
            return this.subtle.generateKey({
                        name: "AES-CBC",
                        length: this.bitLength, //can be  128, 192, or 256
                    },
                    true, //whether the key is extractable (i.e. can be used in exportKey)
                    ["encrypt", "decrypt"] //can be "encrypt", "decrypt", "wrapKey", or "unwrapKey"
                )
                .then((key) => {
                    this.keyStruct = {
                        windowCryptoKey: key // CryptoKey object
                    }
                    return true;
                })
        }

        // --------- Node js AES Key generation -
        return new Promise((resolve, reject) => {
            this.keyStruct = {
                key: toArrayBuffer(crypto.randomBytes(this.byteLength))
            }
            resolve(true);
        });
    }
}

/**
 *******************************************************************************
 **** Symetric encryption ******************************************************
 *******************************************************************************
 */
export class SymCrypt extends CryptoObject {
    constructor(errorFunc, debugFunc, type) {
        super(errorFunc, debugFunc);
        this.id = Date.now();
        this.generateconstants(type);
        this.iv = null;
        this.key = new SymetricKey(errorFunc, debugFunc, this.keylen);
    }

    generateconstants(algo) {
        switch (algo) {
            case 'AES-128-CBC':
                this.algoString = 'AES-128-CBC';
                this.algo = 'aes128';
                this.length = 128;
                this.keylen = 16;
                break;
            case 'AES-256-CBC':
            default:
                this.algoString = 'AES-256-CBC';
                this.algo = 'aes256';
                this.keylen = 32;
                this.length = 256;
        }
    }

    set useWindowCrypto(b) {
        this.key.useWindowCrypto = b;
        if (this.canUseWindowCrypto) this.windowCrypto = b ? true : false;
    }

    get initialVector() {
        if (this.iv == null) return null;
        return this.iv.toString('base64');
    }

    generateKey() {
        return this.key.generate()
    }

    /**
     * AES encryption
     * @param  {[ArrayBuffer|string]} d           [the arrayBuffer or the String to cipher]
     * @param  {[String]} [outputFormat=undefined] [the output format]
     * @param  {[ArrayBuffer]} [rawKey=undefined] [The key ... in raw ]
     * @return {[variable]}                    [description]
     */
    cipher(d, outputFormat = undefined, rawKey = undefined) {

        let dd = (typeof d === "string") ? utf8ToArrayBuffer(d) : d;

        let p = new Promise((resolve, reject) => {
            resolve(true)
        });
        if (rawKey) p = this.key.importKey(rawKey);

        // --------- Use this.wCrypto -------
        if (this.windowCrypto) {
            let dts = Date.now();
            this.iv = this.wCrypto.getRandomValues(new Uint8Array(BLOCK_SIZE));
            //console.log("SymCrypt - cipher - this.key.cryptoKey : ", this.key.cryptoKey);
            //console.log("SymCrypt - cipher - this.iv : ", this.iv);

            return this.subtle.encrypt({
                        name: "AES-CBC",
                        iv: this.iv,
                    },
                    this.key.cryptoKey, //from generateKey or importKey above
                    dd //ArrayBuffer of data you want to encrypt
                )
                .then((encrypted) => {
                    //console.log("win crypt uib output =", new Uint8Array(encrypted));
                    let a = this.formatOutput(encrypted, outputFormat);
                    return a;
                })
        }

        // --------- Use node crypto ---------
        return p.then(() => {
            this.iv = crypto.randomBytes(BLOCK_SIZE);

            //console.log("node crypt uib key =", this.key.rawAsBuffer);
            //console.log("node crypt uib iv =", this.iv);
            let cipher = crypto.createCipheriv(this.algo, this.key.rawAsBuffer, this.iv);
            let base64Iv = this.iv.toString('base64');
            //  console.log("try to cipher - ", toBuffer8(dd) );
            let a = cipher.update(toBuffer8(dd));
            //  let a = cipher.update(new Uint8Array(dd));
            let b = cipher.final();

            let encrypted = toArrayBuffer(Buffer.concat([a, b]));

            //console.log("node crypt uib output =", new Uint8Array(encrypted));
            //      let encrypted =  Buffer.concat([a, b]);
            // console.log("cipher result without format - ", new Uint8Array(encrypted) );
            let c = this.formatOutput(new Uint8Array(encrypted), outputFormat);
            // console.log("cipher result format - ",c );
            return c;
        });

    }

    formatOutput(data, outputFormat = undefined) {
        //let base64Iv = typeof (btoa) === 'undefined' ?  Buffer.from(this.iv).toString('base64') : btoa(String.fromCharCode(...this.iv));
        let base64Iv = Buffer.from(this.iv).toString('base64');

        switch (outputFormat) {
            case "raw":
                return {
                    "rawData": new Uint8Array(data),
                    "iv": new Uint8Array(this.iv),
                    "algo": this.algoString
                }
            case "struct":
                return {
                    "rawData": abToString(data),
                    "iv": base64Iv,
                    "algo": this.algoString
                }
            case "hex":
                return ('{' + this.algoString + '}{' + base64Iv + '}' + toHexString(new Uint8Array(data)));
            case "base64":
                let base64String = Buffer.from(data).toString('base64');
                return ('{' + this.algoString + '}{' + base64Iv + '}' + base64String);
            default:
                return data;
        }
    }

    decipher(encrypted, inputFormat, ouputFormat, rawKey = undefined) {
        let res;

        let algo = undefined;
        let iv = undefined;
        let crypted = undefined;


        switch (inputFormat) {
            case "raw":
                algo = encrypted.algo;
                //iv = new Buffer(encrypted.iv);
                iv = Buffer.from(encrypted.iv);
                crypted = encrypted.rawData;
                break;
            case "struct":
                algo = encrypted.algo;
                //iv = new Buffer(encrypted.iv, 'base64');
                iv = Buffer.from(encrypted.iv, 'base64');
                //crypted = encrypted.rawData ? stringToAb(encrypted.rawData) : new Buffer(encrypted.data, 'hex');
                crypted = encrypted.rawData ? stringToAb(encrypted.rawData) : Buffer.from(encrypted.data, 'hex');
                break;
            case "hex":
                if (!(res = encrypted.match(/^\{([^\}]+)\}\{([^\}]+)\}(.*)/))) {
                    console.error("lybcrypt - SymCrypt.js - decipher - Invalid crypted string");
                    return (this.err("lybcrypt - SymCrypt.js - decipher - Invalid crypted string"));
                }
                algo = res[1];
                //iv = new Buffer(res[2], 'base64');
                iv = Buffer.from(res[2], 'base64');
                //crypted = new Buffer(res[3], 'hex');
                crypted = Buffer.from(res[3], 'hex');
                break;
            case "base64":
            default:
                if (!(res = encrypted.match(/^\{([^\}]+)\}\{([^\}]+)\}(.*)/))) {
                    console.error("lybcrypt - SymCrypt.js - decipher - Invalid crypted string");
                    return (this.err("lybcrypt - SymCrypt.js - decipher - Invalid crypted string"));
                }
                algo = res[1];
                //iv = new Buffer(res[2], 'base64');
                iv = Buffer.from(res[2], 'base64');
                //crypted = new Buffer(res[3], 'base64');
                crypted = Buffer.from(res[3], 'base64');
                break;
        }

        this.generateconstants(algo);


        return this.decipherArrayBuffer(crypted, iv, rawKey)
            .then((clearBuffer) => {
                let uib = new Uint8Array(clearBuffer);
                if (ouputFormat == "string") {
                    //console.log("decrypt uib=", uib);
                    try {
                        //console.log("lybcrypt - SymCrypt.js - decipherArrayBuffer - 50 first elts of uib", uib.slice(0, 50));
                        let long_st = "";
                        uib.slice(0, 50).map((elt) => { long_st += elt.toString(16) + " " });
                        //console.log(long_st);
                        return UTF8.getStringFromBytes(uib);
                    } catch (error) {
                        //console.error("lybcrypt - SymCrypt.js - decipherArrayBuffer - 50 first elts of uib", uib.slice(0, 50));
                        //console.error("lybcrypt - SymCrypt.js - decipherArrayBuffer - UTF8 getStringFromBytes error", error);
                        return "";
                    }
                } else {
                    return uib;
                }
            })
    }


    /**
     * decrypt an Arraybuffer
     * @param  {[Arraybuffer]} encrypted          [the encrypted data]
     * @param  {[Arraybuffer]} iv                 [initialisation vector]
     * @param  {[Arraybuffer]} [rawKey=undefined] [the rawKey]
     * @return {[Arraybuffer]}                    [datas]
     */
    async decipherArrayBuffer(encrypted, iv, rawKey = undefined) {

        if (rawKey) await this.key.importKey(rawKey);

        // --------- Use this.wCrypto -------
        if (this.windowCrypto) {
            //console.log("decrypt with webcrypto", {
            //  encrypted: encrypted,
            //  iv: iv,
            //  cryptoKey: this.key.cryptoKey
            //})
            return this.subtle.decrypt({
                    name: "AES-CBC",
                    iv: iv, //The initialization vector you used to encrypt
                },
                this.key.cryptoKey, //from generateKey or importKey above
                encrypted //ArrayBuffer of the data
            );
        }

        let deci = crypto.createDecipheriv(this.algo, this.key.rawAsBuffer, iv);
        let enc = (encrypted instanceof ArrayBuffer) ? new Uint8Array(encrypted) : encrypted;
        //console.log("node decrypt uib key =", this.key.rawAsBuffer);
        //console.log("node decrypt uib iv =", iv);

        //let r = deci.update(new Buffer(enc));
        let r = deci.update(Buffer.from(enc));
        let f = deci.final();
        let b = f.length == 0 ? r : Buffer.concat([r, f]);
        // console.log("decyphred ", b);
        return b;

    }



    /**
     * Transform the symetric session key to elgmal Key
     * 
     * @param {Uint8Array} mm 
     */
    async padRSA_OAEP(mm, P, label = "") {

        let m = undefined;
        if (mm instanceof ArrayBuffer) m = new Uint8Array(mm);
        if (mm instanceof Uint8Array) m = mm;
        if (!m) return this.err('lybcrypt - SymCrypt.js - padRSA_OAEP - Unknown type for RSA_OAEP. Need Uint8Array or ArrayBuffer');

        if (!P) return this.err('lybcrypt - SymCrypt.js - padRSA_OAEP - Need P argument');
        let byteLength = Math.floor(P.bitLength() / 8);

        let hash = new Hash(this.err, this.debug, 'sha1');
        let hLen = hash.length;
        let k = byteLength;
        let mLen = m.length;
        let lHash = undefined;


        if (mLen > k - 2 * hLen - 2) return this.err('lybcrypt - SymCrypt.js - padRSA_OAEP - Message too long');

        let hh = await hash.hash(label, 'raw');
        lHash = new Uint8Array(hh);


        let ps = new Uint8Array(k - mLen - 2 * hLen - 2);
        let db = concatUint8(lHash, ps);
        let zeroOne = new Uint8Array(1);
        zeroOne[0] = 0x01;

        db = concatUint8(db, zeroOne);
        db = concatUint8(db, m);

        let seed = undefined;
        if (this.windowCrypto) {
            seed = this.wCrypto.getRandomValues(new Uint8Array(hLen));
        } else {
            seed = new Uint8Array(crypto.randomBytes(hLen));
        }
        seed = new Uint8Array([1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0]);


        let dbMask = await this.MGF(seed, k - hLen - 1)

        //console.log("dbMask = ", dbMask);


        let maskedDB = xorUint8(db, dbMask);

        let seedMask = await this.MGF(maskedDB, hLen);
        let maskedSeed = xorUint8(seed, seedMask);
        let init = new Uint8Array(1);
        let em = concatUint8(init, maskedSeed);

        em = concatUint8(em, maskedDB);


        let bigi = new BigInteger(em);


        if (bigi.compareTo(P) >= 0) return this.err("lybcrypt - SymCrypt.js - padRSA_OAEP - integer too large");
        return bigi;

    }

    async unpadRSA_OAEP(bigi, P, label = "") {
        let hash = new Hash(this.err, this.debug, 'sha1');
        let hLen = hash.length;

        if (!P) return this.err('Need P argument');
        let byteLength = Math.floor(P.bitLength() / 8);
        let k = byteLength;

        let em = new Uint8Array(bigi.toBuffer());
        while (em.byteLength < k) em = concatUint8(new Uint8Array(1), em);

        let init = new Uint8Array(em.buffer.slice(0, 1));
        let maskedSeed = new Uint8Array(em.buffer.slice(1, hLen + 1));
        let maskedDB = new Uint8Array(em.buffer.slice(hLen + 1, k));


        let seedMask = await this.MGF(maskedDB, hLen);
        let seed = xorUint8(maskedSeed, seedMask);

        let dbMask = await this.MGF(seed, k - hLen - 1)
        let db = xorUint8(maskedDB, dbMask);

        let hPrime = new Uint8Array(db.buffer.slice(0, hLen));
        let ps = new Uint8Array(db.buffer.slice(hLen));

        let i = 0;
        while ((ps[i] == 0) && (i < ps.byteLength)) i++;
        if (i == ps.byteLength) return this.err("lybcrypt - SymCrypt.js - unpadRSA_OAEP - Decryption error");
        if (ps[i] != 1) return this.err("lybcrypt - SymCrypt.js - unpadRSA_OAEP - unpadRSA_OAEP!=1 - Decryption error");
        return new Uint8Array(ps.buffer.slice(i + 1, ps.byteLength));
    }


    async MGF(seed, l) {
        let t = new Uint8Array(0);
        let hash = new Hash(this.err, this.debug, 'sha1');
        let hLen = hash.length;

        let cMax = Math.ceil(l / hLen);
        for (let c = 0; c < cMax; c++) {
            let C = this.I2OSP(c, 4);
            let hSeed = await hash.hash(concatUint8(seed, C), 'raw');
            let ss = new Uint8Array(hSeed);

            t = concatUint8(t, ss);
        }
        // console.log("MGF ", t.slice(0,l));
        return t.slice(0, l);
    }

    I2OSP(n, l = 4) {
        let t = new Uint8Array(l);

        for (let i = 0; i < l; i++) {
            t[l - i - 1] = n & 0xFF;
            n = n >> 8;
        }
        return t;
    }
}