/* Writen by script */

export const inter = {
    "fr": {
        "App config": "App config",
        "Status": "Statut",
        "Reply": "Répondre",
        "Home": "Accueil",
        "New url": "Nouvelle invitation",
        "URLs": "Invitations",
        "Administration": "Administration",
        "Quorums": "Quorums",
        "Size": "Taille",
        "Vaults": "Coffres",
        "Help": "Aide",
        "Enter": "Entrer",
        "Decrypt": "Déchiffrer",
        "Delete": "Supprimer",
        "Logout": "Déconnexion",
        "Refuse": "Refuser",
        "Close": "Fermer",
        "Accept": "Accepter",
        "Edit": "Modifier",
        "Send a customized notification email": "Envoyer une notification personalisée par email",
        "Name": "Nom",
        "Expire": "Expire le",
        "Properties": "Propriétés",
        "Change password": "Mots de passe",
        "Last Update": "Dernière modification",
        "Expiration date": "Date d'expiration",
        "Creation date": "Date de création",
        "Users": "Utilisateurs",
        "User login": "Login utilisateur",
        "Rename": "Renommer",
        "Yes": "Oui",
        "No": "Non",
        "Cancel": "Abandonner",
        "Loading": "Chargement",
        "Dataroom crypted": "Coffre chiffré",
        "Owner": "Propriétaire",
        "Comments": "Commentaires",
        "General": "Général",
        "Date": "Date",
        "Crypted": "Chiffré",
        "Logs": "Logs",
        "Crypted thread": "Conversation chiffrée",
        "Act as": "Agir comme",
        "Next": "Suivant",
        "Share": "Partager",
        "Shares": "Partages",
        "Subject": "Sujet",
        "Copy to me": "Me mettre en copie",
        "Prev": "Précédent",
        "Message": "Message",
        "Invite": "Inviter",
        "Themes": "Thèmes",
        "Tchat": "Discussions",
        "There is currently no selected thread": "Aucune discussion sélectionnée",
        "Chose someone in the list below": "Choisissez quelqu'un dans la liste ci-dessous",
        "Select a friend": "Sélectionnez un correspondant",
        "No member(s)": "La sélection est vide",
        "Select a user": "Sélectionnez un utilisateur",
        "Delete my account": "Supprimer mon compte",
        "New password": "Nouveau mot de passe",
        "Change": "Changer",
        "For": "Pour",
        "Create": "Créer",
        "Encrypt and send": "Chiffrer et envoyer",
        "Refuse access": "Refuser l'accès",
        "Accept access": "Donner l'accès",
        "Ask for access": "Demander un accès",
        "Cancel request": "Abandonner la demande",
        "Password": "Mot de passe",
        "You need to change your password": "Il est temps de changer votre mot de passe",
        "User created": "Utilisateur créé",
        "New user": "Nouvel utilisateur",
        "Username": "Identifiant",
        "No account yet ?": "Pas encore de compte ?",
        "Login": "Se connecter",
        "Login LDAP": "Se connecter  (LDAP)",
        "Choose a password": "Choisissez un mot de passe",
        "Email": "Adresse mail",
        "OR": "OU",
        "CREATE AN ACCOUNT": "CRÉATION D'UN COMPTE",
        "Create an account": "Création d'un compte",
        "Back to login": "Retour au formulaire de connexion",
        "Back to home": "Retour à l'accueil",
        "LOGIN TO YOUR ACCOUNT": "CONNEXION A VOTRE COMPTE",
        "I read and I accept terms and conditions": "J'ai lu et j'accepte les conditions générales d'utilisation",
        "A email has been sent": "Un mail a été envoyé à ",
        "Please check your email and click on the verification link.": "Surveillez vos courriers et cliquez sur le lien de vérification. Si vous n'avez pas reçu cet email, veuillez dans un premier temps, consulter votre dossier Courrier indésirable.",
        "Congratulation": "Bravo",
        "Error": "Erreur",
        "Your email cannot be validate !": "Votre mail ne peut pas être vérifié !",
        "Never": "Jamais",
        "Anonymous files transfered achieved": "Transfert anonyme vers \"{name}\" terminé",
        "Ready": "Prêt(e)",
        "Sharing": "Partage",
        "Secreting": "Secrets",
        "Quorum group created": "Groupe à quorum créé",
        "Description": "Description",
        "Send files to": "Envoyer à \"{name}\"",
        "Files": "Fichiers",
        "Object": "Objet",
        "Save": "Enregistrer",
        "Are you sure to destroy your account ?": "Êtes-vous sûr de vouloir supprimer votre compte ?",
        "Unknown": "Inconnu",
        "Sorry, unable to display this file": "Désolé, pas de moyen d'afficher ce fichier",
        "Download": "Télécharger",
        "Add users": "Gérer les utilisateurs",
        "Confirmation": "Confirmation",
        "Link": "Lien",
        "Are you sure to delete this thread ?": "Êtes-vous sûr de vouloir supprimer cette discussion ?",
        "Fileset created": "Coffre créé",
        "New folder": "Nouveau répertoire de coffres",
        "Folder created": "Répertoire de coffres \"{name}\" créé",
        "Share(s) added": "Partage(s) ajouté(s)",
        "Change passphrase": "Changer la phrase secrète",
        "Forgot my passphrase": "J'ai oublié ma phrase secrète",
        "Current password": "Mot de passe actuel",
        "Set": "Initialiser",
        "The list is empty": "La liste est vide",
        "Create a new quorum group": "Créer un nouveau groupe à quorum",
        "Auth with Google": "Connexion avec Google",
        "Enter a name": "Entrer un nom",
        "I forget my password": "J'ai oublié mon mot de passe",
        "Password reinitialization": "Réinitialisation du mot de passe",
        "A email has been sent. Please check your email and click on the link to choose a new password": "Un mail a été envoyé à \"{email}\". Veuillez consulter votre boite aux lettre et cliquer sur le lien pour choisir un nouveau mot de passe.",
        "Password reinitialized": "Mot de passe réinitialisé",
        "reinitConfirm": "Attention, en effectuant un changement de mot de passe, tous les coffres que vous n'avez pas partagés seront inaccessibles ",
        "Emails": "Mails",
        "Your email is validated": "Votre mail a été vérifié avec succès.",
        "Valid": "Valider",
        "In progress": "En cours",
        "actions required": "Action(s) requise(s)",
        "Email deleted": "Mail {email} supprimé",
        "Are you sure to drop email ?": "Êtes-vous sûr de vouloir supprimer \"{email}\" ?",
        "An email has been sent": "Un email a été envoyé à \"{email}\"",
        "LoginName": "Utilisateur",
        "Groups": "Groupes",
        "New group": "Nouveau groupe",
        "Group created": "Groupe créé",
        "Devices": "Appareils",
        "Display Name": "Nom affiché",
        "DisplayName changed": "Nom affiché changé pour \"{displayName}\"",
        "Avatar changed": "Avatar modifié",
        "Avatar": "Avatar",
        "Your access is under validation": "Votre identité et votre autorisation d'accès à ce coffre doivent être validées par",
        "Dataroom not decypherable": "Coffre non déchiffrable",
        "Write here a new message": "Écrivez un nouveau message ci-dessous :",
        "Edit the current message": "Changer le message suivant :",
        "crypted message": "Message chiffré",
        "View list": "Vue liste",
        "View tiles": "Vue icônes",
        "You need more rights to see this content": "Vous n'avez pas les permissions pour voir ce contenu",
        "Show more elements": "Afficher plus d'élements",
        "New file": "Nouveau fichier",
        "Number of use": "Nombre d'utilisations",
        "Search": "Rechercher",
        "No result found": "Aucun résultat trouvé",
        "My emails": "Mes emails",
        "My logs": "Mes logs",
        "My password": "Mon mot de passe",
        "My account": "Mon compte",
        "Device without name, you may had a new one": "Appareil sans nom, vous devriez en ajouter un",
        "Keys": "Clefs",
        "Signed keys": "Clefs signées",
        "Signed since": "Signé(es) depuis",
        "The keys you signed": "Les clefs que vous avez signées",
        "Private key in memory up to": "La clef privée est en mémoire jusqu'a",
        "Finger print": "Empreinte numérique",
        "Create a new Quorum with these devices": "Creer un nouveau quorum avec ces appareils",
        "You are on this device": "Vous êtes sur cet appareil",
        "Unknown Key Or Removed Key": "Clef inconnue ou supprimée",
        "Unknown user": "Utilisateur inconnu",
        "Signature not found": "Signature non trouvée",
        "Creation date not found": "Date de création non trouvée",
        "Show debug functions": "Montrer les fonctions de debug",
        "Use care when changing the following settings": "Faites attention en modifiant les paramètres suivants, vous pourriez rendre votre compte inutilisable, contactez le support si vous avez des questions",
        "Advanced search on users": "Recherche avancée sur les utilisateurs",
        "No item(s) selected yet": "Pas d'élément(s) selectionné(s)",
        "Vaults shared with": "Coffres partagés avec",
        "Friends": "Relations",
        "New friend": "Nouvelle relation",
        "Trusted connection": "Relation confirmée",
        "Sign friend": "Confirmer une relation",
        "Add friend": "Ajouter une relation",
        "Continue": "Continuer",
        "Signed By": "Confirmé par",
        "Create a new url": "Créer une nouvelle invitation",
        "Link name": "Nom du lien",
        "Must be filled": "Doit être complété",
        "Recipient email": "Email du destinataire",
        "must be an email": "Doit être un email",
        "must be a number": "Doit être un nombre",
        "No notifications": "Aucune notification",
        "No share user selected": "Aucun utilisateur sélectionné avec lequel partager",
        "Rename folder": "Renommer le répertoire de coffre",
        "Fileset renamed": "Dépôt renommé",
        "by ": "Par ",
        "fileset closed": "Coffre fermé",
        "fileset open": "Coffre ouvert",
        "Move": "Déplacer",
        "Type": "Type",
        "Role": "Rôle",
        "Login name": "Nom de connexion",
        "Quorum groups": "Groupes à quorum",
        "Advanced search on quorums": "Recherche avancée sur les quorums",
        "Quorum Group name": "Nom du groupe à quorum",
        "Threshold": "Seuil",
        "Members": "Membres",
        "Send test mail": "Envoyer un email de test",
        "Add a new mail": "Ajouter un email",
        "Clear": "Clear",
        "Move to": "Déplacer \"{elName}\" vers",
        "Advanced search on files": "Recherche avancée sur les fichiers",
        "No group selected": "Aucun groupe sélectionné",
        "Select a group": "Sélectionnez un groupe",
        "New fileset": "Nouveau coffre",
        "Number of available deposits": "Nombre de coffre(s) disponible(s)",
        "New share": "Nouveau partage",
        "Url created & Link sent": "Invitation et email envoyés",
        "browser error": "Votre navigateur n'est pas à jour ou n'est pas compatible avec l'application.",
        "Please use another browser": "Nous vous invitons à installer la dernière version de Firefox / Chrome / Edge(chromium)",
        "Fake": "Action simulée",
        "Your account is created and ready to use": "Votre compte est créé et prêt",
        "Some notifications": "Des notifications",
        "Adding share(s)": "Partage en cours",
        "New device": "Nouvel appareil",
        "Device created": "Appareil créé",
        "Enter the code": "Saisissez le code",
        "Too many attempts": "Trop de tentatives, veuillez réessayer plus tard",
        "Invalid key": "Clef non valide",
        "Please plug in and active your key": "Merci d'insérer votre clef et de l'activer",
        "Advanced search on folders": "Recherche avancée sur les répertoires de coffres",
        "Number of items to display": "Nombre d'éléments à afficher",
        "Select": "Selectionner",
        "Suppression": "Suppression",
        "cancelWanted": "Annulation de demande",
        "This is not a valid String": "Chaîne non valide",
        "Add a device": "Ajouter un appareil",
        "No device set, please add one": "Aucun appareil configuré, merci d'en ajouter un",
        "Personal Settings": "Menu personnel",
        "Maintenance": "Maintenance",
        "Canceled": "Annulé",
        "My devices": "Mes appareils",
        "New Fileset": "Nouveau coffre",
        "New Folder": "Nouveau répertoire de coffre",
        "Rename FileSet": "Renommer le coffre",
        "Decrypt ok ": "Déchiffrement ok",
        "already exist": "Ce nom existe déjà",
        "New directory": "Nouveau répertoire",
        "Directory created": "Répertoire créé",
        "File added created": "Fichier ajouté",
        "Selection mode": "Mode sélection",
        "Filesets": "Coffres",
        "Folders": "Répertoires de coffres",
        "Downloading": "En cours de chargement",
        "Message deleted": "Message supprimé",
        "add": "Ajouter",
        "Fileset name": "Nom du coffre",
        "Updated": "Mise à jour effectuée",
        "Copied to clipboard": "Copié dans le presse-papiers",
        "Add an email to your account": "Ajouter un email à votre compte",
        "30 chars max": "30 caractères maximum",
        "Url renamed": "Invitation renommée",
        "Already exists": "Ce nom existe déjà",
        "All logs decrypted": "Tous les logs ont été déchiffrés",
        "move finished": "Déplacement terminé",
        "Are you sure to delete thoses elements ?": "Êtes-vous sûr de vouloir supprimer ces éléments ?",
        "File renamed": "Fichier renommé",
        "Share created": "Partage ajouté",
        "Share updated": "Partage mis à jour",
        "Are you sure to delete thoses shares ?": "Êtes-vous sûr de vouloir supprimer ces partages ?",
        "Ip address": "Adresse IP",
        "Owner name": "Nom du propriétaire",
        "Object Name": "Nom de l'objet",
        "Advanced search on logs": "Recherche avancée dans les logs",
        "Ip adress": "Adresse IP",
        "Notifications": "Notifications",
        "There is no message, write an new one in text field below": "Aucun message, vous pouvez en écrire un dans l'entrée de texte ci-dessous",
        "New quorum group": "Nouveau groupe à quorum",
        "Quorum Group created": "Groupe à quorum créé",
        "Are you sure to delete thoses quorum groups ?": "Êtes-vous sûr de vouloir détruire ces groupes à quorum ?",
        "Are you sure to delete thoses users ?": "Êtes-vous sûr de vouloir détruire ces utilisateurs ?",
        "Are you sure to delete thoses groups ?": "Êtes-vous sûr de vouloir détruire ces groupes ?",
        "Group updated": "Groupe mis à jour",
        "Decrypt all logs": "Déchiffrer tous les logs",
        "Decrypt all": "Déchiffrer tout",
        "All logs that you have right to decrypt are so": "Tous les logs que vous avez le droit de déchiffrer sont ainsi",
        "No todos": "Aucune action restante",
        "No quorum group": "Aucun quorum",
        "no log": "Aucun log",
        "No group": "Aucun groupe",
        "No friend": "Aucune relation",
        "No file": "Aucun fichier",
        "No device registered": "Aucun appareil enregistré",
        "Creating Directory": "Création d'un répertoire",
        "Folder renamed": "Répertoire de coffres renommé",
        "Quorum group updated": "Groupe à quorum mis à jour",
        "The threshold cannot be more than then number of admins": "Le seuil ne peut être supérieur au nombre de membres",
        "Create a new user": "Créez un nouvel utilisateur",
        "User updated": "Utilisateur mis à jour",
        "Validated": "Validé",
        "This url is no longer available": "Cette invitation n'est plus disponible",
        "Please ask the sender to send you a new one.": "Veuillez demander à l'expéditeur de vous en envoyer une nouvelle",
        "Your account is validated": "Votre compte est validé",
        "Wanted added to filesets": "Demande d'accès effectuée",
        "Reinitializing keys": "Réinitialisation des clefs cryptographiques",
        "You must choose a new password": "Vous devez choisir un nouveau mot de passe",
        "You can close this window": "Vous pouvez fermer cette fenêtre",
        "Ask": "Demander",
        "Cipher": "Chiffrer",
        "full screen": "Plein écran",
        "Skip": "Passer",
        "Private key in memory ?": "Clef privée en mémoire ?",
        "Are you sure to drop this key from memory ?": "Êtes-vous sûr de supprimer cette clef de la mémoire ?",
        "drop": "Supprimer",
        "release": "Libérer",
        "Need password": "Besoin d'un mot de passe",
        "ChangePassword": "Changer le mot de passe",
        "Old password for this key": "Ancien mot de passe pour cette clef",
        "New password for this key": "Nouveau mot de passe pour cette clef",
        "FingerPrint": "Empreinte",
        "Are you sure to revoke this key ?": "Êtes-vous sûr de révoquer cette clef ?",
        "Are you sure to delete this key ?": "Êtes-vous sur de supprimer cette clef ?",
        "Quorum of device - creation step": "Quorum d'équipement - création étape 1",
        "Min device count": "Nombre minimum d'équipements",
        "Keys on this device": "Les clefs sur cet équipement",
        "Are you sure to delete this device ?": "Êtes-vous sûr de supprimer cet équipement ?",
        "Are you sure to drop this signature ?": "Êtes-vous sûr de supprimer cette signature ?",
        "Move multiple objects": "Déplacer {num} objets",
        "Searching": "Recherche en cours",
        "First page": "Première page",
        "Previous page": "Page précédente",
        "Next page": "Page suivante",
        "Last page": "Dernière page",
        "Rename file or folder": "Renommer fichier ou répertoire",
        "Are you sure to delete thoses devices ?": "Êtes-vous sûr de vouloir supprimer ces équipements ?",
        "See": "Voir",
        "More details": "Plus de détails",
        "Fake finished": "Fake effectué",
        "Sure to launch Fake": "Êtes-vous sur de lancer Fake",
        "Rename fileset": "Renommer le coffre",
        "Url created": "Invitation créée",
        "Show filesets where I am": "Voir les coffres où je suis en partage",
        "Show": "Voir",
        "Are you sure to sign thoses friends ?": "Êtes-vous sûr de signer ces contacts ?",
        "Sign": "Signer",
        "Advanced search on friends": "Recherche avancée sur les contacts",
        "Soon": "Bientôt",
        "Created": "Créé",
        "Advanced search on groups": "Recherche avancée sur les groupes",
        "Loading notifications": "Chargement des notifications",
        "You need to increase the threshold": "Vous devez augmenter le seuil",
        "Send mail": "Envoyer un email",
        "Access to fileset": "Accès au coffre \"{name}\"",
        "Here is the access to the fileset": "Voici l'accès au coffre \"{name}\"",
        "Follow the link": "Suivez le lien",
        "Search a user": "Rechercher un utilisateur",
        "Thread deleted": "Discussion supprimée",
        "Sorry, but you must not be here.": "Désolé, mais vous ne devez pas être sur cette page.",
        "Todos": "Choses à faire",
        "Actions": "Actions",
        "Advanced search on actions": "Recherches avancées sur les actions",
        "Are you sure to delete thoses urls ?": "Êtes-vous sûr de vouloir supprimer ces invitations ?",
        "Url updated": "Invitation modifiée",
        "From": "De",
        "Advanced search on urls": "Recherche avancées sur les invitations",
        "Named Fileset created": "Coffre \"{name}\" créé",
        "Cannot add a message in the thread, you are not a member of this fileset !": "Impossible d'ajouter un message, vous n'êtes pas membre de ce coffre",
        "Cannot remove a message in the thread, is not a member of this fileset !": "Impossible de supprimer un message, vous n'êtes pas membre de ce coffre",
        "Error FileSetShare without any encryption data": "Partage sans données de chiffrement",
        "Error ciphering": "Erreur de chiffrement",
        "File reader not done": "Erreur durant la lecture du fichier",
        "cannot apply a workflow to an non -set element ": "Ne peut pas appliquer de workflow sur un élément vide",
        "workflow error": "Erreur de workflow ({message})",
        "setWFsContent error": "Erreur d'initialisation de workflow ({message})",
        "User doenst exists": "L'utilisateur \"{login}\" n'existe pas",
        "Folder doesnt exists in path": "Le dossier \"{name}\" n'existe pas dans \"{path}\"",
        "Fileset doesnt exists in path": "Le coffre \"{name}\" n'existe pas dans \"{path}\"",
        "You are automaticaly logged": "Vous êtes automatiquement connecté",
        "You can now log on with your password": "Vous pouvez vous connecter avec votre mot de passe",
        "Welcome": "Bienvenue",
        "There was an error from our server. Please contact us.": "Il y a une erreur avec notre serveur",
        "You have been invited": "Vous avez été invité",
        "Please choose a password": "Choisissez un mot de passe",
        "The selected File mut be an image": "Le fichier choisi doit être une image",
        "not an instance of UserInteract !": "N'est pas une instance de UserInteract",
        "No account": "Pas de compte pour \"{displayName}\"",
        "Move error": "Erreur durant le déplacement",
        "Search file by owner": "Recherche par le propriétaire",
        "The name of the owner": "Le nom du propriétaire",
        "Advanced search on filesets": "Recherche avancée sur les coffres",
        "In share": "En partage",
        "In Quorum": "En quorum",
        "Content Validator": "Validateur de contenu",
        "Search filesets shared with": "Recherche des coffres partagés avec",
        "The name of the user": "Le nom de l'utilisateur",
        "By selecting users": "En sélectionnant un utilisateur",
        "The name of the last modifier": "Le nom du dernier à avoir modifié",
        "Generic Search": "Recherche générique",
        "more advanced": "Mode expert",
        "search on groups": "Recherche sur les groupes",
        "The group will contain all selected users": "Le groupe doit contenir tous les utilisateurs sélectionnés",
        "Search a member": "Recherche sur un membre",
        "Search groups containing those users": "Recherche sur le groupe contenant ces utilisateurs",
        "part of the name only available": "Seulement une partie du nom suffit",
        "The name of the member": "Le nom du membre",
        "Extract current page to csv": "Extraire la page courante en csv",
        "Extract page": "Extraire la page",
        "Extract all page to csv": "Extraire toutes les pages en csv",
        "Extract all": "Tout extraire",
        "search on messages": "Recherche sur les messages",
        "Log begin time": "Début",
        "Log end time": "Fin",
        "search on quorums": "Recherche sur les quorums",
        "Search quorums by owner": "Recherche sur les quorums par proprietaire",
        "search on users": "Recherche sur les utilisateurs",
        "Search users by owner": "Recherche des utilisateurs par proprietaire",
        "Cannot change : No keyStore ": "Changement impossible : Pas de keyStore",
        "Invalid password": "Mot de passe invalide",
        "Need Passphrase": "Nouvelle phrase de passe",
        "No keystore in accountStore": "Pas de keyStore dans l'accountStore",
        "no related thread found": "Pas de discussion associée",
        "Thread not decrypted": "Discussion non déchifrée",
        "This is the link to give to your friend": "Voici l'url de dépot à transmettre à votre correspondant",
        "You can copy paste it": "Vous pouvez le copier/coller",
        "Cannot built attributs structure": "Impossible de construire la structure d'attributs",
        "Cannot invite an external user": "Vous ne pouvez pas inviter d'utilisateurs externes",
        "Cannot invite an external user not from collegues": "Vous ne pouvez pas inviter des utilisateurs hors des groupes définis",
        "No validators available. cannot add you.": "Pas de validateurs disponibles. Impossible de vous ajouter.",
        "Folder not found for fileset": "Dossier non trouvé pour le coffre \"{name}\" ({_id})",
        "Owner of the Fileset not found": "Le propriétaire du coffre n'existe pas ({_id})",
        "Unable to rm inod": "Impossible de supprimer le fichier ou répertoire",
        "Cannot add files to a crypted fileset": "Impossible d'ajouter des fichiers à un coffre chiffré",
        "Cannot find directory": "Impossible de trouver le répertoire",
        "Cannot get file from a crypted fileset": "Impossible de télécharger un fichier d'un coffre chiffré",
        "No keyStore for this fileset": "Pas de keyStore pour ce coffre",
        "No sessionKeyId for this fileset": "Pas de clef de session pour ce coffre",
        "cannot download this file": "Impossible de télécharger ce fichier",
        "cannot download file like that outside the browser": "Impossible de télécharger ce fichier de cette façon hors du navigateur",
        "Anonymous Fileset not found": "Coffre anonyme non trouvé",
        "Recipient not found for anonymous fileset": "Destinataire du coffre anonyme non trouvé ({_id})",
        "No keyStore found": "Pas de keyStore",
        "syncGetUniq does not return an array": "La database ne retourne pas une liste",
        "syncGetUniq return more than one element": "La base de donnée retourne plus d'un seul élément or il devrait être unique",
        "Database not open. exited": "Base de donnée non ouverte. Sortie.",
        "Unable to close database": "Impossible de fermer la base de donnée",
        "Passport cannot serialize something different from a user": "Passport ne peut pas \"sérialiser\" quelque chose d'autre qu'un utilisateur",
        "User not found": "Utilisateur \"{login}\" non trouvé",
        "Error while confirmation": "Erreur durant la configuration",
        "Two factor strategy type not registered": "Ce type de stratégie deux facteurs ({type}) n'est pas connu",
        "Two Factor Strategy config is mandatory": "La stratégie à deux facteur est obligatoire",
        "Device already set": "L'équipement est déjà enregistré",
        "Two factor strategy is not registered": "La stratégie deux facteurs n'est pas enregistrée",
        "Two Factor Strategy does not exists for user": "Pas de stratégie deux facteurs pour \"{login}\"",
        "Fail to register U2F key": "Impossible d'enregistrer une clef U2F",
        "No email in google account": "Pas d'email dans le compte google",
        "Cannot create an existing user": "Impossible de créer un utilisateur déjà existant",
        "Cannot find usersStore": "Impossible de trouver le store des utilisateurs",
        "Error while deleting new email": "Erreur en effaçant le nouveau mail",
        "Cannot found or create quorumsStore": "Impossible de trouver ou créer le store des quorums",
        "Ldap may be down or url may be wrong.": "Le serveur ldap semble ne pas répondre",
        "Error with bindDN or bindCredentials": "Erreur avec bindDN ou bindCredentials",
        "Invalid filter for searchBase": "Filtre invalide pour searchBase",
        "Invalid filter for searchFilter": "Filtre invalide pour searchFilter",
        "Config not set": "Configuration non établie",
        "config not found in DB": "Configuration non trouvée dans la database",
        "Device does not exist for validation": "L'équipement n'existe pas pour la validation",
        "Error while resolveChallenge of device": "Erreur durant la résolution du challenge de l'équipement",
        "Anonymous cannot save an existing fileset": "Un coffre existant ne peut pas être modifié par un anonyme",
        "Cannot create another root dir": "Impossible de créer un second dossier racine",
        "No users store found": "Le store des utilisateurs n'a pas été trouvé",
        "Error while preset": "Erreur durant le preset",
        "No devicesStore found": "Pas de store de device",
        "This phonenumber is already used": "Ce numéro de téléphone est déjà utilisé",
        "SMS otp not initialized": "l'otp SMS n'est pas initialisé",
        "wrong code": "Code erroné",
        "isDirectoryPolicyCompliant Fileset parent dir not found": "Le dossier parent du coffre n'est pas trouvé pour y lire la policy",
        "Fileset without owner": "Coffre sans propriétaire",
        "Fileset owner not found": "Le propriétaire du coffre n'a pas été trouvé",
        "Parent not found": "Parent non trouvé ({_id})",
        "Configuration": "Configuration",
        "Configuration updated": "Configuration mise à jour",
        "Passphrase": "Mot de passe de protection",
        "Theme": "Thême",
        "Delete my account ?": "Supprimer mon compte ?",
        "Dashboard": "Tableau de bord",
        "About": "À propos",
        "Password close to be strong": "Mot de passe proche d'être fort",
        "Password good but not enough": "Mot de passe presque bon",
        "Password not so bad... ok": "Mot de passe pas si mal... ok",
        "Password too weak, do better": "Mot de passe trop faible, faites mieux",
        "Password too weak": "Mot de passe trop faible",
        "Password realy too weak": "Mot de passe vraiment trop faible",
        "Password cannot be empty": "Le mot de passe ne peut pas être vide",
        "verification mismatch": "Ne correspond pas",
        "password for verification": "Mot de passe pour vérification",
        "Old password": "Ancien mot de passe",
        "Unknown type": "type inconnu",
        "Release cryptographic access": "Libérer les accès cryptographiques",
        "Crypt for ?": "Chiffrer pour \"{name}\" ?",
        "Which keys will be used to sign ?": "Quelle clef sera utilisée pour signer ?",
        "Change the protection of cryptographic keys": "Changer la protection des clefs cryptographiques",
        "Protect your key": "Protéger votre clef",
        "UserKey": "Clef d'utilisateur",
        "I_Need_Passphrase": "J'ai besoin de votre phrase de passe",
        "You can crypt for these keys :": "Vous pouvez chiffrer pour ces clefs :",
        "You may have to enter old password and new password": "Vous devez entrer l'ancien mot de passe et le nouveau",
        "Enter a new password": "Entrer un nouveau mot de passe",
        "Ok": "Ok",
        "Invalid code": "Code invalide",
        "Add a phone to your account": "Ajouter un téléphone à votre compte",
        "Confirm phone": "Confirmer le téléphone",
        "Go back": "Retour",
        "validate": "Valider",
        "Your phone has been added.": "Votre téléphone a été ajouté",
        "Error when registering device": "Erreur durant l'enregistrement de l'équipement",
        "Add a key to your account": "Ajouter une clef à votre compte",
        "Please plug in your key to register": "Veuillez insérer votre clef pour enregistrement",
        "Register": "Enregistrement",
        "Key added": "Clef ajoutée",
        "Your key has been added.": "Votre clef a été ajoutée.",
        "More infos": "Plus d'informations",
        "email": "email",
        "New": "Nouveau",
        "Chat": "Message",
        "Quorumgroups": "Groupes à quorum",
        "Profile": "Profil",
        "Identity": "Identité",
        "Click": "Cliquer",
        "Or drop your files here": "Ou déposer vos fichiers ici",
        "Drop here your files or click on the download": "Glissez-déposez vos fichiers ici ou cliquez sur l'icone",
        "TwoFactors": "Deux facteurs",
        "You are going to remove a 2FA device, are you sure?": "Êtes-vous sûr de supprimer un équipement 2FA ?",
        "File(s) added or modified": "Fichier(s) ajouté(s) ou modifié(s)",
        "Are you sure to delete configuration for group ": "Êtes-vous sûr de supprimer la configuration pour le groupe \"{name}\" ?",
        "Not authorized": "Non autorisé",
        "Given by": "Donné par",
        "in folder": "dans le dossier",
        "Can create new filesets": "Peut créer des coffres",
        "Can create directories": "Peut créer des dossiers",
        "Can modify this rights": "Peut modifier ces droits",
        "Can delete this directory": "Peut supprimer ce dossier",
        "Can modify subdir rights": "Peut modifier les droits des sous dossiers",
        "Your current rights": "Vos droits actuels",
        "Folder name": "Nom du dossier",
        "Visibility": "Visibilité",
        "Not visible if empty": "Non visible si vide",
        "Visible for members below": "Toujours visible pour les membres ci-dessous",
        "All groups": "Tous les groupes",
        "Avoid validation for": "Évite la validation pour",
        "Validators for new shares": "Valideurs des nouveaux partages",
        "Validators for content": "Valideurs des contenus",
        "Recovering friends": "Quorum ou utilisateur de recouvrement",
        "Add configuration for a new group": "Ajouter une configuration pour un nouveau groupe",
        "Select Display": "Affichage",
        "must be a string": "doit être une chaine de caractère",
        "Last modification": "Dernière modification",
        "Update": "Mise a jour",
        "Groups of Managers": "Groupe des managers",
        "IP limitation": "Liste d'accès IP",
        "Email limitation for invitation": "Limitation d'email pour les invitations",
        "Create a new group": "Créer un nouveau groupe",
        "Group name": "Nom du groupe",
        "unread": "Non lu",
        "Encrypted notification": "Notification chiffrée",
        "roles": "roles",
        "Temporary password": "Mot de passe temporaire",
        "Invitation link": "Lien d'invitation",
        "Access to vault": "Accès au coffre \"{name}\"",
        "Here is the access to the vault": "Voici l'accès au coffre \"{name}\"",
        "Member of": "Membre de",
        "No group(s)": "Pas de groupes",
        "Login already exist": "Ce compte existe déjà",
        "Locked": "Verrouillé",
        "Error while reinit password": "Erreur durant la réinitialisation du mot de passe",
        "User with this confirmString not found": "Utilisateur avec cette chaîne de confirmation non trouvé",
        "Error while validation of device": "Erreur durant la validation de l'équipement",
        "Error while initChallenge of device": "Erreur durant l'initialisation du challenge",
        "This folder is not empty": "Ce dossier n'est pas vide",
        "Group object not filled": "L'objet groupe n'est pas rempli",
        "No recipient for Notification": "Pas de destinataire pour la notification",
        "unknown user with Id": "Utilisateur inconnu ({_id})",
        "recipientUser not understandable": "Utilisateur destinataire incompréhensible",
        "Notification not set": "Notification non initialisée",
        "Cannot find or create quorumStore": "Ne peut pas trouver ou créer le store des quorums",
        "Cannot find or create userStore": "Ne peut pas trouver ou créer le store des utilisateurs",
        "Security violation": "Violation de sécurité : \"{message}\"",
        "Must have valid emails before": "Doit préalablement avoir un email valide",
        "User object not filled": "L'objet utilisateur n'est pas rempli",
        "Cannot find or create filesetsStore": "Ne peut pas frouver ou créer le store des coffres",
        "Error while creating user": "Erreur durant la créaction de l'utilisateur",
        "File is already a directory": "Fichier \"{name}\" existe déjà",
        "File under saving": "Sauvegarde du fichier \"{name}\" en cours",
        "workflow not found": "Workflow \"{name}\" inconnu",
        "Notification without encryption structure": "Notification ({_id}) sans données chiffrées",
        "fileset_wanted_text": "\"{user}\" souhaite un accès au coffre \"{name}\"",
        "Maintenance end": "La maintenance prendra fin le {endDate}",
        "Please ask an administrator": "Merci de contacter un administrateur",
        "Must be a square": "Dimensions maximum {width}x{height}px",
        "File deleted": "Fichier \"{name}\" supprimé",
        "Are you sure to delete file ?": "Êtes-vous sûr de supprimer le fichier \"{name}\" ?",
        "Device deleted": "Equipement \"{name}\" supprimé",
        "Are you sure to delete device ?": "Êtes-vous sûr de supprimer l'équipement \"{name}\" ?",
        "Are you sure to delete Device ?": "Êtes-vous sûr de supprimer l'équipement \"{name}\" ?",
        "File downloaded": "Fichier \"{name}\" téléchargé",
        "Fileset deleted": "Coffre \"{name}\" supprimé",
        "Are you sure to delete fileset ?": "Êtes-vous sûr de supprimer le coffre \"{name}\" ?",
        "Folder deleted": "Dossier \"{name}\" supprimé",
        "Are you sure to delete folder ?": "Êtes-vous sûr de supprimer le dossier \"{name}\" ?",
        "Friend signed": "Clefs du correspondant \"{name}\" signés",
        "Are you sure to signed this friend ?": "Êtes-vous sûr de vouloir signer les clefs de \"{name}\" ?",
        "Group deleted": "Groupe \"{name}\" supprimé",
        "Are you sure to delete group ?": "Êtes-vous sûr de vouloir suprimer le groupe \"{name}\" ?",
        "Quorum deleted": "Groupe à quorum \"{name}\" supprimé",
        "Are you sure to delete quorum group ?": "Êtes-vous sûr de vouloir supprimer le groupe à quorum \"{name}\" ?",
        "Accept access with quorum": "Validation avec le groupe à quorum \"{name}\"",
        "Access accepted": "Demande d'accès pour \"{name}\" ajouté",
        "Access requested": "Accès demandé à \"{name}\"",
        "Request cancelled": "Demande d'accès à \"{name}\" abandonnée",
        "Are you sure to cancel your request ?": "Êtes-vous sûr d'abandonner la demande d'accès à \"{name}\" ?",
        "Share deleted": "Partage \"{name}\" supprimé",
        "Are you sure to delete share ?": "Êtes-vous sûr de supprimer le partage \"{name}\" ?",
        "Refuse access with quorum": "Refuser l'accès avec le quorum \"{name}\"",
        "Access refused": "Accès \"{name}\" refusé",
        "Are you sure to give access to ?": "Êtes-vous sûr de vouloir donner l'accès à \"{name}\" ?",
        "Access granted": "Accès à \"{name}\" accordé",
        "Are you sure to refuse access to ?": "Êtes vous sur de refuser l'accès à \"{name}\" ?",
        "Url deleted": "Invitation \"{name}\" supprimée",
        "Are you sure to delete url ?": "Êtes-vous sûr de vouloir supprimer l'invitation \"{name}\" ?",
        "User deleted": "Utilisateur \"{login}\" supprimé",
        "Are you sure to delete user ?": "Êtes-vous sûr de vouloir supprimer l'utilisateur \"{login}\" ?",
        "object not found in store": "Objet non trouvé dans le store ({_id})",
        "Fileset not found": "Coffre non trouvé ({_id})",
        "Cannot preset user ": "Impossible de préparer l'utilisateur \"{name}\"",
        "Cannot invite a quorum": "Impossible d'inviter un quorum ({_id})",
        "Cannot invite an external user to a non clear fileset": "Impossible d'inviter un utilisateur externe à un coffre chiffré ({_id})",
        "upload file": "Téléversement du fichier \"{name}\"",
        "Unable to add wanted": "Impossible d'ajouter la demande d'accès ({_id})",
        "User not found in store": "Utilisateur non trouvé dans le store ({_id})",
        "Unable to remove wanted": "Impossible de supprimer la demande d'accès ({_id})",
        "Unable to refuse wanted": "Impossible de refuser la demande d'accès ({_id})",
        "Unable to remove share": "Impossible de supprimer le partage ({_id})",
        "Unable to create dir": "Impossible de créer le répertoire \"{newDir}\"",
        "Reading file": "Lecture du fichier \"{name}\"",
        "User not find for anonymous share": "Utilisateur non trouvé pour le partage anonyme ({_id})",
        "Please remove quorum group from folders configuration before deleting it": "Merci de supprimer préalablement le groupe à quorum de la configuration des dossiers",
        "Error CRUD": "Erreur CRUD ({DataBase})",
        "Database error": "Erreur de base de donnée",
        "User not found in database": "L'authentification a échoué pour \"{login}\"",
        "Not authorised from this @IP ": "Accès non autorisé depuis l'adresse ip \"{ip}\"",
        "Confirmation String authentication failed": "La confirmation par code a échoué pour l'utilisateur \"{userName}\"",
        "User not ready": "L'utilisateur \"{login}\" n'est pas prêt",
        "Authentication failed": "L'authentification a échoué pour \"{login}\"",
        "User not found by email ": "Utilisateur non trouvé avec l'email \"{email}\"",
        "user not found": "Utilisateur \"{login}\" non trouvé",
        "Confirmation String invalid": "Code de confirmation invalide pour \"{login}\"",
        "quorum not found": "Groupe à quorum non trouvé ({_id})",
        "Unknown User with id": "Utilisateur inconnu ({_id})",
        "Parent folder doesnt exist": "Le dossier parent de \"{name}\" n'existe pas",
        "group already exist": "Le groupe \"{name}\" existe déjà",
        "QuorumGroup number of admins too small": "Le groupe à quorum \"{name}\" a un nombre d'administrateur trop petit",
        "QuorumGroup already exist": "Le groupe à quorum \"{name}\" existe déjà",
        "User without keyStore": "Utilisateur sans keyStore ({_id})",
        "No keyStore in quorumgroup ": "Groupe a quorum sans keyStore ({_id})",
        "Cannot get data from an empty element": "Impossible de réccupérer des données d'un élément vide ({DataBase})",
        "Object without keyStore": "Objet \"{name}\" sans keyStore",
        "No credentials defined for user ": "Pas de données d'authentification de type ({type}) pour \"{login}\"",
        "User already exist": "L'utilisateur \"{login}\" existe déjà",
        "Email not verified": "L'email pour \"{login}\" n'a pas été vérifié",
        "Email already exist": "L'email \"{email}\" existe déjà",
        "Email doesnt exist": "L'email \"{email}\" n'existe pas",
        "No credentials type defined for user": "Pas de type \"{type}\" de données d'authentification pour \"{login}\"",
        "Credential type not implemented": "Le type \"{type}\" de données d'authentification n'est pas défini",
        "Cannot flush from an empty element": "Impossible d'écrire un élément vide ({DataBase})",
        "Error while flushing element": "Erreur durant l'écriture dans la database \"{DataBase}\"",
        "You have no right to do this search": "Recherche interdite dans \"{storeName}\"",
        "Object not found": "Objet ({_id}) non trouvé",
        "Cannot change workflow for this object": "Impossible de changer le workflow pour l'objet ({_id})",
        "Cannot save an empty object": "Impossible de sauvegarder un objet vide ({DataBase})",
        "Manage": "Tous",
        "Add file": "Ajouter ou modifier des fichiers",
        "Add comments": "Voir et ajouter des commentaires",
        "Add share": "Partager",
        "See logs": "Voir les logs",
        "See shares": "Voir les partages",
        "All access and all management function": "Tout accès et tous les droits",
        "Can download file. otherwise only see in the browser": "Peut télécharger les fichiers, sinon seulement voir le contenu.",
        "Can add or modify files": "Peut ajouter ou modifier des fichiers dans le coffre",
        "Can see and add comments": "Peut voir et ajouter des commentaires dans le coffre",
        "Can add new users on this fileset": "Peut partager ce coffre à de nouveaux utilisateurs",
        "Can see all activities on the fileset": "Peut voir toutes les activités sur ce coffre",
        "Can see all users shareing this fileset": "Peut voir les utilisateurs en partage sur ce coffre",
        "Choose user": "Choisir un utilisateur",
        "Select rights": "Affecter les droits",
        "Customise emails and share": "Personnaliser l'email et partager",
        "Summary": "Récapitulatif",
        "Choose rights for thoses persons": "Selectionner les droits pour ces personnes",
        "Custom emails thoses persons": "Personnalisez les messages (mails) pour ces personnes",
        "share not done": "Partage non effectué (erreur)",
        "share ok": "Partage réussi",
        "invitation to send": "Invitation à envoyer",
        "You must Send him his password or the direct link": "Vous devez lui envoyer ce mot de passe ou le lien direct",
        "password": "mot de passe",
        "invitation sent": "Invitation envoyée",
        "email has received an email with the invitation link": "\"{email}\" a recu un email avec le lien d'invitation.",
        "He must check his email,... and his SPAM too": "Il doit surveiller ses mails et penser à regarder dans les SPAMs également.",
        "You will validate his login": "Vous devrez valider ensuite son compte",
        "Please stay connected and look at notifications or your emails": "Idéalement, restez connecter et surveiller vos notifications ou alors vérifier régulièrement vos emails.",
        "Finish": "Retour",
        "content of the email": "contenu du mail",
        "Group not found": "Groupe non trouvé ({groupId})",
        "Loading content": "Chargement du contenu",
        "Loading Console": "Chargement de la console",
        "Loading login": "Chargement login",
        "Loading application": "Chargement de l'application",
        "thank you to join us": "Merci de nous avoir rejoint",
        "Cannot force a new password to a user with keyStore": "Impossible de forcer un nouveau mot de passe pour un utilisateurs ayant deja un keyStore",
        "Error while changing local credentials": "Erreur durant le changement de mot de passe",
        "Error while setting temporary Authentication String": "Erreur durant l'application de la chaine d'authentification temporaire",
        "You must change for a new personal password first": "Vous devez préalablement changer de mot de passe",
        "You must enter the temporary password.": "Merci de saisir votre mot de passe provisoire.",
        "Then you will be redirected to changing it for a personnal one": "Puis vous serez redirigé pour en choisir un nouveau",
        "Loading vault": "Chargement du coffre",
        "Loading folder": "Chargement d'un répertoire de coffres",
        "Loading users": "Chargement des utilisateurs",
        "Loading user": "Chargement d'un utilisateur",
        "Loading groups": "Chargement des groupes",
        "Loading group": "Chargement d'un groupe",
        "Loading quorum groups": "Chargement des groupes à quorum",
        "Loading quorum group": "Chargement d'un groupes à quorum",
        "Loading logs": "Chargement des journaux",
        "Crypting and saving fileset": "Chiffrement et sauvegarde du coffre {name}",
        "Loading urls": "Chargement des invitations",
        "Loading url": "Chargement de l'invitation",
        "Loading about": "Chargement du a propos",
        "Loading friends": "Chargement des contacts",
        "Loading friend": "Chargement du contact",
        "Loading actions": "Chargement des actions",
        "Access accepted with quorum": "Accès pour \"{name}\" accepté avec le groupe a quorum \"{quorumName}\"",
        "with post-validation": "avec validation",
        "with password": "avec mot de passe",
        "Unknow share type": "Partage type inconnu ({type})",
        "If you sent the password, you do not need to send the link below :": "Si vous avez envoyé le mot de passe, vous n'avez pas à envoyer le lien ci-dessous :",
        "Could not rename with this name (it previously exists, no ? )": "Impossible de renommer en \"{newname}\" (ce nom est déjà utilisé)",
        "Error fetching parent": "Impossible de réccupérer le parent",
        "Error fetching brothers": "Impossible de réccupérer les objets au même niveau",
        "Url will be automatically added at the end of the email": "Un lien sera auromatiquement ajouté en fin de message. Vous n'avez pas à vous en préoccuper.",
        "You don't have rights to see configuration": "Vous n'avez pas le droit de voir la configuration",
        "You don't know this user's keys, are you sure you want to trust this user ?": "Vous ne connaissez pas les clefs de cet utilisateur, êtes vous sûr de lui faire confiance ?",
        "An old key which isn't existing anymore": "Une ancienne clef qui n'existe plus",
        "You don't have the right to see logs": "Vous n'avez pas le droit de voir les logs",
        "You don't have the right to see comments": "Vous n'avez pas le droit de voir ou modifier les commentaires",
        "You don't have the right to see this share": "Vous n'avez pas le droit de voir ce partage",
        "fileset_wanted_user": "\"{user}\" vous demande un accès au coffre \"{name}\"",
        "fileset_wanted_quorum": "\"{user}\" demande au quorum un accès au coffre \"{name}\"",
        "Your session has expired": "Suite à un long moment d'inactivité, vous devez vous réauthentifier.",
        "View use conditions": "Voir les conditions générales d'utilisation",
        "Password asked": "Mot de passe demandé",
        "Ask password change": "Demander un changement du mot de passe",
        "lock": "Verrouiller",
        "Ask password to quorum": "Demander le mot de passe au quorum",
        "A password is asked to the quorum members": "Votre mot de passe va être récupéré par les membres du quorum",
        "In clicking below, you confirm asking to the quorum to deliver to you your password back": "En cliquant ci-dessous, vous confirmez votre demande au groupe d'administrateurs de secrets de récupération de votre mot de passe.",
        "Password change asked": "Changement de mot de passe demandé",
        "Are you sure to ask for password change ?": "Êtes-vous sûr de demander un changement de mot de passe ?",
        "Are you sure to ask for password change for these users ?": "Êtes-vous sûr de demander un changement de mot de passe pour ces utilisateurs ?"

    }
};
export const errors = {
    "fr": {
        "Warning": "Attention",
        "Must be a valid email": "Doit être une adresse mail valide",
        "Must be filled": "Ne peut pas être vide",
        "Sorry, object not found": "Désolé, objet non trouvé",
        "Cannot change password for non local account": "Impossible de modifier le mot de passe d'un compte tiers",
        "Crypting key": "Clef de chiffrement",
        "Signing key": "Clef de signature",
        "Quorum key": "Clef de quorum",
        "Quorum member key": "Clef de membre de quorum",
        "Revoked": "Révoqué(e)",
        "InProgress1": "Cette clef est en attente d'une première validation de quorum",
        "InProgress2": "Cette clef est en attente d'une seconde validation de quorum"
    }
};
export const notifications = {
    "fr": {
        "unknown_notif": "Message inconnu!",
        "unknown_notif_text": "Message inconnu!"
    }
};
export const mail = {
    "fr": {
        "Confirm": "Confirmer"
    }
};
export const sms = {
    "fr": {
        "Verification code": "Voici votre code de validation : \"{otp}\"",
        "A code has been sent to your phone": "Un code a été envoyé sur votre téléphone"
    }
};