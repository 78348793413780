import { withStyles, withTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import React from 'react';
import { MyComponent } from '../../MyComponent.jsx';
import { translate } from '../../../../../SharedLibs/Langs.js';
import { LynError } from '../../../../../SharedLibs/LynError.js';
import { accountStore } from '../../../stores/AccountStore';
import classNames from 'classnames';

import CoolText from './CoolText.jsx';

// const LIGHTERFACTOR = 0.1;

const styles = () => ({
	dialog: {
		minWidth: '30%',
		minHeight: 250,
	},
});

class ErrorDisplay extends MyComponent {

	constructor(props) {
		super(props);

		this.setMessage = this.setMessage.bind(this);
		this.closeMessage = this.closeMessage.bind(this);

		this.state = {
			"open": false,
			"err": undefined,
		};
	}

	closeMessage(event) {
		if (event) event.preventDefault();
		this.setState({ "err": undefined, 'open': false });
	}

	/**
	* Set the way to contact me
	*/
	componentDidMount() {
		this.props.global.registerFunction('error', this.setMessage)
	}

	/**
	* Unregister the setMessage function
	*/
	componentWillUnmount() {
		this.props.global.registerFunction('error', undefined)
	}

	setProgressActionsAsError() {

	}

	/**
	* Add a message to display
	*
	*/
	setMessage(err) {
		this.setProgressActionsAsError();

		//console.log(`ErrorDisplay.jsx - setMessage - err : `, err);
		//console.log("ErrorDisplay.jsx - setMessage - this.props.location.pathname : ",this.props.location.pathname);
		console.log("ErrorDisplay.jsx - setMessage - window.location.href : ",window.location.href);
		console.log("ErrorDisplay.jsx - setMessage - window.location.pathname : ",window.location.pathname);
		if ((err.statusCode == 401) && ( err.error === 'Unauthorized: Session expired' ) && (window.location.pathname = "/")) {
			accountStore.localLogout();
			return this.setState({ "err": new LynError('Something went wrong during authentication (identifier ? password ?)'), 'open': true });
		} 
		if ((err.statusCode == 401) && ( err.error === 'Unauthorized: Session expired' ))  {
			accountStore.localLogout();
			return this.setState({ "err": new LynError('Your session has expired'), 'open': true });
		}
		//console.log("ErrorDisplay.jsx - setMessage - typeof(err) : ",typeof(err));
		let message = err;
		/*if ( typeof(err) === "object") {
			console.log("ErrorDisplay.jsx - setMessage - convert err to string) : ","");
			if (err.message) { message = err.message} else { message = err; }
			console.log("ErrorDisplay.jsx - setMessage - message : ",message);
		} else {
			message = err;
		}*/

		return this.setState({ "err": message, 'open': true });
	}

	getMessage(err) {
		//console.log("ErrorDisplay.jsx - getMessage - err : ",err);
		if (err.error) {
			//console.log("ErrorDisplay.jsx - getMessage - found err.error");
			return translate(err.error, err.props);
		}
		//console.log("ErrorDisplay.jsx - getMessage - using err.message : ",err.message);
		return(err.message);
		//return translate(err.message, err.props);
	}

	render() {
		let message = undefined;
		const err = this.props.error ? this.props.error : this.state.err;
		const open = this.props.error ? true : this.state.open;
		if (err) message = this.getMessage(err);

		if (message) {
			console.log("ErrorDisplay.jsx - render - message : ",message);
		}

		const errText = [
			{
				smallIcon: 'error', 
				style: {
					color : this.props.theme.palette.secondary.main,
				}
			},
			{ hr : 1 },
			{ text: message },
			{ hr : 1 },
		]

		if (err) console.log("ErrorDisplay.jsx - render - showing error in case of display problem -  err",err);
		return (
			<Dialog
				id="lynError"
				open={open}
				onClose={this.closeMessage}
				TransitionComponent={Slide}
				PaperProps={{ className: classNames(this.props.classes.dialog) }}

			>
				<form id="errorForm" key="errorForm" onSubmit={this.closeMessage}>
					<DialogContent>
						<CoolText
							key="errorContent"
							global={this.props.global}
							texts={errText}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							id="errorSubmit"
							//key="submit"
							color="secondary"
							variant="contained"
							type="submit"
							autoFocus
						>
							{"Ok"}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}


ErrorDisplay.propTypes = {
	'global': PropTypes.object,
	'classes': PropTypes.object,
	'error': PropTypes.object,
	'theme': PropTypes.object,
};

export default withTheme()(withStyles(styles)(ErrorDisplay));