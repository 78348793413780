import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

import { withStyles, withTheme, Typography } from '@material-ui/core';
import { translate } from '../../../../../SharedLibs/Langs';
import Fade from '@material-ui/core/Fade';


const styles = (theme) => ({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    top: 'calc( 50% - 100px )',
    left: 0,
    width: '100%',
    position: 'absolute',
    alignItems : 'center'
  },
  gears: {
    width : 124,
    height : 120,
    animation: 'spin-reverse 8s infinite'
  },
  message: {
    paddingTop: 16,
    display: 'flex'
  },

  gear: {
    position: "relative",
    // zIndex: -10,
    left : 10,
    width: 40,
    height: 40,
    animation: 'spin 5s infinite'
  },

  two: {
    position: "relative",
    left: 45,
    top: -33,
    width: 80,
    height: 80,
    animation: 'spin-reverse 5s infinite'
  },

  three: {
    position: "relative",
    top: -56,
    left: 0,
    width: 60,
    height: 60
  },


  '@keyframes spin': {
    '50%': {
      transform: "rotate(360deg)"
    }
  },

  '@keyframes spin-reverse': {
    '50%': {
      transform: "rotate(-360deg)"
    }
  },

});

class Loading extends Component {
  constructor(props) {
    super(props);
  }

  renderGears() {
    return (
      <div key='gears' className={this.props.classes.gears}>
        <div className={this.props.classes.gear}>
          <svg id="blue" viewBox="0 0 100 100" fill={ this.props.theme.palette.text.primary }>
            <path d="M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6      c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3      l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9      c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z"></path>
          </svg>
        </div>
        <div className={`${this.props.classes.gear} ${this.props.classes.two}`}>
          <svg id="pink" viewBox="0 0 100 100" fill={ lighten( this.props.theme.palette.text.primary, 0.2 ) } >
            <path d="M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6      c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3      l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9      c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z"></path>
          </svg>
        </div>
        <div className={`${this.props.classes.gear} ${this.props.classes.three}`}>
          <svg id="yellow" viewBox="0 0 100 100" fill={ lighten( this.props.theme.palette.text.primary, 0.4 ) }>
            <path d="M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6      c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3      l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9      c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z"></path>
          </svg>
        </div>
      </div>
    )
  }

  render() {

    let message = this.props.message ? this.props.message : translate('Loading');

    return (
      <Fade in={true}>
        <div
          id='loading'
          key='loading'
          className={this.props.classes.loading}
        >

          {this.renderGears()}
          <div
            id='message'
            key='message'
            className={this.props.classes.message}
          >
            <Typography variant="h6"
              noWrap={true}
              align="center"
              gutterBottom
              className={this.props.classes.loadMessage}
              style={{ 'color': this.props.color }}
            >
              {message}
            </Typography>
          </div>

        </div>
      </Fade>

    )

  }
}

Loading.propTypes = {
  'message': PropTypes.string,
  'classes': PropTypes.object,
  'theme': PropTypes.object,
};

export default withTheme()(withStyles(styles)(Loading));