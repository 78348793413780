/* eslint-disable no-unused-vars */

import React, { Component } from 'react';
import Debug from '../../../SharedLibs/DebugObj';


export class MyComponent extends Component {

    constructor(props) {

        super(props);
        // --- Set the debug --
        this.deb = new Debug('Component');
        this.l = this.deb.l;
        this.log = this.deb.log;
        this._false = this.deb._false;
        this._true = this.deb._true;
        // --- Set the debug --
    }


    setState( obj , func ) {
        this.log('setState', obj);
        return super.setState( obj, func );
    }

    render() {
        this.log('render', { 'props' : this.props, 'state' : this.state } );
        return super.render();
    }


}