import {
  CryptoObject
} from './Common.js';

const crypto = require('crypto');

const PBKDF2_ITERATION = 10000;
const PBKDF2_HASH = 'sha256';
const BLOCK_SIZE = 16;

/**
 *******************************************************************************
 **** Authentication and Password **********************************************
 *******************************************************************************
 */
export class PasswordObj extends CryptoObject {
  constructor(errorFunc, debugFunc, byteLength = 32) {
    super(errorFunc, debugFunc);
    this.byteLength = byteLength;
    this.struct = undefined;
  }

  get content() {
    return this.struct
  }

  extract() {
    return new Promise((resolve, reject) => {
      resolve(this.struct);
    });
  }


  async pkpbf2(password, length = undefined, b64Salt = undefined, iteration = PBKDF2_ITERATION, hash = PBKDF2_HASH) {
    let salt = b64Salt ? new Buffer(b64Salt, 'base64') : crypto.randomBytes(BLOCK_SIZE);
    let keyStruct = {
      "iteration": iteration,
      "hash": hash,
      "salt": salt.toString('base64'),
      "keylen": length ? length : this.byteLength,
    }
    let rawKey = await this.nodePkpbf2(password, salt, keyStruct.iteration, keyStruct.keylen, keyStruct.hash);

    if (!rawKey) {
      this.err("error in pkpbf2 : this.nodePkpbf2 return empty value.");
      return false;
    }
    this.struct = {
      "key": rawKey.toString('base64'), //  Uint8Array coded un base64
      "salt": keyStruct.salt,
      "iteration": keyStruct.iteration,
      "hash": keyStruct.hash,
      "keylen": keyStruct.keylen,
      "windowCryptoKey": undefined,
    };

    return true;
  }

  async pkpbf2Verify(password, struct) {
    let salt = new Buffer(struct.salt, 'base64');
    let rawKey = await this.nodePkpbf2(password, salt, struct.iteration, struct.keylen ? struct.keylen : this.byteLength, struct.hash);
    if (!rawKey) {
      this.err("error in pkpbf2Verify : this.nodePkpbf2 return empty value.");
      return false;
    }
    if (rawKey.toString('base64') === struct.key) return true;
    return false;
  }



}