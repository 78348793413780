import { LynError } from './LynError.js';

/**
 * 
 * 
 * 
 * example

 * var a=""
 * new Checker("string+").check(a) // false
 * 
 * var b = [ 3 ]
 * new Checker(["int+", '+']).check(b) // true
 * 
 * 
 * "string" -> a string can be undefined or ""
 * "string*" -> a string can be ""
 * "string+" -> a string cannot be "" and cannot be undefined
 * 
 * "/^Abd/" -> a regexp, must match the regexp but can be undefined
 * "/^Abd/+" -> a regexp, must match the regexp and cannot be undefined
 * 
 * "int" -> a int can be undefined or 0
 * "int*" -> a int can be 0
 * "int+" -> a int > 0
 *
 * "function" -> a function or undefined
 * "function+" -> a function and not null.
 *
 * `class(${File.name})+` -> must be an instance of object "File"
 * `class(${File.name})` -> must be an instance of object "File" or undefined
 * 
 * same with expire, email, ...
 * 
 * [ "int" ] must be an array of int, can be empty or undefined
 * [ "int", "*" ] must be an array of int, can be empty but not undefined
 * [ "int", "+" ] must be an array of int, cannot be empty or undefined
 * 
 * { tutu : "string+" } must be an object contaning key tutu (string not null)
 * (but the object can be undefined or {} )
 * 
 * { tutu : "string+" , "*" : 1 } must be an object contaning key tutu (string not null)
 * (but the object must be present )
 * 
 * 
 * [ "in", "string", "int+" ] -> can be a string OR a int>0
 * 
 * [ "enum", 1, 2, 3 ] -> can be one of this values, but can be undefined
 * [ "enum+", 1, 2, 3 ] -> can be one of this values, and cannot be undefined
 * 
 */

var modificators = new RegExp('^(.*)([+*])$', '');


/**
 * Types builded
 */
// an Id 
export const _ID = "/^[a-f,0-9]{24}$/+";
export const _IDS = ["/^[a-f,0-9]{24}$/+"];
export const _IDOrUndefined = "/^[a-f,0-9]{24}$/";

// the owner structure 
export const OWNER = {
  "_id": [ "in" , _ID, "/^0$/" ],
  "login": "string+"
};

export const CHECK_RIGHTS = {
  "manage": "boolean",
  "download": "boolean",
  "addShare": "boolean",
  "addFile": "boolean",
  "addText": "boolean",
  "seeLogs": "boolean",
}


export class Checker {
  constructor(t, suffix) {
    this.suffix = suffix ? suffix : "param";
    this.currentSuffix = this.suffix;
    this.type = t;
  }


  buildSt(t) {
    let st = t.type;
    if (t.type == 'in') {
      let stl = [];
      for (let subtype of t.subtypes) {
        stl.push(this.buildSt(subtype));
      }
      st += " " + stl.join(' or ');
      return st;
    }

    if (t.canBeEmpty === true) st += '(canBeEmpty)';
    if (t.canBeUndefined === true) st += '(canBeUndefined)';
    return st;
  }

  errorMessage(suffix, t, message) {
    return ("Value of \"" + suffix + "\" must be a " + this.buildSt(t) + " and " + message);
  }

  // eslint-disable-next-line complexity
  checkin(obj, t, suffix) {

    // ---------- type of the object  -----------------------------------
    let typeOfObj = typeof obj;

    // --- can be undefined and is undefined !
    if (t.canBeUndefined === true) {
      if (typeOfObj === 'undefined') return true;
      if (obj === null) return true;
    }

    let a ;
    let i = 0;
    let proto;

    switch (t.type) {
      // -------------------------------------------------
      case "null":
        if (!obj) return true;
        return false;
        
      // -------------------------------------------------
      case "in":
        for (let subtype of t.subtypes) {
          let a = this.checkin(obj, subtype, suffix);
          if (a === true) return true;
        }
        return this.errorMessage(suffix, t, 'is not one of those');

        // -------------------------------------------------
      case "enum":
        if (!obj) return this.errorMessage(suffix, t, 'must be one of this enum "' + t.values.join(', ') + '"');
        if (t.values.indexOf(obj) == -1) return this.errorMessage(suffix, t, 'is not in enum "' + t.values.join(', ') + '"');
        return true;

        // -------------------------------------------------
      case "array":
        if (!Array.isArray(obj)) return this.errorMessage(suffix, t, 'is not an array');
        if ((t.canBeEmpty === false) && (obj.length == 0)) return this.errorMessage(suffix, t, 'is empty');
        for (let item of obj) {
          let a = this.checkin(item, t.subtypes, suffix + '[' + i + ']');
          if (a !== true) return a;
          i++;
        }
        return true;

        // -------------------------------------------------
      case "object":
        if (typeOfObj != 'object') return this.errorMessage(suffix, t, 'is not an object');
        if ((t.canBeEmpty === false) && (Object.keys(obj).length == 0)) return this.errorMessage(suffix, t, 'is empty');
        for (let key in t.keys) {
          let a = this.checkin(obj[key], t.keys[key], suffix + '.' + key);
          if (a !== true) return a;
        }
        return true;

        // -------------------------------------------------
      case "boolean":
        if (typeOfObj === 'boolean') return true;
        return this.errorMessage(suffix, t, 'is not a boolean');

        // -------------------------------------------------
      case "int":
        if (Number.isInteger(obj) !== true) return this.errorMessage(suffix, t, 'is not an integer');
        if ((t.canBeEmpty === false) && (obj == 0)) return this.errorMessage(suffix, t, 'is 0');
        return true;

        // -------------------------------------------------
      case "email":
        if (typeOfObj != 'string') return this.errorMessage(suffix, t, 'is not an string');
        if ((t.canBeEmpty === true) && (obj == "")) return true;

        // if (obj.match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) return true;
        if (obj.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)) return true;
        return this.errorMessage(suffix, t, 'is not an email');


        // -------------------------------------------------
      case "expire":
        a = Number.isInteger(obj);
        if (a === true) return true;

        if (typeOfObj != 'string') return this.errorMessage(suffix, t, 'is not an string');
        if ((t.canBeEmpty === true) && (obj == "")) return true;

        if (obj.match(/^-*[0-9]+[smhdwMyY]{0,1}$/)) return true;
        return this.errorMessage(suffix, t, 'is not an expire');

        // -------------------------------------------------
      case "size":
        a = Number.isInteger(obj);
        if (a === true) return true;

        if (typeOfObj != 'string') return this.errorMessage(suffix, t, 'is not an string for a size');
        if ((t.canBeEmpty === true) && (obj == "")) return true;

        if (obj.match(/^-*[0-9]+(B|KB|MB|GB|TB)$/)) return true;
        return this.errorMessage(suffix, t, 'is not an size');

        // -------------------------------------------------
      case "class":      
      if ((t.canBeEmpty === true) && (obj === undefined)) return true;
      if (obj === undefined) return this.errorMessage(suffix, t, 'cannot be empty');
      if ( ! obj.constructor ) return this.errorMessage(suffix, t, 'has no constructor');
        proto = obj.constructor.name;
        if (proto == t.class) return true;
        return this.errorMessage(suffix, t, 'is not the right instance ("'+proto+'" must be "'+t.class+'"');

      case "function":
        if ( typeof( obj ) == 'function' ) return true;
        return this.errorMessage(suffix, t, 'is not a function');

        // -------------------------------------------------
      case "string":
        if (typeOfObj !== 'string') return this.errorMessage(suffix, t, 'is not a string');
        if ((t.canBeEmpty === false) && (obj == "")) return this.errorMessage(suffix, t, 'is empty');
        return true;

        // -------------------------------------------------
      case "regexp":
        if (typeOfObj !== 'string') return this.errorMessage(suffix, t, 'is not a string');
        if (!obj.match(t.regexp)) return this.errorMessage(suffix, t, 'doesnt match "' + t.regexpTxt + '"');
        return true;

      default:
        return this.errorMessage(suffix, t, 'type "' + t.type + '" not understood');
    }
  }


  splitType(type) {
    // --------------- An array of things ----------------------
    if (Array.isArray(type)) {
      if (type.length == 0) return false;

      if (type.length > 1) {
        if (type[0].toLowerCase() === 'in') {
          let types = {
            type: 'in',
            canBeUndefined: false,
            canBeEmpty: false,
            subtypes: []
          };
          for (let i = 1; i < type.length; i++) {
            let a = this.splitType(type[i]);
            if (!a) return false;
            types.subtypes.push(a);
          }
          return types;
        }

        let r = type[0].toLowerCase().match("^(enum)([+]*)$");
        if ((r) && (Array.isArray(type[1]))) {
          let types = {
            type: 'enum',
            canBeUndefined: false,
            canBeEmpty: false,
            values: type[1],
          }
          types.canBeEmpty = r[2] == '+' ? false : true;
          types.canBeUndefined = types.canBeEmpty === false ? false : r[2] == '*' ? false : true;
          return types;
        }

      }
      let types = {
        type: 'array',
        canBeUndefined: true,
        canBeEmpty: true
      }
      let a = this.splitType(type[0]);
      if (!a) return false;
      types.subtypes = a;

      if (type.length == 2) {
        if (type[1] == '+') types.canBeEmpty = false;
        if (type[1] == '*') types.canBeUndefined = false;
      }
      return (types);
    }

    // --------------- An object ----------------------
    if ((typeof type) == 'object') {
      let types = {
        type: 'object',
        canBeUndefined: true,
        canBeEmpty: true,
        keys: {}
      }

      let keys = Object.keys(type);
      for (let key of keys) {
        if (key == '+') {
          types.canBeEmpty = false;
          continue;
        }
        if (key == '*') {
          types.canBeUndefined = false;
          continue;
        }
        types.keys[key] = this.splitType(type[key]);
      }
      return (types);
    }
    // --------------- An object ----------------------

    // --------------- A regexp ----------------------
    let match = type.match("^/(.*)/([+])*$");
    if (((typeof type) == 'string') && (match)) {
      let types = {
        type: "regexp",
        regexpTxt: match[1],
        regexp: new RegExp(match[1]),
        canBeUndefined: true,
        canBeEmpty: true
      }
      types.canBeUndefined = match[2] == '+' ? false : true;
      return (types);
    }
    // --------------- A regexp ----------------------

    // --------------- A string ----------------------
    if ((typeof type) == 'string') {
      let types = {
        type: type.toLowerCase(),
        canBeUndefined: true,
        canBeEmpty: true,
      }

      let r = types.type.match(modificators);
      if (r) {
        types.type = r[1].toLowerCase();
        types.canBeEmpty = r[2] == '+' ? false : true;
        types.canBeUndefined = types.canBeEmpty === false ? false : r[2] == '*' ? false : true;
      }

      // --- A class
      r=type.match(/class\((.*)\)/);
      if ( r ) {
        types.type = 'class';
        types.class = r[1];
      }
      return (types);
    }
    // --------------- A string ----------------------

    return false;
  }

  check(obj, suffix) {
    if (suffix) this.suffix = suffix;
    let t = this.splitType(this.type);
    //console.log("split type ", t);
    if (!t) return "Syntax error in type description";
    return this.checkin(obj, t, this.suffix);
  }

  checkThrow(obj, suffix) {
    let message = this.check(obj, suffix);
    if (message !== true) {
      throw new LynError(message, {
        "type": this.type,
        "obj": obj
      });
    }
    return true;
  }

}