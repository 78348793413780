import io from 'socket.io-client';
var SyncMaster = require('../schmlux/index.js').SyncMaster;
// var SyncMaster = require('../../../../schmlux/Libs/Client/dist/schmlux-client').SyncMaster;

import { currentDebugClasses } from '../../../SharedLibs/DebugObj';

export const sMaster = new SyncMaster(io, '/sc', '/anon', null);

sMaster.setGetCustomHeader(() => {
    const c = currentDebugClasses();
    if (( c ) && ( Array.isArray(c) )) {
        return({
            'Debug' : c.join(',')
        })
    }
    return {};
})

/*
sMaster.setDebugFunc((type, message, data) => {
    console.log(`sMaster > debug sMaster`);
    console.log(`sMaster > type >`, type);
    console.log(`sMaster > message`, message);
    console.log(`sMaster > data > `, data);
})*/
