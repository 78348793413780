import {
    LynError
} from "../../../SharedLibs/LynError";

var myObjectPath = require("object-path");

import SingleStore from './SingleStore';

import {
    sMaster
} from './SyncMaster.js';

import {
    Hash,
    KeyStore
    //manager
    //} from '@lybero/lybcrypt';
    //} from '../../../../lybcrypt/lib/main.js';
} from '../../../SharedLibs/lybcrypt/lib/main.js';

import {
    Checker
} from '../../../SharedLibs/Checker.js';
import {
    lang
} from '../../../SharedLibs/Langs.js';
import {
    AUTH,
    OBJECT_TYPE,
} from '../../../SharedLibs/Constants.js';

class AccountStore extends SingleStore {
    constructor() {
        super();

        // --- Set the debug name --
        this.deb.setNames('AccountStore', 'account');

        this.storeName = 'account';
        this.objectType = OBJECT_TYPE.USER;

        this.saveKeyStore = this.saveKeyStore.bind(this);
        this.refresh = this.refresh.bind(this);
        this.uiPreference = undefined;

        // --- password For Direct Decryption ---
        this.passwordForDirectDecryption = undefined;
    }

    async signUp(data) {

        this.log('signUp', data);
        new Checker({
            "email": "string+", // ---------- The login
            "password": "string+",
            "filesetId": "string",
            "_pathname": "string",
            "confirmString": "string",
            "presetId": "string", // ---- The _id already Set by the host user
            "expire": "expire",
        }, 'signUp').checkThrow(data);

        let hash = new Hash(undefined, undefined, "sha256");
        let passphrase = data.password;

        data.login = data.email;
        data.lang = lang;
        if (!data.authType) data.authType = AUTH.LOCAL;
        if (data.authType === AUTH.LOCAL) {
            data.password = await hash.hash(passphrase);
        }

        // --- avoid _pathname='unknown' at the end
        if (!data._pathname) delete(data._pathname);

        return await this.postAnonAction('signup', undefined, data);
    }


    async wantReinitPassword(data) {
        new Checker({
            "email": "string+", // ---------- The login
            "_pathname": "string",
        }, 'wantReinitPassword').checkThrow(data);

        // --- avoid _pathname='unknown' at the end
        if (!data._pathname) delete(data._pathname);

        await this.postAnonAction('reinitlocal', undefined, data);
        return true;
    }

    async login(data) {
        this.log("login");

        new Checker({
            "login": "string+",
            "password": "string+"
        }, 'login').checkThrow(data);

        //this.deb.start("AccountStore.jsx - login");
        this.initGenericProgress("account", "Login in progress");
        this.passwordForDirectDecryption = data.password;

        let derivedPassword = await this.hash(data.password);

        this.deb.log("AccountStore.js - login - Post", "");
        let currentResult = await this.httpPost("/auth/local", {
            "username": data.login,
            "password": derivedPassword
        });

        this.deb.log("AccountStore.js - login - Post result", currentResult);
        /* AL 29/10/2021 - there was a mistake in the authentication :
           when authentication was failing on the server, an error was generated
           (LocalSecurity.js - configureLocalStrategy and
             CommonSecurity.js - authenticated).
             This is a very, very bad idea, because, it does not allow to distinguish
             between an error (a real one) and a failed authentication.
             So an error was occuring on the node server. 
             I have worked to send back, as advice in passport documentation
             a user equal to false. And then to detect that.
             The key, on the client side, it in AccountStore.js in login.
             So I get the result of the post to get the authentication.
             If it is false, I do not continue in the authentication (do not get
                the keystore, ...). I think that it is the logical way to do.
        */
        if (currentResult === true) {
            await this.getElement();

            this.deb.log("AccountStore.js - login - success !");
            //this.deb.stop("AccountStore.jsx - login");
            // --- Get my keyStore --
            await this.getMyKeyStore(data.password)

            // --- create a new keyStore ---
            if (!this.keyStore) {
                await this.createMyKeyStore(data.password)
            }

            await this.checkMemberQuorumAction();

            this.stopProgress("account");
            this.addUpdateToEmitChange();
            this.emitChange();
            sMaster.startSocket();
            return true;
        } else {
            this.deb.log("AccountStore.js - login - failure !");
            this.stopProgress("account");
            //this.deb.stop("AccountStore.jsx - login");
            return false;
        }
    }

    async checkForAlreadyLogged() {
        this.initGenericProgress("account", "Check for already logged");

        let r = await this.httpGet("/checkiflogged")
            .catch(() => {
                this.stopProgress("account");
            });

        if (r === true) {
            await this.getElement()
                .catch(() => {
                    this.stopProgress("account");
                });
            if (this.logged) {
                sMaster.startSocket();

                // --- Get my keyStore --
                await this.getMyKeyStore()

                // --- create a new keyStore ---
                if (!this.keyStore) {
                    await this.createMyKeyStore()
                }

                await this.checkMemberQuorumAction();
                this.addUpdateToEmitChange();
                this.emitChange();
            }
        }
        this.stopProgress("account");
        return true;
    }

    async loginLdap(data) {
        this.log("Try Login LDAP");

        new Checker({
            "login": "string+",
            "password": "string+"
        }, 'login').checkThrow(data);

        this.initGenericProgress("account", "Login ldap in progress");

        await this.httpPost("/auth/ldap", {
            "username": data.login,
            "password": data.password
        });

        await this.getElement();

        this.stopProgress("account");
        this.addUpdateToEmitChange();
        this.emitChange();
        sMaster.startSocket();
        return true;
    }


    async logout() {
        this.log("logout");
        await this.httpGet("/logout");
        let leavingStores = [];
        this.syncMaster.leaveStores(leavingStores);
        await Promise.all(leavingStores);
        return this.localLogout();
    }

    localLogout() {
        this.log("clear accountStore");
        delete this.data;
        delete this.keyStore;
        this.passwordForDirectDecryption = undefined;
        this.uiPreference = undefined;
        this.addUpdateToEmitChange();
        this.emitChange();
        return true;
    }

    /**
     * 
     * do a hash of a string
     * 
     * @param {string} p 
     */
    hash(p) {
        let hash = new Hash(undefined, undefined, "sha256");
        return hash.hash(p);
    }

    /**
     * Suicide
     */
    async delete() {
        // ------------ Used for debbuging ----------------------------
        this.log("suicide user");
        this.initGenericProgress("account", "Suicide");
        await this.deleteAction('suicide');
        this.stopProgress("account");

        return this.logout();
    }

    /**
     * Send an test email for a user
     *
     */
    async testEmail(data) {
        this.log("testEmail", arguments);
        new Checker({
            "email": "email+",
        }, 'testEmail').checkThrow(data);
        this.initGenericProgress("account", "test Email");
        await this.postAction('sendemail', undefined, data);
        this.stopProgress("account");
        return true;
    }


    /**
     * Changing the passphrase
     * only used in case of AUTH.LOCAL
     *
     */
    async changePassword(data) {
        this.log("changePassword", arguments);

        new Checker({
            "login": "string+",
            "password": "string+",
            "oldPassphrase": "string+",
        }, 'changePassword').checkThrow(data);

        this.log("changePassword");
        this.initGenericProgress("account", "change password");

        /* Check that the original password is the good one */
        /* Correct bps bug - change of password with whatever oldpassword */
        let derivedPassword = await this.hash(data.oldPassphrase);

        await this.httpPost("/auth/local", {
            "username": data.login,
            "password": derivedPassword
        });

        await this.getElement();

        let passphrase = data.password;
        let hash = new Hash(undefined, undefined, "sha256");
        let password = await hash.hash(passphrase);

        if (!this.keyStore) throw new LynError("Cannot change : No keyStore ");

        // Debug version where AC can not change his password.
        // I try to identify the faulty key (and then, maybe I can drop it).
        // All manager.setDebug are supposed to be commented in this code (the 3 hereunder).
        //manager.setDebug(true);
        await this.keyStore.dropPrivateKeys();
        let successfullChange = await this.keyStore.changePassphrase(passphrase, undefined, data.oldPassphrase);
        if (successfullChange === false) {
            this.stopProgress("account");
            //manager.setDebug(false);
            throw new LynError("Invalid password");
        }


        //manager.setDebug(false);
        this.log("Sending new credentials to server", this.content);
        await this.postAction('changepassword', undefined, {
            "type": AUTH.LOCAL,
            "login": this.login,
            "password": password,
        });

        await this.getElement();

        await this.refresh();
        this.stopProgress("account");
        // return this.logout(); 
        // fix(ui): workaround for password change after manual user creation.
        // User will be disconnected and must log again.
        // Now I must create a vault with user informations
        // First create the vault
        /* let savePasswordFS = filesetsStore.create({
            name: data.login,
            description: "Vault for the password of the user",
            parentId: ROOTDIR_ID,
        });
        filesetsStore.addFileByContent(savePasswordFS._id, ROOTDIR_ID, "motdepasse.txt", password);
        */
        return true;
    }

    /**
     * Ask to the good quorum to get back my password that was escrowed. 
     * It is used in AskPasswordToQuorum.jsx.
     */
    async askPasswordToQuorum(data) {
        //this.deb.start();
        this.deb.log("AccountStore.js - askPasswordToQuorum - data", data);

        new Checker({
            "login": "string+",
        }, 'askPasswordToQuorum').checkThrow(data);

        this.initGenericProgress("account", "Ask password to quorum");

        let returnValue = await this.postAnonAction('askpasswordtoquorum', undefined, {
            "login": data.login,
            "confirmString": data.confirmString,
        });
        console.log("AccountStore.js - askPasswordToQuorum - returnValue : ", returnValue)
        this.stopProgress("account");
        // return this.logout(); 
        // fix(ui): workaround for password change after manual user creation.
        // User will be disconnected and must log again.
        // Now I must create a vault with user informations
        // First create the vault
        /* let savePasswordFS = filesetsStore.create({
            name: data.login,
            description: "Vault for the password of the user",
            parentId: ROOTDIR_ID,
        });
        filesetsStore.addFileByContent(savePasswordFS._id, ROOTDIR_ID, "motdepasse.txt", password);
        */
        return returnValue;
    }
    async refresh() {
        if (!this.syncMaster) throw new Error(`${this.storeName} not registerd to a syncManager !`)
        await this.syncMaster.httpGet(`/${this.storeName}`);
    }

    async reinitPassphrase(passphrase) {

        // TODO -----
        this.initGenericProgress("account", "Password reinitialisation");

        this.log("reinitPassphrase", arguments);

        if (!passphrase)
            return this.error("Need Passphrase");

        // --- Get My KeyStore ---
        await this.getMyKeyStore();

        if (this.keyStore) {
            await this.reinitKeyStore(passphrase);
        } else {
            await this.createMyKeyStore(passphrase)
        }

        await this.checkMemberQuorumAction();
        this.stopProgress("account");

        return true;
    }

    async reinitPassword(data) {
        this.log("reinitPassword", arguments);
        new Checker({
            "password": "string+",
            "confirmString": "string+",
            "login": "string+",
        }, 'reinitPassword').checkThrow(data);

        this.initGenericProgress("account", "Password reinitialisation");


        let d = {
            "login": data.login,
            "authType": AUTH.LOCAL,
            "confirmString": data.confirmString,
        };

        await this.httpPost("/auth/local", d);

        await this.getElement();

        let passphrase = data.password;
        let hash = new Hash(undefined, undefined, "sha256");
        let password = await hash.hash(passphrase);

        // --- Get My KeyStore ---
        await this.getMyKeyStore();

        if (this.keyStore) {
            await this.reinitKeyStore(passphrase);
        } else {
            await this.createMyKeyStore(passphrase)
        }

        await this.checkMemberQuorumAction();

        await this.postAction('reinitkey', undefined, {
            "password": password,
            "login": data.login,
            "authType": AUTH.LOCAL,
            "confirmString": data.confirmString,
        });

        this.stopProgress("account");

        sMaster.startSocket();

        return true;
    }

    /**
     * return true if this user is member of one group
     * given in params.
     *
     * @param {[string]} gIds
     */
    isMemberOfOneOfThoseGroups(gIds = []) {
        if (!this.data) return [];
        for (let gId of gIds) {
            if (this.data.memberOf[gId]) return true;
        }
        return false;
    }

    /**
     * return true if the user is connected only one time
     * and with double auth
     */
    withSecondAuth() {
        if (this.data.loggedInformation.length === 0) return false;
        for (let s of this.data.loggedInformation) {
            if (!s.secondAuth) return false;
        }
        return true;
    }


    isAdminOfQuorum(quorumId) {
        let qInfos = this.quorumInfos;
        if (!qInfos[quorumId]) return false;
        for (let q in qInfos[quorumId]) {
            for (let adminId in qInfos[quorumId][q].admins) {
                if (adminId === this._id) return true
            }
        }
        return false;
    }

    /*
    ██╗  ██╗███████╗██╗   ██╗███████╗████████╗ ██████╗ ██████╗ ███████╗
    ██║ ██╔╝██╔════╝╚██╗ ██╔╝██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗██╔════╝
    █████╔╝ █████╗   ╚████╔╝ ███████╗   ██║   ██║   ██║██████╔╝█████╗
    ██╔═██╗ ██╔══╝    ╚██╔╝  ╚════██║   ██║   ██║   ██║██╔══██╗██╔══╝
    ██║  ██╗███████╗   ██║   ███████║   ██║   ╚██████╔╝██║  ██║███████╗
    ╚═╝  ╚═╝╚══════╝   ╚═╝   ╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝
    */

    async getMyKeyStore(password) {
        if (this.keyStore) {
            return false;
        }
        let keyStoreContent = await this.getAction('getmykeystore');
        if (keyStoreContent) {
            this.keyStore = new KeyStore(this._id, this.displayName);
            await this.keyStore.setContent(keyStoreContent);
            if (password) await this.keyStore.releaseAllKeysWithPassphrase(password);
        }
        return false;
    }

    async checkMemberQuorumAction() {
        if (!this.keyStore) return false;
        await this.keyStore.checkMemberQuorumAction()
            .catch((e) => {
                console.error(e);
            });
        return true;
    }

    async createMyKeyStore(password) {
        this.keyStore = new KeyStore(this._id, this.displayName);
        await this.keyStore.create(password)
            .catch((e) => {
                console.error(e);
            });
        return true;

    }

    saveKeyStore(userId, keyStoreContent, keyStorePublicContent) {
        this.log("save keyStore for " + this.displayName);
        return this.postAction('updatekeystore', undefined, {
            "userId": userId,
            "keyStore": {
                "content": keyStoreContent,
                "publicContent": keyStorePublicContent,
            }
        });
    }

    async reinitKeyStore(passphrase) {
        this.log(`Reinit keystore for : ${accountStore._id} - ${accountStore.login}`);
        if (this.keyStore) {
            await this.keyStore.revokeKeys();
            let signKeyId = await this.keyStore.createSignKey(passphrase);
            await this.keyStore.protectPrivateKeyWithPassphrase(signKeyId, passphrase);
            let elGamalKeyId = await this.keyStore.createElGamalKey(passphrase);
            await this.keyStore.protectPrivateKeyWithPassphrase(elGamalKeyId, passphrase);
        } else {
            this.keyStore = new KeyStore(accountStore._id, accountStore.login);
            await this.keyStore.create(passphrase);
        }
        await this.keyStore.save();
    }

    /**
     *
     *  ██████╗ ███████╗████████╗████████╗███████╗██████╗ ███████╗
     * ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝
     * ██║  ███╗█████╗     ██║      ██║   █████╗  ██████╔╝███████╗
     * ██║   ██║██╔══╝     ██║      ██║   ██╔══╝  ██╔══██╗╚════██║
     * ╚██████╔╝███████╗   ██║      ██║   ███████╗██║  ██║███████║
     *  ╚═════╝ ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝
     *
     */

    get logged() {
        return this.data ? true : false;
    }

    get quorumInfos() {
        if (!this.keyStore) return {};
        this.log("AccountStore.js - quorumInfos - this.keyStore.quorumInfos : ",
            this.keyStore.quorumInfos);
        return this.keyStore.quorumInfos;
    }

    get quorumIds() {
        return Object.keys(this.quorumInfos);
    }

    get memberOf() {
        if (this.data == null) return {};
        return (this.data.memberOf);
    }

    get memberOfList() {
        if (this.data == null) return {};
        return (Object.keys(this.data.memberOf));
    }

    get displayName() {
        return this.getField('displayName');
    }

    get loginName() {
        return this.getField('login');
    }

    get emails() {
        return this.getField("contacts.emails", []);
    }

    get phones() {
        return this.getField("contacts.phones", []);
    }

    get avatar() {
        return this.getField("avatar", {});
    }

    get authType() {
        return this.getField("authType", undefined);
    }

    get mustChangePass() {
        return this.getField("flags.mustChangePass", false);
    }

    get mustEscrowPass() {
        return this.getField("flags.mustEscrowPass", false);
    }

    get allU2fDevices() {
        return false;
    }

    get clientId() {
        // TODO usable ?
        return undefined;
    }

    get owner() {
        if (this.data == null) return {
            _id: undefined,
            "login": undefined
        };
        return ({
            _id: this.data._id,
            "login": this.data.login
        });
    }

    get ctime() {
        return this.data.ctime
    }

    get expire() {
        return this.data.expire
    }

    /**
     *
     * ██╗   ██╗██╗    ██████╗ ██████╗ ███████╗███████╗
     * ██║   ██║██║    ██╔══██╗██╔══██╗██╔════╝██╔════╝
     * ██║   ██║██║    ██████╔╝██████╔╝█████╗  █████╗
     * ██║   ██║██║    ██╔═══╝ ██╔══██╗██╔══╝  ██╔══╝
     * ╚██████╔╝██║    ██║     ██║  ██║███████╗██║
     *  ╚═════╝ ╚═╝    ╚═╝     ╚═╝  ╚═╝╚══════╝╚═╝
     */


    /**
     *
     * @param {*} key
     * @param {*} value
     */
    setUiPreference(key, value) {
        if (!this.uiPreference) this.loadPrefFromLocalStorage();
        myObjectPath.set(this.uiPreference, key, value);
        // --------- save into the localStorage ------
        if ((typeof(localStorage) !== 'undefined') && (this.logged)) {
            localStorage.setItem("LynUiPreference_" + this._id, JSON.stringify(this.uiPreference));
            this.log("saving preference into the localStorage ", JSON.stringify(this.uiPreference));
        }
    }


    getUiPreference(key, valueByDefault = undefined) {
        if (!this.uiPreference) this.loadPrefFromLocalStorage();
        return myObjectPath.get(this.uiPreference, key, valueByDefault);
    }


    loadPrefFromLocalStorage() {
        this.uiPreference = {};
        if ((typeof(localStorage) !== 'undefined') && (this.logged)) {
            let storageString = localStorage.getItem("LynUiPreference_" + this._id);
            if (storageString !== null) {
                this.uiPreference = JSON.parse(storageString);
                this.log("Loading uiPreference from localStorage", this.uiPreference);
            }
        }

    }

}

export var accountStore = new AccountStore();
accountStore.registerTo(sMaster);