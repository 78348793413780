import BaseStore from './BaseStore.js';
import { sMaster } from './SyncMaster.js';
import { Friend } from '../Libs/Friend'
import { Checker } from '../../../SharedLibs/Checker'

export const VUE = {
    short: 0,
    full: 1,
};

class FriendsStore extends BaseStore {
    constructor() {
        super();

        // --- Set the debug name --
        this.deb.setNames('FriendsStore', 'friend');

        this.singleton = Friend;
        this.storeName = 'friends';
        this.options = {
            defaultVueLevel: VUE.full,
            maxVueLevel: VUE.full,
            defaultRange: [0, 50]
        };

        this.getPublicKeyStores = this.getPublicKeyStores.bind(this);
        this.getByName = this.getByName.bind(this);
        this.getByLogin = this.getByLogin.bind(this);
        this.getByGroupId = this.getByGroupId.bind(this);

    }
/*
    onSyncInfo(syncInfos) {
        console.log(`Store ${this.storeName} got update from others`, syncInfos);
        return super.onSyncInfo( syncInfos );
    }
    emitChange() {
        console.log(`Store ${this.storeName} emitchange`, this.emitChangeInfos);
        return super.emitChange();
    }
*/

    async getByLogin(login, givenVueLevel) {
        return this.getByKey('login', login, givenVueLevel);
    }
    async getByName(name, givenVueLevel) {
        return this.getByKey('login', name, givenVueLevel);
    }

    async getByGroupId(_ids, givenVueLevel) {
        return this.getAction('getbygroups', _ids, undefined, givenVueLevel);
    }

    getInStoreByLogin( login, givenVueLevel  ) {
        return this.getInStoreByKey( 'login', login, givenVueLevel )
    }

	async presetFriend(data, givenVueLevel) {
		this.log("presetFriend", data);

		new Checker({
			"email": "email+",
			"password": "string",
			"expire": "expire",
		}, 'presetFriend').checkThrow(data);

        return await this.postAction('preset', undefined, data, givenVueLevel);

    }

    getAllMyFriends() {
        return this.getAction('getallmyfriends')
    }

    findFriend(data, givenVueLevel) {
        new Checker({
            "name": "string+",
            "regex": "boolean",
        }, 'findFriend').checkThrow(data);

        return this.postAction('findFriend', undefined, data, givenVueLevel);
    }

    /*
	██╗  ██╗███████╗██╗   ██╗███████╗████████╗ ██████╗ ██████╗ ███████╗
	██║ ██╔╝██╔════╝╚██╗ ██╔╝██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗██╔════╝
	█████╔╝ █████╗   ╚████╔╝ ███████╗   ██║   ██║   ██║██████╔╝█████╗
	██╔═██╗ ██╔══╝    ╚██╔╝  ╚════██║   ██║   ██║   ██║██╔══██╗██╔══╝
	██║  ██╗███████╗   ██║   ███████║   ██║   ╚██████╔╝██║  ██║███████╗
	╚═╝  ╚═╝╚══════╝   ╚═╝   ╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝
    */

    async getPublicKeyStores(userIds) {
            //console.log("getKeyStores from FriendStore", userIds);

            let a = await this.getActionNoUpdate('getpublickeystores', userIds);
            this.log("getKeyStores return", a);
            return a;
        }
        /*
         *
         * ██████╗ ██╗ ██████╗ ██╗  ██╗████████╗███████╗
         * ██╔══██╗██║██╔════╝ ██║  ██║╚══██╔══╝██╔════╝
         * ██████╔╝██║██║  ███╗███████║   ██║   ███████╗
         * ██╔══██╗██║██║   ██║██╔══██║   ██║   ╚════██║
         * ██║  ██║██║╚██████╔╝██║  ██║   ██║   ███████║
         * ╚═╝  ╚═╝╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝
         *
         */
    canCreate() {
        return false;
    }

}

export var friendsStore = new FriendsStore();
friendsStore.registerTo(sMaster);