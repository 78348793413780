import {
    WorkFlow
} from "../../../SharedLibs/WorkFlow.js";

// ---- For debugging ------
import Debug from '../../../SharedLibs/DebugObj'


export class ClientWorkFlow extends WorkFlow {
    constructor(name, states, store) {
        super(name, states)

		// --- Set the debug --
		this.deb = new Debug('workflow');
		this.l = this.deb.l;
		this.log = this.deb.log;
        this._false = this.deb._false;
        this._true = this.deb._true;
        // --- Set the debug --
        
        this.store = store;
        this.changeState = this.changeState.bind(this);
    }


    async changeState(obj, s, data, comment) {
        this.log("changeState", arguments);
        if (!this.isUpperCase(s)) return ("state " + s + " must be uppercase.");

        //--- the state doesnt exists
        if (!this.states[s]) return ("state " + s + " doesnt exists");

        //--- not allow to go to this state
        if (!this.isAllowedToChangeToState(s)) return ("cannot move from state " + this.state + " to " + s + " !");

        // -- Can i have right to change state
        if (!this.canChangeState(obj, this.state, s)) return ("No rights to move from state " + this.state + " to " + s + " !");

        // --- do some exit actions ---
        await this.doExitAction(obj, this.state, s, data);
        await this.doEnterAction(obj, this.state, s, data);


        // --- Notify users -----
        await this.notifyExit(obj, this.state, s, data, comment);
        await this.notifyEnter(obj, this.state, s, data, comment);

        // --- send changement to the server ---
        if ( ! this.store ) throw new Error("Cannot change State (no store)")
        await this.store.postAction('changestate', obj._id, {
            "type": obj.objectType,
            "_id": obj._id,
            "name": this.name,
            "state": s,
            "data": data,
            "comment": comment
        });
        //console.log("got response ", resp);

        return true;
    }

}