import {
    SHARES,
    OBJECT_TYPE,
    OBJECT_PATH
} from '../../../SharedLibs/Constants.js';



import {
    objectGet,
} from '../../../SharedLibs/Tools.js';

import {
    GenericObject
} from './GenericObject.js';
import {
    KeyStore
    //} from '@lybero/lybcrypt';
    //} from '../../../../lybcrypt/lib/main.js';
} from '../../../SharedLibs/lybcrypt/lib/main.js';

export class Friend extends GenericObject {
    constructor(store, accountStore) {
        super(store, accountStore);
        this.objectType = OBJECT_TYPE.FRIEND;
        this.objectPath = OBJECT_PATH.FRIEND;
        this.storeName = 'friends';

        // --- Set the debug name --
        this.deb.setNames('friend');


    }


    get type() {
        return this.getField("type", undefined)
    }

    get avatarIconName() {
        switch (this.type) {
            case SHARES.GROUP:
                return "group";
            case SHARES.QUORUMGROUP:
                return "quorumGroup";

            default:
                break;

        }
        return undefined;
    }

    async buildKeyStoreInstance() {
        if (this.keyStore) return true;
        let publicContent = this.getField("keyStore.publicContent");
        if (publicContent) {
            this.keyStore = new KeyStore(this._id);
            await this.keyStore.setPublicContent(publicContent);
            return true;
        }
        // ... importation ...
        if ((Array.isArray(this.data.publicCryptKeys)) &&
            (this.data.publicCryptKeys.length > 0) &&
            (objectGet(this.data.publicCryptKeys[0], "publicKey"))
        ) {
            this.keyStore = new KeyStore(this._id, this.login);
            let keyId = await this.keyStore.createElGamalKey();
            this.keyStore.keys["0"] = this.keyStore.keys[keyId];
            delete(this.keyStore.keys[keyId]);
            let key = this.keyStore.getKey("0");
            key._id = "0";
            key.publicKeyString = this.data.publicCryptKeys[0].publicKey;
            return true;
        }

        return false;
    }

    get canCryptWith() {
        let k = this.keyStore;
        if (!k) {
            return false;
        }
        let ids = k.getCryptKeyIds();
        if (ids.length > 0) {
            return true;
        }
        return false;
    }

    get canTchatWith() {
        if (this.type == SHARES.USER) return true;
        return this.canCryptWith;
    }

    get displayName() {
        let displayName = this.getField("displayName", undefined);
        if (!displayName) displayName = this.getField("name", undefined);
        if (!displayName) displayName = this.getField("login", undefined);
        return displayName;
    }

    get memberOf() {
        return this.getField("memberOf", {})
    }

    get memberOfList() {
        return Object.keys(this.memberOf);
    }

    isInGroup(_id) {
        let g = this.getField("memberOf", {});
        if (g[_id]) return true;
        return false;
    }

    isInGroups(_ids) {
        for (let gId of _ids) {
            if (this.isInGroup(gId)) return true;
        }
        return false;
    }

    get name() {
        let a = this.getField("name", undefined);
        if (a) return a;
        return this.getField("login", undefined);
    }
    get login() {
        if (this.type == SHARES.USER) return this.getField("login", undefined);
        return this.displayName;
    }

    get publicCryptKeys() {
        return this.getField("publicCryptKeys", undefined)
    }

    get logged() {
        //debugger;
        let result = false;
        if (this.type != SHARES.USER) return false;
        if (!this.data.devices) return false;
        if (Object.keys(this.data.devices).length > 0) {
            Object.keys(this.data.devices).map(((devices_node) => {
                if (this.data.devices[devices_node].session) {
                    result = true;
                }
            }))
        }
        return result;
    }


    get secondAuth() {
        let result = false;
        if (this.type != SHARES.USER) return false;
        if (!this.data.devices) return false;
        if (Object.keys(this.data.devices).length > 0) {
            Object.keys(this.data.devices).map(((devices_node) => {
                if (this.data.devices[devices_node].session) {
                    if (this.data.devices[devices_node].session.secondAuth == 1) {
                        result = true;
                    }
                }
            }))
        }

        return result;
    }

    get locked() {
        if (this.type != SHARES.USER) return false;
        return this.getField("flags.locked", false);
    }

    get ready() {
        if (this.locked) return false;
        if (this.getField("flags.confirmAccount", false)) return false;
        return true;
    }


    get confirmString() {
        return this.getField("confirmString")
    }

    get validEmails() {
        if (this.type != SHARES.USER) return [];
        let list = [];
        let elist = this.getField("contacts.emails", [])
        for (let e of elist) {
            if (typeof e != 'object') continue;
            if (!e.email) continue;
            if (!e.confirmString) continue;
            list.push(e.email);
        }
        return list;
    }

    get publicKeyStore() {
        let keyStore = this.getField("keyStore.publicContent");
        // TODO (migration to keyStore )
        if (!keyStore) return undefined;
        keyStore.userId = this._id;
        return keyStore;
    }




    /*
     *
     * ██████╗ ██╗ ██████╗ ██╗  ██╗████████╗███████╗
     * ██╔══██╗██║██╔════╝ ██║  ██║╚══██╔══╝██╔════╝
     * ██████╔╝██║██║  ███╗███████║   ██║   ███████╗
     * ██╔══██╗██║██║   ██║██╔══██║   ██║   ╚════██║
     * ██║  ██║██║╚██████╔╝██║  ██║   ██║   ███████║
     * ╚═╝  ╚═╝╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝
     *
     */

    canManage() {
        return false;
    }

    canDelete() {
        return this.canManage();
    }

}