import {
    GenericKey,
    TYPE,
    STATUS,
    KEYUSAGE,
    KEYFORMAT,
} from './GenericKey.js';
import {
    ElGamal
} from '../ElGamal.js';
import {
    Share
} from './Share.js';
import {
    debug,
    error
} from './Manager.js';
import {
    SessionKey
} from './SessionKey.js';

export class ElGamalKey extends GenericKey {
    constructor(userId) {
        super(userId);
        this.name = "Generic ElGamal Key";
        this.type = TYPE.elGamal;
        this.status = STATUS.valid;
        this.usages = [KEYUSAGE.cipher, KEYUSAGE.decipher, KEYUSAGE.askWanted, KEYUSAGE.acceptWanted];

        this.elGamal = new ElGamal(error, debug);

    }

    async getContent() {
        let d = await super.getContent();
        d.publicKeyString = this.elGamal.publicKeyString;
        d.encryptedPrivateKeyStruct = this.elGamal.encryptedPrivateKeyStruct;
        return d;
    }
    async getPublicContent() {
        let d = await super.getPublicContent();
        d.publicKeyString = this.elGamal.publicKeyString;
        return d;
    }

    /**
     * Set the content of the key from a saved object
     * @param {object} d 
     */
    async setContent(d) {
        await super.setContent(d);
        if (d.publicKeyString) {
            this.elGamal.publicKeyString = d.publicKeyString;
        }
        if (d.encryptedPrivateKeyStruct) {
            this.elGamal.encryptedPrivateKeyStruct = d.encryptedPrivateKeyStruct;
        }
        return true;
    }

    /**
     * Set the content of the key from a saved object (only public par)
     * @param {object} d 
     */
    async setPublicContent(d) {
        await super.setPublicContent(d);
        if (d.publicKeyString) {
            this.elGamal.publicKeyString = d.publicKeyString;
        }
        return true;
    }

    isAvailableForDecyphering() {
        if (this.status == STATUS.ready) return true;
    }

    canDecrypt() {
        return this.privateKeyReleased;
    }
    canAcceptWanted() {
        return this.canDecrypt();
    }

    canAddWanted() {
        return true;
    }


    get group() {
        if (!this.elGamal) return undefined;
        return this.elGamal.group;
    }

    /**
     * Return the public Key.
     * Can be async due to the extraction from a device for example
     * 
     * @param {string} format 
     */
    async getPublicKey(format = undefined) {
        switch (format) {
            case KEYFORMAT.STRING:
                return this.elGamal.publicKeyString;
            case KEYFORMAT.RAW:
            default:
                return this.elGamal.publicKey;
        }
    }


    /**
     * Return true if the private key is available for decyphering, sign, ...
     */
    get privateKeyReleased() {
        if (this.elGamal.privateKey) return true;
        return false;
    }
    get privateKeyString() {
        if (!this.elGamal.privateKey) return undefined;
        return this.elGamal.privateKeyString;
    }
    set privateKeyString(pk) {
        this.elGamal.privateKeyString = pk;
    }
    set encryptedPrivateKey(struct) {
        this.elGamal.encryptedPrivateKey = struct;
    }
    set publicKeyString(pk) {
        this.elGamal.publicKeyString = pk;
    }

    /**
     * Crypt the provate Key with passphrase
     * @param {string} passphrase 
     */
    async cryptPrivateKeyWithPassphrase(passphrase) {
        if (!this.elGamal.privateKey) return false;
        let struct = await this.elGamal.cryptPrivateKeyWithPassphrase(passphrase);
        if (struct) return true;
        return false;
    }

    async dropPrivateKey() {
        this.elGamal.privateKey = undefined;
        return super.dropPrivateKey();
    }


    /**
     * Release the encrypted PrivateKey with a passphrase
     * Can be async due to the decryption from a device for example
     * @param {string} passphrase 
     * @return {boolean} true if operation OK
     */
    async releasePrivateKey(passphrase) {
        if (this.elGamal.privateKey) return true;
        if (!this.elGamal.encryptedPrivateKeyStruct) return false;
        try {
            await this.elGamal.releasePrivateKey(passphrase);
        } catch (e) {
            return false;
        }
        this.resetTimeout();
        return true;
    }


    /**
     * Generate a keys pair
     * Can be async due to the decryption from a device for example
     * @return {boolean} true if operation OK
     */
    async generate() {
        let ret = await this.elGamal.generateKeys();
        this.resetTimeout();
        return ret;
    }

    async genSessionKeyForWrap() {
        let sessionKey = await this.elGamal.genSessionKeyForWrap();
        return sessionKey;
    }

    /**
     * Revoke a key
     */
    revoke() {
        this.status = STATUS.revoked;
        //this.elGamal.publicKeyString = undefined;
        return true;
    }

    /**
     * Return true if the key is revocable
     */
    isRevocable() {
        if (this.status != STATUS.ready) return false;
        return true;
    }

    async addWanted(share) {
        if (!(share instanceof Share)) return error("Cannot add wanted a non share Structure");
        let encrypted = await this.elGamal.crypt(share.beta);
        share.addWanted(this.userId, this._id, encrypted)
        return true;
    }

    /**
     * accept a wanted 
     * 
     * @param {Share} share 
     * @param {string} userId 
     * @param {string} keyId 
     * @return {Share|boolean} a new Share or false if error.
     */
    async acceptWanted(share, userId, keyId) {
        if (!this.isShareToMe(share)) return error("This share is not to me");
        if (!this.privateKeyReleased) return error("No privateKey for surcrypt alpha");


        let wanted = share.getWanted(userId, keyId);
        if (!wanted) return error("No wanted to accept");

        let newBeta = await this.elGamal.decrypt({
            "alpha": share.alpha,
            "beta": wanted.encrypted.beta,
            "group": share.group

        });
        let s = new Share(keyId, {
            "alpha": wanted.encrypted.alpha,
            "beta": newBeta.toString(),
            "group": share.group
        }, share.params, share.expire);

        share.deleteWanted(userId, keyId);
        return s;
    }

    async crypt(m) {
        let encrypted = await this.elGamal.crypt(m);
        return new Share(this._id, encrypted);
    }

    async cipherSessionKey(sessionKey) {

        if (!(sessionKey instanceof SessionKey))
            return error("No session Key to encrypt in ElGamal");
        if (!sessionKey.elGamalSessionKey)
            return error("No elGamalSessionKey in SessionKey object");

        let encrypted = await this.elGamal.crypt(sessionKey.elGamalSessionKey)

        return new Share(this._id, encrypted, sessionKey.symCrypt.key.params);
    }

    async decipherSessionKey(share) {
        if (!this.isShareToMe(share)) return false;
        if (!this.privateKeyReleased) return error("No privateKey for deciphering");

        debug("lybcrypt - decipherSessionKey", share);

        if (share.params && share.params.hash && share.params.iteration && share.params.keylen && share.params.salt) {
            debug("lybcrypt - decipherSessionKey with wrap ");

            let sessionKey = new SessionKey();
            sessionKey.elGamalSessionKey = await this.elGamal.decrypt(share.encrypted, "BigInteger");
            if (!sessionKey.elGamalSessionKey) return error("Unable to decrypt session key with elgamal");

            await sessionKey.symCrypt.key.wrapKeyFromPassphrase(
                sessionKey.elGamalSessionKey.toString(),
                share.params.keylen, share.params.salt, share.params.iteration, share.params.hash);
            sessionKey.elGamalGroup = this.elGamal.group;

            return sessionKey;
        }

        debug("lybcrypt - decipherSessionKey with RSA_OAEP ");

        let keyRSA_OAEP = await this.elGamal.decrypt(share.encrypted, "BigInteger");
        if (!keyRSA_OAEP) return error("Error RSA_OAEP");

        let sessionKey = new SessionKey();

        let s = await sessionKey.symCrypt.unpadRSA_OAEP(keyRSA_OAEP, this.elGamal.P);
        if (!s) return error("Error RSA_OAEP Padding");

        await sessionKey.setKey(keyRSA_OAEP, s, this.elGamal.group);
        return sessionKey;
    }

}