/* eslint-disable no-unused-vars */

import React from 'react';
import { MyComponent } from './MyComponent.jsx';
//import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { initLanguage, setLocalLang, translate } from '../../../SharedLibs/Langs.js';
import { hexToHSL, objectGet } from '../../../SharedLibs/Tools.js';
import { ROOTDIR_ID, DEFAULT_THEME } from '../../../SharedLibs/Constants.js';
//import { defaultConfig } from "../../../server/src/stores/Libs/Config.js";
import ErrorDisplay from './M1/Lib/ErrorDisplay.jsx';
import SnackDisplay from './M1/Lib/SnackDisplay.jsx';
import ConfirmDisplay from './M1/Lib/ConfirmDisplay.jsx';
import InfoDisplay from './M1/Lib/InfoDisplay.jsx';
import AskDisplay from './M1/Lib/AskDisplay.jsx';

import { configStore } from '../stores/ConfigStore';
import { accountStore } from '../stores/AccountStore';
import { friendsStore } from '../stores/FriendsStore';

import Paper from '@material-ui/core/Paper';
import { SvgIcon, Typography, withStyles } from '@material-ui/core';
import { IconManager } from './M1/ReactToolBox/IconManager.jsx';
import {
    manager
//} from '@lybero/lybcrypt';
//} from "../../../../lybcrypt/lib/main.js";
} from "../../../SharedLibs/lybcrypt/lib/main.js";

import Loadable from 'react-loadable';
import Loading from './M1/Lib/Loading.jsx';
import { setDebug } from '../../../SharedLibs/DebugObj';

const Console = Loadable({
    loader: () => import('./M1/Lib/Console.jsx'),
    loading(props) {
        return (<Loading message={translate('Loading Console')}  {...props} />);
    }
});

const Logged = Loadable({
    loader: () => import('./Logged.jsx'),
    loading(props) {
        return (<Loading message={translate('Loading content')} {...props} />);
    },
});

const NotLogged = Loadable({
    loader: () => import('./NotLogged.jsx'),
    loading(props) {
        return (<Loading message={translate('Loading login')}  {...props} />);
    }
});


let Clipboard = require('clipboard');

const specificStyles = () => ({
    img64: {
        width: '64px',
        height: '64px',
        '& span': {
            '& svg': {
                width: '64px',
                height: '64px',
            }
        },
    },
    '@global': {
        body: {
            margin: 0,
            paddingRight: 0,
            display: 'flex',
            height: '100%',
            width: '100%',
            backgroundImage: "url('/wallpaper')",
            backgroundSize: 'auto',
            backgroundRepeat: 'repeat',
        }
    }
});

export const GLOBALPROPS = PropTypes.shape({
    "logged": PropTypes.bool,
    "lastPlace": PropTypes.string,
    "linkTo": PropTypes.func,
    "logout": PropTypes.func,
    "registerFunction": PropTypes.func,
    "callFunction": PropTypes.func
});

class GlobalApp extends MyComponent {

    constructor(props) {
        super(props);

        // --- Set the debug names--
        this.deb.setNames('globalapp');

        this.onChange = this.onChange.bind(this);
        this.linkTo = this.linkTo.bind(this);
        this.initialisation = this.initialisation.bind(this);
        this.getTheme = this.getTheme.bind(this);
        this.registerFunction = this.registerFunction.bind(this);
        this.callFunction = this.callFunction.bind(this);
        this.clientConfigLoaded = this.clientConfigLoaded.bind(this);
        this.getRegisteredFunctions = this.getRegisteredFunctions.bind(this);
        this.eventFordebug = this.eventFordebug.bind(this);

        new Clipboard('.copyButton');

        this.state = {
            configLoaded: false,
            configLoadError: false,
            logged: false,
            dark: false,
            lastPlace: undefined,
            console: false,
            tempTheme: undefined,
            debug: false,
            urlParams: new URLSearchParams(props.location.search),
            search: props.location.search,
        };

        this.state.defaultTheme = {
            primary: {
                main: '#1b75bc'//blue
            },
            secondary: {
                main: '#f50057'//pink
            },
            others: {}
        };


        this.functionsRegistered = {}

        this.waitForTimeoutForDisplayMessage = false;

    }

    // --- The Url has change, must redraw
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.location.search !== prevState.search) {
            return ({ urlParams: new URLSearchParams(nextProps.location.search), search: nextProps.location.search })
        }
        return null;
    }



    /*darkOrLight(isDark) {
        if (isDark) return this.setState({ "dark": true });
        return this.setState({ "dark": false });
    }*/

    enlightColor(hslColor, amount) {
        let lightColor = { ...hslColor };
        let l = lightColor.l;
        if ((l + amount) > 1) {
            lightColor.l = 1
        } else {
            lightColor.l = l + amount
        }
        return lightColor
    }

    darkenColor(hslColor, amount) {
        let darkColor = { ...hslColor };
        let l = darkColor.l;
        if ((l - amount) < 0) {
            darkColor.l = 0
        } else {
            darkColor.l = l - amount
        }
        return darkColor
    }

    getInThemeOrDefault(theme, path) {
        let valInTheme = objectGet(theme, path);
        if (valInTheme) return valInTheme;
        return objectGet(DEFAULT_THEME, path)
    }

    getTheme(theme) {

        let paramTheme = this.state.urlParams.get('theme');

        if (paramTheme) {
            this.log(`actual temp theme : `, { themeName: paramTheme, themeContent: theme })
        }

        let fontsMainColor = this.getInThemeOrDefault(theme, "fonts.mainColor");
        let iconsRadiuses = this.getInThemeOrDefault(theme, "radiuses.icons");

        //let primaryColorConvertedToHSL = hexToHSL(theme.primary.main);
        let mainTextHSL = hexToHSL(fontsMainColor);

        let hoverMainText = mainTextHSL.l > 0.8 ? this.darkenColor(mainTextHSL, 0.1) : this.enlightColor(mainTextHSL, 0.4);
        let activeMainText = mainTextHSL.l > 0.8 ? this.darkenColor(mainTextHSL, 0.2) : this.enlightColor(mainTextHSL, 0.4);

        let thatTheme = {
            palette: {
                type: this.state.dark ? 'dark' : 'light',
                primary: this.getInThemeOrDefault(theme, "primary"),
                secondary: this.getInThemeOrDefault(theme, "secondary"),
                error: this.getInThemeOrDefault(theme, "error"),
                selectedTabs: this.getInThemeOrDefault(theme, "others.selectedTabs"),
                subHeader: this.getInThemeOrDefault(theme, "others.subHeader"),
                subHeaderText: this.getInThemeOrDefault(theme, "others.subHeaderText"),
                selectedItems: this.getInThemeOrDefault(theme, "others.selectedItems"),
                menuIcons: this.getInThemeOrDefault(theme, "others.menuIcons"),
                /* config: {
                     light: 'hsl(' + primaryColorConvertedToHSL.h + ',' + primaryColorConvertedToHSL.s + '%,85%)',
                     dark: 'hsl(' + primaryColorConvertedToHSL.h + ',' + primaryColorConvertedToHSL.s + '%,50%)'
                 },*/
                background: this.getInThemeOrDefault(theme, "background"),
                fonts: {
                    main: fontsMainColor
                },
                dialogHeader: this.getInThemeOrDefault(theme, "others.dialogHeader"),
                dialogHeaderContrastText: this.getInThemeOrDefault(theme, "others.dialogHeaderText"),
                speedDial: {
                    background: this.getInThemeOrDefault(theme, "speedDial.speedDialBackground"),
                    backgroundHover: this.getInThemeOrDefault(theme, "speedDial.speedDialBackgroundHover"),
                    actionBackground: this.getInThemeOrDefault(theme, "speedDial.speedDialActionBackground"),
                    icons: this.getInThemeOrDefault(theme, "speedDial.speedDialIcons"),
                    mainIcon: this.getInThemeOrDefault(theme, "speedDial.speedDialMainIcon"),
                },
                tips: {
                    background: this.getInThemeOrDefault(theme, "others.tipsBackground"),
                    text: this.getInThemeOrDefault(theme, "others.tipsText"),
                }


            },
            shape: {
                borderRadius: this.getInThemeOrDefault(theme, "radiuses.buttons")
            },
            iconRadius: iconsRadiuses,
            typography: {
                useNextVariants: true,
                fontFamily: this.getInThemeOrDefault(theme, "fonts.customFont")
            },
            overrides: {
                MuiTableCell: {
                    body: {
                        color: `${fontsMainColor} !important`
                    },
                    head: {
                        color: fontsMainColor
                    }
                },
                MuiTableSortLabel: {
                    root: {
                        '&:hover': {
                            color: `hsl(${hoverMainText.h}, ${hoverMainText.s * 100}%, ${hoverMainText.l * 100}%)`
                        }
                    },
                    active: {
                        color: `hsl(${activeMainText.h}, ${activeMainText.s * 100}%, ${activeMainText.l * 100}%)`
                    }
                },
                MuiTypography: {
                    body2: {
                        color: fontsMainColor
                    },
                    caption: {
                        color: fontsMainColor

                    }
                },
                MuiButton: {
                    root: {
                        color: fontsMainColor
                    }
                },

                MuiDialogTitle: { // Name of the component ⚛️ / style sheet
                    root: { // Name of the rule
                        backgroundColor: this.getInThemeOrDefault(theme, "others.dialogHeader"), // Some CSS
                        padding: "24px 24px 10px",
                        marginBottom: "10px",
                        '& h6': {
                            color: this.getInThemeOrDefault(theme, "others.dialogHeaderText")
                        }
                    }
                },
                MuiFab: {
                    root: {
                        borderRadius: iconsRadiuses
                    },
                    primary: {
                        backgroundColor: this.getInThemeOrDefault(theme, "primary.main"),
                        color: this.getInThemeOrDefault(theme, "primary.contrastText"),
                        '&:hover': {
                            backgroundColor: this.getInThemeOrDefault(theme, "primary.dark")
                        }
                    },
                    label: {
                        padding: '5px 5px 5px 5px'
                    }
                },
                MuiAvatar: {
                    root: {
                        borderRadius: iconsRadiuses
                    }
                },
                MuiTab: {
                    root: {
                        lineHeight: 'none'
                    }
                },
                MuiTooltip: {
                    tooltip: {
                        backgroundColor: this.getInThemeOrDefault(theme, "speedDial.speedDialBackground"),
                        color: this.getInThemeOrDefault(theme, "speedDial.speedDialMainIcon"),
                    }
                }
            }
        };

        return createMuiTheme(thatTheme);
    }


    registerFunction(name, func) {
        this.log(`registerFunction`, { name, func });
        this.functionsRegistered[name] = func;

    }

    callFunction(name, ...args) {
        if (!this.functionsRegistered[name]) {
            this.log(`callFunction without function defined`, { name, args });
            //console.log(`callFunction without function defined`, { name, args });
            return undefined
        }
        return this.functionsRegistered[name](...args);
    }

    removeFunction(name) {
        if (this.functionsRegistered[name]) {
            delete this.functionsRegistered[name]
        }
    }

    getRegisteredFunctions() {
        return this.functionsRegistered
    }

    eventFordebug(event, target) {

        // --- get target parent Id
        let ids = [];
        while (target) {
            if (target.id) {
                let name = target.getAttribute('name') ? `(name="${target.getAttribute('name')}")` : '';
                ids.push(`"#${target.id}"${name}`)
            }
            target = target.parentNode
        }

        if (ids.length > 0) {
            console.log(`Event> ${event} on ${ids.join(' < ')}`)
        }
    }

    async componentDidMount() {
        var evet = this.eventFordebug;

        if (this.state.urlParams.has('event')) {
            document.addEventListener('click', function (e) {
                e = e || window.event;
                return evet('click', e.target);
            }, false);
            document.addEventListener('keypress', function (e) {
                e = e || window.event;
                return evet('keypress', e.target);
            }, false);
            document.addEventListener('contextmenu', function (e) {
                e = e || window.event;
                return evet('contextmenu', e.target);
            }, false);
            document.addEventListener('mouseenter', function (e) {
                e = e || window.event;
                return evet('mouseenter', e.target);
            }, false);
        }

        // --- Set getPublickKeyStores early due to the ask of quorum keyStore at logon
        manager.setDebug(false);
        manager.setLogFunc(() => {
        });
        manager.setGetPublicKeyStores(friendsStore.getPublicKeyStores);
        manager.setSaveKeyStoreFunc(accountStore.saveKeyStore);
        // --- Set getPublickKeyStores early due to the ask of quorum keyStore at logon


        accountStore.addChangeListener(this.onChange);
        configStore.addChangeListener(this.clientConfigLoaded);

        this.initialisation();
        let r = await configStore.getClientConfig()
            .catch((e) => {
                console.error("error while download config", e);
                this.setState({ 'configLoadError': true })
            });
        if (r === false) {
            return false;
        }

        // --- Check for already logged ( the page was reloaded ) ---
        await accountStore.checkForAlreadyLogged()
            .catch((e) => {
            });


        this.waitForTimeoutForDisplayMessage = true;
        // Am I logged already ?
        let urlParams = this.state.urlParams;
        if (accountStore.logged) {
            // I am logged
            let message = urlParams.get('message');
            if (message === 'emailOk') {
                if (urlParams.get('password')) {
                    // OK, I can put under escrow the password
                    this.deb.start("GlobalApp.jsx - componentDidMount");
                    this.deb.log("GlobalApp.jsx - componentDidMount - OK - ready to escrow the password");
                    this.deb.stop("GlobalApp.jsx - componentDidMount - GlobalApp.jsx");
                }
            }
        }

        setTimeout(() => { return this.displayInfoMessage() }, 500);

        return this.clientConfigLoaded();
    }

    componentDidCatch(error, info) {
        // console.error('got an error from component', error);
        // console.error('got an error from component errorInfo', info);
        if (this.functionsRegistered['error']) {
            return this.callFunction('error', error)
        }
        return this.setState({ 'error': error })
    }


    componentDidUpdate() {

        if (this.waitForTimeoutForDisplayMessage === false) {
            this.waitForTimeoutForDisplayMessage = true;
            // Am I logged already ?
            let urlParams = this.state.urlParams;
            if (accountStore.logged) {
                // I am logged
                let message = urlParams.get('message');
                if (message === 'emailOk') {
                    if (urlParams.get('password')) {
                        // OK, I can put under escrow the password
                        this.deb.start("GlobalApp.jsx - componentDidUpdate");
                        this.deb.log("GlobalApp.jsx - componentDidUpdate - OK - ready to escrow the password");
                        this.deb.stop("GlobalApp.jsx - componentDidUpdate - GlobalApp.jsx");
                    }
                }
            }
            setTimeout(() => { return this.displayInfoMessage() }, 500);
        }
    }


    componentWillUnmount() {
        manager.setDebug(false);
        manager.setGetPublicKeyStores(undefined);
        manager.setSaveKeyStoreFunc(undefined);
        accountStore.removeChangeListener(this.onChange);

    }

    getLangFromEnv() {
        if (navigator) {
            const userLang = this.state.urlParams.get('lang') || navigator.language || navigator.userLanguage;
            setLocalLang(userLang);
        }
    }

    initialisation() {
        initLanguage();
        this.getLangFromEnv();
    }


    onChange() {
        if (accountStore.logged !== this.state.logged) {
            return this.setState({ "logged": accountStore.logged });
        }
    }

    /**
     * The configuration is set in its store.
     */
    clientConfigLoaded() {
        let theme;
        let tempTheme = this.state.urlParams.get('theme');
        if (tempTheme) {
            theme = configStore.getTheme(tempTheme)
        } else {
            theme = configStore.getTheme(configStore.defaultTheme);
            if (theme) {
                if (!theme.primary || !theme.secondary) theme = undefined; // theme can't be loaded, too old
            }
        }

        if (!theme) return this.setState({ 'configLoaded': true });
        return this.setState({ 'configLoaded': true, defaultTheme: theme });
    }

    changeWindowTabTitle() {
        let title = configStore.appName;
        if (accountStore.logged) title += "-" + accountStore.loginName;

        if (typeof window === 'undefined') return false;
        if (!window.parent) return false;
        if (!window.parent.document) return false;
        window.parent.document.title = title;
        return true;
    }

    setLastPlaceNotChatNotif(url) {
        let regexpNotif = new RegExp('/lynvictus/notifications');
        let regexpTchat = new RegExp('/lynvictus/thread');

        if (!url.match(regexpTchat)) {
            if (!url.match(regexpNotif)) {
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.lastPlace = url
            }
        }
    }

    linkTo(href) {
        if (href === "?") return this.props.history;
        if (href === "back") return this.props.history.goBack();


        if (!href) return this.props.history.push('/?' + this.state.urlParams.toString());

        // --- re-add all previous search string if not in the href ---
        if (href.match(/\?/)) {
            return this.props.history.push(href);
        }

        //console.log(`GlobalApp linkTo ${this.state.urlParams.toString()}`);

        return this.props.history.push(href + '?' + this.state.urlParams.toString());
    }

    displayInfoMessage() {

        this.waitForTimeoutForDisplayMessage = false;
        let urlParams = this.state.urlParams;


        // --- Render Help ---
        if (urlParams.has('help')) {
            urlParams.delete('help');
            return this.callFunction('info', translate('Help'),
                [
                    { text: 'You can add some URL params such as :' },
                    { hr: true },
                    { text: "event=true to display all events in console" },
                    { text: "maintainer=true to bypass maintenance mode" },
                    { text: "theme=[theme name] to preview theme without changing config" },
                    { text: "console=true to get the buildin console" },
                    { text: "debug=xxx for debug" },
                ]
            )
        }

        // --- Check if maintenance ---
        let maintainer = urlParams.get('maintainer');
        if (this.isInMaintenanceMode(maintainer)) {
            return this.callFunction('info', translate("Maintenance"),
                [
                    { br: true },
                    { icon: 'cafe' },
                    { text: configStore.maintenance.maintenanceMessage },
                    { hr: true },
                    {
                        text: translate("Maintenance end",
                            { endDate: new Date(parseInt(configStore.maintenance.maintenanceEnd) + 7200000).toString() })
                    }
                ]
            )
        }

        // --- Not the right browser ---
        if (!this.compatBrowser(window.navigator.userAgent)) {
            return this.callFunction('info', translate("Error"),
                [
                    { br: true },
                    { icon: 'rights' },
                    { br: true },
                    { text: translate("browser error") },
                    { hr: true },
                    { text: translate("Please use another browser") }
                ]
            )
        }

        let message = urlParams.get('message');
        if (message === 'emailOk') {
            urlParams.delete('message');
            let text = [
                { br: true },
                { icon: 'verified' },
                { text: translate("Your account is validated") },
            ]

            if (urlParams.get('password')) {
                text.push({ text: translate("You are automaticaly logged") });
                /* this.deb.start();
                this.deb.log("GlobalApp.jsx - DisplayInfoMessage - accountStore ? ",accountStore);
                this.deb.stop(); */
            } else {
                text.push({ text: translate("You can now log on with your password") });
            }
            text.push({ hr: true });
            text.push({ text: translate("Welcome") });

            return this.callFunction('info', translate("Congratulation"), text)
        }

        if (message === 'emailError') {
            urlParams.delete('message');
            return this.callFunction('info', translate("Error"),
                [
                    { br: true },
                    { icon: 'error' },
                    { text: translate("Your email cannot be validate !") },
                ]
            )
        }

        if (message === 'invit') {

            urlParams.delete('message');
            if (urlParams.has('password')) {
                return this.callFunction('info', translate("Welcome"),
                    [
                        { appLogo: 1 },
                        { text: translate("thank you to join us") },
                        { hr: 1 },
                        { body1: translate("You must change for a new personal password first") },
                    ]
                );
            } else {
                return this.callFunction('info', translate("Welcome"),
                    [
                        { appLogo: 1 },
                        { text: translate("thank you to join us") },
                        { hr: 1 },
                        { body1: translate("You must enter the temporary password.") },
                        { body1: translate("Then you will be redirected to changing it for a personnal one") },
                    ]
                );
            }
        }


    }

    isInMaintenanceMode(maintainer) {
        if (maintainer) return false;
        let maintenance = configStore.maintenance;
        if (!maintenance) return false;

        let modeEnabled = maintenance.maintenanceMode;
        if (!modeEnabled) return false;

        let startTime = maintenance.maintenanceStart;
        let endTime = maintenance.maintenanceEnd;
        let now = Date.now();

        if (startTime > now) return false;
        if (now > endTime) return false;

        return true;

    }

    compatBrowser(userAgent) {
        if (userAgent.match("edge")) return false
        if (userAgent.match("Trident")) return false
        return true
    }

    render() {
        // --- Drop the _pathname from query and change it to and change it to the path 
        if (this.state.urlParams.has('_pathname')) {
            let redirect = this.state.urlParams.get('_pathname');
            this.state.urlParams.delete('_pathname');
            redirect + '?' + this.state.urlParams.toString()
            return (<Redirect to={redirect + '?' + this.state.urlParams.toString()} />);
        }
        // --- Drop the _pathname from query and change it to and change it to the path 


        this.changeWindowTabTitle();

        // --- Set the debug ---
        let debugParam = this.state.urlParams.get('debug');
        if (debugParam) {
            setDebug(debugParam);
        }


        // ---- ClientConfig Not loaded ---
        if (this.state.configLoaded === false) {
            return (<Loading message={translate("Loading application")} />)
        }


        let privateConsole = this.state.urlParams.has('console');
        let muiTheme = this.getTheme(this.state.defaultTheme);

        let globalProps = {
            "linkTo": this.linkTo,
            "urlPP": this.state.urlParams,
            "path": this.props.location.pathname ? this.props.location.pathname : '/',
            "logged": this.state.logged,
            "lastPlace": this.state.lastPlace,
            "registerFunction": this.registerFunction,
            "callFunction": this.callFunction,
            "registeredFunctions": this.functionsRegistered
        };

        // render error on components
        if (this.state.error) {
            return (
                <ErrorDisplay
                    global={globalProps}
                    error={this.state.error}
                />
            )
        }

        return (
            <MuiThemeProvider theme={muiTheme}>
                {
                    accountStore.logged ?
                        <Logged key="logged" global={globalProps} />
                        :
                        <NotLogged key="notlogged" global={globalProps} />
                }
                {
                    privateConsole ?
                        <Console
                            key="console"
                            params={this.state.console}
                        />
                        :
                        ""
                }
                <ErrorDisplay
                    global={globalProps}
                />
                <SnackDisplay
                    global={globalProps}
                />
                <ConfirmDisplay
                    global={globalProps}
                />
                <InfoDisplay
                    global={globalProps}
                />
                <AskDisplay
                    global={globalProps}
                />
            </MuiThemeProvider>
        );
    }


}

GlobalApp.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.node
    ]),
    classes: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
};


export default withRouter(withStyles(specificStyles)(GlobalApp));
