import { 
    GenericKey,
    TYPE,
    STATUS,
    KEYUSAGE,
    KEYFORMAT,
} from './GenericKey.js';
import { 
    ElGamal
} from '../ElGamal.js';
import { 
    Share
} from './Share.js';
import {
    debug,
    error
} from './Manager.js';

export class MemberKey extends GenericKey {
    constructor(userId) {
        super(userId);
        this.name = "Generic Quorum Member Key";
        this.type = TYPE.quorumMember;
        this.usages = [ KEYUSAGE.decipher, KEYUSAGE.acceptWanted ];
        this.quorum = 0;
        this.max = 0;
        this.myRank = 0;
        this.elGamal = new ElGamal( error, debug );
    }

    async getContent() {
        let d = await super.getContent();
        d.quorum = this.quorum;
        d.max = this.max;
        d.quorumUserId = this.quorumUserId;
        d.quorumKeyId = this.quorumKeyId;
        d.myRank = this.myRank;
        d.ranksForAdmins = {};

        for (let rank in this.ranksForAdmins) {
            d.ranksForAdmins[rank] = this.ranksForAdmins[rank];
        }

        d.publicKeyString = this.elGamal.publicKeyString;
        d.encryptedSecretQuorumKeyStruct = this.elGamal.encryptedSecretQuorumKeyStruct;

        return d;
    }

    /**
     * Set the content of the key from a saved object
     * @param {object} d 
     */
    async setContent(d) {

        await super.setContent(d);
        this.max = d.max;
        this.quorum = d.quorum;
        this.quorumUserId = d.quorumUserId;
        this.quorumKeyId = d.quorumKeyId;
        this.myRank = d.myRank;

        this.ranksForAdmins = {};

        for (let rank in d.ranksForAdmins) {
            this.ranksForAdmins[rank] = d.ranksForAdmins[rank];
        }

        if (d.publicKeyString) {
            this.elGamal.publicKeyString = d.publicKeyString;
        }

        if (d.encryptedSecretQuorumKeyStruct) {
            this.elGamal.encryptedSecretQuorumKeyStruct = d.encryptedSecretQuorumKeyStruct;
        }

        return true;
    }

    async del( keyStore ) {
        if ( this.status == STATUS.deleted ) return true;
        await super.del( keyStore );
        return true;
    }
    
    canDecrypt() {
        return false;
    }

    canAcceptWanted() {
        if (this.status != STATUS.ready) return false;
        return this.privateKeyReleased;
    }

    /**
     * Return the public Key.
     * Can be async due to the extraction from a device for example
     * 
     * @param {string} format 
     */
    async getPublicKey(format = undefined) {
        return undefined;
    }

    /**
     * initialize the quorum key with identified 
     * members
     * 
     * @param {QumrumKey} quorumKey 
     */
    async create(quorumKey) {
        this.status = STATUS.sharing;
        this.quorum = quorumKey.quorum;
        this.max = quorumKey.max;
        this.quorumUserId = quorumKey.userId;
        this.quorumKeyId = quorumKey._id;
        this.ranksForAdmins = quorumKey.ranksForAdmins;

        // ------ Compute my rank -------------------------------
        for (let rank in this.ranksForAdmins) {
            if (this.ranksForAdmins[rank] == this.userId) {
                this.myRank = rank;
                break;
            }
        }
        if (!this.myRank) return error("I'm not an admin for this quorum " + this.quorumKeyId);
        return true;
    }

    isMemberOf(userId, quorumKeyId) {
        if (( this.quorumUserId == userId ) && (this.quorumKeyId == quorumKeyId )) return true;
        return false;
    }

    async getInfos() {
        let d = await super.getInfos();
        d.myRank = this.myRank,
        d.quorum = this.quorum;
        d.quorumUserId = this.quorumUserId;
        d.quorumKeyId = this.quorumKeyId;
        return d;
    }
    
    get quorumInfos() {
        let quorumInfos={
            type : this.type,
            myRank : this.myRank,
            status : this.status,
            admins : {}
        };

        for (let rank in this.ranksForAdmins) {
            quorumInfos.admins[this.ranksForAdmins[rank]] = rank;
        }
        
        return quorumInfos;
    }


    /**
     * Return the public Key.
     * Can be async due to the extraction from a device for example
     * 
     * @param {string} format 
     */
    async getPublicKey(format = undefined) {
        switch (format) {
            case KEYFORMAT.STRING:
                return this.elGamal.publicKeyString;
            case KEYFORMAT.RAW:
            default:
                return this.elGamal.publicKey;
        }
    }

    /**
     * 
     * do the initilaisation phase 1 and share information
     * with the other members
     * 
     * @param {{}} keyStores { user1 : keyStore1, user2 : keyStore2  }
     * @returns {shareKeys[]} secretSharesList - a list of secret shareKeys between admins
     */
    async initAndShare(keyStores) {
        this.elGamal.quorumInitPolynome(this.max, this.quorum);
        let secretSharesList = {};
        for (let x = 1; x <= this.max; x++) {
            let userId = this.ranksForAdmins[x];
            if (!userId) return error("initAndShare: Rank out of range");

            let keyStore = keyStores[userId];
            if (!keyStore) return error("initAndShare : No keystore given for user " + userId);

            let d = {
                "secretShare": this.elGamal.quorumGetAdminSecretShareString(x),
                "publicPart": this.elGamal.quorumMyPublicPartString
            }

            let shareKeys = await keyStore.crypt(d);
            if (!shareKeys) return error("initAndShare : Error in crypt for user " + x);

            secretSharesList[x] = shareKeys;
        }
        // set the status to say i have done my initAndShare
        this.status = STATUS.shared;
        return secretSharesList;
    }

    async computeMySecret(publicPart, secretShare) {
        this.elGamal.quorumAddToMySecret(secretShare);
        this.elGamal.quorumAddToPublicKey(publicPart);
        this.status = STATUS.ready;
        return true;
    }


    /**
     * Return true if the private key is available for decyphering, sign, ...
     */
    get privateKeyReleased() {
        if (this.elGamal.quorumMySecretKeyString != "0") return true;
        return false;
    }
    get privateKeyString() {
        if (this.elGamal.quorumMySecretKeyString == "0") return undefined;
        return this.elGamal.quorumMySecretKeyString;
    }
    set privateKeyString(pk) {
        this.elGamal.quorumMySecretKeyString = pk;
    }

    async dropPrivateKey() {
        this.elGamal.quorumMySecretKeyString = "0";        
        return super.dropPrivateKey();
    }

    /**
     * Crypt the private Key with passphrase
     * @param {string} passphrase 
     */
    async cryptPrivateKeyWithPassphrase(passphrase) {
        if (this.elGamal.quorumMySecretKeyString == "0") return false;
        let struct = await this.elGamal.cryptSecretQuorumKeyWithPassphrase(passphrase);
        if (struct) return true;
        return false;
    }


    /**
     * Release the encrypted PrivateKey with a passphrase
     * Can be async due to the decryption from a device for example
     * @param {string} passphrase 
     * @return {boolean} true if operation OK
     */
    async releasePrivateKey(passphrase) {
        if (this.privateKeyReleased) return true;
        if ( ! this.elGamal.encryptedSecretQuorumKeyStruct ) return false;
        try {
            await this.elGamal.releaseSecretQuorumKey(passphrase);
        } catch (e) {
            return false;
        }
        this.resetTimeout();
        return true;
    }

    /**
     * accept a wanted 
     * 
     * @param {Share} share 
     * @param {string} userId 
     * @param {string} keyId 
     * @return {Share|boolean} a new Share or false if error or true if in progress (and share is updated).
     */
    async acceptWanted(share, userId, keyId) {

        if (!(share instanceof Share)) return error("acceptWanted not instance of Share");
        if (!this.privateKeyReleased) return error("No privateKey for surcrypt alpha");

        let wanted = share.getWanted(userId, keyId);
        if (!wanted) return false;

        let c = this.elGamal.quorumSurcryptAlpha(share.encrypted); //wanted.encrypted );
        if (!c) return error("unable to surcrypt alpha");

        if (!wanted.surCrypt) wanted.surCrypt = {};
        wanted.surCrypt[this.myRank] = c;

        // ---- Quorum not raised ----
        if (Object.keys(wanted.surCrypt).length < this.quorum) return true;

        // ---- Quorum raised !----
        let newBeta = await this.elGamal.quorumDecrypt(wanted.encrypted, wanted.surCrypt);

        let s = new Share(keyId, {
            "alpha": wanted.encrypted.alpha,
            "beta": newBeta.toString(),
            "group": share.group
        }, share.params, share.expire);

        share.deleteWanted(userId, keyId);
        return s;
    }

    get memberIds() {
        let a = [];
        for (let rank in this.ranksForAdmins) {
            a.push ( this.ranksForAdmins[rank] );
        }
        return a;
    }

    /**
     * Generate a keys pair
     * Can be async due to the decryption from a device for example
     * @return {boolean} true if operation OK
     */
    async generate() {
        return true;
    }

    async cipherSessionKey() {
        return error("Cannot encrypt with a Quorum member key");
    }

    async decipherSessionKey(encrypted) {
        return error("Cannot directly decrypt with a Quorum member key");

    }

}