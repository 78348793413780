import common from '@material-ui/core/colors/common';
import cyan from '@material-ui/core/colors/cyan';
import grey from '@material-ui/core/colors/grey';
import pink from '@material-ui/core/colors/pink';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionAccountCircle from '@material-ui/icons/AccountCircle';
import ContentAdd from '@material-ui/icons/Add';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import NavigationApps from '@material-ui/icons/Apps';
import NavigationBack from '@material-ui/icons/ArrowBack.js';
import NavigationArrowDropDown from '@material-ui/icons/ArrowDropDown.js';
import NavigationForward from '@material-ui/icons/ArrowForward.js';
import ArrowDropRight from '@material-ui/icons/ArrowRight';
import ActionAssignment from '@material-ui/icons/Assignment.js';
import ActionAutorenew from '@material-ui/icons/Autorenew.js';
import ActionBookmark from '@material-ui/icons/Bookmark';
import ActionBuild from '@material-ui/icons/Build';
import NavigationCancel from '@material-ui/icons/Cancel';
import CommunicationChat from '@material-ui/icons/Chat';
import ActionCheckCircle from '@material-ui/icons/CheckCircle.js';
import NavigationChevronLeft from '@material-ui/icons/ChevronLeft';
import NavigationChevronRight from '@material-ui/icons/ChevronRight';
import ContentClear from '@material-ui/icons/Clear.js';
import NavigationClose from '@material-ui/icons/Close';
import FileCloudDownload from '@material-ui/icons/CloudDownload';
import FileCloudUpload from '@material-ui/icons/CloudUpload';
import ContactMail from '@material-ui/icons/ContactMail.js';
import FileCreateNewFolder from '@material-ui/icons/CreateNewFolder';
import ActionDeleteForever from '@material-ui/icons/DeleteForever';
import ActionDescription from '@material-ui/icons/Description';
import Devices from '@material-ui/icons/Devices';
import ActionDone from '@material-ui/icons/Done';
import ActionDoneAll from '@material-ui/icons/DoneAll';
import EditorModeEdit from '@material-ui/icons/Edit';
import CommunicationEmail from '@material-ui/icons/Email';
import Error from '@material-ui/icons/Error';
import ActionEvent from '@material-ui/icons/Event';
import AvFastForward from '@material-ui/icons/FastForward';
import AvFastRewind from '@material-ui/icons/FastRewind';
import ContentCopy from '@material-ui/icons/FileCopy';
import FolderOpen from '@material-ui/icons/FolderOpen.js';
import SocialGroup from '@material-ui/icons/Group';
import SocialGroupAdd from '@material-ui/icons/GroupAdd';
import ActionGroupWork from '@material-ui/icons/GroupWork';
import ActionHelp from '@material-ui/icons/Help';
import ActionHelpOutline from '@material-ui/icons/HelpOutlined';
import ActionHome from '@material-ui/icons/Home';
import HowToVote from '@material-ui/icons/HowToVote.js';
import ActionInfo from '@material-ui/icons/Info';
import File from '@material-ui/icons/InsertDriveFile';
import ActionInvertColors from '@material-ui/icons/InvertColors.js';
import HardwareKeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import HardwareKeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import ActionList from '@material-ui/icons/List';
import LocalSee from '@material-ui/icons/LocalSee.js';
import CommunicationLocationOff from '@material-ui/icons/LocationOff.js';
import CommunicationLocationOn from '@material-ui/icons/LocationOn.js';
import ActionLock from '@material-ui/icons/Lock';
import ActionLockOpen from '@material-ui/icons/LockOpen';
import ActionLockOutline from '@material-ui/icons/LockOutlined';
import IconMenu from '@material-ui/icons/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MusicNote from '@material-ui/icons/MusicNote.js';
import AvNewReleases from '@material-ui/icons/NewReleases';
import { default as ActionNoteAdd, default as UploadNewFile } from '@material-ui/icons/NoteAdd';
import SocialNotifications from '@material-ui/icons/Notifications';
import NotInterested from '@material-ui/icons/NotInterested.js';
import OndemandVideo from '@material-ui/icons/OndemandVideo.js';
import ActionPanTool from '@material-ui/icons/PanTool';
import SocialPerson from '@material-ui/icons/Person';
import SocialPersonAdd from '@material-ui/icons/PersonAdd';
import SocialPersonOutline from '@material-ui/icons/PersonOutline';
import ContentRedo from '@material-ui/icons/Redo';
import ImageEye from '@material-ui/icons/RemoveRedEye.js';
import ContentSave from '@material-ui/icons/Save';
import ActionSearch from '@material-ui/icons/Search';
import ContentSend from '@material-ui/icons/Send';
import ActionSettings from '@material-ui/icons/Settings';
import ActionSettingsInputComponent from '@material-ui/icons/SettingsInputComponent';
import SocialShare from '@material-ui/icons/Share';
import EditorShowChart from '@material-ui/icons/ShowChart';
import AvSkipNext from '@material-ui/icons/SkipNext';
import AvSkipPrevious from '@material-ui/icons/SkipPrevious';
import AvSort from '@material-ui/icons/SortByAlpha';
import Star from '@material-ui/icons/Star.js';
import StartEmpty from '@material-ui/icons/StarBorder.js';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle.js';
import ImageTimerOff from '@material-ui/icons/TimerOff.js';
import DashboardIcon from '@material-ui/icons/TrendingUp';
import ContentUndo from '@material-ui/icons/Undo';
import ActionVerifiedUser from '@material-ui/icons/VerifiedUser.js';
import ActionViewList from '@material-ui/icons/ViewList';
import ActionViewModule from '@material-ui/icons/ViewModule';
import ActionVisibility from '@material-ui/icons/Visibility';
import ActionVisibilityOff from '@material-ui/icons/VisibilityOff';
import CommunicationVpnKey from '@material-ui/icons/VpnKey';
import AlertWarning from '@material-ui/icons/Warning.js';
import Filter from '@material-ui/icons/Filter.js';
import Code from '@material-ui/icons/Code.js';
import HightLight from '@material-ui/icons/Highlight.js';
import Usb from '@material-ui/icons/Usb.js';
import ExpandMore from '@material-ui/icons/ExpandMore.js';
import DoneAll from '@material-ui/icons/DoneAll.js';
import Sort from '@material-ui/icons/Sort.js'
import React from 'react';
import AudioFile from "../../../../../icons/icon_audioFile.svg";

// return <SvgIcon  {...this.props} ><SignKey/></SvgIcon>
// return (<SvgIcon  {...this.props} ><AudioFile/></SvgIcon>);

import CalcFile from "../../../../../icons/icon_calcFile.svg";
import CodeFile from "../../../../../icons/icon_codeFile.svg";
import Cut from "../../../../../icons/icon_cut.svg";
import FileCabinet from "../../../../../icons/icon_fileCabinet.svg";
import FilePath from "../../../../../icons/icon_filePath.svg";
import Fileset from "../../../../../icons/icon_fileset_closed.svg";
import FilesetOpen from "../../../../../icons/icon_fileset_open.svg";
import Folder from "../../../../../icons/icon_folder_open.svg";
import HtmlFile from "../../../../../icons/icon_htmlFile.svg";
import InProgress1 from "../../../../../icons/icon_inProgress1.svg";
import InProgress2 from "../../../../../icons/icon_inProgress2.svg";
import Link from "../../../../../icons/icon_link.svg";
import Links from "../../../../../icons/icon_links.svg";
import Paste from "../../../../../icons/icon_paste.svg";
import PdfFile from "../../../../../icons/icon_pdfFile.svg";
import PhotoFile from "../../../../../icons/icon_photoFile.svg";
import PresentationFile from "../../../../../icons/icon_presentationFile.svg";
import QuorumKey from "../../../../../icons/icon_quorumKey.svg";
import QuorumMemberKey from "../../../../../icons/icon_quorumMemberKey.svg";
import Rename from "../../../../../icons/icon_rename.svg";
import Filesets from "../../../../../icons/icon_safes.svg";
import ImgTileSafeOpen from "../../../../../icons/icon_safe_open.svg";
import Separator from "../../../../../icons/icon_separator.svg";
import SignKey from "../../../../../icons/icon_signKey.svg";
import TextFile from "../../../../../icons/icon_textFile.svg";
import VideoFile from "../../../../../icons/icon_videoFile.svg";
import WordFile from "../../../../../icons/icon_wordFile.svg";
import ZipFile from "../../../../../icons/icon_zipFile.svg";
import Move from "../../../../../icons/icon_move.svg";
import Checked from "../../../../../icons/icon_checked.svg";
import avatarSmallTile from "../../../styles/img/lybero-avatar-small.png";
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Backspace from '@material-ui/icons/Backspace';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Account from '@material-ui/icons/AccountBox';
import People from '@material-ui/icons/People';
import Renew from '@material-ui/icons/Autorenew';
import Tune from '@material-ui/icons/Tune';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Circle from '@material-ui/icons/FiberManualRecord';
import VpnKey from '@material-ui/icons/VpnKey';
import Fullscreen from '@material-ui/icons/Fullscreen';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';

const cyan500 = cyan['500'];
const cyan700 = cyan['700'];
const pinkA200 = pink.A200;
const grey100 = grey['100'];
const grey300 = grey['300'];
const grey400 = grey['400'];
const grey500 = grey['500'];
const white = common.white;
const darkBlack = common.darkBlack;
const fullBlack = common.fullBlack;


class IconManagerClass {
	constructor() { }

	// eslint-disable-next-line complexity
	getIconByName(name, color = undefined, size = undefined) {
		switch (name) {
			case 'add': return (<ContentAdd color={color} />);
			case 'addPhoto': return (<AddAPhoto color={color} />);
			case 'addFile': return (<ActionNoteAdd color={color} />);
			case 'addGroup': return (<SocialGroupAdd color={color} />);
			//case 'addUser': return (<SocialPersonAdd color={color} />);
			case 'all': return (<ActionDoneAll color={color} />);
			case 'anonymous': return (<SocialPersonOutline color={color} />);
			case 'app': return (<NavigationApps color={color} />);
			case 'assignment': return (<ActionAssignment color={color} />);
			case 'alert': return (<AlertWarning color={color} />);
			case 'back': return (<ContentUndo color={color} />);
			case 'build': return (<ActionBuild color={color} />);
			case 'bookmark': return (<ActionBookmark color={color} />);
			case 'cafe': return (<LocalCafeIcon color={color} />);
			case 'cancel': return (<NavigationCancel color={color} />);
			case 'chart': return (<EditorShowChart color={color} />);
			case 'chat': return (<CommunicationChat color={color} />);
			case 'checkCircle': return (<ActionCheckCircle color={color} />);
			case 'circle': return (<Circle color={color} />);
			case 'clear': return (<ContentClear color={color} />);
			case 'close': return (<NavigationClose color={color} />);
			case 'code': return (<Code color={color} />);
			case 'copy': return (<ContentCopy color={color} />);
			case 'cut': return (<SvgIcon  {...this.props} ><Cut/></SvgIcon>);
			case 'dashboard': return (<DashboardIcon color={color} />);
			case 'date': return (<ActionEvent color={color} />);
			case 'delete': return (<ActionDeleteForever color={color} />);
			case 'description': return (<ActionDescription color={color} />);
			case 'devices': return (<Devices color={color} />);
			case 'done': return (<ActionDone color={color} />);
			case 'down': return (<HardwareKeyboardArrowDown color={color} />);
			case 'downFull': return (<NavigationArrowDropDown color={color} />);
			case 'download': return (<FileCloudDownload color={color} />);
			case 'edit': return (<EditorModeEdit color={color} />);
			case 'email': return (<CommunicationEmail color={color} />);
			case 'enter': return (<ContentRedo color={color} />);
			case 'error': return (<Error color={color} />);
			case 'eye': return (<ImageEye color={color} />);
			case 'fastForward': return (<AvFastForward color={color} />);
			case 'fastRewind': return (<AvFastRewind color={color} />);
			case 'file': return (<File color={color} />);


			case 'fileset': return (<SvgIcon {...this.props} ><Fileset /></SvgIcon>);
			case 'filesetOpen': return (<SvgIcon  {...this.props} ><FilesetOpen/></SvgIcon>);
			case 'filesets': return (<SvgIcon  {...this.props} ><Filesets/></SvgIcon>);
			case 'filter': return (<Filter color={color} />);
			case 'folder': return (<SvgIcon  {...this.props} ><Folder/></SvgIcon>);
			case 'folderOpen': return (<SvgIcon  {...this.props} ><FolderOpen/></SvgIcon>);
			case 'fullScreen': return (<Fullscreen color={color} />);
			case 'group': return (<SocialGroup color={color} />);
			case 'help': return (<ActionHelp color={color} />);
			case 'helpOutline': return (<ActionHelpOutline color={color} />);
			case 'hightlight': return (<HightLight color={color} />);
			case 'home': return (<ActionHome color={color} />);
			case 'info': return (<ActionInfo color={color} />);
			case 'invertColors': return (<ActionInvertColors color={color} />);
			case 'invitations': return (<HowToVote color={color} />);
			case 'inProgress1': return (<SvgIcon  {...this.props} ><InProgress1/></SvgIcon>);
			case 'inProgress2': return (<SvgIcon  {...this.props} ><InProgress2/></SvgIcon>);
			case 'key': return (<CommunicationVpnKey color={color} />);
			case 'left': return (<NavigationChevronLeft color={color} />);
			case 'link': return (<SvgIcon  {...this.props} ><Link/></SvgIcon>);
			case 'imgTileSafeOpen': return (<SvgIcon  {...this.props} ><ImgTileSafeOpen/></SvgIcon>);
			case 'links': return (<SvgIcon  {...this.props} ><Links/></SvgIcon>);
			case 'list': return (<ActionViewList color={color} />);
			case 'lock': return (<ActionLock color={color} width={size} height={size} />);
			case 'lockOpen': return (<ActionLockOpen color={color} />);
			case 'lockOutline': return (<ActionLockOutline color={color} />);
			case 'logs': return (<ActionList color={color} />);
			case 'menu': return (<IconMenu color={color} />);
			case 'more': return (<MoreVertIcon color={color} />);
			case 'navRight': return (<NavigationChevronRight color={color} />);
			case 'new': return (<AvNewReleases color={color} />);
			case 'newFileset': return (<FileCreateNewFolder color={color} />);
			case 'newFile': return (<UploadNewFile color={color} />);
			case 'notif': return (<SocialNotifications color={color} />);
			case 'notime': return (<ImageTimerOff color={color} />);
			case 'paste': return (<SvgIcon  {...this.props} ><Paste/></SvgIcon>);
			case 'pin': return (<CommunicationLocationOn color={color} />);
			case 'pinOff': return (<CommunicationLocationOff color={color} />);
			case 'picture': return (<LocalSee color={color} />);
			case 'quorumGroup': return (<ActionGroupWork color={color} />);
			case 'quorumMemberKey': return (<SvgIcon  {...this.props} ><QuorumMemberKey/></SvgIcon>);
			case 'quorumKey': return (<SvgIcon  {...this.props} ><QuorumKey/></SvgIcon>);
			case 'reload': return (<ActionAutorenew color={color} />);
			case 'rename': return (<SvgIcon  {...this.props} ><Rename/></SvgIcon>);
			case 'revoke': return (<NotInterested color={color} />);
			case 'right': return (<ArrowDropRight color={color} />);
			case 'rights': return (<ActionPanTool color={color} />);
			case 'save': return (<ContentSave color={color} />);
			case 'search': return (<ActionSearch color={color} />);
			case 'see': return (<ActionVisibility color={color} />);
			case 'seeOff': return (<ActionVisibilityOff color={color} />);
			case 'send': return (<ContentSend color={color} />);
			case 'setting': return (<ActionSettings color={color} />);
			case 'settingsInput': return (<ActionSettingsInputComponent color={color} />);
			case 'share': return (<SocialShare color={color} />);
			case 'signKey': return (<SvgIcon  {...this.props} ><SignKey/></SvgIcon>);
			case 'smartphone': return (<SmartphoneIcon color={color} />);
			case 'sort': return (<AvSort color={color} />);
			case 'sound': return (<MusicNote color={color} />);
			case 'skipNext': return (<AvSkipNext color={color} />);
			case 'skipPrevious': return (<AvSkipPrevious color={color} />);
			case 'supervisedUser': return (<SupervisedUserCircle color={color} />);
			case 'star': return (<Star color={color} />);
			case 'starEmpty': return (<StartEmpty color={color} />);
			case 'tiles': return (<ActionViewModule color={color} />);
			case 'up': return (<HardwareKeyboardArrowUp color={color} />);
			case 'upload': return (<FileCloudUpload color={color} />);
			case 'usb': return (<Usb color={color} />);
			case 'user': return (<SocialPerson color={color} />);
			case 'userCircle': return (<ActionAccountCircle color={color} />);
			case 'verified': return (<ActionVerifiedUser color={color} />);
			case 'video': return (<OndemandVideo color={color} />);
			case 'NavigationBack': return (<NavigationBack color={color} />);
			case 'NavigationForward': return (<NavigationForward color={color} />);
			case 'Separator': return (<SvgIcon  {...this.props} ><Separator/></SvgIcon>);
			case 'filePath': return (<SvgIcon  {...this.props} ><FilePath/></SvgIcon>);
			case 'fileCabinet': return (<SvgIcon  {...this.props} ><FileCabinet/></SvgIcon>);
			case 'move': return (<SvgIcon  {...this.props} ><Move/></SvgIcon>);
			case 'contactMail': return (<ContactMail style={{ color: color }} />);
			case 'clearField': return (<Backspace color={color} width={size} height={size} />);
			case 'firstPage': return (<FirstPage style={{ color: color }} />);
			case 'lastPage': return (<LastPage style={{ color: color }} />);
			case 'previousPage': return (<KeyboardArrowLeftIcon style={{ color: color }} />);
			case 'nextPage': return (<KeyboardArrowRightIcon style={{ color: color }} />);
			case 'checked': return (<SvgIcon  {...this.props} ><Checked/></SvgIcon>);
			case 'expand': return (<ExpandMore style={{ color: color }} />);
			case 'multiSelect': return (<DoneAll style={{ color: color }} />);
			case 'sorting': return (<Sort style={{ color: color }} />);
			case 'sortedDesc': return (<ArrowDownwardIcon style={{ color: color }} />);
			case 'sortedAsc': return (<ArrowUpwardIcon style={{ color: color }} />);
			case 'check': return (<CheckCircleIcon style={{ color: color }} />);
			case 'uncheckedCheckbox': return (<CheckBoxOutlineBlankIcon style={{ color: color }} />);
			case 'checkedCheckbox': return (<CheckBoxIcon style={{ color: color }} />);
			case 'account': return (<Account style={{ color: color }} />);
			case 'friends': return (<People style={{ color: color }} />);
			case 'renew': return (<Renew style={{ color: color }} />);
			case 'tune': return (<Tune style={{ color: color }} />);
			case 'addUser': return (<PersonAddIcon style={{ color: color }} />)




			// --- For files ---
			case 'pdfFile': return (<SvgIcon  {...this.props} ><PdfFile/></SvgIcon>);
			case 'calcFile': return (<SvgIcon  {...this.props} ><CalcFile/></SvgIcon>);
			case 'presentationFile': return (<SvgIcon  {...this.props} ><PresentationFile/></SvgIcon>);
			case 'zipFile': return (<SvgIcon  {...this.props} ><ZipFile/></SvgIcon>);
			case 'videoFile': return (<SvgIcon  {...this.props} ><VideoFile/></SvgIcon>);
			case 'audioFile': return (<SvgIcon  {...this.props} ><AudioFile/></SvgIcon>);
			case 'textFile': return (<SvgIcon  {...this.props} ><TextFile/></SvgIcon>);
			case 'CodeFile': return (<SvgIcon  {...this.props} ><CodeFile/></SvgIcon>);
			case 'htmlFile': return (<SvgIcon  {...this.props} ><HtmlFile/></SvgIcon>);
			case 'photoFile': return (<SvgIcon  {...this.props} ><PhotoFile/></SvgIcon>);
			case 'wordFile': return (<SvgIcon  {...this.props} ><WordFile/></SvgIcon>);

			default:
				return (<ActionHelpOutline color={color} />);
		}
	}

	colors(p) {

		return (
			<div className="colors" style={{ "display": "flex", "flexWrap": "wrap" }}>
				<div style={{ "width": "100%" }}><h4>Standard colors</h4></div>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": cyan500 }}>primary1Color</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": cyan700 }}>primary2Color</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": grey400 }}>primary3Color</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": pinkA200 }}>accent1Color</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": grey100 }}>accent2Color</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": grey500 }}>accent3Color</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": darkBlack }}>textColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": fade(darkBlack, 0.54) }}>secondaryTextColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": white }}>alternateTextColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": white }}>canvasColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": grey300 }}>borderColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": fade(darkBlack, 0.3) }}>disabledColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": cyan500 }}>pickerHeaderColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": fade(darkBlack, 0.07) }}>clockCircleColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": fullBlack }}>shadowColor</Paper>
				<div style={{ "width": "100%" }}><h4>Themes colors</h4></div>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.primary1Color }}>primary1Color</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.primary2Color }}>primary2Color</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.primary3Color }}>primary3Color</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.accent1Color }}>accent1Color</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.accent2Color }}>accent2Color</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.accent3Color }}>accent3Color</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.textColor }}>textColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.secondaryTextColor }}>secondaryTextColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.alternateTextColor }}>alternateTextColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.canvasColor }}>canvasColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.borderColor }}>borderColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.disabledColor }}>disabledColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.pickerHeaderColor }}>pickerHeaderColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.clockCircleColor }}>clockCircleColor</Paper>
				<Paper style={{ "width": "200px", "height": "80px", "backgroundColor": p.shadowColor }}>shadowColor</Paper>


			</div>

		);
	}

	getAppIcon(maxHeight, maxWidth, logo) {

		return (<img src={logo} style={{ maxWidth: maxWidth, maxHeight: maxHeight }} />);

		//		return (<img src={path} height={size} width={size} />);
	}

	getAppIconUrlSmall() {
		return (avatarSmallTile);
	}


	getDirectoryImage() {
		return (<SvgIcon  {...this.props} ><Folder/></SvgIcon>);
	}

	/**
	* Return the image link corresponding to the mime type
	*
	*/
	getfileImage(type, color = undefined, size = undefined) {
		if (!type) return (<File />);

		// ---- complete mime type --------
		switch (true) {
			case /application\/pdf.*/.test(type): return (<SvgIcon  {...this.props} ><PdfFile/></SvgIcon>);
			case /application\/.*excel.*/.test(type): return (<SvgIcon  {...this.props} ><CalcFile/></SvgIcon>);
			case /application\/.*spreadsheet.*/.test(type): return (<SvgIcon  {...this.props} ><CalcFile/></SvgIcon>);
			case /application\/.*\.text.*/.test(type): return (<SvgIcon  {...this.props} ><TextFile/></SvgIcon>);
			case /application\/.*word.*/.test(type): return (<SvgIcon  {...this.props} ><WordFile/></SvgIcon>);
			case /application\/.*image.*/.test(type): return (<SvgIcon  {...this.props} ><PhotoFile/></SvgIcon>);
			case /application\/.*presentation.*/.test(type): return (<SvgIcon  {...this.props} ><PresentationFile/></SvgIcon>);
			case /application\/.*powerpoint.*/.test(type): return (<SvgIcon  {...this.props} ><PresentationFile/></SvgIcon>);
			case /application\/.*zip.*/.test(type): return (<SvgIcon  {...this.props} ><ZipFile/></SvgIcon>);
			case /application\/.*rar.*/.test(type): return (<SvgIcon  {...this.props} ><ZipFile/></SvgIcon>);
			case /application\/.*program.*/.test(type): return (<File />);
			default:
		}

		let a = type.split('/');
		switch (a[0]) {
			case "image": return (<LocalSee color={color} width={size} height={size} />);
			case "video": return (<OndemandVideo color={color} width={size} height={size} />);
			case "audio": return (<MusicNote color={color} width={size} height={size} />);
			case "text": return (<SvgIcon  {...this.props} ><TextFile/></SvgIcon>);
			default: "";
		}

		return (<SvgIcon  {...this.props} ><CodeFile/></SvgIcon>);

	}

	/**
	* Set a background color depending on the name
	*/
	getColorFromLetter(name) {
		if (!name) return false;
		let c = 0;
		for (let i = 0; i < name.length; i++) {
			c = (c * name.charCodeAt(i) + name.charCodeAt(i)) % 2500 + 200;
		}
		return ("#" + c.toString(16));
	}

}
export var IconManager = new IconManagerClass();
