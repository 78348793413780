// ----------------------------------------------------------------------
// ------------------- Variables for langage ----------------------------
// ----------------------------------------------------------------------
import {
    TIMEFORMAT
} from './Constants.js';
import {
    inter as inter_en,
    errors as errors_en,
    notifications as notifications_en,
    mail as mail_en,
    sms as sms_en,
} from "./langs/en";
import {
    inter as inter_fr,
    errors as errors_fr,
    notifications as notifications_fr,
    mail as mail_fr,
    sms as sms_fr
} from "./langs/fr"
import {
    inter as inter_de,
    errors as errors_de,
    notifications as notifications_de,
    mail as mail_de,
    sms as sms_de
} from "./langs/de";

//var Globalize = require("globalize");
let Globalize = undefined;
export let availableLangs = ['en', 'fr', 'de'];
export let lang = 'en';

export function initLanguage() {
    Globalize = require("globalize");
    Globalize.load(
        require("cldr-data/main/en/ca-gregorian"),
        require("cldr-data/main/en/currencies"),
        require("cldr-data/main/en/dateFields"),
        require("cldr-data/main/en/numbers"),
        require("cldr-data/main/en/units"),
        require("cldr-data/main/fr/ca-gregorian"),
        require("cldr-data/main/fr/currencies"),
        require("cldr-data/main/fr/dateFields"),
        require("cldr-data/main/fr/numbers"),
        require("cldr-data/main/fr/units"),
        require("cldr-data/main/de/ca-gregorian"),
        require("cldr-data/main/de/currencies"),
        require("cldr-data/main/de/dateFields"),
        require("cldr-data/main/de/numbers"),
        require("cldr-data/main/de/units"),
        require("cldr-data/supplemental/currencyData"),
        require("cldr-data/supplemental/likelySubtags"),
        require("cldr-data/supplemental/plurals"),
        require("cldr-data/supplemental/timeData"),
        require("cldr-data/supplemental/weekData")
    );

    Globalize.loadMessages(inter_en);
    Globalize.loadMessages(errors_en);
    Globalize.loadMessages(notifications_en);
    Globalize.loadMessages(mail_en);
    Globalize.loadMessages(sms_en);
    Globalize.loadMessages(inter_fr);
    Globalize.loadMessages(errors_fr);
    Globalize.loadMessages(notifications_fr);
    Globalize.loadMessages(mail_fr);
    Globalize.loadMessages(sms_fr);
    Globalize.loadMessages(inter_de);
    Globalize.loadMessages(errors_de);
    Globalize.loadMessages(notifications_de);
    Globalize.loadMessages(mail_de);
    Globalize.loadMessages(sms_de);
    setLocalLang('en');

}

export var dateFormatter = (timeStamp) => {
    return timeStamp;
};


export function setLocalLang(l) {
    lang = l ? l : 'en';
    lang = lang.replace(/-.*$/, '');
    if (availableLangs.indexOf(lang) === -1) lang = 'en';
    if (Globalize) {
        Globalize.locale(lang);
        dateFormatter = Globalize.dateFormatter({
            datetime: "medium"
        });
    }
}

export function translate(text, obj = {}) {
    let a = text;
    if (!Globalize) return a;

    try {
        a = Globalize.messageFormatter(text)(obj);
    } catch (e) {
        //console.error(e);
        //console.warn("missing translation (" + lang + ") for " + a)
    }
    return a;
}


export function timeStampToDateMs(t, format = TIMEFORMAT.EXPIRE_DELAY) {

    if ((!t) || (t === 0) || (t === "0")) return translate("Never");

    let d = new Date(t);
    // ---------- No format, return the object ----------
    if (!format) return d;

    // ---------- Return a string with the date - yyyy-MM-ddThh:mm:SS:sss ---------
    if (format === TIMEFORMAT.DATE_ISO) {
        let month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    // ---------- Return a string with the date ---------
    if (format === TIMEFORMAT.DATE_SHORT) return d.toLocaleDateString();

    // ---------- Return a string with the date ---------
    if (format === TIMEFORMAT.DATE_FULL) return d.toLocaleString();

    let options = {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
    };
    let region = 'en-US';
    if (lang === 'fr') {
        region = 'fr-FR'
    }
    if (lang === 'de') {
        region = 'de'
    }
    if (format === TIMEFORMAT.MONTH_DAY) return d.toLocaleString(region, options);

    // ---------- Return a string with the hour:minute ---------
    if (format === TIMEFORMAT.HOURS_MINUTES) {
        let hours = d.getHours();
        let minutes = d.getMinutes();
        let result = "";
        result += hours;
        if (minutes < 10) {
            result += ":0" + minutes
        } else {
            result += ":" + minutes;
        }
        return result;
    }

    // ---------- Return a string with the date ---------
    if (format === TIMEFORMAT.UTC) return d.toUTCString();

    let ms = Math.round(t / 1000) - Math.round(Date.now() / 1000);

    if (!Globalize) return ms;

    if ((ms >= 32140800) || (ms <= -32140800))
        return Globalize.relativeTimeFormatter("year")(Math.round(ms / 32140800));
    if ((ms >= 2678400) || (ms <= -2678400))
        return Globalize.relativeTimeFormatter("month")(Math.round(ms / 2678400));
    if ((ms >= 84400) || (ms <= -84400))
        return Globalize.relativeTimeFormatter("day")(Math.round(ms / 84400));
    if ((ms >= 3600) || (ms <= -3600))
        return Globalize.relativeTimeFormatter("hour")(Math.round(ms / 3600));
    if ((ms >= 60) || (ms <= -60))
        return Globalize.relativeTimeFormatter("minute")(Math.round(ms / 60));
    return Globalize.relativeTimeFormatter("second")(ms);
}