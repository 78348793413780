var parser = require('ua-parser-js');

import {
    TYPE,
    KEYFORMAT,
    STATUS
} from './GenericKey.js';
import {
    ShareKeys
} from './ShareKeys.js';
import {
    GenericKeyStore
} from './GenericKeyStore.js';
import {
    debug,
    error,
} from './Manager.js';


/**
 * The KeyStore for a User
 */
export class DeviceKeyStore extends GenericKeyStore {

    /**
     * constructor for a keyStore
     * 
     * @param {string} userId 
     * 
     */
    constructor(userId, parentKeyStore) {
        super(userId);
        this.parentKeyStore = parentKeyStore;
        this.name = this.getNameFromUserAgent();
    }


    async setContent(d) {
        await super.setContent(d);
        await this.deleteMemberKeyIfNoQuorumKey();
        return true;
    }

    /**
     * 
     */
    async deleteMemberKeyIfNoQuorumKey() {
        // if a quorumKey as disapear from the parent keyStore, drop the corresponding memberKey
        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            if (key.type != TYPE.quorumMember) continue;
            if (!key.quorumKeyId) continue;
            if (!key.status == STATUS.deleted ) continue;
            let qk = this.parentKeyStore.getKey(key.quorumKeyId);
            if ((! qk ) || ( qk.status == STATUS.deleted  )) {
                this.parentKeyStore.startAction("main", "delete device quorum member key");
                key.del( this );
                delete this.keys[keyId];
                this.parentKeyStore.finishAction("main", true);
            }
        }
        return true;
    }


    /**
     * Creation of an elGamal Key
     */
    async createElGamalKey() {
        let _id = await this.createKey(TYPE.elGamal)
        let key = this.getKey(_id);
        if (key) {
            key.withLocalStorage = true;
        }
        return _id;
    }


    async computeMemberSecret(quorumUserId, quorumKeyId, secretSharesList) {
        let keyPub = await this.computeMemberSecretKey(quorumUserId, quorumKeyId, secretSharesList);
        if (!keyPub) return false;
        if (keyPub instanceof ShareKeys) return keyPub;


        let key = this.getQuorumMemberKey(quorumUserId, quorumKeyId);
        if (key) {
            key.withLocalStorage = true;
        }
        return keyPub.getPublicKey(KEYFORMAT.STRING);
    }

    /**
     * Set a name of the keyStore from the user Agent
     * 
     */
    getNameFromUserAgent() {
        if (typeof (navigator) == 'undefined') return "";
        let s = parser(navigator.userAgent);
        let a = s.browser.name + "/" + s.browser.version + " on " + s.os.name;
        if (s.device.model) a += "/" + s.device.model;
        return a;
    }

    /**
     * Return true if the keyStore is currently running on this device
     * if one key as its private part available.
     */
    get onThisDevice() {
        for (let keyId in this.keys) {
            let key = this.keys[keyId];
            if ((key.withLocalStorage) && (key.privateKeyReleased)) return true;
        }
        return false;
    }

    async decryptSessionKey(shareKeys) {
        return await this.decryptSessionKeyInternal(shareKeys);
    }


    async acceptWanted(shareKeys, userId, keyUserId) {
        return await this.acceptWantedInternal(shareKeys, userId, keyUserId)
    }

}