import {
    CryptoObject,
    stringToAb,
    utf8ToArrayBuffer,
    abToString,
    toHexString
} from './Common.js';
import {
    SymCrypt
} from './SymCrypt.js';

const crypto = require('crypto');

var KeyEncoder = require('key-encoder');

/**
 *******************************************************************************
 ********************** Signature **********************************************
 *******************************************************************************
 */
export class Signature extends CryptoObject {
    constructor(errorFunc, debugFunc, algo) {
        super(errorFunc, debugFunc);
        this.generateconstants(algo);
        this.keys = undefined;

        this.privateKey = undefined;
        this.publicKey = undefined;
        this.encryptedPrivateKeyStruct = undefined;
    }

    generateconstants(algo) {
        switch (algo) {
            case 'ECDSA-256':
                this.algoString = 'ECDSA-256';
                this.windowCryptoName = "ECDSA";
                this.windowCryptoHash = "SHA-256";
                this.namedCurve = "P-256"; //can be "P-256", "P-384", or "P-521"
            case 'ECDSA-512':
            default:
                this.algoString = 'ECDSA-512';
                this.windowCryptoName = "ECDSA";
                this.windowCryptoHash = "SHA-512";
                this.namedCurve = "P-256";
                break;
        }
    }

    get publicKeyStruct() {
        if (!this.publicKey) return (this.err("lybcrypt - Signature.js - publicKeyStruct - Public key not set"));

        this.publicKey.algo = this.algoString;
        return this.publicKey;
    }

    set encryptedPrivateKey(struct) {
        this.encryptedPrivateKeyStruct = struct;
    }


    generateKeys() {
        if (this.windowCrypto) {
            return this.subtle.generateKey({
                        name: this.windowCryptoName,
                        namedCurve: this.namedCurve,
                    },
                    true, //whether the key is extractable (i.e. can be used in exportKey)
                    ["sign", "verify"] //can be any combination of "sign" and "verify"
                )
                .then((keys) => {
                    this.keys = keys;
                })
                .then(() => {
                    return this.subtle.exportKey(
                        "jwk", //can be "jwk" or "raw"
                        this.keys.publicKey //extractable must be true
                    )
                })
                .then((pubKey) => {
                    this.publicKey = pubKey;
                    //   this.publicKey = jwkToPem(pubKey, {
                    //     private: false
                    // });
                    // this.publicKeyw = pubKey;
                })
                .then(() => {
                    return this.subtle.exportKey(
                        "jwk", //can be "jwk" or "raw"
                        // "raw", //can be "jwk" or "raw"
                        this.keys.privateKey //extractable must be true
                    )
                })
                .then((privateKey) => {
                    this.privateKey = privateKey;
                    //          let keyEncoder = new KeyEncoder('secp256k1');
                    //          this.privateKey = keyEncoder.encodePrivate(privateKey, 'raw', 'pem')
                    // this.privateKey = jwkToPem(privateKey, {
                    //   private: true
                    // });
                    // 
                });
        }

        // ----------- No windowCrypto, ise internal nodejs signature
        return new Promise((resolve, reject) => {
            let s = crypto.createECDH('secp256k1');
            s.generateKeys();


            let keyEncoder = new KeyEncoder('secp256k1');
            //this.privateKey = s.getPrivateKey("hex");
            //this.publicKey = s.getPublicKey("hex");

            this.privateKey = keyEncoder.encodePrivate(s.getPrivateKey(), 'raw', 'pem')
                //this.publicKey = keyEncoder.encodePublic(s.getPublicKey(), 'raw', 'pem')
            this.publicKey = {
                    pem: keyEncoder.encodePublic(s.getPublicKey(), 'raw', 'pem'),
                    algo: this.algoString,
                }
                //console.log("s publicKey =", this.publicKey);
                //console.log("s privateKey =", this.privateKey);
            resolve(true);
        });


    }

    cryptPrivateKeyWithPassphrase(passKey) {
        let sym = new SymCrypt(this.err, this.debug);
        return sym.key.wrapKeyFromPassphrase(passKey)
            .then(() => {
                return sym.cipher(JSON.stringify(this.privateKey), "base64")
            })
            .then((encryptedPrivateKeyString) => {
                this.encryptedPrivateKeyStruct = {
                    "key": encryptedPrivateKeyString,
                    "salt": sym.key.content.salt,
                    "keylen": sym.key.content.keylen,
                    "iteration": sym.key.content.iteration,
                    "hash": sym.key.content.hash,
                }
                return this.encryptedPrivateKeyStruct;
            })
    }


    releasePrivateKey(passKey) {
        if (!this.encryptedPrivateKeyStruct) return (this.err("lybcrypt - Signature.js - releasePrivateKey - Encrypted private key not set"));
        let sym = new SymCrypt(this.err, this.debug);
        return sym.key.wrapKeyFromPassphrase(
                passKey,
                this.encryptedPrivateKeyStruct.keylen,
                this.encryptedPrivateKeyStruct.salt,
                this.encryptedPrivateKeyStruct.iteration,
                this.encryptedPrivateKeyStruct.hash,
            )
            .then(() => {
                return sym.decipher(this.encryptedPrivateKeyStruct.key, "base64", "string")
            })
            .then((privateKey) => {
                return this.importPrivateKey(JSON.parse(privateKey));
            })
    }


    async importPublicKey(p) {
        this.generateconstants(p.algo);
        if (p.pem) this.windowCrypto = false;
        if (this.windowCrypto) {
            let publicKey = await this.subtle.importKey(
                "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
                p, { //these are the algorithm options
                    name: this.windowCryptoName,
                    namedCurve: this.namedCurve,
                },
                true, //whether the key is extractable (i.e. can be used in exportKey)
                ["verify"] //"verify" for public key import, "sign" for private key imports
            );
            this.keys = {
                "publicKey": publicKey
            }
            let pubKey = await this.subtle.exportKey(
                "jwk", //can be "jwk" or "raw"
                this.keys.publicKey //extractable must be true
            );
            this.publicKey = pubKey;
            return true;
        }

        this.publicKey = p;
        return true;
    }


    importPrivateKey(p) {
        if (this.windowCrypto) {

            return this.subtle.importKey(
                    "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
                    p, { //these are the algorithm options
                        name: this.windowCryptoName,
                        namedCurve: this.namedCurve,
                    },
                    true, //whether the key is extractable (i.e. can be used in exportKey)
                    ["sign"] //"verify" for public key import, "sign" for private key imports
                )
                .then((privateKey) => {
                    this.keys = {
                        "privateKey": privateKey
                    }
                })
                .then(() => {
                    return this.subtle.exportKey(
                        "jwk", //can be "jwk" or "raw"
                        this.keys.privateKey //extractable must be true
                    )
                })
                .then((privateKey) => {
                    this.privateKey = privateKey;
                })
        } else {
            return new Promise((resolve, reject) => {
                this.privateKey = p;
                resolve(true);
            });
        }
    }



    sign(d, outputFormat = undefined) {
        let dd = (typeof d === "string") ? utf8ToArrayBuffer(d) : d;

        if (this.windowCrypto) {
            return this.subtle.importKey(
                    "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
                    this.privateKey, { //these are the algorithm options
                        name: this.windowCryptoName,
                        namedCurve: this.namedCurve,
                    },
                    true, //whether the key is extractable (i.e. can be used in exportKey)
                    ["sign"] //"verify" for public key import, "sign" for private key imports
                )
                .then(() => {
                    return this.subtle.sign({
                            name: this.windowCryptoName,
                            hash: {
                                name: this.windowCryptoHash
                            }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
                        },
                        this.keys.privateKey, //from generateKey or importKey above
                        dd //ArrayBuffer of data you want to sign
                    )
                })
                .then((signature) => {
                    let a = this.formatOutput(signature, outputFormat);
                    //console.log("wsign sig = " + a, new Uint8Array(signature));
                    return a;
                })

        } else {
            return new Promise((resolve, reject) => {
                let sign = crypto.createSign('sha256');
                sign.write(new Buffer(d));
                sign.end();

                let signature = sign.sign({
                    key: this.privateKey
                }, 'base64');
                resolve('{' + this.algoString + '}' + signature);
                let a = this.formatOutput(signature, outputFormat);
                //console.log("nsign sig = " + a, dd);
                //console.log("nsign sig = " + a, new Uint8Array(signature));
                resolve(a);
            });
        }
    }


    formatOutput(data, outputFormat = undefined) {
        switch (outputFormat) {
            case "raw":
                return {
                    "rawData": new Uint8Array(data),
                    "algo": this.algoString,
                }
            case "struct":
                return {
                    "rawData": abToString(data),
                    "algo": this.algoString
                }
            case "hex":
                return ('{' + this.algoString + '}' + toHexString(new Uint8Array(data)));
            case "base64":
                let base64String = Buffer.from(data).toString('base64');
                return ('{' + this.algoString + '}' + base64String);
            default:
                return data;
        }
    }


    verify(d, signature, inputFormat = undefined) {
        let sig = undefined;
        let algo = undefined;
        let res = undefined;
        let dd = (typeof d === "string") ? utf8ToArrayBuffer(d) : d;


        switch (inputFormat) {
            case "raw":
                sig = signature.rawData.buffer;
                break;
            case "struct":
                sig = stringToAb(signature.rawData);
                algo = signature.algo;
                break;
            case "hex":
                if (!(res = signature.match(/^\{([^\}]+)\}(.*)/)))
                    return (this.err("lybcrypt - Signature.js - verify - Invalid crypted string"));
                algo = res[1];
                sig = new Buffer(res[2], 'hex');
                break;
            case "base64":
            default:
                if (!(res = signature.match(/^\{([^\}]+)\}(.*)/)))
                    return (this.err("lybcrypt - Signature.js - verify - Invalid crypted string"));
                algo = res[1];
                sig = new Buffer(res[2], 'base64');
                break;
        }


        if (this.windowCrypto) {

            //console.log("wverify " + d + " sig = " + signature, sig);

            return this.subtle.importKey(
                    "jwk", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
                    this.publicKey, { //these are the algorithm options
                        name: this.windowCryptoName,
                        namedCurve: this.namedCurve,
                    },
                    true, //whether the key is extractable (i.e. can be used in exportKey)
                    ["verify"] //"verify" for public key import, "sign" for private key imports
                )
                .then((publicKey) => {
                    this.keys = {
                        publicKey: publicKey
                    }
                })
                .then(() => {
                    return this.subtle.exportKey(
                        "jwk", //can be "jwk" or "raw"
                        this.keys.publicKey //extractable must be true
                    )
                })
                .then((pubKey) => {
                    this.publicKey = pubKey;
                })
                .then(() => {
                    return this.subtle.verify({
                            name: this.windowCryptoName,
                            hash: {
                                name: this.windowCryptoHash
                            }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
                        },
                        this.keys.publicKey, //from generateKey or importKey above
                        sig, //ArrayBuffer of the signature
                        dd //ArrayBuffer of the data
                    )
                })
        } else {
            return new Promise((resolve, reject) => {
                // console.log("nverify " + d + " sig = " + signature, sig);
                // console.log("nverify " + d + " sig = " + signature, dd);
                let verify = crypto.createVerify('sha256');
                //  verify.update(d);
                verify.write(new Buffer(d));
                verify.end();
                let r = verify.verify({
                    key: this.publicKey.pem
                }, sig);
                resolve(r);
            });

        }
    }


}