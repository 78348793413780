import { withStyles, withTheme } from '@material-ui/core';
import { Slide, Fade, Grow, Zoom } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

var myObjectPath = require("object-path");

import { MyComponent } from '../../MyComponent.jsx';
import { IconManager } from '../ReactToolBox/IconManager.jsx';
import { configStore } from '../../../stores/ConfigStore';

const styles = () => ({
	icon: {
		'& svg': {
			fontSize: 120
		}
	},
	smallIcon: {
		'& svg': {
			fontSize: 80
		}
	},
	customIcon: {
		'& svg': {
			fontSize: 'inherit'
		}
	},
	hr: {
		width: '80%'
	},
	desc: {
		//color: theme.palette.tips.text,
	},
	sub: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%'
	},
	center: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%'
	},
	left: {
		display: 'flex',
		width: '80%',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	}
});

class CoolText extends MyComponent {

	constructor(props) {
		super(props);

		this.getValueInData = this.getValueInData.bind(this);

		this.state = {
			"open": false,
			"message": undefined,
			"options": {},
			"title": undefined,
		};

		this.indexLink = 0;
		this.indexTrans = 0;

	}

	/**
	 *
	 * @param {string} v
	 * @return string;
	 *
	 * search the value into this.data
	 * (used in the regexp)
	 */
	getValueInData(v) {
		let c = v.replace(/{|}/g, '')
		return myObjectPath.get(this.props.data, c, 'unknown');
	}

	replace(str) {
		if (typeof (str) !== 'string') return str;
		return str.replace(/\{[^}]+\}/g, this.getValueInData)
	}


	/**
	 * Adding a link or href around the element
	 * @param {*} element
	 */
	addLink(url, onClick, component) {
		if (!url) return component;

		return /^https?:\/\//.test(url)
			? <a href={url} key={'a' + this.indexLink++}>{component}</a>
			: <Link
				to={url}
				key={'link'}
				onClick={onClick}
			>
				{component}
			</Link>

	}

	addTransition(animation, component) {
		switch (animation) {
			case 'fade':
				return (
					<Fade key={"trans" + this.indexTrans++} in={true}>
						{component}
					</Fade>
				);
			case 'grow':
				return (
					<Grow key={"trans" + this.indexTrans++} in={true}>
						{component}
					</Grow>
				);
			case 'zoom':
				return (
					<Zoom key={"trans" + this.indexTrans++} in={true}>
						{component}
					</Zoom>
				);
			case 'slideUp':
				return (
					<Slide key={"trans" + this.indexTrans++} direction="up" in={true} mountOnEnter unmountOnExit>
						{component}
					</Slide>
				);
			case 'slideDown':
				return (
					<Slide key={"trans" + this.indexTrans++} direction="down" in={true} mountOnEnter unmountOnExit>
						{component}
					</Slide>
				);
			case 'slideLeft':
				return (
					<Slide key={"trans" + this.indexTrans++} direction="left" in={true} mountOnEnter unmountOnExit>
						{component}
					</Slide>
				);
			case 'slideRight':
				return (
					<Slide key={"trans" + this.indexTrans++} direction="right" in={true} mountOnEnter unmountOnExit>
						{component}
					</Slide>
				);
			default:
				return component;
		}
	}

	renderElement(el, key, i = 0) {
		let animation = el.animation;

		// merge with the local style
		let pageStyle = this.props.style ? this.props.style : {};


		for (let k in el) {

			let onClick = el.onClick ? el.onClick : this.props.onClick;
			let style = Object.assign({}, pageStyle, myObjectPath.get(el, 'style', {}));
			switch (k) {
				case 'left':
					return (this.addLink(el.url, onClick, this.addTransition(animation,
						this.renderContent(el[k], key + i + 'sub', 'left', style)
					)))
				case 'component':
					return (this.addLink(el.url, onClick, this.addTransition(animation,
						<div key={key + i} id={el.id} style={style}>
							{el[k]}
						</div>
					)))

				case 'appLogo':
				case 'logo':
					return (this.addLink(el.url, onClick, this.addTransition(animation,
						<div key={key + i} id={el.id} className={this.props.classes.icon}
							style={style}
						>
							{IconManager.getAppIcon(80, 380, configStore.logo)}
						</div>
					)))
				case 'icon':
					return (this.addLink(el.url, onClick, this.addTransition(animation,
						<div key={key + i} id={el.id} className={this.props.classes.icon}
							style={style}
						>
							{IconManager.getIconByName(el.icon)}
						</div>
					)))
				case 'customIcon':
					return (this.addLink(el.url, onClick, this.addTransition(animation,
						<div key={key + i} id={el.id} className={this.props.classes.customIcon}
							style={style}
						>
							{IconManager.getIconByName(el.customIcon)}
						</div>
					)))
				case 'smallIcon':
					return (this.addLink(el.url, onClick, this.addTransition(animation,
						<div key={key + i} id={el.id} className={this.props.classes.smallIcon}
							style={style}
						>
							{IconManager.getIconByName(el.smallIcon)}
						</div>
					)))
				case 'hr':
					return (this.addTransition(animation,
						<hr key={key + i} id={el.id} className={this.props.classes.hr} />
					))
				case 'br':
					return (this.addLink(el.url, onClick, this.addTransition(animation,
						<p key={key + i} id={el.id} className={this.props.classes.padding}>{null}</p>
					)))
				case 'appName':
					return (this.addLink(el.url, onClick, this.addTransition(animation,
						<Typography key={'text' + i} id={el.id} className={this.props.classes.desc}
							align='center'
							variant="h6"
							color='inherit'
							style={style}
						>
							{configStore.appName}
						</Typography>
					)))
				case 'description':
				case 'desc':
				case 'txt':
				case 'text':
					return (this.addLink(el.url, onClick, this.addTransition(animation,
						<Typography key={key + i} id={el.id} className={this.props.classes.desc}
							align='center'
							variant="h6"
							color='inherit'
							style={style}
						>
							{this.replace(el[k])}
						</Typography>
					)))
				case 'h1':
				case 'h2':
				case 'h3':
				case 'h4':
				case 'body1':
				case 'body2':
				case 'subtitle1':
				case 'subtitle2':
				case 'h5':
				case 'h6':
				case 'title':
					return (this.addLink(el.url, onClick, this.addTransition(animation,
						<Typography key={key + i} id={el.id} className={this.props.classes.desc}
							style={style}
							align='center'
							color='inherit'
							variant={k}
						>
							{this.replace(el[k])}
						</Typography>
					)))
				case 'image':
				case 'img':
					return (this.addLink(el.url, onClick, this.addTransition(animation,
						<img id={el.id} key={key + i} src={el[k]} className={this.props.classes.image} style={style} />
					)))
				default:
					break;
			}
		}

		return null;
	}

	renderContent(message, key = 'comp', align = 'center', style = {}) {

		// ---- an Array of Messages -----
		if (Array.isArray(message)) {
			let arr = [];
			let i = 0;
			for (const m of message) {
				arr.push(this.renderElement(m, key, i++))
			}
			return (
				<div
					key={key}
					className={this.props.classes[align]}
					style={style}
				>
					{arr}
				</div>
			)
		}

		// ---- a String ----
		if ((typeof message === 'string')) {
			return (
				<div
					id="infoText"
					key={key}
					style={style}
					className={this.props.classes[align]} dangerouslySetInnerHTML={{ "__html": this.state.message }} />
			)
		}

		// --- a structured object ---
		if (typeof message === 'object') {

			return (
				<div
					key={key}
					className={this.props.classes[align]}
					style={style}
				>
					{this.renderElement(message, key)}
				</div>
			)

		}

		return null;
	}


	render() {
		return (
			this.renderContent(this.props.texts, 'comp', 'center', this.props.style)
		)
	}


}


CoolText.propTypes = {
	'global': PropTypes.object,
	'classes': PropTypes.object,
	'texts': PropTypes.array,
	'onClick': PropTypes.func,
	'style': PropTypes.object,
	'data': PropTypes.object,
};

export default withTheme()(withStyles(styles)(CoolText));