import {
    GenericKey,
    TYPE,
    STATUS,
    KEYUSAGE,
    KEYFORMAT,
} from './GenericKey.js';
import {
    ElGamal
} from '../ElGamal.js';
import {
    Share
} from './Share.js';
import {
    SessionKey
} from './SessionKey.js';
import {
    SymCrypt
} from '../SymCrypt.js';

import {
    debug,
    error
} from './Manager.js';

export class PassKey extends GenericKey {
    constructor(userId) {
        super(userId);
        this.name = "Generic Symetric password Key";
        this.type = TYPE.passphrase;
        this.passphrase = undefined;
        this.usages = [KEYUSAGE.decipher, KEYUSAGE.cipher];
        this.elGamal = new ElGamal(error, debug);
    }

    async getContent() {
        let d = await super.getContent();
        return d;
    }

    /**
     * Set the content of the key from a saved object
     * @param {object} d 
     */
    async setContent(d) {
        await super.setContent(d);
        return true;
    }

    async del(keyStore) {
        if (this.status == STATUS.deleted) return true;
        await super.del(keyStore);
        return true;
    }

    canDecrypt() {
        return true;
    }


    setPassphrase(p) {
        this.passphrase = p;
    }

    /**
     * Generate a keys pair
     * Can be async due to the decryption from a device for example
     * @return {boolean} true if operation OK
     */
    async generate(passphrase) {
        this.passphrase = passphrase;
        return true;
    }

    async cipherSessionKey(sessionKey) {

        if (!this.passphrase)
            return error("Cannot encrypt : no passphrase");
        if (!(sessionKey instanceof SessionKey))
            return error("No session Key to encrypt in ElGamal");
        if (!sessionKey.elGamalSessionKey)
            return error("No elGamalSessionKey in SessionKey object");


        const sym = new SymCrypt(this.err, this.log);
        await sym.key.wrapKeyFromPassphrase(this.passphrase);
        const encrypted = await sym.cipher(sessionKey.elGamalSessionKey.toString(), "base64")

        const p = {
            ...sym.key.params
        };
        p.encrypted = encrypted;
        p.clearFormat = "string";
        p.encryptedFormat = "base64";

        console.log(`encrypted `, encrypted);

        return new Share(this._id, p, sessionKey.symCrypt.key.params);
    }

    async decipherSessionKey(share) {

        if (!this.passphrase) return error("Cannot decrypt : no passphrase");
        if (!(share instanceof Share)) return error("Cannot decrypt a non share Structure");
        if ( share.isEncryptedWithPass() !== true ) return error("Cannot decrypt a non share passphrase share");

        const sym = new SymCrypt(this.err, this.log);
        await sym.key.wrapKeyFromPassphrase(
            this.passphrase,
            share.encrypted.keylen, share.encrypted.salt, share.encrypted.iteration, share.encrypted.hash);

        let sessionKey = new SessionKey();
        sessionKey.elGamalSessionKey = await sym.decipher(share.encrypted.encrypted, share.encrypted.encryptedFormat, share.encrypted.clearFormat);
        if (!sessionKey.elGamalSessionKey) return error("Unable to decrypt session key with passphrase");

        await sessionKey.symCrypt.key.wrapKeyFromPassphrase(
            sessionKey.elGamalSessionKey.toString(),
            share.params.keylen, share.params.salt, share.params.iteration, share.params.hash);
        sessionKey.elGamalGroup = this.elGamal.group;

        return sessionKey;
    }

}