//const fs = require('fs');
const os = require('os');

export const ASK = {
    "NEWPASSPHRASE": "NEWPASSPHRASE",
    "ACCEPTKEYCHOICE": "ACCEPTKEYCHOICE",
    "CRYPTKEYCHOICE": "CRYPTKEYCHOICE",
    "SIGNKEYCHOICE": "SIGNKEYCHOICE",
    "CONFIRM": "CONFIRM",
    "QUORUMDACCEPTWANTED": "QUORUMDACCEPTWANTED",
    "QUORUMMEMBERDECRYPT": "QUORUMMEMBERDECRYPT",
    "ACCEPTFORUNTRUST": "ACCEPTFORUNTRUST",
    "CRYPTFORUNTRUST": "CRYPTFORUNTRUST",
    "SAVELOCALY": "SAVELOCALY",
    "CHANGEPASSPHRASE": "CHANGEPASSPHRASE",
    "RELEASEPRIVATE": "RELEASEPRIVATE",
}


export const TRUSTLEVEL = {
    "dontcare": 0, // never ask if the public key is unknown
    "medium": 1, // ask the user in case of unknown public key
    "paranoid": 2, // only trusted public keys
};


/**
 * debug function 
 * @param {string} message 
 * @param {*} obj 
 */
export var debug = (message, obj = null) => {
    if (manager.debugFlag) {
        if (obj === null) {
            return console.log("DEBUG : " + message);
        } else {
            return console.log("DEBUG : " + message, obj);
        }
    }
    if (!manager.debugStart) return true;
    if (message.match(manager.debugStart)) {
        manager.debugFlag = true;
        if (obj === null) {
            return console.log("STARTD : " + message);
        } else {
            return console.log("STARTD : " + message, obj);
        }
    }
}

/**
 * error function
 * 
 * @param {string} message 
 * @param {*} obj 
 */
export var error = (message, obj) => {
    console.error(message, obj);
    return false;
}

/**
 * Log function
 * 
 * @param {string} type 
 * @param {string} message 
 * @param {*} obj 
 */
export var log = (_id, message) => {
    //console.log("LOG [" + _id + "]: " + message);
}

export var consoleProgressFunc = (obj) => {
    if (obj.aborted) {
        console.log("PROGRESS ABORTED(" + obj.userId + "/" + obj._id + ") " + obj.i + "/" + obj.max + ": " + obj.title + "(" + obj.currentMessage + ")")
        return;
    }
    if (obj.finished) {
        console.log("PROGRESS FINISHED(" + obj.userId + "/" + obj._id + ") " + obj.i + "/" + obj.max + ": " + obj.title + "(" + obj.currentMessage + ")")
        return;
    }
    console.log("PROGRESS (" + obj.userId + "/" + obj._id + ") " + obj.i + "/" + obj.max + ": " + obj.title + "(" + obj.currentMessage + ")")
};

/**
 * 
 * 
 * ███╗   ███╗ █████╗ ███╗   ██╗ █████╗  ██████╗ ███████╗███╗   ███╗███████╗███╗   ██╗████████╗
 * ████╗ ████║██╔══██╗████╗  ██║██╔══██╗██╔════╝ ██╔════╝████╗ ████║██╔════╝████╗  ██║╚══██╔══╝
 * ██╔████╔██║███████║██╔██╗ ██║███████║██║  ███╗█████╗  ██╔████╔██║█████╗  ██╔██╗ ██║   ██║   
 * ██║╚██╔╝██║██╔══██║██║╚██╗██║██╔══██║██║   ██║██╔══╝  ██║╚██╔╝██║██╔══╝  ██║╚██╗██║   ██║   
 * ██║ ╚═╝ ██║██║  ██║██║ ╚████║██║  ██║╚██████╔╝███████╗██║ ╚═╝ ██║███████╗██║ ╚████║   ██║   
 * ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝   
 * 
 */


class Manager {
    constructor() {
        // -------- For debugging ---------
        this.debugFlag = false;
        this.debugStart = undefined;

        this.MAXTRUSTUSERS = 3;
        this.applicationTrustLevel = TRUSTLEVEL.dontcare;

        this.MAXATTEMPTPASSPHRASE = 3;

        if ((os) && (os.tmpdir))
            this.NODEKEYSDIRECTORY = os.tmpdir();

        this.WITHPASSPHRASEMANAGER = true;
        this.PASSPHRASE_EXPIRE = 300000; // 5 min


        this.saveTempoSharesToStorage = undefined;
        this.loadTempoSharesToStorage = undefined;

        this.saveKeyStore = undefined;
        this.getPublicKeyStores = undefined;

        this.interfaceFunc = undefined;


        this.progressFunc = undefined;
    }

    setDebugFunc(func) {
        debug = func;
    }
    startDebugOn(text) {
        this.debugStart = new RegExp(text, 'img');
    }
    setDebug(b) {
        this.debugFlag = b;
    }

    /**
     * Change the error function
     * @param {function} func 
     */
    setErrorFunc(func) {
            error = func;
        }
        /**
         * Change the log function
         * @param {function} func 
         */
    setLogFunc(func) {
            log = func;
        }
        /**
         * Change the progression function
         * @param {function} func 
         */
    setProgressFunc(func) {
        this.progressFunc = func;
    }

    /**
     * Change the saveKeyStore function
     * @param {function} func 
     */
    setSaveKeyStoreFunc(func) {
        this.saveKeyStore = func;
    }

    setSaveTempoSharesToStorage(func) {
        this.saveTempoSharesToStorage = func;
    }
    setLoadTempoSharesToStorage(func) {
        this.loadTempoSharesToStorage = func;
    }

    setGetPublicKeyStores(func) {
        this.getPublicKeyStores = func;
    }

    setInterface(func) {
        this.interfaceFunc = func;
    }

    setApplicationTrustLevel(t) {
        if (t < 0) return error("Trust Level doesn't exist")
        if (t >= Object.keys(TRUSTLEVEL).length) return ("Trust Level doesn't exist")
        this.applicationTrustLevel = t;
        return true;
    }

    getApplicationTrustLevel() {
        return this.applicationTrustLevel;
    }

    setTrustPathLength(d) {
        this.MAXTRUSTUSERS = d;
    }
    getTrustPathLength() {
        return this.MAXTRUSTUSERS;
    }

    setMaxAttempt(d) {
        if (!Number.isInteger(d)) return error("maxAttempt is not an integer")
        this.MAXATTEMPTPASSPHRASE = d;
    }
    getMaxAttempt() {
        return this.MAXATTEMPTPASSPHRASE;
    }

    setPassExpire(d) {
        if (!Number.isInteger(d)) return error("passExpire is not an integer")
        this.PASSPHRASE_EXPIRE = d;
    }
    getPassExpire() {
        return this.PASSPHRASE_EXPIRE;
    }

    setWithPassphraseManager(t) {
        this.WITHPASSPHRASEMANAGER = t ? true : false;
    }
}


export var manager = new Manager();






/**
 * 
 *  █████╗ ███████╗██╗  ██╗    ██╗   ██╗███████╗███████╗██████╗ 
 * ██╔══██╗██╔════╝██║ ██╔╝    ██║   ██║██╔════╝██╔════╝██╔══██╗
 * ███████║███████╗█████╔╝     ██║   ██║███████╗█████╗  ██████╔╝
 * ██╔══██║╚════██║██╔═██╗     ██║   ██║╚════██║██╔══╝  ██╔══██╗
 * ██║  ██║███████║██║  ██╗    ╚██████╔╝███████║███████╗██║  ██║
 * ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝     ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝
 *                                                              
 */



/**
 * ---------------------------------------------------------------------------------- 
 * The function to ask the passphrase to the user
 * This function is with automatic answer 
 * ----------------------------------------------------------------------------------
 * 
 * @param {{}} keys keys object with informations
 * @param {{}} options options
 * @param {func} callBack the callBack function when got all of keyStores
 * @param {...} params parameters to pass to the callBack
 *
 */
export var autoInterfaceFunc = (interact) => {

    // --------- Only for test ----------
    var passphrase = "mySecret";


    let firstKeyId = undefined;
    let passAnswer = undefined;

    switch (interact.type) {
        case ASK.ACCEPTKEYCHOICE:
            return new Promise((resolve, reject) => {
                console.log("+ Accept need private keys ");
                for (let keyId in interact.keys) {
                    console.log("  -" + keyId + " | " + interact.keys[keyId].name);
                }
                firstKeyId = Object.keys(interact.keys)[0];
                passAnswer = interact.currentAttempts < 1 ? passphrase + "err" : passphrase;
                console.log("  > selecting " + firstKeyId + " passphrase=" + passAnswer);
                resolve({
                    "selectedKeyIds": [firstKeyId],
                    "globalPassphrase": passAnswer,
                    "keyPassphrase": {},
                });
            })

        case ASK.CRYPTKEYCHOICE:
            return new Promise((resolve, reject) => {
                console.log("+ Decrypt need private keys ");
                for (let keyId in interact.keys) {
                    console.log("  -" + keyId + " | " + interact.keys[keyId].name);
                }
                firstKeyId = Object.keys(interact.keys)[0];
                passAnswer = interact.currentAttempts < 1 ? passphrase + "err" : passphrase;
                console.log("  > selecting " + firstKeyId + " passphrase=" + passAnswer);
                resolve({
                    "selectedKeyIds": [firstKeyId],
                    "globalPassphrase": passAnswer,
                    "keyPassphrase": {},
                });
            })

        case ASK.NEWPASSPHRASE:
            return new Promise((resolve, reject) => {
                console.log("+ Need a new passPhrase for thoses keys ");
                for (let keyId in interact.keys) {
                    console.log("  -" + keyId + " | " + interact.keys[keyId].name);
                }
                console.log("  >  passphrase=" + passphrase);
                resolve({
                    "globalPassphrase": passphrase,
                });
            })

        case ASK.RELEASEPRIVATE:
            return new Promise((resolve, reject) => {
                console.log("+ Release need private keys ");
                for (let keyId in interact.keys) {
                    console.log("  -" + keyId + " | " + interact.keys[keyId].name);
                }
                firstKeyId = Object.keys(interact.keys)[0];
                passAnswer = interact.currentAttempts < 1 ? passphrase + "err" : passphrase;
                console.log("  > selecting " + firstKeyId + " passphrase=" + passAnswer);
                resolve({
                    "selectedKeyIds": [firstKeyId],
                    "globalPassphrase": passAnswer,
                    "keyPassphrase": {},
                });
            })

        case ASK.SIGNKEYCHOICE:
            return new Promise((resolve, reject) => {
                console.log("+ Sign need private keys ");
                for (let keyId in interact.keys) {
                    console.log("  -" + keyId + " | " + interact.keys[keyId].name);
                }
                firstKeyId = Object.keys(interact.keys)[0];
                passAnswer = interact.currentAttempts < 1 ? passphrase + "err" : passphrase;
                console.log("  > selecting " + firstKeyId + " passphrase=" + passAnswer);
                resolve({
                    "selectedKeyIds": [firstKeyId],
                    "globalPassphrase": passAnswer,
                    "keyPassphrase": {},
                });
            })

        case ASK.SAVELOCALY:
            return new Promise((resolve, reject) => {
                console.log("+ Save localy need private keys ");
                for (let keyId in interact.keys) {
                    console.log("  -" + keyId + " | " + interact.keys[keyId].name);
                }
                firstKeyId = Object.keys(interact.keys)[0];
                passAnswer = interact.currentAttempts < 1 ? passphrase + "err" : passphrase;
                console.log("  > selecting " + firstKeyId + " passphrase=" + passAnswer);
                resolve({
                    "selectedKeyIds": [firstKeyId],
                    "globalPassphrase": passAnswer,
                    "keyPassphrase": {},
                });
            })


        case ASK.QUORUMMEMBERDECRYPT:
            console.log("+ Need passphrase for decryption ");
            return new Promise((resolve, reject) => {
                console.log("  > OK");
                resolve({
                    "selectedKeyIds": undefined,
                    "globalPassphrase": passphrase + "err",
                    "keyPassphrase": {},
                });
            })

        case ASK.CRYPTFORUNTRUST:
        case ASK.ACCEPTFORUNTRUST:
            console.log("+ Crypt / accept for User " + interact.userName + " keys :");
            return new Promise((resolve, reject) => {
                for (let keyId in interact.keys) {
                    console.log("  -" + keyId + " | " + interact.keys[keyId].name);
                }
                console.log("Do you want to sign " + interact.userName + "',s pubkeys :");

                for (let keyId in interact.pubKeys) {
                    console.log("  -" + keyId + " | " + interact.pubKeys[keyId].name);
                }

                console.log("  > Ok for signing");
                resolve({
                    "selectedKeyIds": Object.keys(interact.keys),
                    "signKeyIds": Object.keys(interact.pubKeys),
                });
            })


        case ASK.CHANGEPASSPHRASE:
            console.log("+ Changing passphrase for User " + interact.userName + " keys :");
            return new Promise((resolve, reject) => {
                for (let keyId in interact.keys) {
                    console.log("  -" + keyId + " | " + interact.keys[keyId].name);
                }
                console.log("Give old passphrase and new one ");

                passAnswer = interact.currentAttempts < 1 ? passphrase + "err" : passphrase;
                let newPass = interact.defaultNewGlobalPassphrase ? interact.defaultNewGlobalPassphrase : passphrase;
                console.log("  > old = " + passAnswer + " -> " + newPass);
                resolve({
                    "selectedKeyIds": Object.keys(interact.keys),
                    "globalPassphrase": passAnswer,
                    "newPassphrase": newPass,
                    "keyPassphrase": {},
                });
            })



        default:
            console.error("unknown ASK " + interact.type, interact);
            return new Promise((resolve, reject) => {
                reject(false);
            });
    }

    return true;

}







export var htmlInterfaceFunc = (interact) => {

    // ----- Not in an html context ----
    if (!document) return false;

    // ----- Clear the div -------------
    let div = document.getElementById('keyStoreInterface');
    if (!div) {
        div = document.createElement('div');
        div.setAttribute("id", "keyStoreInterface");
        div.position = "absolute";
        div.style.position = "absolute";
        div.style.background = "whitesmoke";
        div.style.zIndex = 3000;
        div.style.minWidth = "500px";
        div.top = "20px";
        div.left = "20px";
        div.style.top = "20px";
        div.style.left = "20px";
        div.style.padding = "20px";
        div.style.border = "2px solid";

        document.body.insertBefore(div, document.body.firstChild);
        //document.body.appendChild(div);
    }
    let html = '<form id="keyStoreForm"><div><h1>Lybcrypt</h1><div>';
    div.style.visibility = "visible";

    console.log("interact", interact);

    let clearDiv = () => {
        div.innerHTML = "";
        div.style.visibility = "hidden";
    }

    let title = (text, user, device, attempt) => {
        html = '<div id="title">';
        html += '<div id="subtitle"><span>' + user + '(' + device + ')</span><span>attempt ' + (attempt + 1) + '/3</span>';
        html += '<h2>' + text + '</h2>';
        html += '</div>';
        return (html);
    }

    let inputKey = (key, selector = "selectedKeyIds") => {
        console.log(" key ", key);
        html = '<div id="key"><input type="checkbox" name="' + selector + '" value="' + key._id + '" checked>' + key.name;
        if (key.badPassPhrase) html += '<span id="badPassphrase">Bad passphrase</span>'
        html += '</div>'
        return (html);
    }

    return new Promise((resolve, reject) => {

        let onCancel = () => {
            console.log("cancel");
            clearDiv();
            return reject(false);
        };

        let getForm = () => {
            return new FormData(document.getElementById('keyStoreForm'));
        }
        let onGo = () => {
            let f = getForm();
            clearDiv();
            let answer = {
                "selectedKeyIds": f.getAll("selectedKeyIds"),
                "signKeyIds": f.getAll("signKeyIds"),
                "globalPassphrase": f.get("globalPassphrase"),
                "newPassphrase": f.get("newPassphrase"),
                "keyPassphrase": {},
            }
            console.log("answer = ", answer);
            return resolve(answer);
        };

        // ---- setup the title --------
        switch (interact.type) {
            case ASK.RELEASEPRIVATE:
                html += title("Need private keys to relase it", interact.userName, interact.deviceName, interact.currentAttempts)
                break;
            case ASK.SIGNKEYCHOICE:
                html += title("Need private keys for signing", interact.userName, interact.deviceName, interact.currentAttempts)
                break;
            case ASK.ACCEPTKEYCHOICE:
                html += title("Need private keys for accepting", interact.userName, interact.deviceName, interact.currentAttempts)
                break;
            case ASK.CRYPTKEYCHOICE:
                html += title("Need private keys for deciphering", interact.userName, interact.deviceName, interact.currentAttempts)
                break;
            case ASK.SAVELOCALY:
                html += title("Need private keys for saving localy", interact.userName, interact.deviceName, interact.currentAttempts)
                break;
            case ASK.QUORUMMEMBERDECRYPT:
                html += title("Need quorum acceptation  for deciphering", interact.userName, interact.deviceName, interact.currentAttempts);
                break;

            case ASK.CRYPTFORUNTRUST:
                html += title("Accept to crypt for " + interact.otherName, interact.userName, interact.deviceName, interact.currentAttempts);
                break;
            case ASK.ACCEPTFORUNTRUST:
                html += title("Accept untrusted wanted from " + interact.otherName, interact.userName, interact.deviceName, interact.currentAttempts);
                break;
            case ASK.CHANGEPASSPHRASE:
                html += title("Change the passphrase", interact.userName, interact.deviceName, interact.currentAttempts);
                break;
        }



        switch (interact.type) {

            case ASK.RELEASEPRIVATE:
            case ASK.SIGNKEYCHOICE:
            case ASK.CRYPTKEYCHOICE:
            case ASK.ACCEPTKEYCHOICE:
            case ASK.SAVELOCALY:
                html += '<div id="keySelect">';
                for (let keyId in interact.keys) {
                    html += inputKey(interact.keys[keyId]);
                }
                html += '</div>';
                html += '<div id="passPhrase">Passphrase : <input type="password" name="globalPassphrase"></div>'
                break;
            case ASK.QUORUMMEMBERDECRYPT:
                html += '<div id="passPhrase">Temporary passphrase : <input type="password" name="globalPassphrase"></div>'
                break;
            case ASK.NEWPASSPHRASE:
                html += '<div id="keySelect">';
                for (let keyId in interact.keys) {
                    html += inputKey(interact.keys[keyId]);
                }
                html += '</div>';
                html += '<div id="passPhrase">Protect passphrase : <input type="password" name="globalPassphrase"></div>'
                break;

            case ASK.CRYPTFORUNTRUST:
            case ASK.ACCEPTFORUNTRUST:
                html += '<div id="keySelect">';
                for (let keyId in interact.keys) {
                    html += inputKey(interact.keys[keyId]);
                }
                html += '</div>';
                html += "<h2>Sign " + interact.otherName + "'s public key </h2>";
                html += '<div id="keySelect">';
                for (let keyId in interact.pubKeys) {
                    html += inputKey(interact.pubKeys[keyId], "signKeyIds");
                }
                html += '</div>';
                break;
            case ASK.CHANGEPASSPHRASE:
                html += '<div id="keySelect">';
                for (let keyId in interact.keys) {
                    html += inputKey(interact.keys[keyId]);
                }
                html += '</div>';
                html += '<div id="passPhrase">Current passphrase : <input type="password" name="globalPassphrase"></div>'
                html += '<div id="passPhrase">New passphrase : <input type="password" name="newPassphrase"></div>'
                break;

            default:

                console.error("unknown ASK " + interact.type, interact);
                return reject(false);
        }

        html += '<div id="buttons"><button id="keyStoreFormCancel" type="button">Cancel</button>';
        html += '<button id="keyStoreFormGo" type="button">Go</button></div>';
        html += "</div></div></form>"
        div.innerHTML = html;
        document.getElementById("keyStoreFormCancel").addEventListener("click", onCancel);
        document.getElementById("keyStoreFormGo").addEventListener("click", onGo);
        document.getElementById("keyStoreForm").addEventListener("submit", onGo);

    });
}